<div *ngIf="sourceId === AddOnSource.PowerBI" class="mt-3 mb-6 flex flex-wrap">
  <h5>{{ 'catalog.form.fields.permissions.rls.title' | translate }}</h5>

  <div class="flex w-full mt-2">
    <!-- <p-dropdown
        [options]="roleTypes"
        [(ngModel)]="selectedRoleType"
        class="role-type-dropdown"
        optionLabel="value"
        [disabled]="isDisabled"
        required
        [placeholder]="'addons.settings.tabs.security.table.dialog.types.label' | translate" /> -->

    <p-multiSelect

                   [(ngModel)]="selectedDynamicRoles"
                   (onChange)="onDynamicRoleChange($event)"
                   (onClear)="onDynamicRoleClear()"
                   [options]="this.roles"
                   [disabled]="false"
                   [placeholder]="'catalog.form.fields.permissions.rls.role_selection' | translate"
                   showClear
                   optionLabel="roleName"
                   class="dynamic-roles" />

  </div>
</div>

<div class="flex justify-content-start mt-3">
  <h5>{{ 'catalog.tab.permissions.title' | translate }}</h5>
</div>

<div class="field col-12 p-0 mt-2">
  <div class="flex justify-content-start">
    <div class="flex align-items-center">
      <user-dropdown
                     [user]="selectedUser"
                     [placeholder]="'components.user-group-dropdown.placeholder'"
                     (selectedUser)="setUser($event)"
                     [isDisabled]="isDisabled"></user-dropdown>

      <p-multiSelect
                     *ngIf="(sourceId === AddOnSource.PowerBI)"
                     [(ngModel)]="selectedStaticRoles"
                     [disabled]="isDisabled"
                     [options]="this.roles"
                     [placeholder]="'catalog.form.fields.permissions.rls.role_selection' | translate"
                     optionLabel="roleName"
                     class="ml-3" />
    </div>

    <div class="col-1">
      <button
              pButton
              pRipple
              icon="pi pi-plus"
              class="p-button-rounded p-button-outlined ml-2"
              (click)="addPermission()"
              [disabled]='!selectedUser.name || isDisabled'>
      </button>
    </div>
  </div>

  <div class="grid grid-nogutter mt-4">
    <div class="field col-12">
      <p-table
               #dt
               [value]="users"
               [columns]="columnDefinitions"
               responsiveLayout="scroll"
               [rows]="rows"
               [globalFilterFields]="columnDefinitions"
               [paginator]="true"
               [totalRecords]="users.length"
               [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]"
               [showCurrentPageReport]="true"
               currentPageReportTemplate="{{ 'catalog.table.page_report' | translate }} " [(selection)]="selectedUsers"
               selectionMode="multiple"
               [loading]="loading"
               (onLazyLoad)="onPageChange($event)"
               [rowHover]="true"
               (onPage)="onPageChange($event)" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>
              {{ 'catalog.table.colum_header.name' | translate }}
            </th>
            <th>
              {{ 'catalog.table.colum_header.type' | translate }}
            </th>
            <th>
              {{ 'catalog.table.colum_header.mail' | translate }}
            </th>
            <th *ngIf="sourceId === AddOnSource.PowerBI">
              {{ 'catalog.table.colum_header.rls' | translate }}
            </th>
            <th>
              {{ 'catalog.table.colum_header.actions' | translate }}
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td>
              <p-tableCheckbox [value]="user"></p-tableCheckbox>
            </td>
            <td style="width: 30%; min-width: 15rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.name' | translate }}</span>
              {{ user.name }}
            </td>
            <td style="width: 5%; min-width: 8rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.type' | translate }}</span>
              {{ !user.groupType ? 'User' : 'Group' }}
            </td>
            <td style="width: 35%; min-width: 20rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.mail' | translate }}</span>
              {{ user.mail }}
            </td>
            <td style="width: 20%; min-width: 6rem" *ngIf="sourceId === AddOnSource.PowerBI">
              <span class="p-column-title">{{ 'catalog.table.colum_header.rls' | translate }}</span>
              <p-tag
                     *ngFor="let role of user.staticRLS"
                     class="mr-2 role-tag"
                     [value]="role.roleName" />
            </td>
            <td>
              <div class="flex">
                <button
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-outlined p-button-danger mr-2"
                        (click)="deleteItemPermission(user)"
                        [disabled]="isDisabled">
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>