import { Component, ErrorHandler, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { DataViewModule } from "primeng/dataview";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { Table, TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { Item, ItemDynamicRole, ItemPermission } from "../../../api/item";
import { User } from "../../../api/user";
import { AutoCompleteCompleteEvent, AutoCompleteModule } from "primeng/autocomplete";
import { UserService } from "../../../service/user.service";
import { UserDropdownComponent } from "../../../components/user-dropdown/user-dropdown.component";
import { MessageService, SortEvent } from "primeng/api";
import { DropdownChangeEvent, DropdownModule } from "primeng/dropdown";
import { RoleLevelSecurity, RoleLevelSecurityEnum, RoleType } from "../../../api/rls";
import { CommonModule } from "@angular/common";
import { RoleLevelSecurityService } from "../../../service/rls.service";
import { GlobalErrorHandler } from "../../../utils/components/error-handler/error-handler.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { MultiSelectModule } from "primeng/multiselect";
import { AddOnSource } from "../../../api/addon";
import { TagModule } from "primeng/tag";

@Component({
  selector: 'app-item-permissions',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DataViewModule,
    AutoCompleteModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    TableModule,
    ToastModule,
    TranslateModule,
    UserDropdownComponent,
    DropdownModule,
    CommonModule,
    InputSwitchModule,
    MultiSelectModule,
    TagModule
  ],
  templateUrl: './item-permissions.component.html',
  styleUrl: './item-permissions.component.scss',
  providers: [
    UserService,
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    }
  ],
})
export class ItemPermissionsComponent implements OnInit {

  @Input() item: Item = {} as Item;
  @Input() sourceId!: AddOnSource;

  @Input() isDisabled = false;

  @Output() itemPermissionsChanged = new EventEmitter<ItemPermission[]>();
  @Output() dynamicRoleChanged = new EventEmitter<ItemDynamicRole>();

  filteredUsers: User[] = [];
  users: User[] = [];
  selectedUser: User = { name: '' } as User;

  itemPermission: ItemPermission = {} as ItemPermission;
  itemPermissions: ItemPermission[] = [];

  selectedUsers: User[] = [];
  duplicatedUser = false;

  columnDefinitions: string[] = ['name', 'type', 'mail', 'actions'];

  roles: RoleLevelSecurity[] = [];
  roleTypes: RoleType[] = [];
  selectedRoleType: RoleType | undefined;

  selectedDynamicRoles: RoleLevelSecurity[] = [];
  selectedStaticRoles: RoleLevelSecurity[] = [];

  RoleLevelSecurityEnum = RoleLevelSecurityEnum;
  AddOnSource = AddOnSource;

  loading: boolean = false;

  paginatorIndex: number = 0;
  rows = 10;
  page: number = 0;
  size: number = 0;
  @ViewChild('dt') table!: Table;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private userService: UserService,
    private messageService: MessageService,
    private translate: TranslateService,
    private rlsService: RoleLevelSecurityService
  ) { }

  ngOnInit() {
    this.enableRLSColumn();

    this.loadRoles();

    this.loadPermissions(0, 10);
  }

  onPageChange(event: any) {

    let total = event.first + event.rows
    this.users = []
    this.loadPermissions(event.first, total);
  }

  loadPermissions(start: number, end: number): void {
    this.loading = true;
    this.itemPermissions = this.item?.itemPermissions ?? [];

    let userIds = this.itemPermissions.slice(start, end).map(x => x.principalId);

    let usersNew: User[] = this.itemPermissions.map(itemPermission => ({
      id: itemPermission.principalId,
      name: '',
      mail: '',
      setting: { language: '', allowNotifications: true },
      groupType: undefined,
      isAdmin: false,
      staticRLS: [{ id: 1, roleName: '', installedAddonId: 0, type: 0 }],
      photo: '',
      hasSentFeedback: false,
      agreementStatus: false
    }));

    this.userService.getUserAndGroupsByIds(userIds).subscribe(userOrGroups => {
      userOrGroups.forEach(userOrGroup => {
        this.setPermissionRoles(userOrGroup);
        const index = usersNew.findIndex(u => u.id === userOrGroup.id);
        if (index !== -1) {
          Object.assign(usersNew[index], userOrGroup);
        }
      });

      this.users = usersNew;
      this.loading = false;
    });
  }

  enableRLSColumn(): void {
    if (this.sourceId === AddOnSource.PowerBI)
      this.columnDefinitions.push('rls');
  }

  setPermissionRoles(user: User): void {
    this.itemPermissions.forEach(permission => {
      if (permission.principalId === user.id) {
        const roles = permission.roles.map(x => x.role);

        user.staticRLS = roles;
      }
    });
  }

  loadRoleTypes(): void {
    this.roleTypes = [
      {
        id: RoleLevelSecurityEnum.Static,
        value: this.translate.instant('addons.settings.tabs.security.table.dialog.types.static')
      },
      {
        id: RoleLevelSecurityEnum.Dynamic,
        value: this.translate.instant('addons.settings.tabs.security.table.dialog.types.dynamic')
      }
    ];

    if (this.item.itemDynamicRoles) {
      this.selectedRoleType = this.roleTypes.find(role => role.id === RoleLevelSecurityEnum.Dynamic);

      const dynamicRoles = this.item.itemDynamicRoles.roles;

      this.selectedDynamicRoles = this.roles
        .filter(role => dynamicRoles.some(dynamicRole => dynamicRole.role.id === role.id));
    }
  }

  getRolesByType(type: RoleLevelSecurityEnum): RoleLevelSecurity[] {
    return this.roles.filter(role => role.type === type);
  }

  loadRoles(): void {
    this.rlsService.getAll().subscribe({
      next: (roles: RoleLevelSecurity[]) => {
        this.roles = roles;

        this.loadRoleTypes();
      },
      error: (e) => this.errorHandler.handleError(e)
    });
  }

  isRoleTypeDynamic(roleType?: RoleType): boolean {
    return roleType?.id === RoleLevelSecurityEnum.Dynamic;
  }

  isRoleTypeStatic(roleType?: RoleType): boolean {
    return roleType?.id === RoleLevelSecurityEnum.Static;
  }

  deleteItemPermission(user: User): boolean {
    this.users = this.users
      .filter(x => x.id !== user.id);

    this.itemPermissions = this.itemPermissions
      .filter(x => x.principalId !== user.id);

    this.itemPermissionsChanged.emit(this.itemPermissions);

    return false;
  }

  search(event: AutoCompleteCompleteEvent): void {
    this.userService.getUserAndGroups(event.query)
      .subscribe(users => this.filteredUsers = users);
  }

  onDynamicRoleChange(event: DropdownChangeEvent): void {
    const roles = event.value.map((x: any) => { return { role: x } });

    this.dynamicRoleChanged.emit({
      itemId: this.item.id as number,
      roles: roles
    });
  }

  onDynamicRoleClear(): void {
    this.dynamicRoleChanged.emit({
      itemId: this.item.id as number,
      roles: []
    });
  }

  translatedMessageService(
    severity: string = 'error',
    summary: string = 'Error',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }

  addPermission(): void {
    if (this.users.some((x => x.id == this.selectedUser.id))) {
      this.translatedMessageService(
        'error',
        'Error',
        'tooltip.content.user_group_duplicated'
      );

      this.selectedUser = { name: '' } as User;
      return
    }

    if (this.sourceId === AddOnSource.PowerBI)
      this.selectedUser.staticRLS = this.selectedStaticRoles;

    const itemPermissionRoles = this.selectedStaticRoles.map(roles => {
      return {
        role: {
          ...roles
        }
      }
    });

    this.itemPermissions.push({
      principalId: this.selectedUser.id,
      roles: itemPermissionRoles
    } as ItemPermission);

    this.users.push(this.selectedUser);

    this.itemPermissionsChanged.emit(this.itemPermissions);
    this.selectedStaticRoles = [];
    this.selectedUser = { name: '' } as User;
  }

  setUser(user: User): void {
    this.selectedUser = user;
  }
}
