<p-toast></p-toast>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <h3 class="mb-3">{{ 'general_settings.title' | translate }}</h3>
      <span class="mr-3 text-gray-500">
        {{ 'general_settings.subtitle' | translate }}
      </span>

      <div class="mt-4 settings">
        <p-tabView class="w-full h-full">
          <p-tabPanel header="Tenant">
            <form [formGroup]="form">
              <input hidden type="text" pInputText id="id" formControlName="id" name="id" />

              <div formGroupName="tenantSettings">
                <h3 class="mb-3">{{ 'general_settings.tabs.tenant.title' | translate }}</h3>

                <span class="mr-3 text-gray-500">
                  {{ 'general_settings.tabs.tenant.description' | translate }}
                </span>

                <div class="flex mt-5">
                  <input hidden type="text" pInputText id="id" formControlName="id" name="id" />

                  <p-floatLabel class="mr-5 w-5">
                    <input
                      required
                      pInputText
                      id="tenantName"
                      class="w-full"
                      formControlName="tenantName" />
                    <label for="tenantName">{{ 'general_settings.tabs.tenant.form.tenantName' | translate }}</label>
                  </p-floatLabel>

                  <user-dropdown
                    *ngIf="patched"
                    [required]="true"
                    [userId]="form.get('tenantSettings.ownerId')?.value"
                    [placeholder]="'general_settings.tabs.tenant.form.applicationOwner'"
                    (onChange)="validateOwner($event)"
                    (selectedUser)="setOwner($event)"></user-dropdown>
                </div>

                <div class="flex my-5">
                  <input hidden type="text" pInputText id="environmentTypeId" formControlName="environmentTypeId" name="environmentTypeId" />

                  <p-floatLabel class="mr-5">
                    <p-dropdown
                      showClear
                      required
                      (onChange)="setEnvironmentType($event)"
                      class="environment-type-dropdown"
                      [options]="environmentTypes"
                      formControlName="environmentType"
                      optionLabel="name"
                      id="environmentType" />
                    <label for="environmentType">{{ 'general_settings.tabs.tenant.form.environmentType' | translate }}</label>
                  </p-floatLabel>

                  <div class="w-5">
                    <p-floatLabel class="w-full">
                      <input
                        pInputText
                        required
                        id="url"
                        formControlName="url"
                        class="w-full" />
                      <label for="url">URL</label>
                    </p-floatLabel>
                    <small class="invalid-field"
                           *ngIf="form.get('tenantSettings.url')?.dirty && form.get('tenantSettings.url')?.errors?.['invalid-url']">
                      {{ 'general_settings.tabs.tenant.form.validation.url' | translate }}
                    </small>
                  </div>
                </div>
              </div>

              <div class="mt-5" formGroupName="tenantSettings">
                <h3 class="mb-3">{{ 'general_settings.tabs.tenant.metrics.title' | translate }}</h3>

                <span class="mr-3 text-gray-500">
                  {{ 'general_settings.tabs.tenant.metrics.description' | translate }}
                </span>

                <div class="flex mt-5">
                  <p-floatLabel class="w-8">
                    <input
                      pInputText
                      id="tagManagerId"
                      formControlName="tagManagerId"
                      class="w-full" />
                    <label for="tagManagerId">{{ 'general_settings.tabs.tenant.form.tagManagerId' | translate }}</label>
                  </p-floatLabel>
                </div>
              </div>
              <div id="general-feedback">
                <app-general-feedback
                  [isFeedbackEnabled]="this.form.get('isFeedbackEnabled')?.value"
                  (isFeedbackEnabledChange)="feedbackToggle($event)">
                </app-general-feedback>
              </div>
            </form>


          </p-tabPanel>

          <p-tabPanel header="Authentication">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic in reprehenderit atque ea quaerat? Voluptate, nesciunt iste, aspernatur perferendis minus reiciendis earum dolorum vel quibusdam neque eligendi impedit at deleniti?
            </p>
          </p-tabPanel>

          <p-tabPanel header="Firewall">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet corporis voluptatem perferendis quae quo eaque neque doloribus esse, fuga doloremque delectus, quaerat saepe similique assumenda ex tenetur, rerum minus suscipit?
            </p>
          </p-tabPanel>

          <p-tabPanel header="About">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet corporis voluptatem perferendis quae quo eaque neque doloribus esse, fuga doloremque delectus, quaerat saepe similique assumenda ex tenetur, rerum minus suscipit?
            </p>
          </p-tabPanel>
        </p-tabView>

      </div>

      <div class="footer relative flex justify-content-end border-top-1 border-200 pt-4 w-full bg-white">
        <p-button
          rounded
          [disabled]="!(form.valid && form.dirty)"
          [loading]="loading"
          (click)="save()"
          severity="primary">{{ 'addons.save_btn' | translate }}</p-button>
      </div>
    </div>
  </div>
</div>
