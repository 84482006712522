import { CommonModule } from '@angular/common';
import { Component, ErrorHandler, Inject, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { mapFilters } from '../../api/item-paginator';
import { UsersGroup, UsersGroupUsers } from '../../api/user';
import { LayoutService } from '../../service/app.layout.service';
import { UsersGroupsService } from '../../service/users-groups.service';
import { GlobalErrorHandler } from '../../utils/components/error-handler/error-handler.component';
import { AddUsersToGroupComponent } from './add-users-to-group/add-users-to-group.component';
import { NewGroupComponent } from './new-group/new-group.component';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-users-groups',
  standalone: true,
  imports: [
    ToastModule,
    TranslateModule,
    ButtonModule,
    NewGroupComponent,
    TableModule,
    CommonModule,
    InputTextModule,
    DialogModule,
    TabViewModule,
    AddUsersToGroupComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [MessageService],
  templateUrl: './users-groups.component.html',
  styleUrl: './users-groups.component.scss',
})
export class UsersGroupsComponent {
  usersGroups: UsersGroup[] = [];
  usersGroup: UsersGroup = {} as UsersGroup;
  isNewUsersGroupVisible: boolean = false;
  deleteUsersGroupDialog: boolean = false;
  @ViewChild('dt') table!: Table;
  totalRecords: number = 0;
  paginatorIndex: number = 0;
  rows = 10;
  page: number = 0;
  size: number = 0;
  sortField: string | string[] = 'Id';
  sortOrder: number = 1;
  filters: any = {};
  loading: boolean = false;
  cols = [
    { field: 'id', header: 'Id' },
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
  ];
  selectedRows: UsersGroup[] = [];
  isFilterRowVisible: boolean = false;

  translatedTemplate: any = {
    page_report: '',
    table_search_field: '',
    form_tab_validation_tooltip: '',
    dialog_change_status: '',
    dialog_delete_item: '',
    dialog_unpublish_item: '',
    dialog_publish_item: '',
  };

  form!: FormGroup;

  activeTabIndex: number = 0;

  constructor(
    private translate: TranslateService,
    public layoutService: LayoutService,
    public usersGroupsService: UsersGroupsService,
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private messageService: MessageService
  ) {}
  getAllUsersGroups(event: TableLazyLoadEvent) {
    this.loading = true;
    this.page = event.first && event.rows ? event.first / event.rows : 0;
    this.size = event.rows ?? this.rows;

    this.sortField = event.sortField ?? 'Id';
    this.sortOrder = event.sortOrder ?? 1;

    this.filters = event.filters;
    let filters = this.filters ? mapFilters(this.filters) : {};

    this.usersGroupsService
      .getUsersGroups({
        currentPage: this.page,
        numberOfRecords: this.size,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        filters: filters,
      })
      .subscribe({
        next: (response) => {
          this.usersGroups = response.items;
          this.totalRecords = response.total;
          this.loading = false;
        },
        error: (e) => this.errorHandler.handleError(e),
      });
  }

  ngOnInit() {
    this.initTranslations();
    this.translate.onLangChange.subscribe(() => this.initTranslations());
    this.form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      users: new FormControl([]),
    });

  }

  initTranslations() {
    this.translate
      .get('users_groups.table.page_report')
      .subscribe((res: string) => {
        this.translatedTemplate.page_report = res;
      });

    this.translate
      .get('users_groups.form.tab.general.validation_tooltip')
      .subscribe((res: string) => {
        this.translatedTemplate.form_tab_validation_tooltip = res;
      });
  }

  openNew() {
    this.usersGroup = {} as UsersGroup;
    this.form.reset();
    this.isNewUsersGroupVisible = true;
  }

  isDisabled(): boolean {
    return !(this.form.valid && this.form.dirty);
  }

  onEdit(userGroup: UsersGroup) {
    this.form.patchValue(userGroup);
    this.form.markAsDirty();
  }
  onUserChanged(usersGroupUsers: UsersGroupUsers[]) {
    this.usersGroup.users = usersGroupUsers;
    this.form.patchValue({ users: usersGroupUsers });
    this.form.markAsDirty();
  }

  setLastPage() {
    this.totalRecords++;
    this.paginatorIndex = Math.floor(this.totalRecords / this.rows) * this.rows;
    this.isNewUsersGroupVisible = false;
  }

  save() {
    const usersGroup = this.usersGroups.find(
      (x) => x.name === this.form.value.name
    );
    if (usersGroup && usersGroup.id !== this.form.value.id) {
      this.translatedMessageService(
        'error',
        'Error',
        'tooltip.content.users_group_duplicated'
      );
      return;
    }

    if (this.form.valid) {
      this.usersGroup = this.form.value;

      if (this.usersGroup.id) {
        this.usersGroupsService.updateUsersGroup(this.usersGroup).subscribe({
          next: () => {
            this.getAllUsersGroups({
              first: this.paginatorIndex,
              rows: this.rows,
              sortField: this.sortField,
              sortOrder: this.sortOrder,
              filters: this.filters,
            });
            this.translatedMessageService(
              'success',
              'Successful',
              'tooltip.content.item_updated'
            );
          },
          error: (e) => this.errorHandler.handleError(e),
        });
      } else {
        this.usersGroupsService.createUsersGroup(this.usersGroup).subscribe({
          next: () => {
            this.setLastPage();

            this.getAllUsersGroups({
              first: this.paginatorIndex,
              rows: this.rows,
              sortField: this.sortField,
              sortOrder: this.sortOrder,
              filters: this.filters,
            });
            this.translatedMessageService(
              'success',
              'Successful',
              'tooltip.content.item_created'
            );
          },
          error: (e) => this.errorHandler.handleError(e),
        });
      }
      this.hideDialog();
    }
  }

  editUserGroup(userGroup: UsersGroup) {
    this.isNewUsersGroupVisible = true;
    this.usersGroup = userGroup;
    this.form.patchValue(userGroup);
  }

  openDeleteDialog(usersGroup: UsersGroup) {
    this.usersGroup = usersGroup;
    this.translate
      .get('seals.dialogs.delete_item.message', {
        name: this.usersGroup.name,
      })
      .subscribe((translation: any) => {
        this.translatedTemplate.dialog_delete_item = translation;
        this.deleteUsersGroupDialog = true;
      });
  }

  confirmDeleteUsersGroup() {
    this.deleteUsersGroupDialog = false;
    this.usersGroupsService.deleteUsersGroup(this.usersGroup.id).subscribe({
      complete: () => {
        this.usersGroup = {} as UsersGroup;
        this.getAllUsersGroups({
          first: this.paginatorIndex,
          rows: this.rows,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          filters: this.filters,
        });

        this.translatedMessageService(
          'success',
          'Successful',
          'tooltip.content.item_deleted'
        );
      },
      error: (e) => {
        if (e.status === HttpStatusCode.BadRequest)
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: e.error[0]?.Message,
            life: 3000,
          });

        return this.errorHandler.handleError(e);
      },
    });
  }
  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  toggleFilter() {
    this.isFilterRowVisible = !this.isFilterRowVisible;
  }
  translatedMessageService(
    severity: string = 'success',
    summary: string = 'Successful',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }

  hideDialog() {
    this.isNewUsersGroupVisible = false;
    this.activeTabIndex = 0;
  }
}
