import { Observable } from "rxjs";
import { AddOnSchedulerLog } from "../api/addon";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";

/**
 * Service for managing addons
 */
@Injectable({
  providedIn: 'root',
})
export class AddOnLogService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getAll(installedAddOnId: number): Observable<AddOnSchedulerLog[]> {
    return this.httpClient.get<AddOnSchedulerLog[]>(`${this.baseRoute}addons-scheduler/logs`, {
      params: { installedAddOnId }
    });
  }
}
