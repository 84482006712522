<p-toast></p-toast>

<p-dialog
  [header]="addon.title + ' ' + ('addons.setup.title' | translate)"
  [modal]="true"
  [(visible)]="showSetupDialog"
  [draggable]="false"
  [style]="{ width: '800px' }"
  (onShow)="onShow()"
  (onHide)="onHide()">

  <p-stepper [linear]="true">
    <p-stepperPanel [header]="translation.headers[0]">
      <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column">
          <div class="flex-wrap flex p-4">
            <form #form="ngForm" class="flex flex-wrap">
              <p-floatLabel *ngFor="let field of authenticationFields; let index = index;" class="w-full py-3">
                @if (field.title.toLowerCase().includes('secret')) {
                <p-password
                  [id]="field.id"
                  [name]="field.id"
                  [(ngModel)]="authenticationFields[index].value"
                  class="w-full"
                  [feedback]="false"
                  [toggleMask]="true"
                  required />
                }
                @else {
                <input
                  pInputText
                  [id]="field.id"
                  [name]="field.id"
                  [(ngModel)]="authenticationFields[index].value"
                  class="w-full"
                  #ctrl="ngModel"
                  required />
                }

                <label [for]="field.id">{{ field.title }}</label>
              </p-floatLabel>
            </form>

            <p-messages *ngIf="messages.length" [(value)]="messages" [enableService]="false" class="w-full" />

          </div>
        </div>
        <div class="flex pt-4 justify-content-end">
          <p-button
            [label]="'addons.setup.stepper.navigation.next' | translate"
            [disabled]="!form.valid"
            [loading]="loading"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="connect(nextCallback)" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel [header]="translation.headers[1]">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column">
          <div class="flex-wrap flex font-medium p-4">
            <p *ngFor="let status of connectionLog.messages" class="w-full" [innerHTML]="parseMessage(status)"></p>
          </div>
        </div>
        <div class="flex pt-4 justify-content-between">
          <p-button
            [label]="'addons.setup.stepper.navigation.back' | translate"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()" />
          <p-button
            [label]="'addons.setup.stepper.navigation.next' | translate"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel [header]="translation.headers[2]">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <div class="flex flex-column">
          <div class="flex-wrap flex font-medium p-4">
            <div class="w-full">
              <p>
                <span class="font-bold text-lg" style="color: var(--primary-color)">{{ connectionLog.reports.length }}</span>
                {{ 'addons.setup.stepper.load_info.total_reports' | translate }}
              </p>
            </div>

            <addon-scheduler
              *ngIf="sourceId === AddOnSource.PowerBI"
              (frequencyInMin)="setSchedulerFrequency($event)"
              class="mt-5">
            </addon-scheduler>

            <div class="w-full mt-5">
              <p>{{ 'addons.setup.stepper.load_info.email_notification' | translate }}</p>
              <p-inputSwitch [(ngModel)]="emailChecked" />
            </div>
          </div>
        </div>

        <div class="flex pt-4 justify-content-between">
          <p-button
            [label]="'addons.setup.stepper.navigation.back' | translate"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()" />

          <p-button
            [loading]="loading"
            [label]="'addons.setup.stepper.navigation.finish' | translate"
            (onClick)="finish()" />
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>

</p-dialog>
