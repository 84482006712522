import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Term } from '../api/term';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class GlossaryService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getTerms(): Observable<Term[]> {
    let params = new HttpParams();
    params = params.append('Name', '');

    return this.httpClient.get<Term[]>(`${this.baseRoute}term`, { params });
  }

  getTermsFiltered(filters: any): Observable<Term[]> {
    return this.httpClient.get<Term[]>(`${this.baseRoute}term/get-by-filter`, {
      params: filters
    });
  }

  deleteTerm(id: number): Observable<Term> {
    const url = `${this.baseRoute}term?id=${id}`;
    return this.httpClient.delete<any>(url);
  }

  insertTerm(term: Term): Observable<Term> {
    const addTerm = {
      name: term.name,
      acronym: term.acronym,
      type: term.type,
      definition: term.definition,
      owner: term.owner,
      synonymous: term.synonymous,
      examples: term.examples,
      origin: term.origin,
      gpdr: term.gpdr,
    };

    return this.httpClient.post<Term>(`${this.baseRoute}term/`, addTerm);
  }

  updateTerm(term: Term): Observable<Term> {
    return this.httpClient.put<Term>(`${this.baseRoute}term/`, term);
  }
}
