<div>
  <div class="gallery mt-8">
    <div>
      <p-carousel
        *ngIf="images.length > 0"
        [value]="images"
        [numVisible]="5"
        [numScroll]="5"
        [circular]="false"
        [responsiveOptions]="responsiveOptions">
        <ng-template let-image pTemplate="item">
          <p-image
            appendTo="body"
            [src]="image.itemImageSrc"
            alt="Image"
            width="250"
            [preview]="true" />
        </ng-template>
      </p-carousel>
    </div>
  </div>
  <div class="about mt-5">
    <h2>{{ 'item_details.details-overview-tab.about' | translate }}</h2>
    <p class="text-lg">{{ item.description }}</p>
  </div>
</div>
