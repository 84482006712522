<p-dataView #dv [value]="reviews" emptyMessage="{{ 'item_details.details-reviews-tab.empty' | translate }}">
  <ng-template pTemplate="header">
    <div class="title-review">
      <span>{{ 'item_details.details-reviews-tab.title' | translate }}</span>
    </div>
    <div class="review-filters">
      <div class="flex flex-column md:flex-row md:justify-content-between filter-options">
        <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By" (onChange)="onFilterChange($event)" styleClass="mb-2 md:mb-0" />
      </div>
      <div class="flex flex-column md:flex-row md:justify-content-between filter-options">
        <p-dropdown [options]="filterOptions" [(ngModel)]="filterKey" placeholder="Filter By" (onChange)="onFilterChange($event)" styleClass="mb-2 md:mb-0" />
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="list" let-reviews>
    <div class="grid grid-nogutter">
      <div class="col-12 review-card" *ngFor="let review of reviews; let first = first">
        <div class="review-container mb-3 mt-5">
          <div class="header flex align-items-center">

            <div class="w-full flex align-items-center">
              @if (review?.userPhoto) {
              <img alt="personal-picture" [src]="review.userPhoto" class="profile-pic mr-3">
              }
              @else {
              <i class="pi pi-user profile-pic mr-3 flex align-items-center justify-content-center empty-profile-pic"></i>
              }

              <div class="user-info">
                <span class="user-name text-900 font-medium">{{ review.userName }}</span>
              </div>
            </div>

            <div class="actions flex justify-content-end gap-2" *ngIf="isMyReview(review)">
              @if (isMyReview(review) && isEditing) {
              <p-button
                pButton
                rounded
                outlined
                (click)="update(review)"
                icon="pi pi-check" />

              <p-button
                pButton
                pRipple
                rounded
                outlined
                icon="pi pi-times"
                class="p-button-text"
                severity="danger"
                (click)="revertEdit(review)" />
              }
              @else {
              <p-button
                pButton
                outlined
                rounded
                (click)="setEditMode(review)"
                icon="pi pi-pencil" />

              <p-button
                pButton
                outlined
                rounded
                (click)="markReviewToDelete(review)"
                severity="danger"
                icon="pi pi-trash" />
              }
            </div>
          </div>

          <div class="comment text-600 ml-3 mt-4 mb-5">
            @if (isMyReview(review) && isEditing) {
            <textarea
              pInputTextarea
              required
              [placeholder]="'item_reviews.form.comment' | translate"
              [(ngModel)]="review.comment"
              class="w-full"
              rows="10"
              cols="20"
              maxlength="500">
            </textarea>

            }
            @else {
            <p>{{ review.comment }}</p>
            }
          </div>

          <div class="footer flex align-items-center ml-2">
            <p-rating [(ngModel)]="review.rate" [cancel]="false" [readonly]="!(isMyReview(review) && isEditing)" class="mr-2"></p-rating>
            <span class="review-date text-500 text-sm ml-2">{{ review.createdAt | date:datetimeFormat }}</span>
            <span class="review-version text-500 text-sm ml-2">{{ 'item_details.details-reviews-tab.item-version' | translate }}{{ review.itemVersion }}</span>
          </div>
        </div>
      </div>
    </div>

    <p-dialog
      [(visible)]="deleteDialog"
      [header]="'components.dialogs.header.confirm' | translate"
      [modal]="true"
      [style]="{ width: '450px' }">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="selectedReview"> {{ 'item_reviews.dialog.delete' | translate }}</span>
      </div>
      <ng-template pTemplate="footer">
        <button
          pButton
          pRipple
          icon="pi pi-times"
          class="p-button-text"
          [label]="'components.dialogs.no' | translate"
          (click)="deleteDialog = false"></button>
        <button
          pButton
          pRipple
          icon="pi pi-check"
          class="p-button-text"
          [label]="'components.dialogs.yes' | translate"
          (click)="confirmDelete(selectedReview)"></button>
      </ng-template>
    </p-dialog>

    <p-paginator
      [rows]="rows"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="[5, 10, 20]"
      (onPageChange)="onPageChange($event)">
    </p-paginator>
  </ng-template>
</p-dataView>
