import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';
import { Item } from '../api/item';
import { PaginatedResponse } from '../api/item-paginator';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getStoreItems(filters: any): Observable<PaginatedResponse<Item>> {
    return this.httpClient.get<PaginatedResponse<Item>>(`${this.baseRoute}store/`, {
      params: filters,
    });
  }
}
