<div class="flex justify-content-start mt-3">
  <h5>{{ 'catalog.tab.custom_fields' | translate }}</h5>
</div>

<div class="field col-12">
  <input type="text" pInputText id="name" maxlength="60" [(ngModel)]="itemCustomField.label" placeholder="{{ 'catalog.table.colum_header.name' | translate }}" [disabled]="isDisabled" />
</div>
<div class="field col-12">
  <textarea id="value" pInputTextarea rows="2" maxlength="512" cols="20" [(ngModel)]="itemCustomField.value"
    placeholder="{{ 'catalog.table.colum_header.value' | translate }}" [disabled]="isDisabled"></textarea>
</div>
<div class="flex justify-content-start mt-2">
  <div class="col-4">
    <p-dropdown id="visible" [(ngModel)]="itemCustomField.visible" [options]="itemCustomFieldVisible"
      optionLabel="description" optionValue="value" [filter]="true" [disabled]="isDisabled" filterBy="value" [showClear]="true"
      placeholder="{{ 'catalog.table.colum_header.visible' | translate }}">
    </p-dropdown>
  </div>
  <div class="col-1">
    <button pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-outlined"
      (click)="saveItemCustomField()" [disabled]="isDisabled"></button>
  </div>
</div>

<div class="grid grid-nogutter mt-4">
  <div class="field col-12">
    <p-table #dt [value]="itemCustomFields" [columns]="['id', 'name', 'value', 'visible']" responsiveLayout="scroll"
      [rows]="10" [globalFilterFields]="['id', 'name', 'value', 'visible']" [paginator]="true"
      [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]" [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'catalog.table.page_report' | translate }} " [(selection)]="selectedItemCustomFields"
      selectionMode="multiple" [rowHover]="true" dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="id">
            {{ 'catalog.table.colum_header.id' | translate }} <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            {{ 'catalog.table.colum_header.name' | translate }} <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="value">
            {{ 'catalog.table.colum_header.value' | translate }} <p-sortIcon field="value"></p-sortIcon>
          </th>
          <th pSortableColumn="visible">
            {{ 'catalog.table.colum_header.visible' | translate }} <p-sortIcon field="visible"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-itemCustomField>
        <tr>
          <td>
            <p-tableCheckbox [value]="itemCustomField"></p-tableCheckbox>
          </td>
          <td style="width: 10%; min-width: 6rem">
            <span class="p-column-title">{{ 'catalog.table.colum_header.id' | translate }}</span>
            {{ itemCustomField.id }}
          </td>
          <td style="width: 35%; min-width: 20rem">
            <span class="p-column-title">{{ 'catalog.table.colum_header.name' | translate }}</span>
            {{ itemCustomField.label }}
          </td>
          <td style="width: 35%; min-width: 20rem">
            <span class="p-column-title">{{ 'catalog.table.colum_header.value' | translate }}</span>
            {{ itemCustomField.value }}
          </td>
          <td style="width: 10%; min-width: 6rem">
            <span class="p-column-title">{{ 'catalog.table.colum_header.visible' | translate }}</span>
            {{ itemCustomField.visible }}
          </td>
          <td>
            <div class="flex">
              <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger mr-2"
                (click)="deleteItemCustomField(itemCustomField)" [disabled]="isDisabled"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>