import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AgreementsService } from '../service/agreements.service';
import { HasTermsToAcceptQuery } from '../api/agreement';

export const AgreementGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const agreementsService = inject(AgreementsService);

  return agreementsService.hasTermsToAccept().pipe(
    map((hasUserToAgree: HasTermsToAcceptQuery) => {
      if (!hasUserToAgree.hasTermsToAccept) {
        return true;
      } else if (state.url == '/user-agreement') {
        return true;
      } else {
        router.navigate(['/user-agreement']);
        return false;
      }
    }),
    catchError((error) => {
      console.error('Error fetching user information:', error);
      router.navigate(['/my-catalog']);
      return of(false);
    })
  );
};
