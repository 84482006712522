<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">

      @if (!loginDisplay) {
      <div>
        <p class="welcome">{{'home.welcome' | translate}}</p>
        <p>Please log-in to get access to the application.</p>
      </div>
      }

      @if (loginDisplay) {
      <div>
        <p>
          {{ 'home.welcome' | translate }}</p>
        <p>{{ 'home.click_to_navigate' | translate }}</p>

      </div>
      }
    </div>
  </div>
</div>
