<p-toast></p-toast>

<div *ngIf="hasSentReview" class="flex justify-content-center p-3">
  <h1 class="text-xl">{{ 'item_reviews.review_submitted' | translate }}</h1>
</div>

<form [formGroup]="form" class="mt-3" *ngIf="!hasSentReview">
  <div class="mb-3 text-xl flex align-items-center">
    <b>{{ 'item_reviews.form.rate' | translate }}</b>

    <p-rating
      required
      formControlName="rate"
      [cancel]="false"
      class="ml-2 review-ratings flex align-items-center" />
  </div>

  <div class="flex flex-wrap justify-content-end align-items-center gap-2 mb-2">
    <textarea
      pInputTextarea
      required
      [placeholder]="'item_reviews.form.comment' | translate"
      class="w-full"
      formControlName="comment"
      rows="10"
      cols="20"
      maxlength="500">
    </textarea>

    <small [ngClass]="{ 'comment-limit': form.get('comment')?.value?.length === 500 }">
      {{ form.get('comment')?.value?.length }}/500
    </small>

    <button
      pButton
      rounded
      (click)="save()"
      [disabled]="!(form.valid && form.dirty)">
      {{ 'item_reviews.form.submit' | translate }}
    </button>
  </div>
</form>
