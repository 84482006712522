export interface ItemPermissionRole {
  role: RoleLevelSecurity;
}

export interface RoleLevelSecurity {
  id: number;
  roleName: string;
  installedAddonId: number;
  type: number;
}

export type RoleType = {
  id: RoleLevelSecurityEnum,
  value: string;
}

export enum RoleLevelSecurityEnum {
  Static = 1,
  Dynamic = 2
}
