import { Component, ErrorHandler, Inject, OnInit } from "@angular/core";
import { GlobalErrorHandler } from "../../../utils/components/error-handler/error-handler.component";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { TabViewModule } from "primeng/tabview";
import { InputTextModule } from "primeng/inputtext";
import { AddonCardComponent } from "../addon-card/addon-card.component";
import { ToastModule } from "primeng/toast";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { AddOn, InstalledAddOn } from "../../../api/addon";
import { LayoutService } from "../../../service/app.layout.service";
import { AddonService } from "../../../service/addon.service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-addons',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    TabViewModule,
    InputTextModule,
    AddonCardComponent,
    ToastModule,
    ProgressSpinnerModule,
    RouterModule
  ],
  templateUrl: './addon-list.component.html',
  styleUrl: './addon-list.component.scss',
})
export class AddonListComponent implements OnInit {
  availableAddons: AddOn[] = [];
  installedAddons: InstalledAddOn[] = [];

  staticAvailableAddons: AddOn[] = [];
  staticInstalledAddons: InstalledAddOn[] = [];

  loading: boolean = false;

  userId: string = '';
  activeIndex: number = 0;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private layoutService: LayoutService,
    private addonService: AddonService,
    private translationService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.layoutService.setAdminStuff(true);

    this.loadAddons();

    this.translationService.onLangChange
      .subscribe(() => this.loadAddons());
  }

  onGlobalFilter(event: Event) {
    const value = (event.target as HTMLInputElement).value.toLowerCase();

    if (!value) {
      this.availableAddons = this.staticAvailableAddons;
      this.installedAddons = this.staticInstalledAddons;

      return;
    };

    if (this.activeIndex === 0)
      this.availableAddons = this.staticAvailableAddons.filter(addon => addon.title.toLowerCase().includes(value));

    if (this.activeIndex === 1)
      this.installedAddons = this.staticInstalledAddons.filter(addon => addon.addOn.title.toLowerCase().includes(value));
  }

  loadAddons(): void {
    this.loadAvailableAddons();
    this.loadInstalledAddons();
  }

  loadSettings(event: InstalledAddOn): void {
    this.router.navigate(['settings'], {
      relativeTo: this.route,
      state: event
    });
  }

  uninstall(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Successful',
      detail: this.translationService.instant('addons.setup.stepper.tooltip.uninstall'),
      life: 3000,
    });

    this.loadAddons();
  }

  loadAvailableAddons(): void {
    this.loading = true;

    this.addonService.getAvailableAddons()
      .subscribe({
        next: addons => {
          this.availableAddons = addons;
          this.staticAvailableAddons = this.availableAddons;
          this.loading = false;
        },
        error: (e) => this.errorHandler.handleError(e)
      });
  }

  loadInstalledAddons(): void {
    this.loading = true;

    this.addonService.getInstalledAddons()
      .subscribe({
        next: addons => {
          this.installedAddons = addons;
          this.staticInstalledAddons = this.installedAddons;
          this.loading = false;
        },
        error: (e) => this.errorHandler.handleError(e)
      });
  }
}
