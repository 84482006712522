import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CarouselModule } from 'primeng/carousel';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { Item } from '../../api/item';
import { ItemCardComponent } from '../../components/item-card/item-card.component';
import { LayoutService } from '../../service/app.layout.service';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

type TranslatedTemplate = {
  pageReport: string;
};

@Component({
  selector: 'app-my-catalog',
  standalone: true,
  imports: [
    ItemCardComponent,
    CarouselModule,
    SkeletonModule,
    TableModule,
    FilterButtonComponent,
    DataViewModule,
    ChipModule,
    TranslateModule,
    ToastModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './my-catalog.component.html',
  styleUrl: './my-catalog.component.scss',
})
export class MyCatalogComponent implements OnInit {

  @ViewChild('carousel') carousel!: ElementRef;

  items: Item[] = [];

  isLoading = true;
  sidebarVisible: boolean = false;

  page: number = 0;

  responsiveOptions: any[] | undefined;

  translatedTemplate: TranslatedTemplate = {} as TranslatedTemplate;

  constructor(
    public layoutService: LayoutService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.responsiveOptions = [
      {
        breakpoint: '1960px',
        numVisible: 7,
        numScroll: 7
      },
      {
        breakpoint: '1830px',
        numVisible: 6,
        numScroll: 6
      },
      {
        breakpoint: '1560px',
        numVisible: 5,
        numScroll: 5
      },
      {
        breakpoint: '1230px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '980px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '800px',
        numVisible: 2,
        numScroll: 2
      }
    ];

    this.layoutService.setAdminStuff(false);
    this.initTranslations();
    this.translateService.onLangChange.subscribe(() => this.initTranslations());
  }

  initTranslations(): void {
    this.translateService
      .get('my_catalog.dataview.page_report')
      .subscribe((res: string) => (this.translatedTemplate.pageReport = res));
  }

  get hasFavorites(): boolean {
    return Boolean(this.favorites.length);
  }

  get favorites(): Item[] {
    return this.items.filter(item => item.favorited);
  }

  isSidebarOpen: boolean = false;

  setIsSidebarOpen(value: boolean): void {
    this.isSidebarOpen = value;
  }

  updateFilteredItems(filteredItems: Item[]) {
    this.items = filteredItems;
    this.isLoading = false;
  }

  get itemsCounter() {
    return this.items.length - this.favorites.length;
  }

  onFavoriteChange(isFavorited: boolean): void {
    if (isFavorited)
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('tooltip.successful'),
        detail: this.translateService.instant('item_favorites.tooltip.add'),
        life: 3000,
      });
    else
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('tooltip.successful'),
        detail: this.translateService.instant('item_favorites.tooltip.remove'),
        life: 3000,
      });
  }

  @HostListener('wheel', ['$event']) onMousewheel(event: any) {
    if (event.target.offsetParent.className !== "p-carousel-items-container")
      return;

    const maxScroll = (this.carousel as any)
      ?.indicatorContent
      ?.nativeElement?.children?.length -1 || 0;

    if (!maxScroll)
      return;

    event.preventDefault();

    if (event.wheelDelta > 0) {
      if (this.page < maxScroll)
        this.page += 1;
    }
    else
      if (this.page > 0)
        this.page -= 1;
  }
}
