import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private http: HttpClient) {}

  sendFeedback(feedbackData: { rate: number; description: string }): Observable<any> {
    return this.http.post<any>(`${this.baseRoute}Feedback`, feedbackData);
  }

  declinefeedback(){
    return this.http.put<any>(`${this.baseRoute}Feedback/decline`, {})
  }

}
