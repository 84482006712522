import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environments';
import { Item } from '../api/item';
import {
  buildHttpParams,
  PaginatedResponse,
  PaginationInfo,
} from '../api/item-paginator';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getById(itemId: number): Observable<Item> {
    return this.httpClient.get<Item>(`${this.baseRoute}item/${itemId}`);
  }

  getItems(pagination?: PaginationInfo): Observable<PaginatedResponse<Item>> {
    let params = buildHttpParams(pagination);

    return this.httpClient
      .get<PaginatedResponse<Item>>(`${this.baseRoute}item/`, { params })
      .pipe(
        catchError((error) => {
          console.error('Error fetching Items:', error);
          return throwError(() => error);
        })
      );
  }

  getItemsFiltered(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}item/get-by-filter`, {
      params: filters,
    });
  }

  getCatalogItems(
    pagination?: PaginationInfo
  ): Observable<PaginatedResponse<Item>> {
    let params = buildHttpParams(pagination, true);
    return this.httpClient.get<PaginatedResponse<Item>>(
      `${this.baseRoute}item/my-catalog`,
      {
        params,
      }
    );
  }

  getCatalogFavoritesItems(
    pagination?: PaginationInfo
  ): Observable<PaginatedResponse<Item>> {
    let params = buildHttpParams(pagination, true);
    return this.httpClient.get<PaginatedResponse<Item>>(
      `${this.baseRoute}item/my-catalog/favorites`,
      {
        params,
      }
    );
  }

  getStoreItems(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}store/`, {
      params: filters,
    });
  }

  add(item: Item): Observable<Item> {
    return this.httpClient.post<Item>(`${this.baseRoute}item/`, item);
  }

  update(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/`, item);
  }

  publishItem(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/publish`, item);
  }
  unpublishItem(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/unpublish`, item);
  }

  deleteItem(itemToDelete: number[]): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.baseRoute}item?id=${itemToDelete[0]}`
    );
  }
}
