import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
  selector: 'app-addons',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './addons.component.html',
  styleUrl: './addons.component.scss',
})
export class AddonComponent {

  constructor() { }

}
