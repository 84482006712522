import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { Item, ItemPreview } from '../../../api/item';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-uploader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    MessageService,
    ConfirmationService,
  ],
  imports: [
    FileUploadModule,
    ButtonModule,
    ToastModule,
    ToolbarModule,
    SharedModule,
    ButtonModule,
    RippleModule,
    FileUploadModule,
    TableModule,
    InputTextModule,
    RatingModule,
    FormsModule,
    DialogModule,
    NgIf,
    NgClass,
    InputTextareaModule,
    DropdownModule,
    RadioButtonModule,
    InputNumberModule,
    CurrencyPipe,
    CommonModule,
    TagModule,
    InputSwitchModule,
    TabViewModule,
    MenuModule,
    TooltipModule,
    TranslateModule
  ],
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  @Input() item: Item = {} as Item;
  @Input() isDisabled = false;
  @Output() onUpload = new EventEmitter<ItemPreview[]>();

  itemPreviews: ItemPreview[] = [];
  selectedFiles: File[] = [];
  isThumbnailSet = ""
  files: File[] = [];

  @ViewChild('fileUploader') fileUploader!: FileUpload;

  constructor(private cdref: ChangeDetectorRef,
    private translate: TranslateService) { }

  ngOnInit() {
    this.itemPreviews = this.item.itemPreviews ?? [];
  }

  async ngAfterViewInit() {
    await Promise.all(this.itemPreviews.map(async preview => {
      const url = 'data:image/png;base64,' + preview.content;

      const res: Response = await fetch(url);
      const blob: Blob = await res.blob();

      const file = new File([blob], preview.name, { type: 'image/png' });

      this.fileUploader.files.push(file);
      this.setThumbnailOnLoad();

      this.fileUploader.cd.detectChanges();
      this.cdref.detectChanges();
    }));
  }

  onFileSelected(event: any, uploader: FileUpload): void {
    this.selectedFiles = uploader.files;

    if (uploader.files.length >= 5)
      return;

    let newItem: ItemPreview;

    this.selectedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const bytes = e.target.result.split('base64,')[1];

        newItem = {
          isThumbnail: false,
          content: bytes,
          name: file.name,
          refUrl: ''
        };

        if (!this.itemPreviews.find(f => f.name === newItem.name))
          this.itemPreviews.push(newItem);
      };

      reader.onloadend = () => {
        this.onUpload.emit(this.itemPreviews);
      }

      reader.readAsDataURL(file);
    });
  }

  setThumbnailOnLoad(): void {
    if (this.itemPreviews.length)
      this.isThumbnailSet = this.itemPreviews?.filter(x => x.isThumbnail === true)[0]?.name;
  }

  setThumbnail(file: File, uploader: FileUpload): void {
    let index = uploader.files.indexOf(file);
    this.isThumbnailSet = uploader.files[index].name;

    this.itemPreviews.forEach((item, forIndex) => {
      if (forIndex === index)
        item.isThumbnail = true;
      else
        item.isThumbnail = false;
    });

    this.onUpload.emit(this.itemPreviews);

    this.fileUploader.cd.detectChanges();
    this.cdref.detectChanges();
  }

  deleteFile(file: File, uploader: FileUpload, event: any): void {
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);

    this.itemPreviews = this.itemPreviews.filter((element, fIndex) => fIndex !== index);

    this.onUpload.emit(this.itemPreviews);
  }

  renderImage(file: File): string {
    return URL.createObjectURL(file);
  }
}
