import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';
import { Item } from '../api/item';
import { Observable } from 'rxjs';
import { ItemOrderRequest } from '../api/item-order-request';

/**
 * Service for managing item ordering.
 */
@Injectable({
  providedIn: 'root',
})
export class UserItemOrderService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  update(payload: ItemOrderRequest): Observable<Item> {
    return this.httpClient.post<Item>(`${this.baseRoute}user/item-order`, payload);
  }
}
