import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { HttpClient } from "@angular/common/http";
import { RoleLevelSecurity } from "../api/rls";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class RoleLevelSecurityService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<RoleLevelSecurity[]> {
    return this.httpClient.get<RoleLevelSecurity[]>(`${this.baseRoute}addons/rls`);
  }

  add(installedAddonId: number, rls: RoleLevelSecurity): Observable<void> {
    return this.httpClient.post<void>(`${this.baseRoute}addons/installed/${installedAddonId}/rls`, rls);
  }

  update(installedAddonId: number, rls: RoleLevelSecurity): Observable<void> {
    return this.httpClient.put<void>(`${this.baseRoute}addons/installed/${installedAddonId}/rls`, rls);
  }

  delete(installedAddonId: number, rlsId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseRoute}addons/installed/${installedAddonId}/rls/${rlsId}`);
  }

  canRemove(installedAddonId: number, rlsId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseRoute}addons/installed/${installedAddonId}/rls/can-remove/${rlsId}`);
  }
}
