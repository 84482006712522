import { Component, ErrorHandler, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DropdownModule } from 'primeng/dropdown';
import { GeneralFeedbackComponent } from '../../components/general-feedback/general-feedback.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnvironmentTypeEnum } from '../../api/settings';
import { UserDropdownComponent } from '../../components/user-dropdown/user-dropdown.component';
import { User } from '../../api/user';
import { ButtonModule } from 'primeng/button';
import { SettingsService } from '../../service/settings.service';
import { GlobalErrorHandler } from '../../utils/components/error-handler/error-handler.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CustomInputValidators } from '../../utils/validator.utils';

type EnvironmentType = {
  id: EnvironmentTypeEnum,
  name: string
};

@Component({
  selector: 'app-general-settings',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    TranslateModule,
    TabViewModule,
    InputTextModule,
    FloatLabelModule,
    DropdownModule,
    GeneralFeedbackComponent,
    FormsModule,
    ReactiveFormsModule,
    UserDropdownComponent,
    ButtonModule,
    ToastModule,
    CommonModule,
    ProgressSpinnerModule
  ],
  templateUrl: './general-settings.component.html',
  styleUrl: './general-settings.component.scss',
})
export class GeneralSettingsComponent implements OnInit {

  form!: FormGroup;

  @Output() isFeedbackEnabled = new EventEmitter<boolean>();
  isFeedbackEnabledValue: boolean = false;

  environmentTypes: EnvironmentType[] = [];
  loading: boolean = true;
  patched: boolean = false;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private messageService: MessageService,
    private translateService: TranslateService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl<number>(0),
      tenantSettings: new FormGroup({
        id: new FormControl<number>(0),
        tenantName: new FormControl<string>('', [CustomInputValidators.noWhitespaceValidator()]),
        environmentTypeId: new FormControl<number | null>(null),
        environmentType: new FormControl<EnvironmentType | null>(null),
        ownerId: new FormControl<string | null>(''),
        tagManagerId: new FormControl<string | null>(null, [CustomInputValidators.noWhitespaceValidator()]),
        url: new FormControl<string>('', [
          CustomInputValidators.noWhitespaceValidator(),
          CustomInputValidators.urlValidator()
        ])
      }),
      isFeedbackEnabled: new FormControl<boolean>(false)
    });

    this.environmentTypes = [
      { id: EnvironmentTypeEnum.DEV, name: 'Development' },
      { id: EnvironmentTypeEnum.PROD, name: 'Production' }
    ];

    this.getSettings();
  }

  setEnvironmentType(event: any): void {
    this.form.get('tenantSettings.environmentTypeId')?.setValue(event.value?.id);
  }

  validateOwner(event: string | User): void {
    if (typeof event === 'string')
      if (!event.trim())
        this.form.get('tenantSettings.ownerId')?.setErrors({ 'invalid': true });
  }

  setOwner(user: User): void {
    this.form.get('tenantSettings.ownerId')?.setValue(user.id);
    this.form.get('tenantSettings.ownerId')?.setErrors(null);
    this.form.markAsDirty();
  }

  getSettings(): void {
    this.loading = true;

    this.settingsService
      .get()
      .subscribe(settings => {
        this.form.patchValue(settings);
        this.patched = true;
        this.loading = false;
      });
  }

  feedbackToggle(isFeedbackEnabledTemp: boolean): void {
    this.form.patchValue({ isFeedbackEnabled: isFeedbackEnabledTemp });
    this.form.markAsDirty();
  }

  save(): void {
    const settings = this.form.value;

    delete settings.tenantSettings.environmentType;

    this.loading = true;

    if (settings.id) {
      this.settingsService.update(settings).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('tooltip.successful'),
            detail: this.translateService.instant('general_settings.tabs.tenant.save'),
            life: 3000,
          });

          this.form.markAsPristine();

          this.loading = false;
        },
        error: (e) => {
          this.errorHandler.handleError(e);
          this.loading = false;
          this.form.markAsDirty()
        }
      })
    }
    else {
      this.settingsService.create(settings).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('tooltip.successful'),
            detail: this.translateService.instant('general_settings.tabs.tenant.save'),
            life: 3000,
          });

          this.loading = false;
        },
        error: (e) => {
          this.errorHandler.handleError(e);
          this.loading = false;

        }
      })
    }
  }
}
