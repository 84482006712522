import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environments';
import {
  buildHttpParams,
  PaginatedResponse,
  PaginationInfo,
} from '../api/item-paginator';
import { Seal, SealRequest } from '../api/seals';

@Injectable({
  providedIn: 'root',
})
export class SealService {
  baseRoute = environment.genuzApiEndpoint;
  constructor(private httpClient: HttpClient) {}

  getSeals(pagination?: PaginationInfo): Observable<PaginatedResponse<Seal>> {
    let params = buildHttpParams(pagination);

    return this.httpClient
      .get<PaginatedResponse<Seal>>(this.baseRoute + 'seal', { params })
      .pipe(
        catchError((error) => {
          console.error('Error fetching seals:', error);
          return throwError(() => error);
        })
      );
  }

  createSeal(seal: Seal): Observable<Seal> {
    // Remove the ID from the object to avoid conflicts
    let request: SealRequest = { ...seal };
    delete request.id;
    return this.httpClient.post<Seal>(this.baseRoute + 'seal', request).pipe(
      catchError((error) => {
        console.error('Error creating seal:', error);
        return throwError(() => error);
      })
    );
  }

  updateSeal(seal: Seal): Observable<Seal> {
    return this.httpClient
      .put<Seal>(this.baseRoute + 'seal/' + seal.id, seal)
      .pipe(
        catchError((error) => {
          console.error('Error updating seal:', error);
          return throwError(() => error);
        })
      );
  }

  deleteSeal(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.baseRoute + 'seal/' + id).pipe(
      catchError((error) => {
        console.error('Error deleting seal:', error);
        return throwError(() => error);
      })
    );
  }
}
