<div class="grid item-details">
    <div class="col-12">
        <div class="card min-h-screen">
            <div class="details">
                @if(item){
                <div class="header">
                    <div class="icon">
                        <img src="{{iconUrlImg}}" alt="" srcset="">
                    </div>
                    <div class="headline">
                        <h1>{{ item.headline }}</h1>
                    </div>
                    <div class="name">
                        <h2> {{ item.name }}</h2>
                    </div>
                    <div class="subheader">
                        <div>
                            <p-chip [label]=" getTypeLabel(item.itemType?.id)" />
                        </div>
                        <div class="ratings">
                            <p-rating [(ngModel)]="rating" [cancel]="false" [readonly]="true" />
                            <span class="p-text-secondary text-lg ml-2">
                                <b>{{rating}} ({{nRates}})</b>
                            </span>
                        </div>
                        <div class=" owner">
                            <span class="p-text-secondary block text-xl">
                                <b>{{ 'item_details.header.subheader.onwer' | translate }}: </b>
                                {{ item.ownerName}}
                            </span>
                        </div>
                    </div>
                    <div class="actions">
                        @if(userHasPermission){
                        <a pButton rounded [href]="itemUrl" target="_blank"
                           [label]="'item_details.header.actions.open' | translate"
                           size="large"></a>
                        } @else if (userPermissionPending){
                        <p-button rounded [label]="'item_details.header.actions.pending' | translate" disabled
                                  size="large" />
                        } @else{
                        <a pButton rounded [label]="'item_details.header.actions.get' | translate"
                           (click)="toggleRequestForm()" size="large"></a>
                        }
                    </div>
                </div>
                <div class="content px-8">
                    <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex">
                        <p-tabPanel [header]="'item_details.content.tab.overview' | translate">

                            <app-details-overview-tab [item]="item"></app-details-overview-tab>
                        </p-tabPanel>
                        <p-tabPanel [header]="'item_details.content.tab.relationships' | translate">

                            <app-details-relationship-tab [item]="item"></app-details-relationship-tab>
                        </p-tabPanel>
                    </p-tabView>
                </div>
                }
            </div>
        </div>
    </div>
</div>
@if(item){
<app-request-access-form [(isOpen)]="openRequestForm" [item]="item"
                         (requestSent)="checkUserPermissions($event)"></app-request-access-form>
}