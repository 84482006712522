import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../../service/app.layout.service";
import { SearchService } from "../../service/search.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ItemPaginator } from "../../api/item-paginator";
import { Item, ItemIcon, ItemPreview, ItemStatusEnum } from "../../api/item";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { FilterButtonComponent } from "../my-catalog/filter-button/filter-button.component";
import { ChipModule } from "primeng/chip";
import { UserService } from "../../service/user.service";
import { Observable } from "rxjs";
import { ItemSearchResult } from "../../api/item-search-result";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from "primeng/paginator";
import { ItemRequestService } from "../../service/item-request.service";
import { ItemAccessRequestStatusEnum } from "../../api/item-request";
import { HighlightDirective } from "../../directives/highlight.directive";
import { RequestAccessFormComponent } from "../../components/request-access-form/request-access-form.component";
import { RatingModule } from "primeng/rating";
import { catalogTypesTranslationPaths, translateFromArrayAt } from "../../api/term";
import { DetailsComponent } from "../../components/details/details.component";

@Component({
  selector: 'item-search',
  standalone: true,
  providers: [],
  imports: [
    CommonModule,
    TranslateModule,
    ProgressSpinnerModule,
    FilterButtonComponent,
    ChipModule,
    ButtonModule,
    PaginatorModule,
    RouterModule,
    HighlightDirective,
    RequestAccessFormComponent,
    RatingModule,
    DetailsComponent
  ],
  templateUrl: './item-search.component.html',
  styleUrl: './item-search.component.scss',
})
export class ItemSearchComponent implements OnInit {

  loading: boolean = true;
  items: Item[] = [];
  total: number = 0;
  queryParams!: ItemPaginator;
  first: number = 0;
  rows: number = 15;
  rating = '3.0';
  sidebarVisible = false;
  selectedItem?: Item;
  itemUrl = '';

  openRequestForm = false;

  constructor(
    private layoutService: LayoutService,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private itemRequestService: ItemRequestService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.layoutService.setAdminStuff(false);

    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params as ItemPaginator;

      if (!this.queryParams.text)
        this.loading = false;

      this.paginate(this.queryParams);
    })
  }

  paginate(paginator: ItemPaginator): void {
    paginator = {
      ...paginator,
      numberOfRecords: this.rows,
      itemStatusId: ItemStatusEnum.PUBLISHED
    };

    this.loading = true;

    this.searchService.search(paginator).subscribe((result: ItemSearchResult) => {
      this.items = result.items;
      this.total = result.total;

      this.items.forEach(async item => {
        this.setPhoto(item);
        this.setAccessStatus(item);
        await this.setThumbnail(item);
      });

      this.loading = false;

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: paginator,
        queryParamsHandling: 'merge'
      });
    });
  }

  async getFileFromBlob(file: ItemPreview | ItemIcon): Promise<File> {
    const url = 'data:image/png;base64,' + file?.content;

    const res = await fetch(url);
    const blob = await res.blob();

    return new File([blob], `${file?.name}`, { type: 'image/png' });
  }

  setPhoto(item: Item): void {
    if (item.ownerId)
      this.getOwnerPhoto(item.ownerId)
        .subscribe(avatar => item.ownerPhoto = avatar)
  }

  async setThumbnail(item: Item): Promise<void> {
    const thumbnail = item.itemPreviews.find((preview: any) => preview.isThumbnail) as ItemPreview;
    const icon = item.itemIcon;

    if (thumbnail)
      thumbnail.refUrl = URL.createObjectURL(await this.getFileFromBlob(thumbnail));

    if (icon)
      icon.refUrl = URL.createObjectURL(await this.getFileFromBlob(icon));
  }

  getThumbnail(item: Item): string {
    return item.itemPreviews.find(preview => preview.isThumbnail)?.refUrl || '';
  }

  getIcon(item: Item): string {
    return item.itemIcon?.refUrl || '';
  }

  getOwnerPhoto(ownerId?: string): Observable<string> {
    return this.userService.getPhotoByUserId(ownerId ?? '');
  }

  hasAccess(item: Item): boolean {
    const userMsalId = this.userService.getUserId();
    return item.itemPermissions.some(
      (permission) => permission.userId === userMsalId
    );
  }

  isOwner(item: Item): boolean {
    const userMsalId = this.userService.getUserId();

    return item.ownerId === userMsalId;
  }

  setAccessStatus(item: Item): void {
    const userMsalId = this.userService.getUserId();
    const userHasPermission = this.hasAccess(item);

    if (!userHasPermission) {
      this.itemRequestService.getMyItemRequests().subscribe(requests => {
        const itemRequest = requests.find(
          (request) =>
            request.itemId === item.id &&
            request.userId === userMsalId &&
            request.statusId === ItemAccessRequestStatusEnum.Pending
        );

        item.isAccessPending = !!itemRequest;
      })
    }
  }

  updateFilteredItems(filteredItems: any[]): void { }

  toggleRequestForm(item: Item, event: any): void {
    event.preventDefault();

    this.openRequestForm = !this.openRequestForm;
    this.selectedItem = this.openRequestForm ? item : undefined;

    this.setAccessStatus(item);
  }

  toggleDetailsView(item: Item, event: any): void {
    event.preventDefault();

    this.sidebarVisible = !this.sidebarVisible;
    this.selectedItem = this.sidebarVisible ? item : undefined;
  }

  onPageChange(event: any): void {
    this.first = event.first;
    this.rows = event.rows;

    let paginator: ItemPaginator = {
      ...this.queryParams,
      currentPage: (event.page + 1).toString(),
      numberOfRecords: event.rows,
      itemStatusId: ItemStatusEnum.PUBLISHED
    };

    this.paginate(paginator);
  }

  getTypeLabel(typeKey?: number): string {
    if (!typeKey) return '';

    return translateFromArrayAt(
      catalogTypesTranslationPaths,
      typeKey as number,
      this.translateService
    );
  }

  toItemView(item: any): void {

    const itemUrl =
    item.id +
    '&url=' +
    encodeURIComponent(`${item.itemAccessUrl}`);

    window.open('/item-view?itemId=' + itemUrl, '_blank');
  }
}
