<p-autoComplete
  id="user-dropdown"
  name="user"
  filterBy="name"
  appendTo="body"
  field="name"
  [required]="required"
  [placeholder]="placeholder | translate"
  [(ngModel)]="user"
  [suggestions]="users"
  [showClear]="true"
  [disabled]="isDisabled"
  (ngModelChange)="modelChange($event)"
  (onSelect)="selectUser($event)"
  (completeMethod)="search($event)"
  (onClear)="onClear()">

  <ng-template let-user pTemplate="item">
    <div class="flex align-items-center gap-2">
      <div>
        <i [class]="user.groupType ? 'pi pi-users' : 'pi pi-user'"></i>
        {{ user.name }}
        {{ user.mail ? '(' + user.mail + ')' : '' }}
        {{ user.groupType ? '(' + ('components.user-dropdown.groupType' | translate) + ')' : '' }}
      </div>
    </div>
  </ng-template>
</p-autoComplete>
