import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import {
  GenericType,
  catalogTypesTranslationPaths,
  termsTypesTranslationPaths,
  translateFromArray,
} from '../../../api/term';
import { CatalogService } from '../../../service/catalog.service';
import { GlossaryService } from '../../../service/glossary.service';
import { UserDropdownComponent } from '../../../components/user-dropdown/user-dropdown.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { User } from '../../../api/user';
import { SearchService } from '../../../service/search.service';
import { ActivatedRoute } from '@angular/router';
import { UserItemFavoriteService } from '../../../service/user-item-favorites.service';

type FilterPagination = {
  size: number;
  page: number;
};

export type SelectedFilters = {
  selectedType: string;
  selectedOwner: User;
  selectedTermName: string;
};

@Component({
  selector: 'app-filter-button',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FieldsetModule,
    DropdownModule,
    FormsModule,
    SidebarModule,
    SkeletonModule,
    UserDropdownComponent,
    TranslateModule,
  ],
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss',
})
export class FilterButtonComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() isMyCatalogFilter!: boolean;
  @Input() isSearchFilter!: boolean;
  @Input() filterPagination!: FilterPagination;
  @Output() filteredItems = new EventEmitter<any[]>();
  @Output() selectedFilters = new EventEmitter<SelectedFilters>();

  isLoading = false;
  isSidebarVisible: boolean = false;

  getTypeOptions = (): Observable<GenericType[]> =>
    translateFromArray(catalogTypesTranslationPaths, this.translateService);

  getTermTypeOptions = (): Observable<GenericType[]> =>
    translateFromArray(termsTypesTranslationPaths, this.translateService);

  termOptions: SelectItem[] = [];

  selectedType!: string;
  selectedOwner!: User;
  selectedTermType!: string;
  selectedTermName!: string;

  pageSize: number = 10;
  currentPage: number = 0;

  constructor(
    private catalogService: CatalogService,
    private glossaryService: GlossaryService,
    private searchService: SearchService,
    private favoritesService: UserItemFavoriteService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {}

  isOutlined() {
    return !(this.selectedType || this.selectedOwner || this.selectedTermName);
  }

  sidebarVisibleToggle() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  loadTerms(): void {
    const filters = {
      type: this.selectedTermType,
    };

    this.glossaryService.getTermsFiltered(filters).subscribe((terms) => {
      this.termOptions = terms.map((term) => ({
        label: term.name,
        value: term.id,
      }));
    });
  }

  clearFilters(): void {
    this.selectedOwner = { name: '' } as User;
    this.selectedType = '';
    this.selectedTermName = '';
    this.selectedTermType = '';
    this.termOptions = [];
  }

  loadItems(toggleSidebar: boolean = true) {
    this.isLoading = true;

    let filters: any = {
      StatusId: 2,
    };

    if (this.filterPagination)
      filters = { ...filters, ...this.filterPagination };

    if (this.selectedType) {
      filters.ItemTypeId = this.selectedType;
    }

    if (this.selectedOwner) {
      filters.OwnerName = this.selectedOwner.name;
    }

    if (this.selectedTermName) {
      filters.TermId = this.selectedTermName;
    }

    if (this.isMyCatalogFilter) {
      this.catalogService
        .getCatalogItems({
          currentPage: this.filterPagination?.page ?? 0,
          numberOfRecords: this.filterPagination?.size ?? 10,
          filters: filters,
        })
        .subscribe((filteredItems) => {
          this.filteredItems.emit(filteredItems.items);
          this.selectedFilters.emit({
            selectedType: this.selectedType,
            selectedOwner: this.selectedOwner,
            selectedTermName: this.selectedTermName,
          });

          this.isLoading = false;
          if (toggleSidebar) this.sidebarVisibleToggle();
        });
    } else if (this.isSearchFilter) {
      this.activatedRoute.queryParams.subscribe((params) => {
        filters = {
          ...filters,
          ...params,
        };

        this.searchService.search(filters).subscribe((filteredItems) => {
          this.filteredItems.emit(filteredItems.items);
          this.isLoading = false;
          if (toggleSidebar) this.sidebarVisibleToggle();
        });
      });
    } else {
      console.log(filters);
      this.catalogService.getStoreItems(filters).subscribe((filteredItems) => {
        this.filteredItems.emit(filteredItems);
        this.isLoading = false;
        if (toggleSidebar) this.sidebarVisibleToggle();
      });
    }
  }

  setOwner(user: User) {
    this.selectedOwner = user;
  }
}
