import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Item, ItemCustomField } from '../../../api/item';
import { GlossaryService } from '../../../service/glossary.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item-custom-fields',
  standalone: true,
  imports: [
    FormsModule,
    DataViewModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    JsonPipe,
    TableModule,
    ToastModule,
    TranslateModule
  ],
  templateUrl: './item-custom-fields.component.html',
  styleUrl: './item-custom-fields.component.scss',
  providers: [GlossaryService, MessageService],
})

export class ItemCustomFieldsComponent {
  @Input() item: Item = {} as Item;
  @Input() isDisabled = false;
  @Output() itemCustomFieldsChanged = new EventEmitter<ItemCustomField[]>();

  itemCustomFields: ItemCustomField[] = [];

  selectedItemCustomFields: ItemCustomField[] = [];

  itemCustomField: ItemCustomField = {};

  itemCustomFieldVisible = ([] = [
    { value: true, description: 'Sim' },
    { value: false, description: 'Não' },
  ]);

  translatedTemplate: any = {
    page_report: '',
    table_search_field: ''
  };

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.itemCustomFields = (!!this.item.itemCustomFields ? this.item.itemCustomFields : []);
  }

  saveItemCustomField() {
    this.itemCustomFields.push({
      id: 10,
      label: this.itemCustomField.label,
      value: this.itemCustomField.value,
      visible: this.itemCustomField.visible,
    });

    this.itemCustomFieldsChanged.emit(this.itemCustomFields);
    this.itemCustomField = {};
  }

  deleteItemCustomField(itemCustomField: ItemCustomField): boolean {
    this.itemCustomFields = this.itemCustomFields
      .filter(x => x.id != itemCustomField.id);

    this.itemCustomFieldsChanged.emit(this.itemCustomFields);

    return false;
  }
}
