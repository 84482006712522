import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { HttpClient } from "@angular/common/http";
import { Observable, share } from "rxjs";

export interface ItemUserAccessResponse {
    readonly itemId: number,
    readonly userId: string,
    readonly userHasAccess: boolean,
}

@Injectable({
    providedIn: 'root'
})
export class ItemService {

    private readonly _baseUrl = environment.genuzApiEndpoint;

      constructor(private httpClient: HttpClient) {}
    
      public userHasAccess(itemId: number, userId: string): Observable<ItemUserAccessResponse> {
    
        return this.httpClient.get<ItemUserAccessResponse>(`${this._baseUrl}Item/${itemId}/user-has-access/${userId}`)
          .pipe(share());
      }
}