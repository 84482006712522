import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { Item } from '../../api/item';
import { ItemCardComponent } from '../../components/item-card/item-card.component';
import { LayoutService } from '../../service/app.layout.service';
import { CatalogService } from '../../service/catalog.service';
import { UserItemOrderService } from '../../service/user-item-order.service';
import {
  FilterButtonComponent,
  SelectedFilters,
} from './filter-button/filter-button.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

type TranslatedTemplate = {
  pageReport: string;
};

@Component({
  selector: 'app-my-catalog',
  standalone: true,
  imports: [
    ItemCardComponent,
    CarouselModule,
    SkeletonModule,
    TableModule,
    FilterButtonComponent,
    DataViewModule,
    ChipModule,
    TranslateModule,
    ToastModule,
    DragDropModule,
    ScrollingModule,
    ProgressSpinnerModule,
  ],
  providers: [MessageService],
  templateUrl: './my-catalog.component.html',
  styleUrl: './my-catalog.component.scss',
})
export class MyCatalogComponent implements OnInit {
  @ViewChild('carousel') carousel!: ElementRef;

  items: Item[] = [];
  originalItems: Item[] = [];

  isLoading = true;
  sidebarVisible: boolean = false;

  page: number = 0;

  responsiveOptions: any[] | undefined;

  translatedTemplate: TranslatedTemplate = {} as TranslatedTemplate;

  draggedItem: Item | null = null;
  dragOverIndex: number = -1;
  dropPosition: 'before' | 'after' = 'before';

  isFiltered: boolean = false;

  pageSize: number = 10;
  currentPage: number = 0;
  loading: boolean = false;
  allItemsLoaded: boolean = false;

  favoritesItems: Item[] = [];
  selectedFilters: any = {};
  currentFilters: any = {};

  constructor(
    public layoutService: LayoutService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private userItemOrderService: UserItemOrderService,
    private catalogService: CatalogService
  ) {}

  ngOnInit() {
    this.responsiveOptions = [
      {
        breakpoint: '1960px',
        numVisible: 7,
        numScroll: 7,
      },
      {
        breakpoint: '1830px',
        numVisible: 6,
        numScroll: 6,
      },
      {
        breakpoint: '1560px',
        numVisible: 5,
        numScroll: 5,
      },
      {
        breakpoint: '1230px',
        numVisible: 4,
        numScroll: 4,
      },
      {
        breakpoint: '980px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '800px',
        numVisible: 2,
        numScroll: 2,
      },
    ];

    this.layoutService.setAdminStuff(false);
    this.initTranslations();
    this.translateService.onLangChange.subscribe(() => this.initTranslations());
    this.loadInitialItems();
  }

  initTranslations(): void {
    this.translateService
      .get('my_catalog.dataview.page_report')
      .subscribe((res: string) => (this.translatedTemplate.pageReport = res));
  }

  get hasFavorites(): boolean {
    return Boolean(this.favorites.length);
  }

  get favorites(): Item[] {
    return this.favoritesItems;
  }

  filterByNonFavorite(items: Item[]): Item[] {
    return items.filter((item) => !item.favorited);
  }

  isSidebarOpen: boolean = false;

  get sidebarStatus(): boolean {
    return this.isSidebarOpen;
  }
  get currentPagination() {
    return {
      page: this.currentPage,
      size: this.pageSize,
    };
  }

  setIsSidebarOpen(value: boolean): void {
    this.isSidebarOpen = value;
  }

  updateSelectedFilters(selectedFilters: SelectedFilters) {
    this.selectedFilters = selectedFilters;
    this.currentFilters = {
      ...(selectedFilters.selectedType && { ItemTypeId: selectedFilters.selectedType }),
      ...(selectedFilters.selectedOwner && { OwnerName: selectedFilters.selectedOwner.name }),
      ...(selectedFilters.selectedTermName && { TermId: selectedFilters.selectedTermName })
    };
    this.loadInitialItems();
  }

  onFavoriteChange(item: Item): void {
    if (item.favorited) {
      this.items = this.items.filter((i) => i.id !== item.id);

      this.favoritesItems = [...this.favoritesItems, item];

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('tooltip.successful'),
        detail: this.translateService.instant('item_favorites.tooltip.add'),
        life: 3000,
      });
    } else {
      // adds item to items and order by item.id
      this.items = [...this.items, item];

      this.items.sort((a, b) => {
        if ((a.id ?? 0) < (b.id ?? 0)) {
          return -1;
        }
        if ((a.id ?? 0) > (b.id ?? 0)) {
          return 1;
        }
        return 0;
      });

      this.favoritesItems = this.favoritesItems.filter((i) => i.id !== item.id);
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('tooltip.successful'),
        detail: this.translateService.instant('item_favorites.tooltip.remove'),
        life: 3000,
      });
    }
  }

  @HostListener('wheel', ['$event']) onMousewheel(event: any) {
    if (event.target.offsetParent.className !== 'p-carousel-items-container')
      return;

    const maxScroll =
      (this.carousel as any)?.indicatorContent?.nativeElement?.children
        ?.length - 1 || 0;

    if (!maxScroll) return;

    event.preventDefault();

    if (event.wheelDelta > 0) {
      if (this.page < maxScroll) this.page += 1;
    } else if (this.page > 0) this.page -= 1;
  }

  onDragStart(event: DragEvent, item: Item): void {
    if (this.isFiltered) {
      event.preventDefault();
      return;
    }

    this.draggedItem = item;
    if (event.dataTransfer) {
      event.dataTransfer.setData('text', '');
    }
    document.body.classList.add('dragging-active');
  }

  onDragEnd(): void {
    this.draggedItem = null;
    this.dragOverIndex = -1;
    this.dropPosition = 'before';
    document.body.classList.remove('dragging-active');
  }

  onDragOver(event: DragEvent, index: number): void {
    if (!this.draggedItem || this.isFiltered) return;
    event.preventDefault();

    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const mouseX = event.clientX - rect.left;

    this.dropPosition = mouseX < rect.width * 0.4 ? 'before' : 'after';
    this.dragOverIndex = index;
  }

  onDrop(event: DragEvent, index: number) {
    if (!this.draggedItem || this.isFiltered) return;
    event.preventDefault();

    const movingToFavorites = this.draggedItem.favorited;

    let favorites = this.items.filter((x) => x.favorited);
    let others = this.items.filter((x) => !x.favorited);

    if (this.draggedItem.favorited) {
      favorites = favorites.filter((x) => x !== this.draggedItem);
    } else {
      others = others.filter((x) => x !== this.draggedItem);
    }

    if (this.dropPosition === 'after') {
      index++;
    }

    if (movingToFavorites) {
      favorites = [
        ...favorites.slice(0, index),
        this.draggedItem,
        ...favorites.slice(index),
      ];
    } else {
      others = [
        ...others.slice(0, index),
        this.draggedItem,
        ...others.slice(index),
      ];
    }

    this.items = [...favorites, ...others];

    const orders = [
      ...this.items
        .filter((item) => item.favorited)
        .map((item, i) => ({ itemId: item.id as number, order: i })),
      ...this.items
        .filter((item) => !item.favorited)
        .map((item, i) => ({ itemId: item.id as number, order: i })),
    ];

    this.userItemOrderService.update({ orders }).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('tooltip.successful'),
        detail: this.translateService.instant('tooltip.content.item_order'),
      });
    });

    this.draggedItem = null;
    this.dragOverIndex = -1;
    this.dropPosition = 'before';
  }

  loadInitialItems() {
    this.currentPage = 0;
    this.items = [];
    this.allItemsLoaded = false;
    this.loadFavorites();
    this.loadMoreItems();
  }

  loadFavorites() {
    this.catalogService
      .getCatalogFavoritesItems({
        currentPage: 0,
        numberOfRecords: 1000,
        sortField: 'id',
        sortOrder: 1,
        filters: this.currentFilters,
      })
      .subscribe({
        next: (response) => {
          this.favoritesItems = response.items;
        },
        error: (error) => {
          console.error('Erro ao carregar itens favoritos:', error);
        },
      });
  }

  get itemsCounter() {
    return this.items.length;
  }

  loadMoreItems() {
    if (this.loading || this.allItemsLoaded) return;

    this.loading = true;

    this.catalogService
      .getCatalogItems({
        currentPage: this.currentPage,
        numberOfRecords: this.pageSize,
        sortField: 'id',
        sortOrder: 1,
        filters: {
          ...this.currentFilters,
          StatusId: 2,
        },
      })
      .subscribe({
        next: (response) => {
          const newItems = response.items;
          this.items = [...this.items, ...newItems];

          if (newItems.length < this.pageSize) {
            this.allItemsLoaded = true;
          } else {
            setTimeout(() => {
              const container = document.querySelector('.items-container');
              if (container) {
                const content = container.querySelector('.grid-nogutter');
                if (content && content.clientHeight <= container.clientHeight) {
                  this.allItemsLoaded = false;
                  this.loadMoreItems();
                }
              }
            }, 100);
          }

          this.currentPage++;
          this.loading = false;
        },
        error: (error) => {
          console.error('Erro ao carregar itens:', error);
          this.loading = false;
        },
      });
  }

  onScroll(event: any) {
    const element = event.target;
    const nearBottom =
      element.scrollHeight - element.scrollTop - element.clientHeight < 50;

    if (nearBottom && !this.loading && !this.allItemsLoaded) {
      this.loadMoreItems();
    }
  }
}
