<!-- <p-toolbar>
  <a class="" href="/">{{ title }}</a>

  <div class="toolbar-spacer"></div>

  <a pButton pRipple [routerLink]="['profile']">Profile</a>

  @if (!loginDisplay) {
  <p-button (click)="loginMenu.toggle($event)">Login</p-button>
  }
  <p-menu #loginMenu [model]="loginItems" [popup]="true">
  </p-menu>

  @if (loginDisplay) {
  <button pButton [raised]="true" (click)="logoutMenu.toggle($event)">Logout</button>
  }
  <p-menu #logoutMenu [popup]="true" [model]="logoutItems">
  </p-menu>

</p-toolbar> -->
<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  @if (!isIframe) {
  <router-outlet></router-outlet>
  }
</div>
