<p-toast></p-toast>
<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <h3 class="mb-5">{{ 'users_groups.header' | translate }}</h3>

      <p-table #dt [value]="usersGroups" [columns]="cols" responsiveLayout="scroll" [rows]="rows"
               [globalFilterFields]="['Id','Name','Description']" [paginator]="true" [(first)]="paginatorIndex"
               [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]" [totalRecords]="totalRecords"
               [showCurrentPageReport]="true"
               [currentPageReportTemplate]="translatedTemplate.page_report" [(selection)]="selectedRows"
               selectionMode="multiple" [rowHover]="true" dataKey="id" [lazy]="true" [loading]="loading"
               (onLazyLoad)="getAllUsersGroups($event)">
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
              <div class="my-2">
                <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-outlined mr-3"
                        (click)="openNew()"></button>
              </div>
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="onGlobalFilter(dt, $event)"
                     [placeholder]="'users_groups.table.search_field' | translate" class="w-full sm:w-auto" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="Id">{{ 'users_groups.table.colum_header.id' | translate }} <p-sortIcon
                          field="Id"></p-sortIcon></th>
            <th pSortableColumn="Name"> {{ 'users_groups.table.colum_header.name' | translate }} <p-sortIcon
                          field="Name"></p-sortIcon></th>
            <th pSortableColumn="Description">{{ 'users_groups.table.colum_header.description' | translate }}
              <p-sortIcon
                          field="Description"></p-sortIcon>
            </th>
            <th><p-button [icon]="
              isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'
            " (click)="toggleFilter()" [rounded]="true" [text]="true" [raised]="true" severity="secondary" /></th>
          </tr>
          <tr *ngIf="isFilterRowVisible">
            <th></th>
            <th>
              <p-columnFilter type="text" field="Id"
                              placeholder="{{ 'users_groups.table.table_filter.id' | translate }}"
                              ariaLabel="Filter Id" />
            </th>
            <th>
              <p-columnFilter type="text" field="Name"
                              placeholder="{{ 'users_groups.table.table_filter.name' | translate }}"
                              ariaLabel="Filter Name" />
            </th>
            <th>
              <p-columnFilter type="text" field="Description"
                              placeholder="{{ 'users_groups.table.table_filter.description' | translate }}"
                              ariaLabel="Filter Type" />
            </th>
            <th>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-usersGroup>
          <tr>
            <td>
              <p-tableCheckbox [value]="usersGroup"></p-tableCheckbox>
            </td>
            <td>{{ usersGroup.id }}</td>
            <td>{{ usersGroup.name }}</td>
            <td>{{ usersGroup.description }}</td>
            <td>
              <div class="flex">
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-outlined mr-2"
                        (click)="editUserGroup(usersGroup)"></button>
                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-outlined"
                        (click)="openDeleteDialog(usersGroup)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>


      <p-dialog [style]="{width: '50vw'}" [breakpoints]="{ '1199px': '75vw', '1000px': '90vw' }"
                [(visible)]="isNewUsersGroupVisible"
                [modal]="true" [draggable]="false" [resizable]="false" (onHide)="hideDialog()" class="p-fluid">
        <ng-template pTemplate="header">
          @if(usersGroup.id){
          <h3>{{ usersGroup.name }}</h3>
          } @else {
          <h3>{{ 'users_groups.form.header.new' | translate }}</h3>
          }
        </ng-template>

        <ng-template pTemplate="content">
          <form [formGroup]="form" class="item-form ">
            <p-tabView [(activeIndex)]="activeTabIndex">
              <p-tabPanel header="info" class="line-height-3 m-0">
                <ng-template pTemplate="header">
                  <div class="flex justify-content-start mt-3">
                    <p>{{ 'users_groups.form.tab.general.title' | translate }}
                      <i class="pi pi-exclamation-circle" style="color: red"
                         *ngIf="activeTabIndex != 0 && (!form.valid && form.dirty)"
                         pTooltip="You have validation errors in the form."></i>
                    </p>
                  </div>
                </ng-template>

                <app-new-group [usersGroup]="usersGroup" (onEdit)="onEdit($event)"></app-new-group>

              </p-tabPanel>
              <p-tabPanel header="users" class="line-height-3 m-0">
                <ng-template pTemplate="header">
                  <div class="flex justify-content-start mt-3">
                    <p>{{ 'users_groups.form.tab.users.title' | translate }}

                    </p>
                  </div>
                </ng-template>

                <app-add-users-to-group [usersGroup]="usersGroup"
                                        (onChanged)="onUserChanged($event)"></app-add-users-to-group>

              </p-tabPanel>
            </p-tabView>
            <div class="flex justify-content-end flex-wrap">
              <div class="w-auto gap-2 flex justify-end items-end mb-5">
                <button pButton pRipple label="{{ 'users_groups.buttons.cancel' | translate }}" icon="pi pi-times"
                        class="p-button"
                        (click)="hideDialog()"></button>
                <button pButton pRipple label="{{ 'users_groups.buttons.save' | translate }}" icon="pi pi-check"
                        class="p-button"
                        (click)="save()" [disabled]="isDisabled()"></button>
              </div>
            </div>
          </form>
        </ng-template>
      </p-dialog>


      <p-dialog [(visible)]="deleteUsersGroupDialog" header="{{ 'components.dialogs.header.confirm' | translate }}"
                [modal]="true"
                [style]="{ width: '450px' }">
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
          <span *ngIf="usersGroup" [innerHTML]="translatedTemplate.dialog_delete_item"></span>
        </div>
        <ng-template pTemplate="footer">
          <button pButton pRipple icon="pi pi-times" class="p-button-text"
                  label="{{ 'components.dialogs.no' | translate }}"
                  (click)="deleteUsersGroupDialog = false"></button>
          <button pButton pRipple icon="pi pi-check" class="p-button-text"
                  label="{{ 'components.dialogs.yes' | translate }}" (click)="confirmDeleteUsersGroup()"></button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>