<p-toast></p-toast>

<div class="mt-3">
  <div class="mb-4">
    <h5 class="font-bold">{{ 'addons.settings.tabs.capacity.manager.title' | translate }}</h5>
    <p>{{ 'addons.settings.tabs.capacity.manager.description' | translate }}</p>
  </div>

  <form [formGroup]="form">
    <div class="flex flex-wrap align-items-center gap-3 mb-3 mt-6">
      <div class="flex w-full gap-4">

        <div class="flex w-6 gap-4">
          <p-dropdown
            [options]="capacityTypes"
            required
            formControlName="capacityType"
            optionLabel="name"
            class="w-4"
            (onChange)="resetCapacityType()"
            [placeholder]="'addons.settings.tabs.capacity.fields.capacityTypeDropdown' | translate" />

          <p-floatLabel class="w-full" *ngIf="form.get('capacityType')?.value?.id === CapacityType.PowerBI">
            <input
              pInputText
              required
              (keyup.enter)="refreshCapacityInfo($event)"
              (focusout)="refreshCapacityInfo($event)"
              formControlName="capacityId"
              id="capacityId"
              class="flex-auto w-full" />
            <label for="capacityId">{{ 'addons.settings.tabs.capacity.fields.capacityId' | translate }}</label>
          </p-floatLabel>

          <p-floatLabel class="w-full" *ngIf="form.get('capacityType')?.value?.id === CapacityType.MSFabric">
            <input
              pInputText
              required
              (keyup.enter)="refreshCapacityInfo($event)"
              (focusout)="refreshCapacityInfo($event)"
              formControlName="capacityName"
              id="capacityName"
              class="flex-auto w-full" />
            <label for="capacityName">{{ 'addons.settings.tabs.capacity.fields.capacityName' | translate }}</label>
          </p-floatLabel>
        </div>

        <div class="capacity-info flex flex-wrap justify-content-between gap-4 w-6 mb-4" *ngIf="loading">
          <div class="flex flex-wrap gap-4 w-6">
            <p-skeleton width="20rem" />
            <div class="flex gap-4">
              <p-skeleton width="8rem" />
              <p-skeleton width="5rem" />
            </div>
          </div>
          <p-skeleton width="8rem" height="2rem" borderRadius="16px" shape="circle" class="flex align-items-center" />
        </div>

        <div class="capacity-info flex justify-content-between gap-4 w-6" *ngIf="capacity && !loading && valid">
          <div class="flex align-items-center flex-wrap">
            <p *ngIf="capacity.displayName" class="font-semibold w-full">{{ capacity.displayName }}</p>

            <div class="flex gap-4">
              <p *ngIf="capacity.typeId">{{ 'addons.settings.tabs.capacity.fields.type' | translate }}: <b>{{ getCapacityType(capacity.typeId) }}</b></p>
              <p *ngIf="capacity.sku">SKU: <b>{{ capacity.sku }}</b></p>
            </div>

            <p *ngIf="form.get('capacityId')?.errors?.['capacity-not-found']" class="font-semibold">
              {{ 'addons.settings.tabs.capacity.validation.capacityNotFound' | translate }}
            </p>
          </div>

          <p-tag
            class="capacity-status flex align-items-center ml-6"
            [icon]="getStatusIcon(capacity.statusId)"
            [severity]="getStatusSeverity(capacity.statusId)"
            [value]="getStatus(capacity.statusId)"
            [rounded]="true" />
        </div>
      </div>

      <div class="flex w-full gap-4 mt-2" *ngIf="form.get('capacityType')?.value?.id === CapacityType.MSFabric">
        <p-floatLabel class="w-6">
          <input
            pInputText
            required
            formControlName="subscriptionId"
            (keyup.enter)="refreshCapacityInfo($event)"
            (focusout)="refreshCapacityInfo($event)"
            id="subscriptionId"
            class="flex-auto w-full" />
          <label for="subscriptionId">{{ 'addons.settings.tabs.capacity.fields.subscriptionId' | translate }}</label>
        </p-floatLabel>

        <p-floatLabel class="w-6">
          <input
            pInputText
            required
            formControlName="resourceGroupName"
            (keyup.enter)="refreshCapacityInfo($event)"
            (focusout)="refreshCapacityInfo($event)"
            id="resourceGroupName"
            class="flex-auto w-full" />
          <label for="resourceGroupName">{{ 'addons.settings.tabs.capacity.fields.resourceGroupName' | translate }}</label>
        </p-floatLabel>
      </div>
    </div>
  </form>
</div>

<addon-schedule [schedules]="schedules"></addon-schedule>
