<a (click)="cardClickHandler($event, isMyCatalogView)">
  <p-card [header]="getTypeLabel(item.itemType?.id)" [subheader]="item.name">
    <ng-template pTemplate="header">
      <div class="item-card-img" style="background-image: url({{urlImg}});"></div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="flex gap-3 mt-1 justify-content-between align-items-baseline">
        <a (click)="toggleSidebar($event)"
           class="p-button-link underline"
           rounded="true"
           target="_blank">
          {{'components.item-card.button.view' | translate }}</a>
        @if(isMyCatalogView){
        <a *ngIf="item.itemAccessUrl"
           pButton
           (click)="openButton()"
           [href]="itemUrl"
           class="p-button"
           rounded="true"
           target="_blank"
           [label]="'components.item-card.button.open' | translate"></a>
        <a *ngIf="!item.itemAccessUrl"
           pButton
           class="p-button p-button-disabled"
           style="opacity: 0.4;"
           rounded="true"
           (click)="$event.preventDefault();"
           [label]="'components.item-card.button.open' | translate"></a>
        } @else {
        @if (userPermissionPending){
        <a pButton disabled class="p-button" rounded="true" target="_blank"
           [label]="'item_details.header.actions.pending' | translate "></a>
        } @else{
        <a pButton rounded [label]="'item_details.header.actions.get' | translate"
           (click)="toggleRequestForm($event)"></a>
        }
        }
      </div>
    </ng-template>
  </p-card>
</a>

<app-request-access-form [(isOpen)]="openRequestForm" [item]="item" [isRequest]="true" [requester]="item.ownerName"
                         (requestSent)="checkUserPermissions($event)"></app-request-access-form>
<app-details [(isOpen)]="sidebarVisible" [item]="item"></app-details>