import { ItemPermissionRole, RoleLevelSecurity } from './rls';
import { Term } from './term';
import { Observable } from 'rxjs';

export enum ItemStatusEnum {
  DRAFT = 1,
  PUBLISHED = 2
}

export interface ItemStatus {
  id?: number;
  description?: string;
}

export interface Item {
  id?: number;
  name?: string;
  headline?: string;
  description?: string;
  ownerId?: string;
  ownerName?: string;
  ownerPhoto?: string;
  curator?: string;
  creationDate?: Date;
  creatorUser?: string;
  itemTerms?: ItemTerm[];
  itemCustomFields?: ItemCustomField[];
  itemPreviews: ItemPreview[];
  itemPermissions: ItemPermission[];
  itemDynamicRoles: ItemDynamicRole;
  itemType?: ItemType;
  itemTypeId?: number;
  itemSource?: ItemSource;
  itemSourceId?: number;
  itemIcon: ItemIcon;
  itemVersion: string;
  status?: ItemStatus;
  statusId?: number;
  itemAccessMethod?: ItemAccessMethod;
  itemAccessUrl?: string;
  isDeleted?: boolean;
  isAccessPending?: boolean;
  favorited?: boolean;
  resources: {
    hasFilter: boolean;
    hasPagination: boolean;
    hasStatusBar: boolean;
  }
}

export interface ItemType {
  id?: number;
  description?: string;
}

export interface ItemSource {
  id?: number;
  name?: string;
}

export interface ItemTerm {
  id?: number;
  termId?: number;
  itemId?: number;
  term?: Term;
  item?: Item;
}

export interface ItemCustomField {
  id?: number;
  label?: string;
  value?: string;
  itemId?: number;
  visible?: boolean;
}

export interface ItemPreview {
  isThumbnail: boolean;
  content: File;
  name: string;
  refUrl: string;
}

export interface ItemDynamicRole {
  itemId: number;
  roles: ItemPermissionRole[];
}

export interface ItemPermission {
  id: number;
  userId: string;
  roles: ItemPermissionRole[];
}

export interface ItemAccessMethod {
  id?: number;
  desc?: string;
  value?: string;
}

export interface ItemIcon {
  content: File;
  name: string;
  refUrl: string;
}

export const itemSources: ItemSource[] = [
  { id: 1, name: 'Genuz' },
  { id: 2, name: 'Power Bi' },
];

export function getItemSourceById(id: number): ItemSource {
  return itemSources.find((source) => source.id === id)!;
}

export function getAllItemSources(): Observable<ItemSource[]> {
  return new Observable((observer) => {
    observer.next(itemSources);
    observer.complete();
  });
}
