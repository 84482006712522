import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { Item } from '../../api/item';
import { ItemAccessRequestStatusEnum } from '../../api/item-request';
import {
  catalogTypesTranslationPaths,
  translateFromArrayAt,
} from '../../api/term';
import { RequestAccessFormComponent } from '../../components/request-access-form/request-access-form.component';
import { GA4Service } from '../../service/ga4.service';
import { ItemRequestService } from '../../service/item-request.service';
import { UserService } from '../../service/user.service';
import { DetailsComponent } from '../details/details.component';
import { RatingModule } from 'primeng/rating';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { TooltipModule } from 'primeng/tooltip';

type Rating = {
  total: number,
  average: number
};

@Component({
  selector: 'app-item-card',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    TranslateModule,
    RequestAccessFormComponent,
    DetailsComponent,
    RatingModule,
    FormsModule,
    TruncatePipe,
    TooltipModule
  ],
  templateUrl: './item-card.component.html',
  styleUrl: './item-card.component.scss',
})
export class ItemCardComponent implements OnInit, AfterViewInit {
  @Input() item!: Item;
  @Input() isMyCatalogView!: boolean;

  @Output() onFavoriteChange = new EventEmitter<boolean>();

  rating: Rating = {
    total: 0,
    average: 0
  };

  itemUrl = '';
  urlImg = 'assets/no_preview.png';

  userHasPermission = false;
  userPermissionPending = false;
  openRequestForm = false;
  sidebarVisible = false;

  constructor(
    private cdref: ChangeDetectorRef,
    private translateService: TranslateService,
    private userService: UserService,
    private itemRequestService: ItemRequestService,
    private gAService: GA4Service
  ) {}

  ngOnInit() {
    if (!this.isMyCatalogView) this.checkUserPermissions(this.item);

    this.itemUrl =
      '/item-view?itemId=' +
      this.item.id +
      '&url=' +
      encodeURIComponent(`${this.item.itemAccessUrl}`);
  }

  async ngAfterViewInit() {
    if (!this.item.itemPreviews) return;

    const thumbnail =
      this.item.itemPreviews.find((preview: any) => preview.isThumbnail) ||
      this.item.itemIcon;

    if (!thumbnail) return;

    const url = 'data:image/png;base64,' + thumbnail?.content;

    const res = await fetch(url);
    const blob = await res.blob();

    const file = new File([blob], `${thumbnail?.name}`, { type: 'image/png' });
    this.urlImg = URL.createObjectURL(file);

    this.cdref.detectChanges();
  }

  getTypeLabel(typeKey?: number): string {
    if (!typeKey) return '';

    return translateFromArrayAt(
      catalogTypesTranslationPaths,
      typeKey as number,
      this.translateService
    );
  }

  checkUserPermissions(item: Item) {
    const userMsalId = this.userService.getUserId();
    this.userHasPermission = item.itemPermissions.some(
      (permission) => permission.userId === userMsalId
    );

    if (!this.userHasPermission) {
      this.itemRequestService.getMyItemRequests().subscribe((requests) => {
        const itemRequest = requests.find(
          (request) =>
            request.itemId === item.id &&
            request.userId === userMsalId &&
            request.statusId === ItemAccessRequestStatusEnum.Pending
        );
        this.userPermissionPending = !!itemRequest;
      });
    }
  }

  toggleRequestForm(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.openRequestForm = !this.openRequestForm;
  }

  @Output() onToggleSidebar = new EventEmitter<boolean>();

  closeSidebar(value: boolean): void {
    this.onToggleSidebar.emit(value);
  }

  toggleSidebar(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.sidebarVisible = !this.sidebarVisible;
    this.onToggleSidebar.emit(this.sidebarVisible);
  }

  cardClickHandler(event: any, isMyCatalogView: boolean): void {
    event.preventDefault();
    this.gAService.sendGTMEvent('analytics_open', {
      'Item Name': this.item.name,
      'Item Source': this.item.itemSourceId,
    });
    if (isMyCatalogView) {
      document.location = this.itemUrl;
    } else {
      this.toggleSidebar(event);
    }
  }
  openButton() {
    this.gAService.sendGTMEvent('analytics_open', {
      'Item Name': this.item.name,
      'Item Source': this.item.itemSourceId,
    });
  }

  setAverageRate(rating: Rating): void {
    this.rating = rating;
  }

  setFavoriteChange(isFavorited: boolean): void {
    this.onFavoriteChange.emit(isFavorited);
  }
}
