<p-toast></p-toast>

<div class="mb-4 mt-5">
  <h5 class="font-bold flex align-items-center">
    <span class="mr-3">
      {{ 'addons.settings.tabs.capacity.schedule.title' | translate }}
    </span>
    <p-button
      icon="pi pi-plus"
      (onClick)="showScheduleModal = true; isEditing = false"
      rounded
      outlined />
  </h5>

  <div class="flex justify-content-between align-items-center flex-wrap">
    <p class="m-0">{{ 'addons.settings.tabs.capacity.schedule.description' | translate }}</p>
  </div>

  <div class="mt-4">
    <p-table
      #table
      [value]="schedules"
      [tableStyle]="{ 'min-width': '50rem' }"
      [rows]="5"
      [currentPageReportTemplate]="'addons.settings.tabs.security.table.page_report' | translate"
      paginator
      showCurrentPageReport
      rowHover>
      <ng-template pTemplate="header">
        <tr>
          <th class="w-32rem">{{ 'addons.settings.tabs.capacity.table.name' | translate }}</th>
          <th class="w-22rem">{{ 'addons.settings.tabs.capacity.table.recurrency' | translate }}</th>
          <th class="w-15rem">{{ 'addons.settings.tabs.capacity.table.turn_on' | translate }}</th>
          <th class="w-15rem">{{ 'addons.settings.tabs.capacity.table.turn_off' | translate }}</th>
          <th class="w-15rem">{{ 'addons.settings.tabs.capacity.table.last_run_time' | translate }}</th>
          <th class="w-1rem">{{ 'addons.settings.tabs.capacity.table.actions' | translate }}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-schedule>
        <tr>
          <td class="font-bold" style="color: var(--primary-color)">{{ schedule.name }}</td>
          <td>{{ formatRecurrency(schedule.recurrencyTypeId, schedule.recurrencyValue) }}</td>
          <td>{{ schedule.turnOn | date:'shortTime' }}</td>
          <td>{{ schedule.turnOff | date:'shortTime' }}</td>
          <td>{{ schedule.lastRunTime | date:'short' }}

          <td>
            <div class="flex">
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-outlined mr-2"
                (click)="edit(schedule)"></button>

              <button
                pButton
                pRipple
                icon="pi pi-trash"
                class="p-button-rounded p-button-outlined p-button-danger mr-2"
                (click)="displayDeleteModal(schedule)"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog
    [header]="(isEditing ? 'addons.settings.tabs.capacity.dialog.header_edit' : 'addons.settings.tabs.capacity.dialog.header') | translate"
    [modal]="true"
    [draggable]="false"
    [(visible)]="showScheduleModal"
    (onHide)="onModalHide()"
    [style]="{ width: '30rem', height: '470px' }">

    <form [formGroup]="form">
      <div class="flex flex-wrap align-items-center gap-3 mb-3">
        <p-floatLabel class="w-full mt-4">
          <input
            pInputText
            required
            formControlName="name"
            id="name"
            class="flex-auto w-full" />
          <label for="name">{{ 'addons.settings.tabs.capacity.table.name' | translate }}</label>
        </p-floatLabel>
      </div>

      <div class="flex align-items-center gap-3 mb-2 mt-3">
        <p-floatLabel class="w-full mt-4">
          <input
            pInputText
            required
            [readonly]="true"
            [disabled]="true"
            (focus)="op.toggle($event)"
            autocomplete="off"
            formControlName="recurrencyValue"
            id="recurrencyValue"
            class="flex-auto w-full" />
          <label for="recurrencyValue">{{ 'addons.settings.tabs.capacity.table.recurrency' | translate }}</label>

          <p-overlayPanel #op>
            <div class="recurrency-picker">
              <p-tabView [(activeIndex)]="activeIndex" (onChange)="clearAllValues()">
                <p-tabPanel [header]="'addons.settings.tabs.capacity.picker.weekly' | translate">                  
                  <div class="flex flex-wrap gap-2">
                    <button
                      *ngFor="let day of weekdays; let index = index"
                      [ngClass]="{'active': isWeekdaySelected(index) }"
                      pButton
                      pRipple
                      severity="secondary"
                      (click)="setRecurrency(index.toString())"
                      [label]="day"
                      ></button>
                  </div>
                </p-tabPanel>
                <p-tabPanel [header]="'addons.settings.tabs.capacity.picker.monthly' | translate">               
                  <p-calendar
                    appendTo="body"
                    inline
                    [showOtherMonths]="false"                   
                    selectionMode="multiple"
                    class="w-full recurrency-calendar"
                    inputId="calendar-timeonly"
                    id="recurrencyValue"
                    formControlName="recurrencyMonths"                   
                    ><ng-template pTemplate="day" let-date>
                      <span class="p-2" (click)="onDayClick($event)">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </p-calendar>                   
                </p-tabPanel>
              </p-tabView>

              <p-floatLabel class="block w-full mt-3 px-4 mb-4">
                <p-calendar
                  showIcon
                  [iconDisplay]="'input'"
                  class="w-full"
                  id="recurrencyEndDate"
                  inputId="recurrencyEndDate"
                  formControlName="recurrencyEndDate" />
                <label for="recurrencyEndDate">{{ 'addons.settings.tabs.capacity.table.recurrencyEndDate' | translate }}</label>
              </p-floatLabel>
            </div>
          </p-overlayPanel>

        </p-floatLabel>
      </div>

      <div class="flex align-items-center gap-3 mb-2 mt-3">
        <p-floatLabel class="w-full mt-4">
          <p-calendar
            showIcon
            timeOnly
            [iconDisplay]="'input'"
            class="w-full"
            inputId="calendar-timeonly"
            id="turnOn"
            formControlName="turnOn">
            <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
              <i class="pi pi-clock pointer-events-none pointer-events-none" (click)="clickCallBack($event)"></i>
            </ng-template>
          </p-calendar>
          <label for="turnOn">{{ 'addons.settings.tabs.capacity.table.turn_on' | translate }}</label>
        </p-floatLabel>
      </div>

      <div class="flex align-items-center gap-3 mb-2 mt-3">
        <p-floatLabel class="w-full mt-4">
          <p-calendar
            showIcon
            timeOnly
            [iconDisplay]="'input'"
            class="w-full"
            inputId="calendar-timeonly"
            id="turnOff"
            formControlName="turnOff">
            <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
              <i class="pi pi-clock pointer-events-none pointer-events-none" (click)="clickCallBack($event)"></i>
            </ng-template>
          </p-calendar>
          <label for="turnOff">{{ 'addons.settings.tabs.capacity.table.turn_off' | translate }}</label>
        </p-floatLabel>
      </div>
    </form>

    <div class="flex justify-content-end gap-2">
      <p-button
        class="mt-5"
        type="submit"
        [label]="'addons.save_btn' | translate"
        [disabled]="isSaveDisabled()"
        (onClick)="save()" />
    </div>
  </p-dialog>

  <p-dialog
    [header]="'components.dialogs.header.confirm' | translate"
    [(visible)]="showDeleteScheduleModal"
    (onHide)="onModalHide()"
    [modal]="true"
    [style]="{ width: '30rem' }">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span [innerHTML]="('addons.settings.tabs.capacity.dialog.delete_schedule' | translate).replace('{schedule}', this.form.get('name')?.value)"></span>
    </div>

    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        icon="pi pi-times"
        class="p-button-text"
        label="{{ 'components.dialogs.no' | translate }}"
        (click)="showDeleteScheduleModal = false"></button>

      <button
        pButton
        pRipple
        icon="pi pi-check"
        class="p-button-text"
        label="{{ 'components.dialogs.yes' | translate }}"
        (click)="delete()"></button>
    </ng-template>
  </p-dialog>

</div>
