<p-toast></p-toast>

<h3 class="mb-5">
  <a class="p-button p-button-link underline p-0 header-link" routerLink="/addons">{{ 'addons.header' | translate }}</a>
  <i class="pi pi-angle-right mx-2 text-lg"></i>
  <span class="font-semibold">{{ addon.title }} {{ 'addons.settings.header' | translate }}</span>
</h3>

<div class="flex flex-wrap w-full align-content-between settings">
  <p-tabView class="w-full h-full" (activeIndexChange)="onTabChange($event)">
    <p-tabPanel [header]="'addons.settings.tabs.general.title' | translate">
      <form #form="ngForm" class="flex flex-wrap mt-3">
        <div class="mb-4">
          <h5 class="font-bold">{{ 'addons.settings.tabs.general.auth.title' | translate }}</h5>
          <p>{{ 'addons.settings.tabs.general.auth.description' | translate }}</p>
        </div>

        <p-floatLabel *ngFor="let field of credentials.slice(0,3); let index = index;" class="w-full py-3">
          @if (field.title.toLowerCase().includes('secret')) {
          <p-password
            [id]="field.id"
            [name]="field.id"
            [(ngModel)]="credentials[index].value"
            [feedback]="false"
            [toggleMask]="true"
            required />
          }
          @else {
          <input title="credentials"
            pInputText
            [id]="field.id"
            [name]="field.id"
            [(ngModel)]="credentials[index].value"
            class="w-6"
            #ctrl="ngModel"
            required />
          }

          <label [for]="field.id">{{ field.title }}</label>
        </p-floatLabel>

        <div *ngIf="sourceId === AddOnSource.PowerBI" class="mt-5 w-full">
          <h5 class="font-bold">{{ 'addons.settings.tabs.general.workspace.title' | translate }}</h5>
          <p class="pb-3">{{ 'addons.settings.tabs.general.workspace.description' | translate }}</p>

          <p-floatLabel class="w-full py-3">
            <input title="workspace-title"
              pInputText
              [id]="this.workspaceField?.id"
              [name]="this.workspaceField?.title"
              [ngModel]="this.workspaceField?.value"
              class="w-6 mb-3"
              #ctrl="ngModel"
              required />
            <label> {{ this.workspaceField?.title }}</label>
          </p-floatLabel>

          <addon-scheduler
            (frequencyInMin)="setSchedulerFrequency($event); form.form.markAsDirty()"
            [initialFrequency]="addOnSchedulerSettings?.frequencyInMinutes"
            class="mt-2 w-6 block">
          </addon-scheduler>
        </div>
      </form>
    </p-tabPanel>

    <p-tabPanel [header]="'addons.settings.tabs.security.title' | translate">
      <role-level-security [installedAddOnId]="installedAddOn.id" (rolesChanged)="setRoles($event)"></role-level-security>
    </p-tabPanel>

    <p-tabPanel [header]="'addons.settings.tabs.capacity.title' | translate" *ngIf="sourceId === AddOnSource.PowerBI">
      <addon-capacity
        [refresh]="refreshTrigger.asObservable()"
        [capacity]="capacity"
        [schedules]="capacitySchedules"
        [sourceId]="sourceId"
        (capacityChanged)="setCapacity($event)"
        (isCapacityValid)="setValid($event)"
        ></addon-capacity>
    </p-tabPanel>

    <p-tabPanel [header]="'addons.settings.tabs.audit.title' | translate">
      <div class="mb-4">
        <h5 class="font-bold">{{ 'addons.settings.tabs.audit.logs.title' | translate }}</h5>
        <p>{{ 'addons.settings.tabs.audit.logs.description' | translate }}</p>
      </div>

      <div class="flex justify-content-end">
        <section>
          <span class="block mt-2 md:mt-0 p-input-icon-left mb-4">
            <i class="pi pi-search"></i>
            <input aria-label="log filter" pInputText type="text" [(ngModel)]="searchTerm"
              [placeholder]="'search.placeholder' | translate" class="sm:w-auto" />
          </span>
        </section>
      </div>

      <p-panel showHeader="false" class="p-0 log-panel">
        <div class="audit-logs p-4">
          <div *ngFor="let log of filterData" class="mb-3">
            <p *ngFor="let message of log.messages" [innerHTML]="parseMessage(message.message)"></p>
          </div>
        </div>
      </p-panel>

      <div class="mt-4 w-full flex justify-content-end">
        <p-button (click)="copyToClipboard()">{{ 'addons.settings.copy' | translate }}</p-button>
      </div>
    </p-tabPanel>
  </p-tabView>

  <div class="footer relative flex justify-content-end border-top-1 border-200 py-4 w-full bg-white">
    <p-button
      rounded
      severity="primary"
      [disabled]="!(isValid && isDirty) && !(form.valid && form.dirty)"
      [loading]="loading"
      (click)="save()"
      >{{ 'addons.save_btn' | translate }}</p-button>
  </div>
</div>
