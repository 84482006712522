import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { MsalService } from '@azure/msal-angular';
import { lastValueFrom, of } from 'rxjs';
import { map, catchError, last, tap, switchMap } from 'rxjs/operators';
import { SettingsService } from '../service/settings.service'
import { DialogHandlerService } from '../service/dynamic-dialog.service';

function getUserId(authService: MsalService): string {
  const account = authService.instance.getActiveAccount();
  return account?.localAccountId || '';
}

export const GeneralFeedbackGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const authService = inject(MsalService);
  const settingsService = inject(SettingsService);
  const dialogService = inject(DialogHandlerService);
  const router = inject(Router);

  return userService.getUserInformation(getUserId(authService)).pipe(
    switchMap(async(user) => {

    return await lastValueFrom(settingsService.getFeedback()).then(isFeedbackEnabled => {

      if (!user.hasSentFeedback && isFeedbackEnabled) {
        setTimeout(() => {
          dialogService.openFeedbackDialog();
        }, 5000); 
        return true;
      } else {
        return true;
      }
      })
    }
   ),
    catchError((error) => {
      console.error('Error fetching user information:', error);
      router.navigate(['/my-catalog']);
      return of(false);
    })
  ) ;
};
