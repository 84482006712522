import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { User, UsersGroup, UsersGroupUsers } from '../../../api/user';
import { UserDropdownComponent } from '../../../components/user-dropdown/user-dropdown.component';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-add-users-to-group',
  standalone: true,
  imports: [
    DialogModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    UserDropdownComponent,
    TableModule,
    ToastModule,
  ],
  providers: [MessageService],
  templateUrl: './add-users-to-group.component.html',
  styleUrl: './add-users-to-group.component.scss',
})
export class AddUsersToGroupComponent {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  @Output() onChanged = new EventEmitter<UsersGroupUsers[]>();

  @Input() usersGroup!: UsersGroup;
  selectedUser: User = { name: '' } as User;
  selectedUsers: User[] = [];
  isDisabled: boolean = false;

  users: User[] = [];
  usersGroupUsers: UsersGroupUsers[] = [];

  columnDefinitions: string[] = ['name', 'type', 'mail', 'actions'];

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  setUser(user: User): void {
    this.selectedUser = user;
  }

  translatedMessageService(
    severity: string = 'error',
    summary: string = 'Error',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }

  addUser(): void {
    if (this.users === undefined) {
      this.users = [];
    }

    if (this.users.some((x) => x.id == this.selectedUser.id)) {
      this.translatedMessageService(
        'error',
        'Error',
        'tooltip.content.user_group_duplicated'
      );

      this.selectedUser = { name: '' } as User;
      return;
    }

    this.users.push(this.selectedUser);
    this.usersGroupUsers.push({ userId: this.selectedUser.id });
    this.onChanged.emit(this.usersGroupUsers);
    this.selectedUser = { name: '' } as User;
  }

  deleteUser(user: User): void {
    this.users = this.users.filter((u) => u.id !== user.id);
    this.usersGroupUsers = this.usersGroupUsers.filter(
      (u) => u.userId !== user.id
    );
    this.onChanged.emit(this.usersGroupUsers);
  }

  ngOnInit() {
    this.loadUsers();
  }

  loadUsers() {
    this.usersGroupUsers = this.usersGroup.users ?? [];
    for (let user of this.usersGroupUsers) {
      this.userService
        .getUserAndGroupsById(user.userId)
        .subscribe((userOrGroup) => {
          this.users.push(userOrGroup);
        });
    }
  }

  hideDialog() {
    this.isVisible = false;
    this.usersGroup = {} as UsersGroup;
    this.isVisibleChange.emit(this.isVisible);
  }
}
