import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { PanelModule } from "primeng/panel";
import { SliderChangeEvent, SliderModule } from "primeng/slider";
import { TagModule } from "primeng/tag";

@Component({
  selector: 'addon-scheduler',
  standalone: true,
  providers: [
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SliderModule,
    FormsModule,
    TagModule,
    PanelModule
  ],
  templateUrl: './addon-scheduler.component.html',
  styleUrl: './addon-scheduler.component.scss',
})
export class AddonSchedulerComponent implements OnInit {

  @Output() frequencyInMin = new EventEmitter<number>();
  @Input() initialFrequency?: number = undefined;

  value = 60;

  constructor() { }

  ngOnInit(): void {
    if (this.initialFrequency)
      this.value = this.initialFrequency;
  }

  onChange(event: SliderChangeEvent): void {
    this.frequencyInMin.emit(event.value);
  }
}
