import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';
import { Agreement, HasTermsToAcceptQuery, UserAgreement } from '../api/agreement';

/**
 * Service for managing agreements requests.
 */
@Injectable({
  providedIn: 'root',
})

export class AgreementsService {
  baseRoute = environment.genuzApiEndpoint;
  constructor(private httpClient: HttpClient) {}

  hasTermsToAccept(): Observable<HasTermsToAcceptQuery> {
    return this.httpClient.get<HasTermsToAcceptQuery>(`${this.baseRoute}Agreements/has-user-terms-to-accept`);
  }

  getActiveAgreement(): Observable<Agreement> {
    return this.httpClient.get<Agreement>(`${this.baseRoute}Agreements`);
  }

  getAgreementsData(url: string): Observable<any> {
    return this.httpClient.get(url,{responseType:'text'})
  }

  acceptAgreement(body: UserAgreement): Observable<UserAgreement> {
    return this.httpClient.post<UserAgreement>(
      `${this.baseRoute}Agreements`,
      body
    );
  }
}
