import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CarouselModule } from 'primeng/carousel';
import { DataViewModule } from 'primeng/dataview';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { Item } from '../../api/item';
import { ItemCardComponent } from '../../components/item-card/item-card.component';
import { LayoutService } from '../../service/app.layout.service';
import { ChipModule } from 'primeng/chip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FilterButtonComponent } from '../my-catalog/filter-button/filter-button.component';
import { CommonModule } from '@angular/common';
import { StoreService } from '../../service/store.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

type TranslatedTemplate = {
  pageReport: string;
}

@Component({
  selector: 'app-store',
  standalone: true,
  imports: [
    CommonModule,
    ItemCardComponent,
    CarouselModule,
    SkeletonModule,
    TableModule,
    FilterButtonComponent,
    DataViewModule,
    ChipModule,
    TranslateModule,
    ScrollingModule,
    ProgressSpinnerModule
  ],
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit {
  items: Item[] = [];
  itemRows: Item[][] = [];
  readonly itemSize = 390; // Height of each row including padding
  itemsPerRow = 4; // Default value, will be updated based on container width
  isLoading = true;

  translatedTemplate: TranslatedTemplate = {} as TranslatedTemplate;

  pageSize: number = 15;
  currentPage: number = 0;
  loading: boolean = false;
  allItemsLoaded: boolean = false;
  displayedItems: Item[] = [];
  itemsCounter: number = 0;

  constructor(
    public layoutService: LayoutService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private storeService: StoreService
  ) {
    this.calculateItemsPerRow();
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateItemsPerRow();
  }

  calculateItemsPerRow() {
    const containerWidth = window.innerWidth - 48; // Account for padding
    const itemWidth = 286; // 270px width + 16px gap
    this.itemsPerRow = Math.max(1, Math.floor(containerWidth / itemWidth));
    this.updateItemRows();
  }

  updateItemRows() {
    if (!this.items?.length) {
      this.itemRows = [];
      return;
    }

    this.itemRows = [];
    for (let i = 0; i < this.items.length; i += this.itemsPerRow) {
      this.itemRows.push(this.items.slice(i, i + this.itemsPerRow));
    }
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.layoutService.setAdminStuff(false);
    this.initTranslations();
    this.translateService.onLangChange
      .subscribe(() => this.initTranslations());
    this.loadInitialItems();
  }

  get currentPagination() {
    return {
      page: this.currentPage,
      size: this.pageSize
    }
  }

  initTranslations(): void {
    this.translateService
      .get('my_catalog.dataview.page_report')
      .subscribe((res: string) => this.translatedTemplate.pageReport = res);
  }

  updateFilteredItems(filteredItems: any) {
    this.displayedItems = filteredItems.items;
    this.updateItemRows();
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  trackByFn(_index: number, row: Item[]): string {
    return row.map(item => String(item.id || '')).join(',');
  }

  loadInitialItems() {
    this.currentPage = 0;
    this.displayedItems = [];
    this.allItemsLoaded = false;
    this.loadMoreItems();
  }

  checkAndLoadMoreIfNeeded() {
    const container = document.querySelector('.items-container');
    if (!container) {
      return;
    }

    const containerHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    // If there's no scrollbar (content height equals container height) and we haven't loaded all items
    if (containerHeight <= clientHeight && !this.allItemsLoaded && !this.loading) {
      this.pageSize += 10; // Increase page size
      this.loadInitialItems(); // Reload with new page size
    }
  }

  loadMoreItems() {
    if (this.loading || this.allItemsLoaded) return;

    this.loading = true;

    this.storeService
      .getStoreItems({
        page: this.currentPage,
        size: this.pageSize,
      })
      .subscribe({
        next: (response) => {
          const newItems = response.items;
          this.displayedItems = [...this.displayedItems, ...newItems];
          this.itemsCounter = response.total;

          // Check if we have enough items to fill the container
          if (newItems.length < this.pageSize || this.displayedItems.length >= response.total) {
            this.allItemsLoaded = true;
          } else {
            // Check if we need more items to create a scrollbar
            setTimeout(() => {
              const container = document.querySelector('.items-container');
              if (container) {
                const content = container.querySelector('.grid-nogutter');
                if (content && content.clientHeight <= container.clientHeight) {
                  this.allItemsLoaded = false;
                  this.loadMoreItems();
                }
              }
            }, 100);
          }

          this.currentPage++;
          this.loading = false;
        },
        error: (error) => {
          console.error('Erro ao carregar itens:', error);
          this.loading = false;
        }
      });
  }

  onScroll(event: any) {
    const element = event.target;
    const nearBottom = element.scrollHeight - element.scrollTop - element.clientHeight < 50;

    if (nearBottom && !this.loading && !this.allItemsLoaded) {
      this.loadMoreItems();
    }
  }
}
