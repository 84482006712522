import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { User } from '../../api/user';
import { UserService } from '../../service/user.service';
import { DividerModule } from 'primeng/divider';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-manage-profile',
  standalone: true,
  providers: [MessageService],
  imports: [
    SidebarModule,
    TranslateModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
    ChipModule,
    ToastModule,
    InputTextModule,
    DividerModule,
    DropdownModule,
    FormsModule,
    InputSwitchModule,
  ],
  templateUrl: './manage-profile.component.html',
  styleUrl: './manage-profile.component.scss',
})
export class ManageProfileComponent {
  @Input() isOpen: boolean = false;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() user: User | undefined;
  @Output() userChange: EventEmitter<User> = new EventEmitter<User>();
  languages: any[] = [];
  selectedLanguage: any;
  emailNotification: boolean = false;

  constructor(
    private authService: MsalService,
    private userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (typeof window !== 'undefined')
      this.selectedLanguage = localStorage.getItem('language');
    this.translate.get('languages.options.en').subscribe((res: string) => {
      this.languages.push({
        label: res,
        code: 'en',
      });
    });

    this.translate.get('languages.options.pt').subscribe((res: string) => {
      this.languages.push({
        label: res,
        code: 'pt',
      });
    });
    this.setUserInfo();
  }

  setUserInfo(): void {
    this.userService.getUserInformation(this.getUserId()).subscribe((user) => {
      if (!user) return;
      this.user = user;
      this.emailNotification = user.setting?.allowNotifications ?? false;
      this.userService.getPhoto().subscribe((photo) => {
        if (this.user) this.user.photo = photo;
      });
    this.userChange.emit(this.user);
    });
  }
  getUserId(): string {
    const account = this.authService.instance.getActiveAccount();
    return account?.localAccountId || '';
  }

  toggleOpen(event: Event) {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }

  ngOnChanges() {
    this.isOpenChange.emit(this.isOpen);
  }

  updatePreferences(event: InputSwitchChangeEvent) {
    this.userService
      .updateNotificationPreference(this.getUserId(), event.checked)
      .subscribe((user) => {
        if (this.user)
          this.user.setting.allowNotifications =
            user.setting.allowNotifications;
        this.userChange.emit(this.user);
      });
  }

  changeSystemLanguage(event: DropdownChangeEvent) {
    if (typeof window !== 'undefined')
      localStorage.setItem('language', event.value);
    this.translate.use(event.value);
    this.userService
      .updateLanguagePreference(this.getUserId(), event.value)
      .subscribe((user) => {
        if (this.user) this.user.setting.language = user.setting.language;
        this.userChange.emit(this.user);
      });
  }
}
