import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { Item } from '../../api/item';
import { ItemRequest } from '../../api/item-request';
import { User } from '../../api/user';
import { GA4Service } from '../../service/ga4.service';
import { ItemRequestService } from '../../service/item-request.service';
import { UserService } from '../../service/user.service';
import { CustomInputValidators } from '../../utils/validator.utils';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Observable, of } from 'rxjs';
import { RoleLevelSecurity } from '../../api/rls';
import { MultiSelectModule } from 'primeng/multiselect';
import { RoleLevelSecurityService } from '../../service/rls.service';

@Component({
  selector: 'app-request-access-form',
  standalone: true,
  providers: [MessageService],
  imports: [
    SidebarModule,
    TranslateModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
    ChipModule,
    ToastModule,
    CommonModule,
    MultiSelectModule,
    AsyncPipe
  ],
  templateUrl: './request-access-form.component.html',
  styleUrl: './request-access-form.component.scss',
})
export class RequestAccessFormComponent implements OnInit {
  @Input() item!: Item;
  @Input() isOpen: boolean = false;
  @Input() isRequest!: boolean;
  @Input() isApprovalRequest!: boolean;
  @Input() request?: ItemRequest;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() requestSent: EventEmitter<Item> = new EventEmitter<Item>();
  @Input() requester?: string;

  requestAccessForm!: FormGroup;
  user!: User;
  submitButtonDisabled = false;
  submitButtonLabel: string = this.translate.instant(
    'request_access.form.button.submit'
  );
  isWaiting = false;

  selectedRoles: RoleLevelSecurity[] = [];
  roles$: Observable<RoleLevelSecurity[]> = of([]);

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private itemRequestService: ItemRequestService,
    private messageService: MessageService,
    private translate: TranslateService,
    private gAService: GA4Service,
    private rlsService: RoleLevelSecurityService
  ) {}

  ngOnInit(): void {
    this.roles$ = this.rlsService.getAll();

    this.createRequestAccessForm();
    this.setUserInfo();
  }

  createRequestAccessForm(): void {
    this.requestAccessForm = this.fb.group({
      itemName: this.item.name,
      ownerInfo: this.item.ownerName,
      requestJustification: ['', [Validators.required, CustomInputValidators.noWhitespaceValidator()]],
      roles: []
    });
  }

  get isRejectionRequest() {
    return !this.isRequest && !this.isApprovalRequest;
  }

  setUserInfo(): void {
    if (this.item.ownerId) {
      this.userService
        .getUserInformation(this.item.ownerId)
        .subscribe((user) => {
          this.user = user;

          if (this.user)
            this.userService
              .getPhotoByUserId(this.user.id)
              .subscribe((photo) => (this.user.photo = photo));
        });
    }
  }

  onSubmit(): void {
    this.submitButtonDisabled = true;
    this.submitButtonLabel = this.translate.instant(
      'request_access.form.button.waiting'
    );
    this.isWaiting = true;

    if (this.requestAccessForm.valid && this.item) {
      const params: any = {
        justification: this.requestAccessForm.value?.requestJustification?.trim(),
        requestId: this.request?.id,
        roles: this.requestAccessForm.value?.roles || []
      };

      if (this.isRequest)
        this.sendCreateRequestAccessItem(params);

      if (this.isRejectionRequest)
        this.sendRejectionRequestAccessItem(params);

      if (this.isApprovalRequest)
        this.sendApprovalRequestAccessItem(params);
    }
  }

  sendCreateRequestAccessItem(params: any): void {
    this.gAService.sendGTMEvent('access_request');
    params.itemId = this.item.id;

    let responsRequestHandler = {
      next: () => {
        this.translatedMessageService(
          'success',
          'tooltip.successful',
          'tooltip.content.item_request_sent'
        );

        this.submitButtonLabel = this.translate.instant(
          'request_access.form.button.submit'
        );
        this.isWaiting = false;

        this.closeSidebar();

        this.requestSent.emit(this.item);
        this.requestAccessForm.reset();
      },
      error: () => {
        this.submitButtonLabel = this.translate.instant(
          'request_access.form.button.submit'
        );
        this.isWaiting = false;

        this.translatedMessageService(
          'error',
          'tooltip.error',
          'tooltip.content.item_request_failed'
        );
      },
    };

    this.itemRequestService
      .postMyItemRequest(params)
      .subscribe(responsRequestHandler);
  }

  sendApprovalRequestAccessItem(params: any): void {
    let responseRequestHandler = {
      next: () => {
        this.translatedMessageService(
          'success',
          'tooltip.successful',
          'tooltip.content.item_approval_sent'
        );

        this.submitButtonLabel = this.translate.instant(
          'request_access.form.button.submit'
        );
        this.isWaiting = false;

        this.closeSidebar();

        this.requestSent.emit(this.item);
        this.requestAccessForm.reset();
      },
      error: () => {
        this.submitButtonLabel = this.translate.instant(
          'request_access.form.button.submit'
        );
        this.isWaiting = false;

        this.translatedMessageService(
          'error',
          'tooltip.error',
          'tooltip.content.item_request_failed'
        );
      },
    };

    this.submitButtonDisabled = false;
    this.itemRequestService
      .approveMyItemRequest(params)
      .subscribe(responseRequestHandler);
  }

  sendRejectionRequestAccessItem(params: any): void {
    params.requestId = this.request?.id;

    let responsRequestHandler = {
      next: () => {
        this.translatedMessageService(
          'success',
          'tooltip.successful',
          'tooltip.content.item_rejection_sent'
        );

        this.submitButtonLabel = this.translate.instant(
          'request_access.form.button.submit'
        );
        this.isWaiting = false;

        this.closeSidebar();

        this.requestSent.emit(this.item);
        this.requestAccessForm.reset();
      },
      error: () => {
        this.submitButtonLabel = this.translate.instant(
          'request_access.form.button.submit'
        );
        this.isWaiting = false;

        this.translatedMessageService(
          'error',
          'tooltip.error',
          'tooltip.content.item_rejection_failed'
        );
      },
    };

    this.submitButtonDisabled = false;
    this.itemRequestService
      .rejectMyItemRequest(params)
      .subscribe(responsRequestHandler);
  }

  closeSidebar(): void {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }

  ngOnChanges() {
    this.isOpenChange.emit(this.isOpen);
  }

  onShow(): void {
    if (this.isApprovalRequest)
      this.requestAccessForm.removeControl('requestJustification');
  }

  toggleOpen(event: Event) {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }

  translatedMessageService(
    severity: string = 'success',
    summary: string = 'Successful',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }
}
