import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';
import { CreateItemReviewCommand, ItemReview, ItemReviewGetList, ItemReviews, UpdateItemReviewCommand } from '../api/item-review';

/**
 * Service for managing item requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ItemReviewService {
  baseRoute = environment.genuzApiEndpoint;
  constructor(private httpClient: HttpClient) {}

  getItemReviews(requestParams: ItemReviewGetList): Observable<ItemReviews> {
    return this.httpClient.get<ItemReviews>(`${this.baseRoute}item/${requestParams.itemId}/reviews`, { params: {
      currentPage: requestParams.currentPage,
      numberOfRecords: requestParams.numberOfRecords,
      itemVersion: requestParams.itemVersion,
      orderBy: requestParams.orderBy,
      filterBy: requestParams.filterBy
    }});
  }

  create(itemId: number, payload: CreateItemReviewCommand): Observable<ItemReview> {
    return this.httpClient.post<ItemReview>(`${this.baseRoute}item/${itemId}/review`, payload);
  }

  delete(itemId: number, reviewId: number): Observable<ItemReview> {
    return this.httpClient.delete<ItemReview>(`${this.baseRoute}item/${itemId}/review/${reviewId}`);
  }

  update(itemId: number, reviewId: number, payload: UpdateItemReviewCommand): Observable<ItemReview> {
    return this.httpClient.put<ItemReview>(`${this.baseRoute}item/${itemId}/review/${reviewId}`, payload);
  }

  getAverageRate(itemReview: ItemReviews): number {
    let averageRate = 0;

    const ratingCount = itemReview.averageRate.ratingCount;
    const scoring = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 };

    const rates = { ...scoring, ...ratingCount };

    averageRate = ((
      rates[1] * 1 +
      rates[2] * 2 +
      rates[3] * 3 +
      rates[4] * 4 +
      rates[5] * 5) / itemReview.averageRate.total);

    return Number(averageRate.toFixed(1));
  }
}
