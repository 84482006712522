import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CarouselModule } from 'primeng/carousel';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { Item } from '../../api/item';
import { ItemCardComponent } from '../../components/item-card/item-card.component';
import { LayoutService } from '../../service/app.layout.service';
import { FilterButtonComponent } from './filter-button/filter-button.component';

type TranslatedTemplate = {
  pageReport: string;
};

@Component({
  selector: 'app-my-catalog',
  standalone: true,
  imports: [
    ItemCardComponent,
    CarouselModule,
    SkeletonModule,
    TableModule,
    FilterButtonComponent,
    DataViewModule,
    ChipModule,
    TranslateModule,
  ],
  templateUrl: './my-catalog.component.html',
  styleUrl: './my-catalog.component.scss',
})
export class MyCatalogComponent implements OnInit {
  items: Item[] = [];

  isLoading = true;

  translatedTemplate: TranslatedTemplate = {} as TranslatedTemplate;

  constructor(
    public layoutService: LayoutService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.layoutService.setAdminStuff(false);
    this.initTranslations();
    this.translateService.onLangChange.subscribe(() => this.initTranslations());
  }

  initTranslations(): void {
    this.translateService
      .get('my_catalog.dataview.page_report')
      .subscribe((res: string) => (this.translatedTemplate.pageReport = res));
  }

  updateFilteredItems(filteredItems: any[]) {
    this.items = filteredItems;
    this.isLoading = false;
  }
}
