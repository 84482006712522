<p-toast></p-toast>

<div class="mb-4 mt-2">
  <h5 class="font-bold flex align-items-center">
    <span class="mr-3">
      {{ 'addons.settings.tabs.security.rls.title' | translate }}
    </span>
    <p-button
      icon="pi pi-plus"
      (onClick)="showRoleModal = true; isEditing = false"
      rounded
      outlined />
  </h5>

  <div class="flex justify-content-between align-items-center flex-wrap">
    <p class="m-0">{{ 'addons.settings.tabs.security.rls.description' | translate }}</p>
  </div>

  <div class="mt-4">
    <p-table
      #table
      [value]="roles"
      [tableStyle]="{ 'min-width': '50rem' }"
      [rows]="5"
      [currentPageReportTemplate]="'addons.settings.tabs.security.table.page_report' | translate"
      [globalFilterFields]="['roleName', 'type']"
      paginator
      showCurrentPageReport
      rowHover>
      <ng-template pTemplate="header">
        <tr>
          <th class="w-32rem">{{ 'addons.settings.tabs.security.table.role' | translate }}</th>
          <th class="w-22rem">{{ 'addons.settings.tabs.security.table.type' | translate }}</th>
          <th class="w-1rem">{{ 'addons.settings.tabs.security.table.actions' | translate }}</th>
          <th class="w-1rem">
            <p-button
              rounded
              text
              raised
              [icon]="isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'"
              (click)="isFilterRowVisible = !isFilterRowVisible"
              severity="secondary" />
          </th>
        </tr>

        <tr *ngIf="isFilterRowVisible">
          <th class="w-32rem">
            <p-columnFilter
              type="text"
              field="roleName"
              [placeholder]="'addons.settings.tabs.security.table.filter.name' | translate" />
          </th>
          <th class="w-22rem">
            <p-columnFilter
              type="text"
              field="type"
              [showMenu]="false">
              <ng-template pTemplate="filter" let-filter="filterCallback">
                <p-dropdown
                  [options]="roleTypes"
                  appendTo="body"
                  optionLabel="value"
                  optionValue="id"
                  (onChange)="filter($event.value?.toString())"
                  [placeholder]="'addons.settings.tabs.security.table.filter.type' | translate">
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-role>
        <tr>
          <td class="font-bold" style="color: var(--primary-color)">{{ role.roleName }}</td>
          <td>{{ getRoleTypeById(role.type)?.value }}</td>
          <td>

            <div class="flex">
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-outlined mr-2"
                (click)="edit(role)"></button>

              <button
                pButton
                pRipple
                icon="pi pi-trash"
                class="p-button-rounded p-button-outlined p-button-danger mr-2"
                (click)="displayDeleteModal(role)"></button>
            </div>
          </td>
          <td></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog
    [header]="(isEditing ? 'addons.settings.tabs.security.table.dialog.header_edit' : 'addons.settings.tabs.security.table.dialog.header') | translate"
    [modal]="true"
    [draggable]="false"
    [(visible)]="showRoleModal"
    (onHide)="onModalHide()"
    [style]="{ width: '30rem', height: '350px' }">

    <form [formGroup]="form">
      <div class="flex flex-wrap align-items-center gap-3 mb-4">
        <p-floatLabel class="w-full mt-4">
          <input
            pInputText
            required
            (focusout)="onFocusOut($event)"
            formControlName="roleName"
            aria-describedby="role-name-help"
            id="roleName"
            class="flex-auto w-full" />
          <label for="roleName">{{ 'addons.settings.tabs.security.table.dialog.name' | translate }}</label>
        </p-floatLabel>

        <div class="w-full h-1rem relative">
          <small *ngIf="form.get('roleName')?.errors?.['duplicate']" id="role-name-help" class="text-red-500 block">
            {{ 'addons.settings.tabs.security.validation.duplicate' | translate }}
          </small>

          <small *ngIf="form.get('roleName')?.errors?.['emptyField']" id="role-name-help" class="text-red-500 block">
            {{ 'addons.settings.tabs.security.validation.emptyField' | translate }}
          </small>
        </div>
      </div>


      <div class="flex align-items-center gap-3 mb-2 mt-2 w-4rem">
        <p-dropdown
          formControlName="type"
          [options]="roleTypes"
          optionLabel="value"
          optionValue="id"
          required
          [placeholder]="'addons.settings.tabs.security.table.dialog.types.label' | translate" />
      </div>
    </form>

    <div class="flex justify-content-end gap-2">
      <p-button
        class="mt-5"
        type="submit"
        [label]="'addons.save_btn' | translate"
        [disabled]="!form.valid"
        (onClick)="save()" />
    </div>
  </p-dialog>

  <p-dialog
    [header]="'components.dialogs.header.confirm' | translate"
    [(visible)]="showDeleteRoleModal"
    (onHide)="onModalHide()"
    [modal]="true"
    [style]="{ width: '30rem' }">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span [innerHTML]="('addons.settings.tabs.security.dialogs.delete_role' | translate).replace('{role}', this.form.get('roleName')?.value)"></span>
    </div>

    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        icon="pi pi-times"
        class="p-button-text"
        label="{{ 'components.dialogs.no' | translate }}"
        (click)="showDeleteRoleModal = false"></button>

      <button
        pButton
        pRipple
        icon="pi pi-check"
        class="p-button-text"
        label="{{ 'components.dialogs.yes' | translate }}"
        (click)="delete()"></button>
    </ng-template>
  </p-dialog>
</div>
