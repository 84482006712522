import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { DataViewModule } from 'primeng/dataview';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { Item } from '../../api/item';
import { ItemCardComponent } from '../../components/item-card/item-card.component';
import { LayoutService } from '../../service/app.layout.service';
import { ChipModule } from 'primeng/chip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FilterButtonComponent } from '../my-catalog/filter-button/filter-button.component';

type TranslatedTemplate = {
  pageReport: string;
}

@Component({
  selector: 'app-store',
  standalone: true,
  imports: [
    ItemCardComponent,
    CarouselModule,
    SkeletonModule,
    TableModule,
    FilterButtonComponent,
    DataViewModule,
    ChipModule,
    TranslateModule
  ],
  templateUrl: './store.component.html',
  styleUrl: './store.component.scss',
})
export class StoreComponent implements OnInit {
  items: Item[] = [];

  isLoading = true;

  translatedTemplate: TranslatedTemplate = {} as TranslatedTemplate;

  constructor(
    public layoutService: LayoutService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.layoutService.setAdminStuff(false);
    this.initTranslations();
    this.translateService.onLangChange
      .subscribe(() => this.initTranslations());
  }

  initTranslations(): void {
    this.translateService
      .get('my_catalog.dataview.page_report')
      .subscribe((res: string) => this.translatedTemplate.pageReport = res);
  }

  updateFilteredItems(filteredItems: any[]) {
    this.items = filteredItems;
    this.isLoading = false;
  }
}
