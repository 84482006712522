import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CustomInputValidators } from '../../../utils/validator.utils';
import { RatingModule } from 'primeng/rating';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Item } from '../../../api/item';
import { DialogModule } from 'primeng/dialog';
import { ItemReviewService } from '../../../service/item-review.service';
import { CreateItemReviewCommand } from '../../../api/item-review';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { GA4Service } from '../../../service/ga4.service';

@Component({
  selector: 'item-review-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    RatingModule,
    ButtonModule,
    TranslateModule,
    DialogModule,
    ToastModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './item-review-form.component.html',
  styleUrl: './item-review-form.component.scss'
})
export class ItemReviewFormComponent implements OnInit {

  @Input() item!: Item;
  @Input() hasSentReview: boolean = false;
  @Output() onReviewSubmitted: EventEmitter<void> = new EventEmitter<void>();

  form!: FormGroup;

  constructor(
    private itemReviewService: ItemReviewService,
    private translationService: TranslateService,
    private messageService: MessageService,
    private gAService: GA4Service
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      rate: new FormControl(null),
      comment: new FormControl('', [CustomInputValidators.noWhitespaceValidator()])
    });
  }

  save(): void {
    const payload: CreateItemReviewCommand = {
      ...this.form.value,
      itemVersion: this.item.itemVersion
    };

    this.itemReviewService.create(
      this.item.id as number,
      payload).subscribe((review) => {
        this.form.reset();

        this.onReviewSubmitted.emit();

        this.gAService.sendGTMEvent('item_review_save', {
          'Item Name': this.item.name,
          'Comment': review.comment,
          'Rating': review.rate
        });

        this.messageService.add({
          severity: 'success',
          summary: this.translationService.instant('tooltip.successful'),
          detail: this.translationService.instant('item_reviews.tooltip.save'),
          life: 3000,
        });
      });
  }
}
