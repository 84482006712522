import { Item } from './item';
import { User } from './user';

export interface ItemRequest {
  id: number;
  date: Date;
  justification: string;
  itemId: number;
  item: Item;
  userId: string;
  statusId: ItemAccessRequestStatusEnum;
  response: string;
  requestType: ItemRequestTypeEnum;
  user: User;
}

export interface ItemRequestPost {
  justification?: string;
  itemId?: number;
}

export enum ItemAccessRequestStatusEnum {
  Pending = 1,
  Cancelled = 2,
  Approved = 3,
  Rejected = 4,
}

export enum ItemRequestTypeEnum {
  All = 0,
  Received = 1,
  Sent = 2,
  Pending = 3
}
