<p-card [style]="{ width: '350px' }" class="addon-card">
  <div class="grid header">
    <img class="col-fixed header-icon"
         width="100"
         height="100"
         [src]="addon.logo ?? 'assets/no_preview.png'" />
    <p class="col font-bold flex align-items-center">{{ addon.title }}</p>

    <p-button
      *ngIf="isInstalled"
      icon="pi pi-cog"
      class="ml-2"
      severity="secondary"
      (click)="openSettings()"
      [rounded]="true"
      [outlined]="true" />
  </div>

  <p class="mt-3">
    {{ addon.description }}
  </p>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-between gap-3 mt-2">
      <a class="p-button-link underline flex justify-content-center align-items-center"
         rounded
         [href]="addon.linkDetail"
         target="_blank">{{ 'addons.card.footer.view_more' | translate }}</a>

      <p-button
        (click)="loadWorkspace()"
        *ngIf="!isInstalled"
        [disabled]="!addon.authenticationFields"
        rounded
        severity="primary"
        target="_blank">{{ 'addons.card.footer.install' | translate  }}</p-button>

      <p-button
        (click)="uninstall()"
        *ngIf="isInstalled"
        [disabled]="!addon.authenticationFields"
        rounded
        severity="warning"
        target="_blank">{{ 'addons.card.footer.uninstall' | translate  }}</p-button>
    </div>
  </ng-template>
</p-card>

<addon-setup-dialog
  [addon]="addon"
  [showSetupDialog]="showSetupDialog"
  (closed)="onDialogClose($event)">
</addon-setup-dialog>
