import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environments';
import {
  buildHttpParams,
  PaginatedResponse,
  PaginationInfo,
} from '../api/item-paginator';
import { UsersGroup, UsersGroupRequest } from '../api/user';

@Injectable({
  providedIn: 'root',
})
export class UsersGroupsService {
  baseRoute = environment.genuzApiEndpoint;
  constructor(private httpClient: HttpClient) {}

  getUsersGroups(
    pagination?: PaginationInfo
  ): Observable<PaginatedResponse<UsersGroup>> {
    let params = buildHttpParams(pagination);

    return this.httpClient
      .get<PaginatedResponse<UsersGroup>>(this.baseRoute + 'usersgroups', {
        params,
      })
      .pipe(
        catchError((error) => {
          console.error('Error fetching users groups:', error);
          return throwError(() => error);
        })
      );
  }

  createUsersGroup(usersGroup: UsersGroup): Observable<UsersGroup> {
    var request: UsersGroupRequest = {
      ...usersGroup
    };

    return this.httpClient
      .post<UsersGroup>(this.baseRoute + 'usersgroups', request)
      .pipe(
        catchError((error) => {
          console.error('Error creating users group:', error);
          return throwError(() => error);
        })
      );
  }

  updateUsersGroup(usersGroup: UsersGroup): Observable<UsersGroup> {
    var request: UsersGroupRequest = {
      ...usersGroup,
      
    };

    return this.httpClient
      .put<UsersGroup>(this.baseRoute + 'usersgroups/' + usersGroup.id, request)
      .pipe(
        catchError((error) => {
          console.error('Error updating users group:', error);
          return throwError(() => error);
        })
      );
  }

  deleteUsersGroup(id: number): Observable<void> {
    return this.httpClient
      .delete<void>(this.baseRoute + 'usersgroups/' + id)
      .pipe(
        catchError((error) => {
          console.error('Error deleting users group:', error);
          return throwError(() => error);
        })
      );
  }
}
