import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { Observable } from "rxjs";
import { ItemPaginator } from "../api/item-paginator";
import { ItemSearchResult } from "../api/item-search-result";

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  search(params: ItemPaginator): Observable<ItemSearchResult> {
    return this.httpClient.get<ItemSearchResult>(
      `${this.baseRoute}search`, { params: params as any });
  }
}
