import {
  CommonModule,
  CurrencyPipe,
  JsonPipe,
  NgClass,
  NgIf,
} from '@angular/common';
import { Component, ErrorHandler, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { Table, TableModule, TablePageEvent } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { Observable } from 'rxjs';
import {
  GenericType,
  Term,
  termsTypesTranslationPaths,
  translateFromArray,
  translateFromArrayAt,
} from '../../api/term';
import { User } from '../../api/user';
import { UserDropdownComponent } from '../../components/user-dropdown/user-dropdown.component';
import { LayoutService } from '../../service/app.layout.service';
import { CustomerService } from '../../service/customer.service';
import { GA4Service } from '../../service/ga4.service';
import { GlossaryService } from '../../service/glossary.service';
import { GlobalErrorHandler } from '../../utils/components/error-handler/error-handler.component';

@Component({
  selector: 'app-glossary',
  standalone: true,
  providers: [
    MessageService,
    ConfirmationService,
    CustomerService,
    GlossaryService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    ToastModule,
    ToolbarModule,
    SharedModule,
    ButtonModule,
    RippleModule,
    FileUploadModule,
    TableModule,
    InputTextModule,
    RatingModule,
    FormsModule,
    DialogModule,
    NgIf,
    NgClass,
    InputTextareaModule,
    DropdownModule,
    RadioButtonModule,
    InputNumberModule,
    CurrencyPipe,
    CommonModule,
    TagModule,
    InputSwitchModule,
    TabViewModule,
    MenuModule,
    ReactiveFormsModule,
    UserDropdownComponent,
    JsonPipe,
    TranslateModule,
  ],
  templateUrl: './glossary.component.html',
  styleUrl: './glossary.component.scss',
})
export class GlossaryComponent {
  form!: FormGroup;

  paginatorIndex: number = 0;
  paginatorRows: number = 10;

  tableItemActions: any[] = [];

  termDialog: boolean = false;

  deleteTermDialog: boolean = false;

  changeTermStatusDialog: boolean = false;

  terms: Term[] = [];

  term: Term = this.emptyTerm();

  activeTabIndex: number = 0;

  selectedTerms: Term[] = [];

  submitted: boolean = false;

  rowsPerPageOptions = [5, 10, 20];
  translatedTemplate: any = {
    page_report: '',
    form_tab_validation_tooltip: '',
    dialog_change_status: '',
    dialog_delete_term: '',
  };

  isFilterRowVisible: boolean = false;

  constructor(
    public layoutService: LayoutService,
    private glossaryService: GlossaryService,
    private messageService: MessageService,
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private translate: TranslateService,
    private gAService: GA4Service
  ) {}

  ngOnInit() {
    this.layoutService.setAdminStuff(true);

    this.initTranslations();

    this.translate.onLangChange.subscribe(() => {
      this.initTranslations();
    });

    this.glossaryService
      .getTerms()
      .subscribe((result) => (this.terms = result));

    this.form = new FormGroup({
      id: new FormControl(''),
      status: new FormControl(true, { nonNullable: true }),
      name: new FormControl(''),
      acronym: new FormControl(''),
      type: new FormControl(0, { nonNullable: true }),
      definition: new FormControl(''),
      owner: new FormControl(''),
      synonymous: new FormControl(''),
      examples: new FormControl(''),
      gpdr: new FormControl(false, { nonNullable: true }),
      origin: new FormControl(''),
    });
  }

  initTranslations() {
    this.translate
      .get('glossary.table.page_report')
      .subscribe((res: string) => {
        this.translatedTemplate.page_report = res;
      });

    this.translate
      .get('glossary.form.tab.general.validation_tooltip')
      .subscribe((res: string) => {
        this.translatedTemplate.form_tab_validation_tooltip = res;
      });

    this.translate
      .get('glossary.table.cell.actions.change_status')
      .subscribe((res: string) => {
        this.tableItemActions.push({
          label: res,
          icon: 'pi pi-pencil',
        });
      });
  }

  toggleFilter() {
    this.isFilterRowVisible = !this.isFilterRowVisible;
  }

  setOwner(user: User): void {
    this.form.controls['owner'].setValue(user.name);
    this.form.markAsDirty();
  }

  getTermTypeLabel(termTypeKey: number): string {
    return translateFromArrayAt(
      termsTypesTranslationPaths,
      termTypeKey,
      this.translate
    );
  }

  emptyTerm(): Term {
    return {
      status: true,
      gpdr: false,
      type: 0,
    };
  }

  getTermTypes(): Observable<GenericType[]> {
    return translateFromArray(termsTypesTranslationPaths, this.translate);
  }

  openNew() {
    this.term = this.emptyTerm();
    this.submitted = false;
    this.termDialog = true;
  }

  editTerm(term: Term) {
    this.termDialog = true;
    this.term = term;
    this.form.patchValue(term);
  }

  deleteTerm(term: Term) {
    this.term = { ...term };
    this.translate
      .get('glossary.dialogs.delete_term.message', {
        name: this.term.name,
      })
      .subscribe((translation: any) => {
        this.translatedTemplate.dialog_delete_term = translation;
        this.deleteTermDialog = true;
      });
  }

  confirmChangeStatus() {
    this.changeTermStatusDialog = false;

    if (this.term.id) {
      this.term.status = !this.term.status;

      this.glossaryService.updateTerm(this.term).subscribe({
        next: () => {
          this.glossaryService
            .getTerms()
            .subscribe((terms) => (this.terms = terms));

          this.translatedMessageService(
            'success',
            'tooltip.successful',
            'tooltip.content.term_status_changed'
          );
        },
        error: (e) => this.errorHandler.handleError(e),
      });

      this.terms[this.findIndexById(this.term.id)] = this.term;
    }

    this.term = this.emptyTerm();
  }

  translatedMessageService(
    severity: string = 'success',
    summary: string = 'Successful',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }

  confirmDelete() {
    this.deleteTermDialog = false;

    let itemToDelete: number = this.term.id!;

    this.glossaryService.deleteTerm(itemToDelete).subscribe({
      next: () => {
        this.terms = this.terms.filter((val) => val.id !== this.term?.id);

        this.translatedMessageService(
          'success',
          'tooltip.successful',
          'tooltip.content.term_deleted'
        );

        this.term = this.emptyTerm();
      },
      error: (e) => this.errorHandler.handleError(e),
    });
  }

  hideDialog() {
    this.termDialog = false;
    this.submitted = false;
    this.activeTabIndex = 0;
    this.form.reset();
  }

  changeTermStatus(term: Term) {
    this.term = { ...term };

    const statusKey = this.term.status
      ? 'entities.term.status.inactive'
      : 'entities.term.status.active';

    this.translate
      .get(['glossary.dialogs.change_status.message', statusKey], {
        name: this.term.name,
      })

      .subscribe((translations: any) => {
        this.translatedTemplate.dialog_change_status = translations[
          'glossary.dialogs.change_status.message'
        ].replace('{{status}}', translations[statusKey]);

        this.changeTermStatusDialog = true;
      });
  }

  pageChange(event: TablePageEvent): void {
    this.paginatorIndex = event.first;
    this.paginatorRows = event.rows;
  }

  nextPage(): void {
    this.paginatorIndex = this.paginatorIndex + this.paginatorRows;
  }

  saveTerm() {
    this.term = { ...this.term, ...this.form.value };

    this.submitted = true;

    if (
      this.term.name?.trim() &&
      this.term.type &&
      this.term.definition?.trim()
    ) {
      if (this.term.id) {
        this.glossaryService.updateTerm(this.term).subscribe({
          next: (result) => {
            this.terms[this.terms.findIndex((term) => term.id === result.id)] =
              result;

            this.terms = [...this.terms];

            this.translatedMessageService(
              'success',
              'tooltip.successful',
              'tooltip.content.term_updated'
            );
          },
          error: (e) => this.errorHandler.handleError(e),
        });
      } else {
        this.gAService.sendGTMEvent('glossary_add');
        this.glossaryService.insertTerm(this.term).subscribe({
          next: (result) => {
            this.terms = [...this.terms, result];

            if (this.terms.length > this.paginatorRows) this.nextPage();

            this.translatedMessageService(
              'success',
              'tooltip.successful',
              'tooltip.content.term_created'
            );
          },
          error: (e) => this.errorHandler.handleError(e),
        });
      }

      this.terms = [...this.terms];
      this.termDialog = false;
    }
  }

  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.terms.length; i++) {
      if (this.terms[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }
}
