import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomInputValidators {
  static noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control.value !== 'string')
        return null;
      
      const isWhitespace = control.value?.length > 0 && (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  static urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isUrl = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,512}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
        control.value
      );
      return isUrl ? null : { 'invalid-url': true };
    };
  }
}
