import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  ErrorHandler,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  factories,
  IEmbedConfiguration,
  models,
  service,
} from 'powerbi-client';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { interval, Subscription } from 'rxjs';
import { Item } from '../../api/item';
import { AppTopBarComponent } from '../../components/app-topbar/app.topbar.component';
import { DetailsComponent } from '../../components/details/details.component';
import { SafeURLPipe } from '../../pipes/safe-url.pipe';
import { CatalogService } from '../../service/catalog.service';
import { PowerBiService } from '../../service/power-bi.service';
import { GlobalErrorHandler } from '../../utils/components/error-handler/error-handler.component';
import { UserService } from '../../service/user.service';
import { User } from '../../api/user';
import { Router } from '@angular/router';

const THIRTY_MINUTES = 30 * 60 * 1000;

@Component({
  selector: 'app-item-view',
  standalone: true,
  providers: [
    MessageService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  imports: [
    AppTopBarComponent,
    CommonModule,
    FormsModule,
    ButtonModule,
    SafeURLPipe,
    SidebarModule,
    DetailsComponent,
    ToastModule,
    TranslateModule,
  ],
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss'],
})
export class ItemViewComponent implements OnInit, OnDestroy {
  @Input() item!: Item;
  @ViewChild('reportContainer') reportContainer!: ElementRef;

  sanitizedUrl: string = '';
  isFullscreen: boolean = false;
  isIframe: boolean = false;
  sidebarVisible: boolean = false;
  sourceId!: number;
  embedConfig: IEmbedConfiguration | undefined;
  error: boolean = false;
  isLoading: boolean = true;
  userId = this.userService.getUserId();
  userInfo: User | undefined;

  private tokenUpdateSubscription = new Subscription();
  private powerbi?: service.Service;

  private ERRORMESSAGES = {
    LOAD_ITEM: 'item_view.tooltip.error_loading_item',
    EMBED_TOKEN: 'item_view.tooltip.error_getting_embedded_token',
  };

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslateService,
    private catalogService: CatalogService,
    private powerBiService: PowerBiService,
    private userService: UserService,
    private router: Router,
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.loadItem();

    this.userService.getUserInformation(this.userId).subscribe({
      next: (user) => {
        this.userInfo = user;
        // if (user.agreementStatus != true) {
        //   this.router.navigate(['/user-agreement']);
        // }
      },
    })
  }

  ngOnDestroy(): void {
    this.tokenUpdateSubscription.unsubscribe();
  }

  private loadItem(): void {
    this.route.queryParams.subscribe(({ itemId }) => {
      if (itemId) {
        this.catalogService.getById(itemId).subscribe({
          next: (item) => this.handleItem(item),
          error: (error) =>
            this.handleError(error, this.ERRORMESSAGES.LOAD_ITEM),
        });
      }
    });
  }

  private handleItem(item: Item): void {
    this.item = item;
    this.sourceId = item.itemSourceId!;
    this.item.itemSourceId === 1 && item.itemAccessUrl
      ? this.initializeUrl(item.itemAccessUrl)
      : this.initializeEmbbedReport();
  }

  private initializeUrl(url: string): void {
    this.sanitizedUrl = url;
    this.isLoading = false;
  }

  private initializeEmbbedReport(): void {
    this.powerBiBootstrap();
    this.startTokenUpdateInterval();
  }

  private powerBiBootstrap(): void {
    this.updateEmbedToken();
  }

  private startTokenUpdateInterval(): void {
    this.tokenUpdateSubscription.add(
      interval(THIRTY_MINUTES).subscribe(() => this.updateEmbedToken())
    );
  }

  private updateEmbedToken(): void {
    const { reportId, workspaceId } = this.extractReportInfo();

    this.powerBiService
      .getEmbeddedToken(this.sourceId, reportId, workspaceId, this.item.id as number)
      .subscribe({
        next: (token) => this.handleEmbedToken(token.embeddedToken, reportId),
        error: (error) =>
          this.handleError(error, this.ERRORMESSAGES.EMBED_TOKEN),
      });
  }

  private extractReportInfo(): { reportId: string; workspaceId: string } {
    const parsedUrl = new URL(this.item.itemAccessUrl!);
    return {
      reportId: parsedUrl.searchParams.get('reportId') || '',
      workspaceId: parsedUrl.searchParams.get('groupId') || '',
    };
  }

  private handleEmbedToken(embedToken: string, reportId: string): void {
    this.isLoading = false;

    const settings = this.item.resources;

    this.embedConfig = {
      type: 'report',
      id: reportId,
      embedUrl: this.item.itemAccessUrl,
      accessToken: embedToken,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            visible: settings.hasFilter
          },
          pageNavigation: {
            visible: settings.hasPagination
          }
        },
        bars: {
          statusBar: {
            visible: settings.hasStatusBar
          }
        }
      },
    };

    this.powerbi
      ? this.updateExistingReport(embedToken)
      : this.initializePowerBi();
  }

  private initializePowerBi(): void {
    this.powerbi = new service.Service(
      factories.hpmFactory,
      factories.wpmpFactory,
      factories.routerFactory
    );
    this.powerbi.embed(this.reportContainer.nativeElement, this.embedConfig);
  }

  private async updateExistingReport(embedToken: string): Promise<void> {
    const report = this.powerbi!.get(this.reportContainer.nativeElement);
    await report.setAccessToken(embedToken);
  }

  private handleError(error: any, context: string): void {
    this.isLoading = false;
    this.error = true;
    console.error(`${context}:`, error);
    this.errorHandler.handleError({
      message: this.translationService.instant(context),
    });
  }

  toggleFullscreen(): void {
    this.isFullscreen = !this.isFullscreen;
    document.body.classList.toggle('fullscreen', this.isFullscreen);
  }
}
