import { FeedbackService } from './general-feedback.service';
import { Injectable } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { GeneralFeedbackDialogComponent } from '../utils/components/general-feedback-dialog/general-feedback-dialog.component';

@Injectable({
  providedIn: 'root',
  deps: [DialogService]
})

export class DialogHandlerService {
  private dialogRef: DynamicDialogRef | undefined;

  constructor(
    private dialogService: DialogService,
    private feedbackService: FeedbackService,
  ) {}

  openFeedbackDialog() {
    const config = {
      header: 'Feedback',
      width: '38rem',
    };

    this.dialogRef = this.dialogService.open(GeneralFeedbackDialogComponent, config);

  }

  RefuseFeedback() {
    this.feedbackService.declinefeedback()
  }

  closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
