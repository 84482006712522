<p-toast></p-toast>

<div class="filter-button">
  <app-filter-button
    [items]="items"
    [isMyCatalogFilter]="true"
    (selectedFilters)="updateSelectedFilters($event)"
    ></app-filter-button>
</div>

<div class="content favorites" *ngIf="hasFavorites" [ngClass]="{ 'sidebar-open': isSidebarOpen}">
  <div class="header">
    <div class="col-6">
      <h4 class="mb-6">{{ 'my_catalog.grid.header_favorites' | translate }}</h4>
    </div>
  </div>
  <div class="grid w-full">
    <div class="col-12">
      <div class="card">
        <div class="col-12 items-counter">
          <span>
            {{
            favorites.length == 1
            ? favorites.length + ('my_catalog.filter.chip.singular' | translate)
            : favorites.length + ('my_catalog.filter.chip.plural' | translate)
            }}
          </span>

        </div>
        @if(!isLoading || favorites.length > 0){
        <p-carousel
          #carousel
          [page]="page"
          [value]="favorites"
          [numVisible]="5"
          [numScroll]="5"
          [responsiveOptions]="responsiveOptions"
          layout="grid">
          <ng-template pTemplate="item" let-item>
            <div class="grid-nogutter" [class.dragging-active]="draggedItem?.favorited">
              <app-item-card
                class="p-2"
                [item]="item"
                [isMyCatalogView]="true"
                (onToggleSidebar)="setIsSidebarOpen($event)"
                (onFavoriteChange)="onFavoriteChange($event)"
                [class.dragging]="item === draggedItem"
                [class.drop-target-left]="favorites.indexOf(item) === dragOverIndex && dropPosition === 'before'"
                [class.drop-target-right]="favorites.indexOf(item) === dragOverIndex && dropPosition === 'after'"
                [class.no-drag]="isFiltered"
                [draggable]="!isSidebarOpen && !isFiltered"
                (dragstart)="onDragStart($event, item)"
                (dragend)="onDragEnd()"
                (dragover)="onDragOver($event, favorites.indexOf(item))"
                (drop)="onDrop($event, favorites.indexOf(item))">
              </app-item-card>
            </div>
          </ng-template>
        </p-carousel>
        } @else {
          <p-progressSpinner
          class="scroll-pagination-spinner"
          styleClass="w-4rem h-4rem"
          strokeWidth="8"
          fill="transparent"
          ariaLabel="loading" />
        }
      </div>
    </div>
  </div>
</div>

<div class="content available-items">
  <div class="header">
    <div class="col-6">
      <h4 class="mb-6">{{ 'my_catalog.grid.header' | translate }}</h4>
    </div>
  </div>
  <div class="grid w-full">
    <div class="col-12">
      <div class="card">
        <div class="col-12 items-counter">
          <span>
            {{
            itemsCounter == 1
            ? itemsCounter + ('my_catalog.filter.chip.singular' | translate)
            : itemsCounter + ('my_catalog.filter.chip.plural' | translate)
            }}
          </span>
        </div>

        <!-- Container com scroll -->
        <div class="items-container" 
             (scroll)="onScroll($event)" 
             style="height: 600px; overflow-y: auto;">
          
          <div class="grid-nogutter" [class.dragging-active]="draggedItem && !draggedItem.favorited">
            <div *ngFor="let item of items; let i = index"
                 class="p-2"
                 [class.dragging]="item === draggedItem"
                 [class.drop-target-left]="i === dragOverIndex && dropPosition === 'before'"
                 [class.drop-target-right]="i === dragOverIndex && dropPosition === 'after'"
                 [class.no-drag]="isFiltered"
                 [draggable]="!isSidebarOpen && !isFiltered"
                 (dragstart)="onDragStart($event, item)"
                 (dragend)="onDragEnd()"
                 (dragover)="onDragOver($event, i)"
                 (drop)="onDrop($event, i)">
              <app-item-card 
                [item]="item" 
                [isMyCatalogView]="true"
                (onToggleSidebar)="setIsSidebarOpen($event)"
                (onFavoriteChange)="onFavoriteChange($event)">
              </app-item-card>
            </div>
          </div>
          <p-progressSpinner
            *ngIf="loading"
            class="scroll-pagination-spinner"
            styleClass="w-4rem h-4rem"
            strokeWidth="8"
            fill="transparent"
            ariaLabel="loading" />
          <!-- Mensagem quando todos os itens foram carregados -->
          @if(allItemsLoaded) {
            <div class="text-center p-4 text-600">
              {{ 'my_catalog.no_more_items' | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
