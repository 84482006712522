<input hidden type="text" pInputText id="id" [value]="usersGroup.id" name="id" />

<div class="field col-12 p-0 mt-2">
  <div class="flex justify-content-start">
    <div class="col-4">

      <user-dropdown
                     [user]="selectedUser"
                     [placeholder]="'components.user-group-dropdown.placeholder'"
                     (selectedUser)="setUser($event)"
                     [isDisabled]="isDisabled"></user-dropdown>

    </div>
    <div class="col-1">
      <button
              pButton
              pRipple
              icon="pi pi-plus"
              class="p-button-rounded p-button-outlined ml-2"
              (click)="addUser()"
              [disabled]='!selectedUser.name || isDisabled'>
      </button>
    </div>
  </div>
</div>

<div class="grid grid-nogutter mt-4">
  <div class="field col-12">
    <p-table
             #dt
             [value]="users"
             [columns]="columnDefinitions"
             responsiveLayout="scroll"
             [rows]="10"
             [globalFilterFields]="columnDefinitions"
             [paginator]="true"
             [totalRecords]="users.length"
             [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]"
             [showCurrentPageReport]="true"
             currentPageReportTemplate="{{ 'users_groups.table.page_report' | translate }} "
             [(selection)]="selectedUsers"
             selectionMode="multiple"
             [rowHover]="true"
             dataKey="id">

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="name">
            {{ 'users_groups.table.colum_header.name' | translate }} <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="type">
            {{ 'users_groups.table.colum_header.type' | translate }} <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th pSortableColumn="mail">
            {{ 'users_groups.table.colum_header.mail' | translate }} <p-sortIcon field="mail"></p-sortIcon>
          </th>
          <th pSortableColumn="actions">
            {{ 'users_groups.table.colum_header.actions' | translate }}
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            <p-tableCheckbox [value]="user"></p-tableCheckbox>
          </td>
          <td style="width: 30%; min-width: 15rem">
            <span class="p-column-title">{{ 'catalog.table.colum_header.name' | translate }}</span>
            {{ user.name }}
          </td>
          <td style="width: 5%; min-width: 8rem">
            <span class="p-column-title">{{ 'catalog.table.colum_header.type' | translate }}</span>
            {{ !user.groupType ? 'User' : 'Group' }}
          </td>
          <td style="width: 35%; min-width: 20rem">
            <span class="p-column-title">{{ 'catalog.table.colum_header.mail' | translate }}</span>
            {{ user.mail }}
          </td>
          <td>
            <div class="flex">
              <button
                      pButton
                      pRipple
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-outlined p-button-danger mr-2"
                      (click)="deleteUser(user)"
                      [disabled]="isDisabled">
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
