<p-toast></p-toast>

<div class="mb-4 mt-2">
  <h5 class="font-bold flex align-items-center">
    <span class="mr-3">
      {{ 'addons.settings.tabs.security.rls.title' | translate }}
    </span>
    <p-button
      icon="pi pi-plus"
      (onClick)="showRoleModal = true; isEditing = false"
      rounded
      outlined />
  </h5>

  <div class="flex justify-content-between align-items-center flex-wrap">
    <p class="m-0">{{ 'addons.settings.tabs.security.rls.description' | translate }}</p>
  </div>

  <div class="mt-4">
    <p-table
      #table
      [value]="roles"
      [tableStyle]="{ 'min-width': '50rem' }"
      [rows]="5"
      [loading]="loading"
      [currentPageReportTemplate]="'addons.settings.tabs.security.table.page_report' | translate"
      [globalFilterFields]="['roleName']"
      (onPage)="onPageChange($event)" dataKey="id"
      (onLazyLoad)="onPageChange($event)"
      paginator
      showCurrentPageReport
      rowHover>
      <ng-template pTemplate="header">
        <tr>
          <th class="w-32rem">{{ 'addons.settings.tabs.security.table.role' | translate }}</th>
          <th class="w-1rem">{{ 'addons.settings.tabs.security.table.actions' | translate }}</th>
          <th class="w-1rem">
            <p-button
              rounded
              text
              raised
              [icon]="isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'"
              (click)="isFilterRowVisible = !isFilterRowVisible"
              severity="secondary" />
          </th>
        </tr>

        <tr *ngIf="isFilterRowVisible">
          <th class="w-32rem">
            <p-columnFilter
              type="text"
              field="roleName"
              [placeholder]="'addons.settings.tabs.security.table.filter.name' | translate" />
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-role>
        <tr>
          <td class="font-bold" style="color: var(--primary-color)">{{ role.roleName }}</td>
          <td>

            <div class="flex">
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-outlined mr-2"
                (click)="edit(role)"></button>

              <button
                pButton
                pRipple
                icon="pi pi-trash"
                class="p-button-rounded p-button-outlined p-button-danger mr-2"
                (click)="displayDeleteModal(role)"></button>
            </div>
          </td>
          <td></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog
    [header]="(isEditing ? 'addons.settings.tabs.security.table.dialog.header_edit' : 'addons.settings.tabs.security.table.dialog.header') | translate"
    [modal]="true"
    [draggable]="false"
    [(visible)]="showRoleModal"
    (onHide)="onModalHide()"
    [style]="{ width: '70rem', height: '50rem' }">

    <form [formGroup]="form">
      <div class="flex flex-wrap align-items-center gap-3 mb-4">
        <p-floatLabel class="w-full mt-4">
          <input
            pInputText
            required
            (focusout)="onFocusOut($event)"
            formControlName="roleName"
            aria-describedby="role-name-help"
            id="roleName"
            class="flex-auto w-full" />
          <label for="roleName">{{ 'addons.settings.tabs.security.table.dialog.name' | translate }}</label>
        </p-floatLabel>

        <div class="w-full h-1rem relative">
          <small *ngIf="form.get('roleName')?.errors?.['duplicate']" id="role-name-help" class="text-red-500 block">
            {{ 'addons.settings.tabs.security.validation.duplicate' | translate }}
          </small>

          <small *ngIf="form.get('roleName')?.errors?.['emptyField']" id="role-name-help" class="text-red-500 block">
            {{ 'addons.settings.tabs.security.validation.emptyField' | translate }}
          </small>
        </div>
      </div>
    </form>

    <div class="flex justify-content-start">
      <div class="flex align-items-center">
        <user-dropdown
          [user]="selectedUser"
          [placeholder]="'components.user-group-dropdown.placeholder'"
          (selectedUser)="setUser($event)"
          ></user-dropdown>
      </div>

      <div class="col-1">
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          class="p-button-rounded p-button-outlined ml-2"
          (click)="addPermission()"
          [disabled]="!selectedUser.name">
        </button>
      </div>
    </div>

    <div class="grid grid-nogutter mt-4">
      <div class="field col-12">
        <p-table
          #dt
          [value]="users"
          [columns]="columnDefinitions"
          responsiveLayout="scroll"
          [rows]="10"
          [globalFilterFields]="columnDefinitions"
          [paginator]="true"
          [loading]="loading"
          [totalRecords]="totalRecords"
          [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{ 'catalog.table.page_report' | translate }} "
          [(selection)]="selectedUsers"
          selectionMode="multiple"
          [rowHover]="true"
          dataKey="id">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="name">
                {{ 'catalog.table.colum_header.name' | translate }} <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th pSortableColumn="type">
                {{ 'catalog.table.colum_header.type' | translate }} <p-sortIcon field="type"></p-sortIcon>
              </th>
              <th pSortableColumn="mail">
                {{ 'catalog.table.colum_header.mail' | translate }} <p-sortIcon field="mail"></p-sortIcon>
              </th>
              <th pSortableColumn="actions">
                {{ 'catalog.table.colum_header.actions' | translate }}
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
            <tr>
              <td style="width: 30%; min-width: 15rem">
                <span class="p-column-title">
                  {{ 'catalog.table.colum_header.name' | translate }}
                </span>
                {{ user.name }}
              </td>
              <td style="width: 5%; min-width: 8rem">
                <span class="p-column-title">
                  {{ 'catalog.table.colum_header.type' | translate }}
                </span>
                {{ !user.groupType ? 'User' : 'Group' }}
              </td>
              <td style="width: 35%; min-width: 20rem">
                <span class="p-column-title">
                  {{ 'catalog.table.colum_header.mail' | translate }}
                </span>
                {{ user.mail }}
              </td>
              <td>
                <div class="flex">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-outlined p-button-danger mr-2"
                    (click)="deletePermission(user)">
                  </button>
                </div>
              </td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="flex justify-content-end gap-2">
      <p-button
        class="mt-5"
        type="submit"
        [label]="'addons.save_btn' | translate"
        [disabled]="!form.valid"
        (onClick)="save()" />
    </div>
  </p-dialog>

  <p-dialog
    [header]="'components.dialogs.header.confirm' | translate"
    [(visible)]="showDeleteRoleModal"
    (onHide)="onModalHide()"
    [modal]="true"
    [style]="{ width: '30rem' }">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span [innerHTML]="('addons.settings.tabs.security.dialogs.delete_role' | translate).replace('{role}', this.form.get('roleName')?.value)"></span>
    </div>

    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        icon="pi pi-times"
        class="p-button-text"
        label="{{ 'components.dialogs.no' | translate }}"
        (click)="showDeleteRoleModal = false"></button>

      <button
        pButton
        pRipple
        icon="pi pi-check"
        class="p-button-text"
        label="{{ 'components.dialogs.yes' | translate }}"
        (click)="delete()"></button>
    </ng-template>
  </p-dialog>
</div>
