<p-dialog [style]="{ width: '50vw'}" [breakpoints]="{ '1199px': '75vw', '1000px': '90vw' }" [(visible)]="isVisible"
  [modal]="true" [draggable]="false" [resizable]="false" (onHide)="hideDialog()" class="p-fluid">
  <ng-template pTemplate="header">
    @if(seal.id){
    <h3>{{ seal.name }}</h3>
    } @else {
    <h3>{{ 'seals.form.header.new' | translate }}</h3>
    }
  </ng-template>

  <ng-template pTemplate="content">
    <form [formGroup]="form" class="item-form ">
      <input hidden type="text" pInputText id="id" formControlName="id" name="id" />

      <div class="grid nested-grid">
        <div class="col-6">
          <div class="grid">
            <div class="field col-12">
              <label for="name">
                {{ 'seals.form.fields.name.label' | translate }}
                <span class="p-error">*</span>
              </label>
              <input id="name" type="text" formControlName="name" pInputText maxlength="50" required
                [ngClass]="{ 'ng-invalid ng-dirty': form.controls['name'].dirty && !form.controls['name'].value }">
              <small class="ng-dirty ng-invalid" *ngIf="form.controls['name'].dirty && !form.controls['name'].value">
                {{ 'seals.form.fields.name.validation_text' | translate }}
              </small>
            </div>
            <div class="field col-12">
              <label for="description">
                {{ 'seals.form.fields.description.label' | translate }}
              </label>
              <textarea id="description" pInputTextarea formControlName="description" rows="10" maxlength="1024"
                cols="20">
              </textarea>
            </div>
          </div>
        </div>
        <div class="field col-6 flex flex-column">
          <div class="field">
            <div class="label-container">
              <label for="emoji">
                {{ 'seals.form.fields.seal.label' | translate }}
                <span class="p-error">*</span>
              </label>
              <span *ngIf="selectedEmoji" class="selected-emoji">
                <ngx-emoji [isNative]="true" [set]="emojiset" [emoji]="selectedEmoji"></ngx-emoji>
              </span>
            </div>

            <emoji-mart [set]="emojiset" [isNative]="true" [darkMode]="false" [showPreview]="false" [sheetSize]="16"
              [showSingleCategory]="true" [enableFrequentEmojiSort]="true" [emojiSize]="24" [emojiTooltip]="true"
              (emojiSelect)="selectEmoji($event)">
            </emoji-mart>
          </div>

          <small class="p-error" *ngIf="form.controls['emoji'].dirty && !form.controls['emoji'].value">
            {{ 'seals.form.fields.emoji.validation_text' | translate }}
          </small>
        </div>
      </div>

      <div class="flex justify-content-end flex-wrap">
        <div class="w-auto gap-2 flex justify-end items-end mb-5">
          <button pButton pRipple label="{{ 'seals.buttons.cancel' | translate }}" icon="pi pi-times" class="p-button"
            (click)="hideDialog()"></button>
          <button pButton pRipple label="{{ 'seals.buttons.save' | translate }}" icon="pi pi-check" class="p-button"
            (click)="save()" [disabled]="!(form.valid && form.dirty)"></button>
        </div>
      </div>
    </form>
  </ng-template>
</p-dialog>