<app-topbar *ngIf="!isFullscreen"></app-topbar>
<div class="layout-main-container">
  <div class="iframe-container" [ngClass]="{'fullscreen': isFullscreen}">

    @if(sourceId == 1) {
    <iframe [src]="sanitizedUrl | safeURL:'resourceUrl'" frameborder="0" id="viewFrame"></iframe>
    } @else {
    <div class="iframe-container" #reportContainer [ngClass]="{'fullscreen': isFullscreen}">
    </div>
    }
    <button class="info-button" (click)="sidebarVisible = true">
      <i class=" pi pi-info"></i></button>
    <button class="toggle-button" (click)="toggleFullscreen()">
      <i [ngClass]="isFullscreen ? 'pi pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi pi-arrow-up-right-and-arrow-down-left-from-center'"></i>
    </button>

    @if(error){
    <div class="display-error">
      <i class="pi pi-exclamation-triangle mr-1" style="color: var(--red-700)"></i>
      <p class="error-message">{{ 'item_view.page.error' | translate }}</p>
    </div>
    }
    @if(isLoading){
    <div class="display-loading">
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    </div>
    }
  </div>
</div>

<app-details [item]="item" [(isOpen)]="sidebarVisible" [showActions]="false"></app-details>
<p-toast></p-toast>
