<form [formGroup]="form">
  <input hidden type="text" pInputText id="id" formControlName="id" name="id" />

  <div class="field col-12">
    <label for="name"> {{ 'users_groups.form.fields.name.label' | translate }} <span
            class="p-error">*</span></label>
    <input type="text" pInputText id="name" formControlName="name" required maxlength="80"
           [ngClass]="{ 'ng-invalid ng-dirty': form.controls['name'].dirty && !form.controls['name'].value }"
           required (input)="onChange()" />
    <small class="ng-dirty ng-invalid" *ngIf="form.controls['name'].dirty && !form.controls['name'].value">
      {{ 'users_groups.form.fields.name.validation_text' | translate }}
    </small>
  </div>
  <div class="field col-12">
    <label for="description">
      {{ 'users_groups.form.fields.description.label' | translate }}
      <span class="p-error">*</span>
    </label>
    <textarea id="description" pInputTextarea formControlName="description" rows="10" maxlength="1024" cols="20"
              [ngClass]="{ 'ng-invalid ng-dirty': form.controls['description'].dirty && !form.controls['description'].value }"
              required (input)="onChange()">
          </textarea>
    <small class="ng-dirty ng-invalid"
           *ngIf="form.controls['description'].dirty && !form.controls['description'].value"> {{
      'users_groups.form.fields.description.validation_text' | translate }}</small>
  </div>

</form>