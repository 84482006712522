<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <h3 class="mb-5"> {{ 'item_requests.title' | translate }}</h3>

      <div class="flex gap-3 mb-3 counter-filter-buttons">
        <p-button
          [rounded]="true"
          size="large"
          [severity]="activeRequestTypeFilter === 0 ? 'primary' : 'secondary'"
          [label]="'item_requests.table.fields.types.all' | translate"
          [badge]="itemRequestsUnfiltered.length.toString()"
          (click)="filterByType(requestTypeEnum.All)"
          />
        <p-button
          [rounded]="true"
          size="large"
          [severity]="activeRequestTypeFilter === requestTypeEnum.Received ? 'primary' : 'secondary'"
          [label]="'item_requests.table.fields.types.received' | translate"
          [badge]="getRequestTypeCount(requestTypeEnum.Received)"
          (click)="filterByType(requestTypeEnum.Received)"
          />
        <p-button
          [rounded]="true"
          size="large"
          [severity]="activeRequestTypeFilter === requestTypeEnum.Sent ? 'primary' : 'secondary'"
          [label]="'item_requests.table.fields.types.sent' | translate"
          [badge]="getRequestTypeCount(requestTypeEnum.Sent)"
          (click)="filterByType(requestTypeEnum.Sent)"
          />
        <p-button
          [rounded]="true"
          size="large"
          label="Pending"
          [severity]="activeRequestTypeFilter === requestTypeEnum.Pending ? 'primary' : 'warning'"
          [label]="'item_requests.table.fields.status.pending' | translate"
          [badge]="getRequestTypeCount(requestTypeEnum.Pending)"
          (click)="filterByType(requestTypeEnum.Pending)"
          />
      </div>

      <p-toast></p-toast>
      <p-table
        selectionMode="multiple"
        dataKey="id"
        responsiveLayout="scroll"
        #dt
        [value]="itemRequests"
        [rows]="10"
        [globalFilterFields]="['id', 'date', 'type','Requester', 'item', 'justification', 'owner', 'status']"
        [paginator]="true"
        [rowsPerPageOptions]="[10,20,30,40,50,100]"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="translatedTemplate.page_report"
        [(selection)]="selectedReports"
        [rowHover]="true"
        [first]="paginatorIndex"
        (onPage)="pageChange($event)">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="id">{{ 'item_requests.table.column_header.id' | translate }} <p-sortIcon field="id"></p-sortIcon></th>
            <th pSortableColumn="date">{{ 'item_requests.table.column_header.date' | translate }} <p-sortIcon field="date"></p-sortIcon></th>
            <th pSortableColumn="type">{{ 'item_requests.table.column_header.type' | translate }} <p-sortIcon field="type"></p-sortIcon></th>
            <th pSortableColumn="Requester"> {{'item_requests.table.column_header.requester' | translate}} <p-sortIcon field="Requester"></p-sortIcon></th>
            <th pSortableColumn="item">{{ 'item_requests.table.column_header.item' | translate }} <p-sortIcon field="item"></p-sortIcon></th>
            <th pSortableColumn="justification">{{ 'item_requests.table.column_header.justification' | translate }} <p-sortIcon field="justification"></p-sortIcon></th>
            <th pSortableColumn="owner">{{ 'item_requests.table.column_header.owner' | translate }} <p-sortIcon field="owner"></p-sortIcon></th>
            <th pSortableColumn="status">{{ 'item_requests.table.column_header.status' | translate }} <p-sortIcon field="status"></p-sortIcon></th>
            <th>{{ 'item_requests.table.column_header.actions' | translate }} </th>
            <th>
              <p-button [icon]="
                  isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'
                " (click)="toggleFilter()" [rounded]="true" [text]="true" [raised]="true" severity="secondary" />
            </th>
          </tr>
          <tr *ngIf="isFilterRowVisible">
            <th></th>
            <th><p-columnFilter type="text" field="id" placeholder="{{ 'item_requests.table.filter_placeholders.id' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.id' | translate }}" /></th>
            <th><p-columnFilter type="text" field="date" placeholder="{{ 'item_requests.table.filter_placeholders.date' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.date' | translate }}" /></th>
            <th><p-columnFilter type="text" field="type" placeholder="{{ 'item_requests.table.filter_placeholders.type' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.type' | translate }}" /></th>
            <th><p-columnFilter type="text" field="requester" placeholder="{{ 'item_requests.table.filter_placeholders.requester' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.requester' | translate }}" /></th>
            <th><p-columnFilter type="text" field="item" placeholder="{{ 'item_requests.table.filter_placeholders.item' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.item' | translate }}" /></th>
            <th><p-columnFilter type="text" field="justification" placeholder="{{ 'item_requests.table.filter_placeholders.justification' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.justification' | translate }}" /></th>
            <th><p-columnFilter type="text" field="owner" placeholder="{{ 'item_requests.table.filter_placeholders.owner' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.owner' | translate }}" /></th>
            <th><p-columnFilter type="text" field="status" placeholder="{{ 'item_requests.table.filter_placeholders.status' | translate }}" ariaLabel="{{ 'item_requests.table.filter_aria_label.status' | translate }}" /></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-request>
          <tr>
            <td>
              <p-tableCheckbox [value]="request"></p-tableCheckbox>
            </td>
            <td style="width:5%; min-width:7rem;">
              <span class="p-column-title">
                {{ 'item_requests.table.column_header.id' | translate }}
              </span>
              {{ request.id }}
            </td>
            <td style="width:50%; min-width:10rem;">
              <span class="p-column-title">{{ 'item_requests.table.column_header.date' | translate }}</span>
              {{ request.date | date:'M/d/yy h:mm a' }}
            </td>
            <td style="width:50%; min-width:10rem;">
              <span class="p-column-title">{{ 'item_requests.table.column_header.type' | translate }}</span>
              {{ getRequestType(request) }}
            </td>
            <td style="width:50%; min-width:20rem;">
              <span class="p-column-title">{{ 'item_requests.table.column_header.requester' | translate }}</span>
              {{request.user.name}}
            </td>
            <td style="width:50%; min-width:10rem;">
              <span class="p-column-title">{{ 'item_requests.table.column_header.item' | translate }}</span>
              {{ request.item.name }}
            </td>
            <td style="width:50%; min-width:20rem;">
              <span class="p-column-title">{{ 'item_requests.table.column_header.justification' | translate }}</span>
              {{ request.justification }}
              <span class="rejection-response">{{ printRejectionResponse(request) }}</span>
            </td>
            <td style="width:50%; min-width:15rem;">
              <span class="p-column-title">{{ 'item_requests.table.column_header.owner' | translate }}</span>
              {{ request.item.ownerName }}
            </td>
            <td style="width:14%; min-width: 10rem;">
              <span class="p-column-title">
                {{ 'item_requests.table.column_header.status' | translate }}
              </span>
              <p-tag [severity]="getRequestStatus(request).severity" [value]="getRequestStatus(request).name" />
            </td>
            <td>
              <div class="flex">
                <button title="menu" type="button" pButton icon="pi pi-ellipsis-v"
                        (click)="tableItemMenu.toggle($event)"
                        [disabled]="request.statusId !== itemAccessRequestStatusEnum.Pending"
                        class="p-button-rounded p-button-outlined"></button>
                <p-menu #tableItemMenu [popup]="true" [model]="tableActions" appendTo="body">
                  <ng-template pTemplate="item" let-item>
                    <a pRipple rel="noopener noreferrer" target="_blank"
                       *ngIf="isActionVisible(request, item)"
                       class="flex align-items-center p-menuitem-link"
                       (click)="setSelectedItemRow(request)">
                      <span [class]="item.icon"></span>
                      <span class="ml-2">{{ item.label }}</span>
                    </a>
                  </ng-template>
                </p-menu>
              </div>
            </td>
            <td></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-request-access-form
  *ngIf="item"
  [isRequest]="false"
  [isApprovalRequest]="isApprovalRequest"
  [(isOpen)]="openRequestForm"
  [item]="item"
  [request]="selectedReport"
  [requester]="selectedReport.user.name"
  (requestSent)="onRequestSent($event)">
</app-request-access-form>
