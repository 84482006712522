import { Item } from './item';

export interface ItemReview {
  id: number;
  userId: string;
  userName: string;
  userPhoto: string;
  itemId: number;
  itemVersion: string;
  rate: number;
  comment: string;
  createdAt: Date;
}

export interface ItemReviewAverageRate {
  total: number;
  ratingCount: Record<string, number>;
}

export interface ItemReviews {
  reviews: ItemReview[];
  averageRate: ItemReviewAverageRate;
  hasSentReview: boolean;
}

export interface ItemReviewGetList {
  itemId: number;
  currentPage: number;
  itemVersion: string;
  numberOfRecords: number;
  orderBy: string;
  filterBy: string;
}

export interface CreateItemReviewCommand {
  rate: number;
  itemVersion: number;
  comment: string;
}

export interface UpdateItemReviewCommand {
  rate: number;
  comment: string;
}

export enum ItemReviewOrderByEnum {
  MostRecent = 'most-recent',
  AscendingRate = 'ascending-rate',
  DescendingRate = 'descending-rate'
}

export enum ItemReviewFilterByEnum {
  All = 'all',
  ByMe = 'by-me'
}
