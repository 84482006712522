@if(!isLoading){
<p-button
          (click)="sidebarVisibleToggle()"
          [rounded]="true"
          [outlined]="isOutlined()"
          [label]="'my_catalog.filter.buttons.filters' | translate"
          icon="pi pi-filter"
          class="mb-4" />
}
@else {
<p-skeleton width="4rem" height="2rem" styleClass="mb-4" borderRadius="16px" />
}

<p-sidebar [(visible)]="isSidebarVisible" styleClass="px-3 w-25rem" position="right">
  <h3>{{ 'my_catalog.filter.headline' | translate }}</h3>
  <form (ngSubmit)="loadItems()" class="search-form">
    <div class="p-fluid p-formgrid flex flex-column">
      <div class="field col">
        <label for="type">{{ 'my_catalog.filter.fields.type' | translate }}</label>
        <p-dropdown [options]="(getTypeOptions() | async)!" optionLabel="name" optionValue="key"
                    [(ngModel)]="selectedType"
                    [placeholder]="'my_catalog.filter.dropdown.type' | translate" [showClear]="true"
                    name="type"></p-dropdown>
      </div>
      <div class="field col">
        <label for="owner">{{ 'my_catalog.filter.fields.owner' | translate }}</label>
        <user-dropdown [user]="selectedOwner" [placeholder]="'components.user-dropdown.placeholder'" (selectedUser)="setOwner($event)"></user-dropdown>
      </div>
      <div class="field col">
        <label for="termType">{{ 'my_catalog.filter.fields.termType' | translate }}</label>
        <p-dropdown [options]="(getTermTypeOptions() | async)!" optionLabel="name" optionValue="key"
                    [(ngModel)]="selectedTermType"
                    [placeholder]="'my_catalog.filter.dropdown.termType' | translate" [showClear]="true" name="termType"
                    (onChange)="loadTerms()"></p-dropdown>
      </div>
      <div class="field col">
        <label for="termName">{{ 'my_catalog.filter.fields.term' | translate }}</label>
        <p-dropdown [options]="termOptions" [(ngModel)]="selectedTermName" name="termName" [showClear]="true"
                    [placeholder]="'my_catalog.filter.dropdown.term' | translate"
                    [disabled]="!selectedTermType"></p-dropdown>
      </div>
      <div class="field col flex gap-1">
        <button pButton type="submit" [label]="'my_catalog.filter.buttons.apply' | translate"></button>
        <button pButton type="reset" severity="secondary" [label]="'my_catalog.filter.buttons.clear' | translate"
                (click)="loadItems(false)"></button>
      </div>
    </div>
  </form>
</p-sidebar>