<p-sidebar [(visible)]="isOpen" position="right" (onHide)="toggleOpen($event)" styleClass="p-3 w-30rem">
  <ng-template pTemplate="header">
    <div class="flex justify-content-between align-items-center w-full">
      <div class="mb-3">
        <h4 class="m-0">{{ 'manage_profile.form.headline' | translate }}</h4>
        <small class="text-500">Microsoft User</small>
      </div>
    </div>
  </ng-template>
  <div class="text-500 font-bold mb-3">{{ 'manage_profile.basics' | translate }}</div>
  <div class="flex flex-column align-items-start gap-1 mb-3">
    <span>{{ 'manage_profile.form.profile_image' | translate }}</span>
    <img class="border-circle" [src]="user?.photo" style="width: 80px" />
  </div>
  <div class="p-fluid p-formgrid flex flex-column">
    <div class="field col px-0 mb-0">
      <label for="ownerInfo">{{ 'manage_profile.form.name' | translate }} </label>
      <br>
      <input pInputText disabled [value]="user?.name">
    </div>
    <div class="field col px-0">
      <label for="ownerInfo">{{ 'manage_profile.form.mail' | translate }} </label>
      <br>
      <input pInputText disabled [value]="user?.mail">
    </div>
  </div>
  <p-divider class="w-full mb-3" />
  <div class="text-500 font-bold mb-3">{{ 'manage_profile.preferences.label' | translate }}</div>
  <div class="p-fluid p-formgrid flex flex-column">
    <label>{{ 'manage_profile.preferences.language' | translate }}</label>
    <p-dropdown styleClass="mt-2"
                id="systemLanguage"
                name="systemLanguage"
                [options]="languages"
                optionLabel="label"
                optionValue="code"
                [ngModel]="selectedLanguage"
                placeholder="{{ 'languages.options.placeholder' | translate }}"
                (onChange)="changeSystemLanguage($event)">
    </p-dropdown>
    <p-divider class="w-full mb-3" />
    <div class="text-500 font-bold mb-3">{{ 'manage_profile.notifications.label' | translate }}</div>
    <div class="p-fluid p-formgrid flex flex-column">
      <label>{{ 'manage_profile.notifications.email' | translate }}</label>
      <p-inputSwitch styleClass="mt-2" [(ngModel)]="emailNotification" (onChange)="updatePreferences($event)" />
    </div>
  </div>
</p-sidebar>
<p-toast></p-toast>