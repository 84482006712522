import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionStatus,
} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { LayoutService } from '../../service/app.layout.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [CommonModule, ButtonModule, RouterModule, TranslateModule],
})
export class HomeComponent implements OnInit {
    loginDisplay = false;

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        public layoutService: LayoutService,
    ) { }

    ngOnInit(): void {
      this.layoutService.setAdminStuff(true);
      this.msalBroadcastService.msalSubject$
          .pipe(
              filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
          )
          .subscribe((result: EventMessage) => {
              const payload = result.payload as AuthenticationResult;
              this.authService.instance.setActiveAccount(payload.account);
              console.log('ngOnInit', this.authService.instance.getActiveAccount());
          });

      this.msalBroadcastService.inProgress$
          .pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None)
          )
          .subscribe(() => {
              this.setLoginDisplay();
          });
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }
}
