import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, throwError } from 'rxjs';
import { environment } from '../../environments/environments';
import { User } from '../api/user';
import { MsalService } from '@azure/msal-angular';
import { UserNotification } from '../api/user-notification';

@Injectable({ providedIn: 'root' })
export class UserNotificationsService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(
    private httpClient: HttpClient,
    private authService: MsalService
  ) {}

  getAll(): Observable<UserNotification[]> {
    return this.httpClient.get<UserNotification[]>(`${this.baseRoute}user/notifications`);
  }
  
  readAllNotifications(): Observable<HttpResponse<any>> {
    return this.httpClient.put<HttpResponse<any>>(`${this.baseRoute}user/notifications`, {});
  }

  deleteNotification(notificationId: number): Observable<HttpResponse<any>> {
    return this.httpClient.delete<HttpResponse<any>>(`${this.baseRoute}user/notifications/${notificationId}`, {});
  }

  deleteAllNotifications(): Observable<HttpResponse<any>> {
    return this.httpClient.delete<HttpResponse<any>>(`${this.baseRoute}user/notifications`, {});
  }
}
