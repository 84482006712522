import { AsyncPipe, CommonModule } from "@angular/common";
import { Component, ErrorHandler, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { AddOn, InstalledAddOn } from "../../../api/addon";
import { AddonSetupComponent } from "../addon-setup/addon-setup.component";
import { AddonService } from "../../../service/addon.service";
import { GlobalErrorHandler } from "../../../utils/components/error-handler/error-handler.component";
import { RouterModule } from "@angular/router";
import { AddOnSchedulerSettings } from "../../../api/addon-scheduler";

@Component({
  selector: 'addon-card',
  standalone: true,
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    CardModule,
    AsyncPipe,
    AddonSetupComponent,
    RouterModule
  ],
  templateUrl: './addon-card.component.html',
  styleUrl: './addon-card.component.scss',
})
export class AddonCardComponent implements OnInit {

  @Input() addon!: AddOn;
  @Input() installedAddon!: InstalledAddOn;
  @Input() isInstalled: boolean = false;

  @Input() schedulerSettings!: AddOnSchedulerSettings;

  @Output() refresh = new EventEmitter();
  @Output() settings = new EventEmitter<InstalledAddOn>();

  showSetupDialog: boolean = false;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private addonService: AddonService) { }

  async ngOnInit() {
    if (this.addon.logo)
      this.addon.logo = await this.blobToImage(this.addon.logo);
  }

  loadWorkspace(): void {
    if (this.isInstalled) return;

    this.showSetupDialog = true;
  }

  openSettings(): void {
    this.settings.emit(this.installedAddon);
  }

  uninstall(): void {
    this.addonService.uninstall(this.addon.id)
      .subscribe({
        next: () => {
          this.refresh.emit();
        },
        error: (e) => this.errorHandler.handleError(e)
      });
  }

  async blobToImage(content: string): Promise<string> {
    if (!content) return '';

    const url = 'data:image/png;base64,' + content;

    const res = await fetch(url);
    const blob = await res.blob();
    const file = new File([blob], '', { type: 'image/png' });

    return URL.createObjectURL(file);
  }

  onDialogClose(event: InstalledAddOn): void {
    this.installedAddon = event;
    this.refresh.emit();
  }
}
