import { CommonModule, NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SharedModule } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { FileUpload, FileUploadModule } from "primeng/fileupload";
import { InputTextModule } from "primeng/inputtext";
import { RippleModule } from "primeng/ripple";
import { ToastModule } from "primeng/toast";
import { Item, ItemIcon } from "../../../api/item";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'item-icon-uploader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FileUploadModule,
    ButtonModule,
    ToastModule,
    SharedModule,
    RippleModule,
    InputTextModule,
    DialogModule,
    NgIf,
    NgClass,
    CommonModule,
    TranslateModule
  ],
  templateUrl: './item-icon-uploader.component.html',
  styleUrls: ['./item-icon-uploader.component.scss']
})
export class ItemIconUploaderComponent implements OnInit {

  @Input() item: Item = {} as Item;
  @Input() isDisabled = false;
  @Output() onIconUpload = new EventEmitter<ItemIcon>();
  @ViewChild('fileUploader') fileUploader!: FileUpload;

  itemIcon: ItemIcon = {} as ItemIcon;
  files: File[] = [];

  constructor(private cdref: ChangeDetectorRef, private translate: TranslateService) { }

  ngOnInit() {
    this.itemIcon = this.item.itemIcon;
  }

  async ngAfterViewInit() {
    if (!this.itemIcon) return;

    const url = 'data:image/png;base64,' + this.itemIcon.content;

    const res: Response = await fetch(url);
    const blob: Blob = await res.blob();

    const file = new File([blob], this.itemIcon.name, { type: 'image/png' });

    this.fileUploader.files.push(file);

    this.fileUploader.cd.detectChanges();
    this.cdref.detectChanges();
  }

  onFileSelected(event: any, uploader: FileUpload): void {
    const files = uploader.files;

    if (files.length > 1) {
      event.currentFiles.splice(-1, 1);
      return;
    }

    files.forEach(file => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const bytes = e.target.result.split('base64,')[1];

        this.itemIcon = {
          content: bytes,
          name: file.name,
          refUrl: ''
        };
      };

      reader.onloadend = () => {
        this.onIconUpload.emit(this.itemIcon);
      }

      reader.readAsDataURL(file);
    });
  }

  deleteFile(file: File, uploader: FileUpload, event: any): void {
    const index = uploader.files.indexOf(file);

    uploader.remove(event, index);

    this.itemIcon = {} as ItemIcon;
    this.onIconUpload.emit(this.itemIcon);
  }

  renderImage(file: File): string {
    return URL.createObjectURL(file);
  }
}
