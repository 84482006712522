import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GeneralFeedbackDialogComponent } from '../../utils/components/general-feedback-dialog/general-feedback-dialog.component';
import { SettingsService } from '../../service/settings.service';


type TranslatedTemplate = {
  pageReport: string;
};

@Component({
  selector: 'app-general-feedback',
  standalone: true,
  imports: [
    CardModule,
    InputSwitchModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    TooltipModule,
    DynamicDialogModule,
  ],
  templateUrl: './general-feedback.component.html',
  styleUrl: './general-feedback.component.scss',
  providers: [DialogService],
})
export class GeneralFeedbackComponent {

  constructor(
    private translateService: TranslateService,
    public dialogService: DialogService,
    private settingsService: SettingsService
  ) {}

  @Input() isFeedbackEnabled: boolean = false;
  @Output() isFeedbackEnabledChange = new EventEmitter<boolean>();

  translatedTemplate: TranslatedTemplate = {} as TranslatedTemplate;
  ref: DynamicDialogRef | undefined;

  initTranslations(): void {
    this.translateService
      .get('general_settings')
      .subscribe((res: string) => (this.translatedTemplate.pageReport = res));
  }

  onFeedbackToggle(isEnabled: boolean): void {
    this.isFeedbackEnabled = isEnabled;
    this.isFeedbackEnabledChange.emit(isEnabled);
  }

  show() {
    this.ref = this.dialogService.open(GeneralFeedbackDialogComponent, {
      header: 'Feedback',
      width: '25vw',
      modal: true,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
    });
  }
}
