import { CommonModule, CurrencyPipe, NgClass, NgIf } from '@angular/common';
import {
  Component,
  ErrorHandler,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ConfirmationService,
  LazyLoadEvent,
  MenuItem,
  MessageService,
  SharedModule,
} from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import {
  Table,
  TableLazyLoadEvent,
  TableModule,
  TablePageEvent,
} from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import {
  getAllItemSources,
  Item,
  ItemAccessMethod,
  ItemCustomField,
  ItemDynamicRole,
  ItemIcon,
  ItemPermission,
  ItemPreview,
  ItemSource,
  ItemStatusEnum,
  ItemTerm,
  ItemType,
} from '../../api/item';
import { CatalogService } from '../../service/catalog.service';
import { ItemTermsComponent } from './item-terms/item-terms.component';
import { GlobalErrorHandler } from '../../utils/components/error-handler/error-handler.component';
import { ItemCustomFieldsComponent } from './item-custom-fields/item-custom-fields.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { UserDropdownComponent } from '../../components/user-dropdown/user-dropdown.component';
import { MsalService } from '@azure/msal-angular';
import { ItemIconUploaderComponent } from './item-icon-uploader/item-icon-uploader.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  GenericType,
  catalogTypesTranslationPaths,
  translateFromArray,
  translateFromArrayAt,
} from '../../api/term';
import { map, Observable, filter } from 'rxjs';
import { ItemPermissionsComponent } from './item-permissions/item-permissions.component';
import { User } from '../../api/user';
import { LayoutService } from '../../service/app.layout.service';
import { AddonService } from '../../service/addon.service';
import { AddOnSource } from '../../api/addon';
import { GA4Service } from '../../service/ga4.service';
import { mapFilters } from '../../api/item-paginator';

@Component({
  selector: 'app-catalog',
  standalone: true,
  providers: [
    MessageService,
    ConfirmationService,
    CatalogService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    ToastModule,
    ToolbarModule,
    SharedModule,
    ButtonModule,
    RippleModule,
    FileUploadModule,
    TableModule,
    InputTextModule,
    RatingModule,
    FormsModule,
    DialogModule,
    NgIf,
    NgClass,
    InputTextareaModule,
    DropdownModule,
    RadioButtonModule,
    InputNumberModule,
    CurrencyPipe,
    CommonModule,
    TagModule,
    InputSwitchModule,
    TabViewModule,
    MenuModule,
    ItemTermsComponent,
    ImageUploaderComponent,
    ItemCustomFieldsComponent,
    ReactiveFormsModule,
    UserDropdownComponent,
    ItemIconUploaderComponent,
    TranslateModule,
    ItemPermissionsComponent,
  ],
  templateUrl: './catalog.component.html',
  styleUrl: './catalog.component.scss',
})
export class CatalogComponent implements OnInit {
  form!: FormGroup;
  resourcesForm!: FormGroup;

  @ViewChild('dt') table!: Table; // Refer�ncia ao componente p-table
  totalRecords: number = 0; // Total de registros retornados pela API
  paginatorIndex: number = 0;
  rows = 10;
  page: number = 0;
  size: number = 0;
  sortField: string | string[] = 'Id';
  sortOrder: number = 1;
  filters: any = {};

  loading: boolean = false;

  tableItemActions: MenuItem[] = [];

  items: Item[] = [];
  item: Item = {} as Item;

  itemsTypes: ItemType[] = [
    { id: 1, description: 'Visualization' },
    { id: 2, description: 'Report' },
    { id: 3, description: 'Data Product' },
    { id: 4, description: 'Dataset' },
  ];

  itemAccessMethods: ItemAccessMethod[] = [];

  selectedItems: Item[] = [];

  selectedItem: Item = {} as Item;

  submitted: boolean = false;

  cols: any[] = [];

  rowsPerPageOptions = [5, 10, 20];

  itemDialog: boolean = false;

  publishItemStatusDialog: boolean = false;

  unpublishItemStatusDialog: boolean = false;

  deleteItemDialog: boolean = false;
  isFilterRowVisible: boolean = false;

  activeTabIndex: number = 0;

  isSourceVisible: boolean = false;

  translatedTemplate: any = {
    page_report: '',
    table_search_field: '',
    form_tab_validation_tooltip: '',
    dialog_change_status: '',
    dialog_delete_item: '',
    dialog_unpublish_item: '',
    dialog_publish_item: '',
  };

  AddOnSource = AddOnSource;

  itemStatus = ItemStatusEnum;

  getTypeOptions = (): Observable<GenericType[]> =>
    translateFromArray(catalogTypesTranslationPaths, this.translate);
  getSourceOptions = (): Observable<ItemSource[]> => getAllItemSources();

    constructor(
    public layoutService: LayoutService,
    private catalogService: CatalogService,
    private messageService: MessageService,
    private authService: MsalService,
    private translate: TranslateService,
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private addonService: AddonService,
    private gAService: GA4Service
  ) {}

  ngOnInit() {
    this.layoutService.setAdminStuff(true);
    this.initTranslations();
    this.translate.onLangChange.subscribe(() => this.initTranslations());

    this.cols = [
      { field: 'id', header: 'Id' },
      { field: 'itemType.description', header: 'Type' },
      { field: 'name', header: 'Name' },
      { field: 'owner', header: 'Owner' },
      { field: 'status', header: 'Status' },
      { field: 'actions', header: 'Actions' },
    ];

    this.form = new FormGroup({
      id: new FormControl(''),
      itemTypeId: new FormControl(null, { nonNullable: true }),
      itemSourceId: new FormControl(this.AddOnSource.Genuz, {
        nonNullable: true,
      }),
      name: new FormControl('', { nonNullable: true }),
      headline: new FormControl('', { nonNullable: true }),
      description: new FormControl(''),
      itemAccessMethod: new FormControl({
        value: '',
        disabled: true,
      }),
      itemAccessUrl: new FormControl({ value: '', disabled: false }),
      ownerName: new FormControl(''),
      ownerId: new FormControl(''),
      itemVersion: new FormControl(''),
      creatorUser: new FormControl(''),
      resources: new FormGroup({
        hasFilter: new FormControl<boolean>(true),
        hasPagination: new FormControl<boolean>(true),
        hasStatusBar: new FormControl<boolean>(true),
      }),
    });

    this.getSourceVisibility().subscribe((isVisible) => {
      this.isSourceVisible = isVisible;
      if (!this.item.itemSourceId) {
        this.getAccessMethodBySource(1);
      }
    });
  }
  getAllCatalog(event: TableLazyLoadEvent): void {
    this.loading = true;
    // Carregar os dados paginados com base no evento

    this.page = event.first && event.rows ? event.first / event.rows : 0;
    this.size = event.rows ?? this.rows;

    this.sortField = event.sortField ?? 'Id'; // Campo a ser ordenado
    this.sortOrder = event.sortOrder ?? 1; // 1 para ascendente, -1 para descendente

    this.filters = event.filters;
    let filters = this.filters ? mapFilters(this.filters) : {};


    this.catalogService
      .getItems({

        currentPage: this.page,
        numberOfRecords: this.size,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        filters: filters,

      })
      .subscribe((result) => {
        this.items = result.items; // Dados retornados
        this.totalRecords = result.total; // Total de registros
        this.loading = false;
      });
  }

  // Método para recarregar a tabela manualmente
  resetTable(): void {
    this.paginatorIndex = 0; // Opcional: voltar para a primeira página
    this.table.reset(); // Reinicia a tabela (opcional, para resetar filtros/ordenação)
  }

  getSourceVisibility(): Observable<boolean> {
    return this.addonService
      .getInstalledAddons()
      .pipe(map((result) => result.length > 0));
  }

  initTranslations() {
    this.tableItemActions = [];
    this.itemAccessMethods = [];

    this.translate.get('catalog.table.page_report').subscribe((res: string) => {
      this.translatedTemplate.page_report = res;
    });

    this.translate
      .get('catalog.form.tab.general.validation_tooltip')
      .subscribe((res: string) => {
        this.translatedTemplate.form_tab_validation_tooltip = res;
      });

    this.translate.get('catalog.buttons.unpublish').subscribe((res: string) => {
      this.tableItemActions.push({
        label: res,
        icon: 'pi pi-fw pi-ban',
        command: () => this.changeItemStatusToUnpublish(),
      });
    });

    this.translate.get('catalog.buttons.publish').subscribe((res: string) => {
      this.tableItemActions.push({
        label: res,
        icon: 'pi pi-fw pi-upload',
        command: () => this.changeItemStatusToPublished(),
      });
    });

    this.itemAccessMethods.push({
      id: 1,
      desc: this.translate.instant(
        'entities.catalog.catalog-access-method.external-link'
      ),
      value: '',
    });

    this.itemAccessMethods.push({
      id: 2,
      desc: this.translate.instant(
        'entities.catalog.catalog-access-method.power-bi'
      ),
      value: '',
    });
  }

  isValidAccessUrl(url: string): boolean {
    return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,512}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
      url
    );
  }

  onSourceChange(event: DropdownChangeEvent): void {
    this.getAccessMethodBySource(event.value);
  }

  pageChange(event: TablePageEvent): void {
    this.paginatorIndex = event.first;
    this.totalRecords = event.rows;
  }

  nextPage(): void {
    this.totalRecords++; // Incrementa o total de registros

    // Atualiza o �ndice da primeira p�gina para ir at� a �ltima
    this.paginatorIndex = Math.floor(this.totalRecords / this.rows) * this.rows;
    this.getAllCatalog({
      first: this.paginatorIndex,
      rows: this.rows,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filters: this.filters,
    });
  }

  openNew() {
    this.item = {} as Item;
    this.itemDialog = true;

    this.form.controls['creatorUser'].setValue(
      this.authService.instance.getActiveAccount()?.name ?? ''
    );
    this.getSourceVisibility().subscribe((isVisible) => {
      this.isSourceVisible = isVisible;
      if (!this.item.itemSourceId) {
        this.getAccessMethodBySource(1);
      }
    });
  }

  publishSelectedItems() {
    let missingOwner = false;

    this.selectedItems.forEach((item) => {
      if (item.status!.id !== ItemStatusEnum.DRAFT) return;

      if (!item.ownerName) {
        missingOwner = true;
        return;
      }

      item.status!.id = ItemStatusEnum.PUBLISHED;

      this.catalogService.publishItem(item).subscribe({
        next: (result) => {
          this.items[this.items.findIndex((item) => item.id === result.id)] =
            result;
        },
        error: (error) => this.errorHandler.handleError(error),
      });
    });

    if (missingOwner) {
      this.translatedMessageService(
        'error',
        'tooltip.error',
        'tooltip.content.some_items_publish_requires_owner'
      );
    } else {
      this.translatedMessageService(
        'success',
        'Successful',
        'tooltip.content.items_published'
      );
    }

    this.selectedItems = [];
  }

  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  displayItemAccessUrl(selectedId?: number): void {
    if (selectedId === 1) this.form.controls['itemAccessUrl'].enable();
    else this.form.controls['itemAccessUrl'].disable();
  }

  onAccessMethodChange(item: DropdownChangeEvent): void {
    this.displayItemAccessUrl(item.value?.id);
  }

  onAccessMethodClear(): void {
    this.item.itemAccessUrl = '';
    this.form.controls['itemAccessUrl'].reset();
  }

  setSelectedItemRow(item: Item) {
    this.selectedItem = item;
  }

  changeItemStatusToPublished() {
    this.item = { ...this.selectedItem };

    if (!this.item.ownerId) {
      this.translatedMessageService(
        'error',
        'tooltip.error',
        'tooltip.content.items_publish_requires_owner'
      );

      return;
    }

    this.translate
      .get(['catalog.dialogs.publish_item.message'], {
        name: this.item.name,
      })

      .subscribe((translations: any) => {
        this.translatedTemplate.dialog_publish_item =
          translations['catalog.dialogs.publish_item.message'];
        this.publishItemStatusDialog = true;
      });
  }

  changeItemStatusToUnpublish() {
    this.item = { ...this.selectedItem };

    this.translate
      .get(['catalog.dialogs.unpublish_item.message'], {
        name: this.item.name,
      })

      .subscribe((translations: any) => {
        this.translatedTemplate.dialog_unpublish_item =
          translations['catalog.dialogs.unpublish_item.message'];

        this.unpublishItemStatusDialog = true;
      });
  }

  saveItem(): void {
    this.item = { ...this.item, ...this.form.value };

    if (
      this.item.name?.trim() &&
      this.item.itemTypeId &&
      this.item.headline?.trim()
    ) {
      if (this.item.id) {
        this.catalogService.update(this.item).subscribe({
          next: (result) => {
            this.items[this.items.findIndex((item) => item.id === result.id)] =
              result;

            this.translatedMessageService(
              'success',
              'Successful',
              'tooltip.content.item_updated'
            );
          },
          error: (e) => this.errorHandler.handleError(e),
        });
      } else {
        this.gAService.sendGTMEvent('catalog_add', {
          'Item Source': this.item.itemSourceId,
        });
        this.catalogService.add(this.item).subscribe({
          next: (result) => {
            this.items = [...this.items, result];

            this.nextPage();

            this.translatedMessageService(
              'success',
              'Successful',
              'tooltip.content.item_created'
            );
          },
          error: (error) => this.errorHandler.handleError(error),
        });
      }

      this.hideDialog();
      this.items = [...this.items];
    }
  }

  translatedMessageService(
    severity: string = 'success',
    summary: string = 'Successful',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }

  editItem(item: Item): void {
    this.catalogService.getById(item.id!).subscribe(item => {
      this.item = item;

      this.form.patchValue(item);
      this.getAccessMethodBySource(item.itemSourceId!);
      this.getSourceVisibility().subscribe((isVisible) => {
        this.isSourceVisible = isVisible;
        if (!this.item.itemSourceId) {
          this.getAccessMethodBySource(1);
        }
      });

      this.form.enable();
      this.form.controls['creatorUser'].setValue(
        this.authService.instance.getActiveAccount()?.name ?? ''
      );

      this.itemDialog = true;
    });
  }

  visualizeItem(item: Item): void {
    this.catalogService.getById(item.id!).subscribe(item => {
      this.item = item;

      this.item = { ...item };

      this.itemDialog = true;

      this.form.patchValue(item);
      this.form.disable();
    });
  }

  getAccessMethodBySource(itemSourceId: number) {
    switch (itemSourceId) {
      case 1:
        this.form.controls['itemAccessMethod'].setValue(
          this.itemAccessMethods[0]
        );
        break;
      case 2:
        this.form.controls['itemAccessMethod'].setValue(
          this.itemAccessMethods[1]
        );
        break;
    }
  }

  deleteItem(item: Item) {
    this.item = { ...item };
    this.translate
      .get('catalog.dialogs.delete_item.message', {
        name: this.item.name,
      })
      .subscribe((translation: any) => {
        this.translatedTemplate.dialog_delete_item = translation;
        this.deleteItemDialog = true;
      });
  }

  confirmDelete() {
    this.deleteItemDialog = false;

    let itemToDelete: number[] = [this.item.id!];

    this.catalogService.deleteItem(itemToDelete).subscribe({
      complete: () => {
        this.getAllCatalog({
          first: this.paginatorIndex,
          rows: this.rows,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          filters: this.filters,
        });

        this.translatedMessageService(
          'success',
          'Successful',
          'tooltip.content.item_deleted'
        );
      },
      error: (error) => this.errorHandler.handleError(error),
    });

    this.item = {} as Item;
  }

  confirmPublishItem() {
    this.item.status!.id = ItemStatusEnum.PUBLISHED;
    this.catalogService.publishItem(this.item).subscribe({
      next: () => {
        this.getAllCatalog({
          first: this.paginatorIndex,
          rows: this.rows,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          filters: this.filters,
        });

        this.translatedMessageService(
          'success',
          'Successful',
          'tooltip.content.item_published'
        );
      },
      error: (error) => this.errorHandler.handleError(error),
    });

    this.publishItemStatusDialog = false;
  }

  confirmUnpublishItem() {
    this.catalogService.unpublishItem(this.item).subscribe({
      next: () => {
        this.getAllCatalog({
          first: this.paginatorIndex,
          rows: this.rows,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          filters: this.filters,
        });

        this.translatedMessageService(
          'success',
          'Successful',
          'tooltip.content.item_unpublished'
        );
      },
      error: (error) => this.errorHandler.handleError(error),
    });

    this.unpublishItemStatusDialog = false;
  }

  isEditable(item: Item): boolean {
    return item.status?.id === ItemStatusEnum.DRAFT;
  }

  getItemAccessUrlLabel() {
    if (this.form.controls['itemAccessMethod'].value?.id === 1) {
      return this.translate.instant('catalog.form.fields.access.label');
    } else if (this.form.controls['itemAccessMethod'].value?.id === 2) {
      return this.translate.instant('catalog.form.fields.power_bi_url.label');
    }
  }

  isActionVisible(item: Item, label: string): boolean {
    if (
      label === this.translate.instant('catalog.buttons.publish') &&
      item.status?.id != ItemStatusEnum.DRAFT
    )
      return false;

    if (
      label === this.translate.instant('catalog.buttons.unpublish') &&
      item.status?.id != ItemStatusEnum.PUBLISHED
    )
      return false;

    if (
      label == this.translate.instant('catalog.buttons.delete') &&
      item.status?.id != ItemStatusEnum.DRAFT
    )
      return false;

    return true;
  }

  getItemTypeLabel(ItemTypeKey: number): string {
    return translateFromArrayAt(
      catalogTypesTranslationPaths,
      ItemTypeKey,
      this.translate
    );
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  toggleFilter() {
    this.isFilterRowVisible = !this.isFilterRowVisible;
  }

  onItemTermsChanged(itemTerms: ItemTerm[]): void {
    this.item.itemTerms = [...itemTerms];
    this.form.markAsDirty();
  }

  onItemCustomFieldsChanged(itemCustomFields: ItemCustomField[]) {
    this.item.itemCustomFields = itemCustomFields;
    this.form.markAsDirty();
  }

  onItemPermissionsChanged(itemPermissions: ItemPermission[]) {
    this.item.itemPermissions = itemPermissions;
    this.form.markAsDirty();
  }

  onDynamicRoleChanged(dynamicRoles: ItemDynamicRole): void {
    this.item.itemDynamicRoles = dynamicRoles;
    this.form.markAsDirty();
  }

  setPreviews(previews: ItemPreview[]): void {
    this.item.itemPreviews = previews;
    this.form.markAsDirty();
  }

  setItemIcon(icon: ItemIcon): void {
    this.item.itemIcon = icon;
    this.form.markAsDirty();
  }

  setOwner(user: User): void {
    this.form.controls['ownerName'].setValue(user.name);
    this.form.controls['ownerId'].setValue(user.id);
    this.form.markAsDirty();
  }

  hideDialog(): void {
    this.itemDialog = false;
    this.activeTabIndex = 0;
    this.form.reset({
      resources: {
        hasFilter: true,
        hasPagination: true,
        hasStatusBar: true,
      },
    });
  }
}
