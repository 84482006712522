<p-autoComplete
  id="user-dropdown"
  name="user"
  filterBy="name"
  appendTo="body"
  field="name"
  [required]="required"
  [placeholder]="placeholder | translate"
  [(ngModel)]="user"
  [suggestions]="users"
  [showClear]="true"
  [disabled]="isDisabled"
  (ngModelChange)="modelChange($event)"
  (onSelect)="selectUser($event)"
  (completeMethod)="search($event)">

  <ng-template let-user pTemplate="item">
    <div class="flex align-items-center gap-2">
      <div>{{ user.name + ' (' + user.mail + ')' }}</div>
    </div>
  </ng-template>
</p-autoComplete>
