import { DialogHandlerService } from './../../../service/dynamic-dialog.service';
import { Component, OnDestroy } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RatingModule } from 'primeng/rating';
import { FormsModule } from '@angular/forms';
import { FeedbackService } from '../../../service/general-feedback.service';
import { NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GA4Service } from '../../../service/ga4.service';

@Component({
  selector: 'app-general-feedback-dialog',
  standalone: true,
  imports: [
    DialogModule,
    Button,
    InputTextareaModule,
    RatingModule,
    FormsModule,
    NgIf,
    TranslateModule
  ],
  templateUrl: './general-feedback-dialog.component.html',
  styleUrls: ['./general-feedback-dialog.component.scss'],
})
export class GeneralFeedbackDialogComponent implements OnDestroy {
  ratingValue: number = 0;
  feedbackText: string = '';
  visible = false;
  errorMessage: string = '';
  isFeedbackSubmitted: boolean = false;
  showFeedbackForm: boolean = true;

  constructor(
    private feedbackService: FeedbackService,
    private dialogHandlerService: DialogHandlerService,
    private translateService: TranslateService,
    private gAService: GA4Service,
  ) {}

  submitFeedback() {
    if (this.ratingValue === 0) {
      this.errorMessage = this.translateService.instant('general_feedback.error-message');
      return;
    }

    const feedbackData = {
      rate: this.ratingValue,
      description: this.feedbackText.trim()
    };

    this.isFeedbackSubmitted = true;

    this.gAService.sendGTMEvent('submit_review', {
      'Nota Avaliacao': this.ratingValue,
      'Comentario Avaliacao' : this.feedbackText.trim()
    });

    this.feedbackService.sendFeedback(feedbackData).subscribe(
      (response) => {
        this.showFeedbackForm = false;
        this.clearFields();
      },
      (error) => {
        console.error('Erro ao enviar feedback:', error);
      }
    );
  }

  checkRatingValue() {
    if (this.ratingValue > 0) {
      this.errorMessage = '';
    }
  }

  clearFields() {
    this.ratingValue = 0;
    this.feedbackText = '';
    this.errorMessage = '';
  }

  ngOnDestroy() {
    if (!this.isFeedbackSubmitted && this.showFeedbackForm) {
      this.feedbackService.declinefeedback().subscribe(() => {});
    }
  }

  closeFeedback() {
    this.dialogHandlerService.closeDialog();
  }
}

