export enum EnvironmentTypeEnum {
  DEV = 1,
  PROD
}

export interface TenantSettings {
  id: number;
  tenantName: string;
  ownerId: string;
  environmentTypeId: EnvironmentTypeEnum;
  url: string;
  tagManagerId: string;
}

export interface Settings {
  tenantSettings: TenantSettings;
  isFeedbackEnabled: boolean;
}
