export const environment = {
  production: true,
  genuzApiEndpoint:
    'https://capp-genuz-backend.redflower-ee0038e7.eastus.azurecontainerapps.io/api/',
  msalConfig: {
    auth: {
      clientId: 'f66225ed-39c9-4422-ac11-0b59f20f752b',
      authority:
        'https://login.microsoftonline.com/8a610109-3152-46d4-936c-d1358e167fec',
      redirectUri: 'https://genuz.arteris.com.br/'
    },
  },
  apiConfig: {
    scopes: ['api://f66225ed-39c9-4422-ac11-0b59f20f752b/user.read'],
    uri: 'https://capp-genuz-backend.redflower-ee0038e7.eastus.azurecontainerapps.io',
  },
  graphEndpoints: {
    photo: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  },
  powerBiEndpoints: {
    capacities: 'https://api.powerbi.com/v1.0/myorg/capacities',
  },
};
