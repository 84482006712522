import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { Item, ItemTerm } from '../../../api/item';
import { Term, termsTypesTranslationPaths, translateFromArrayAt } from '../../../api/term';
import { GlossaryService } from '../../../service/glossary.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item-terms',
  standalone: true,
  imports: [
    FormsModule,
    DataViewModule,
    DropdownModule,
    ButtonModule,
    JsonPipe,
    TableModule,
    ToastModule,
    TranslateModule
  ],
  templateUrl: './item-terms.component.html',
  styleUrl: './item-terms.component.scss',
  providers: [GlossaryService, MessageService],
})
export class ItemTermsComponent implements OnInit {
  @Input() item: Item = {} as Item;
  @Input() isDisabled = false;
  @Output() itemTermsChanged = new EventEmitter<ItemTerm[]>();
  itemTerms: ItemTerm[] = [];
  terms: Term[] = [];
  selectedTerm?: number;

  constructor(
    private termService: GlossaryService,
    private messageService: MessageService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.termService.getTerms().subscribe((terms: Term[]) => {
      this.terms = terms;
      this.itemTerms = this.item.itemTerms ?? [];

      this.itemTerms.forEach((itemTerm: ItemTerm) => {
        itemTerm.term = this.terms.find(term => term.id === itemTerm.termId);
      });
    });
  }

  addTerm() {
    if (this.selectedTerm) {
      const existingTerm = this.itemTerms?.find(
        (itemTerm) => itemTerm.termId === this.selectedTerm
      );

      if (existingTerm) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Term already exists in the list',
          life: 3000,
        });
      } else {
        const newTerm: ItemTerm = {
          term: this.terms.find((term) => term.id === this.selectedTerm),
          termId: this.selectedTerm,
          itemId: this.item.id,
        };

        this.itemTerms = this.itemTerms
          ? [...this.itemTerms, newTerm]
          : [newTerm];

        this.selectedTerm = undefined;

        this.itemTermsChanged.emit(this.itemTerms);

        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Term added to the list',
          life: 3000,
        });
      }
    }
  }

  removeTerm(itemTermId?: number) {
    this.itemTerms = this.itemTerms?.filter(
      (itemTerm) => itemTerm.termId !== itemTermId
    );

    this.itemTermsChanged.emit(this.itemTerms);

    this.messageService.add({
      severity: 'success',
      summary: 'Successful',
      detail: 'Term deleted from the list',
      life: 3000,
    });
  }

  getTermTypeLabel(termTypeId: number): string {
    return translateFromArrayAt(termsTypesTranslationPaths, termTypeId, this.translate);
  }
}
