<p-toast></p-toast>
<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <h3 class="mb-5">{{ 'seals.header' | translate }}</h3>
      <p-table #dt [value]="seals" [columns]="cols" responsiveLayout="scroll" [rows]="rows"
               [globalFilterFields]="['Id','Name','Description']" [paginator]="true" [(first)]="paginatorIndex"
               [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]" [totalRecords]="totalRecords"
               [showCurrentPageReport]="true"
               [currentPageReportTemplate]="translatedTemplate.page_report" [(selection)]="selectedRows"
               selectionMode="multiple" [rowHover]="true" dataKey="id" [lazy]="true" [loading]="loading"
               (onLazyLoad)="getAllSeals($event)">
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
              <div class="my-2">
                <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-outlined mr-3"
                        (click)="openNew()"></button>
              </div>
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="onGlobalFilter(dt, $event)"
                     [placeholder]="'catalog.table.search_field' | translate" class="w-full sm:w-auto" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="Id">{{ 'seals.table.colum_header.id' | translate }} <p-sortIcon
                          field="Id"></p-sortIcon></th>
            <th pSortableColumn="Name"> {{ 'seals.table.colum_header.name' | translate }} <p-sortIcon
                          field="Name"></p-sortIcon></th>
            <th pSortableColumn="Description">{{ 'seals.table.colum_header.description' | translate }} <p-sortIcon
                          field="Description"></p-sortIcon></th>
            <th pSortableColumn=""></th>
            <th><p-button [icon]="
              isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'
            " (click)="toggleFilter()" [rounded]="true" [text]="true" [raised]="true" severity="secondary" /></th>
          </tr>
          <tr *ngIf="isFilterRowVisible">
            <th></th>
            <th>
              <p-columnFilter type="text" field="Id" placeholder="{{ 'seals.table.table_filter.id' | translate }}"
                              ariaLabel="Filter Id" />
            </th>
            <th>
              <p-columnFilter type="text" field="Name" placeholder="{{ 'seals.table.table_filter.name' | translate }}"
                              ariaLabel="Filter Name" />
            </th>
            <th>
              <p-columnFilter type="text" field="Description"
                              placeholder="{{ 'seals.table.table_filter.type' | translate }}" ariaLabel="Filter Type" />
            </th>
            <th>
            </th>
            <th>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-seal>
          <tr>
            <td>
              <p-tableCheckbox [value]="seal"></p-tableCheckbox>
            </td>
            <td>{{ seal.id }}</td>
            <td>{{ seal.name }}</td>
            <td>{{ seal.description }}</td>
            <td><ngx-emoji [set]="emojiset" [emoji]="seal.emoji"></ngx-emoji></td>
            <td>
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-outlined mr-2"
                      (click)="openEditDialog(seal)"></button>
              <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger mr-2"
                      (click)="openDeleteDialog(seal)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <app-new-seal [(isVisible)]="isNewSealVisible" [seal]="seal" (didSaveEvent)="didSave($event)"
                    (didEditEvent)="didEdit($event)"> </app-new-seal>
    </div>
  </div>
  <p-dialog [(visible)]="deleteSealDialog" header="{{ 'components.dialogs.header.confirm' | translate }}" [modal]="true"
            [style]="{ width: '450px' }">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span *ngIf="seal" [innerHTML]="translatedTemplate.dialog_delete_item"></span>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'components.dialogs.no' | translate }}"
              (click)="deleteSealDialog = false"></button>
      <button pButton pRipple icon="pi pi-check" class="p-button-text"
              label="{{ 'components.dialogs.yes' | translate }}" (click)="confirmDeleteSeal()"></button>
    </ng-template>
  </p-dialog>
</div>