import { Component, ErrorHandler, Input, OnInit } from "@angular/core";
import { GlobalErrorHandler } from "../../../../../../utils/components/error-handler/error-handler.component";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FloatLabelModule } from "primeng/floatlabel";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { CapacitySchedule } from "../../../../../../api/capacity";
import { TableModule } from "primeng/table";
import { DialogModule } from "primeng/dialog";
import { CalendarModule } from "primeng/calendar";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TabViewModule } from "primeng/tabview";
import { CustomInputValidators } from "../../../../../../utils/validator.utils";
import { PrimeNGConfig } from "primeng/api";

enum RecurrencyType {
  Weekly = 1,
  Monthly
};

@Component({
  selector: 'addon-schedule',
  standalone: true,
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    FloatLabelModule,
    InputTextModule,
    ToastModule,
    TableModule,
    DialogModule,
    CalendarModule,
    OverlayPanelModule,
    TabViewModule
  ],
  templateUrl: './capacity-schedule.component.html',
  styleUrl: './capacity-schedule.component.scss',
})
export class AddonScheduleComponent implements OnInit {

  form!: FormGroup;

  @Input() schedules: CapacitySchedule[] = [];

  weekdays: string[] = [];
  selectedWeekdays: number[] = [];
  selectedMonths: number[] = [];

  showScheduleModal: boolean = false;
  showDeleteScheduleModal: boolean = false;

  activeIndex: number = 0;
  isEditing: boolean = false;

  constructor(private translation: TranslateService, private primeConfig: PrimeNGConfig) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl<number>(0),
      name: new FormControl<string>('', [CustomInputValidators.noWhitespaceValidator()]),
      recurrencyTypeId: new FormControl<number>(RecurrencyType.Weekly),
      recurrencyValue: new FormControl<string>('', [CustomInputValidators.noWhitespaceValidator()]),
      recurrencyMonths: new FormControl<Date[]>([], [CustomInputValidators.noWhitespaceValidator()]),
      recurrencyEndDate: new FormControl<Date | undefined>(undefined, [CustomInputValidators.noWhitespaceValidator()]),
      turnOn: new FormControl<Date | undefined>(undefined, [CustomInputValidators.noWhitespaceValidator()]),
      turnOff: new FormControl<Date | undefined>(undefined, [CustomInputValidators.noWhitespaceValidator()])
    });

    this.initTranslations();

    this.schedules.forEach(schedule => {
      schedule.recurrencyEndDate = new Date(schedule.recurrencyEndDate as Date);
    });

    this.translation.onLangChange.subscribe(() => this.initTranslations());
  }

  initTranslations(): void {
    this.translation.get('addons.settings.tabs.capacity.picker.weekdays')
      .subscribe(weekdays => this.weekdays = weekdays)
  }

  onModalHide(): void {
    this.selectedMonths = [];
    this.selectedWeekdays = [];
    this.form.reset();
  }

  onDayClick(value: any): void {
    this.setRecurrency(value.target.innerText);
  }

  clearAllValues(): void {
    this.form.get('recurrencyValue')?.reset();
    this.form.get('recurrencyMonths')?.setValue(null);
    this.selectedWeekdays = [];
    this.selectedMonths = [];
  }

  setRecurrency(value: string): void {
    const date = Number(value);

    let selectedDates: number[] = [];

    if (this.isWeeklyRecurrency())
      selectedDates = this.selectedWeekdays;

    if (this.isMonthlyRecurrency())
      selectedDates = this.selectedMonths;

    if (selectedDates.includes(date))
      selectedDates = selectedDates.filter(d => d !== date);
    else
      selectedDates.push(date);

    const recurrencyValue = selectedDates.sort((a, b) => a - b).join(',');

    this.form.get('recurrencyValue')?.setValue(recurrencyValue);

    if (this.isWeeklyRecurrency())
      this.selectedWeekdays = selectedDates;

    if (this.isMonthlyRecurrency())
      this.selectedMonths = selectedDates;

    this.form.get('recurrencyTypeId')?.setValue(this.activeIndex + 1);
  }

  formatRecurrency(typeId: number, recurrency: string): string {
    const recurrencyFormatted = recurrency
      .split(',')
      .map(Number)
      .map(num => {
        if (typeId === RecurrencyType.Weekly)
          return this.weekdays[num];

        if (typeId === RecurrencyType.Monthly) {
          const ordinals: { [key: number]: string } = { 1: 'st', 2: 'nd', 3: 'rd' };

          return (num + ordinals[Number(num.toString().at(-1))]) || num + 'th';
        }

        return '';
      }).join(', ');

    if (typeId === RecurrencyType.Weekly)
      return this.translation
        .instant('addons.settings.tabs.capacity.table.recurrencyWeekly')
        .replace('{weekdays}', recurrencyFormatted);

    if (typeId === RecurrencyType.Monthly)
      return this.translation
        .instant('addons.settings.tabs.capacity.table.recurrencyMonthly')
        .replace('{day}', recurrencyFormatted);

    return '';
  }

  isSaveDisabled(): boolean {
    return (!this.form.valid || !(this.form.get('turnOn')?.value || this.form.get('turnOff')?.value));
  }

  isWeeklyRecurrency(): boolean {
    return (this.activeIndex + 1) === RecurrencyType.Weekly;
  }

  isMonthlyRecurrency(): boolean {
    return (this.activeIndex + 1) === RecurrencyType.Monthly;
  }

  isWeekdaySelected(weekday: number): boolean {
    return this.selectedWeekdays.includes(weekday);
  }

  save(): void {
    if (this.isEditing)
      this.update();
    else
      this.add();
  }

  update(): void {
    const scheduleIdx = this.schedules
      .findIndex(schedule => schedule.id === this.form.value.id);

    this.schedules[scheduleIdx] = this.form.value;

    this.showScheduleModal = false;
  }

  add(): void {
    this.schedules.push(this.form.value);
    this.schedules = [...this.schedules];

    this.showScheduleModal = false;
  }

  edit(schedule: CapacitySchedule): void {
    if (schedule.turnOn)
      schedule.turnOn = new Date(schedule.turnOn as Date);

    if (schedule.turnOff)
      schedule.turnOff = new Date(schedule.turnOff as Date);

    this.form.patchValue(schedule);

    const selectedRecurrencies = schedule.recurrencyValue.split(',').map(Number);

    if (schedule.recurrencyTypeId === RecurrencyType.Weekly)
      this.selectedWeekdays = selectedRecurrencies;

    if (schedule.recurrencyTypeId === RecurrencyType.Weekly)
      this.selectedMonths = selectedRecurrencies;

    this.isEditing = true;

    this.showScheduleModal = true;
  }

  delete(): void {
    const scheduleId = this.form.get('id')?.value;

    this.schedules.splice(this.schedules.findIndex(schedule => schedule.id === scheduleId));

    this.schedules = [...this.schedules];

    this.showDeleteScheduleModal = false;
  }

  displayDeleteModal(schedule: CapacitySchedule): void {
    this.form.patchValue(schedule);
    this.showDeleteScheduleModal = true;
  }
}
