import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { HttpClient } from "@angular/common/http";
import { AddOnSchedulerSettings } from "../api/addon-scheduler";
import { Observable } from "rxjs";

/**
 * Service for managing addon schedules
 */
@Injectable({
  providedIn: 'root',
})
export class AddonScheduleService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) { }

  setup(schedulerSettings: AddOnSchedulerSettings): Observable<void> {
    return this.httpClient.post<void>(`${this.baseRoute}addons-scheduler/setup`, schedulerSettings);
  }
};
