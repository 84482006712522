import { Component, ErrorHandler, Inject, OnInit } from "@angular/core";
import { GlobalErrorHandler } from "../../../utils/components/error-handler/error-handler.component";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { AddOn, AddOnSchedulerLog, AddOnSource, InstalledAddOn, UpdateInstalledAddOnCommand, Credentials, translateMessage } from "../../../api/addon";
import { TabViewModule } from "primeng/tabview";
import { FormsModule, NgForm } from "@angular/forms";
import { FloatLabelModule } from "primeng/floatlabel";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { AddonSchedulerComponent } from "../addon-widgets/power-bi/addon-scheduler.component";
import { AddOnSchedulerSettings } from "../../../api/addon-scheduler";
import { PanelModule } from "primeng/panel";
import { AddonService } from "../../../service/addon.service";
import { Location } from '@angular/common';
import { AddOnLogService } from "../../../service/addon-log.service";
import { Subject, interval } from "rxjs";
import { RoleLevelSecurityComponent } from "./tabs/security/role-level-security.component";
import { AddonCapacityComponent } from "./tabs/capacity/addon-capacity.component";
import { RoleLevelSecurity } from "../../../api/rls";
import { Capacity, CapacitySchedule } from "../../../api/capacity";
import { increaseTimeOffset } from "../../../utils/time.utils";
import { RoleLevelSecurityService } from "../../../service/rls.service";

@Component({
  selector: 'addon-settings',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    RouterModule,
    ToastModule,
    TabViewModule,
    FormsModule,
    FloatLabelModule,
    PasswordModule,
    InputTextModule,
    PanelModule,
    AddonSchedulerComponent,
    RoleLevelSecurityComponent,
    AddonCapacityComponent
  ],
  templateUrl: './addon-settings.component.html',
  styleUrl: './addon-settings.component.scss',
})

export class AddonSettingsComponent implements OnInit {

  refreshTrigger: Subject<void> = new Subject<void>();

  addon!: AddOn;
  credentials!: any;
  form!: NgForm;

  isDirty: boolean = false;
  isValid: boolean = true;

  sourceId!: AddOnSource;

  AddOnSource = AddOnSource;

  addOnSchedulerSettings: AddOnSchedulerSettings | null = { } as AddOnSchedulerSettings | null;

  workspaceField: Credentials | any

  roles: RoleLevelSecurity[] = [];
  capacity: Capacity | undefined;
  capacitySchedules: CapacitySchedule[] = [];

  tabIndexes = [
    {
      name: 'General',
      index: 0
    },
    {
      name: 'Security',
      index: 1
    },
    {
      name: 'Capacity',
      index: 2
    },
    {
      name: 'Audit',
      index: 3
    },
    {
      name: 'Audit',
      index: 4
    }
  ];

  logs!: AddOnSchedulerLog[];
  searchTerm: string = "";

  loading: boolean = false;
  language: string = 'en'

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private translationService: TranslateService,
    private messageService: MessageService,
    private addonLogService: AddOnLogService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private addonService: AddonService,
    private rlsService: RoleLevelSecurityService
  ) {}

  ngOnInit() {
    this.loadAddon();
  }

  installedAddOn!: InstalledAddOn;

  loadAddon(): void {
    const nav = this.router.lastSuccessfulNavigation;

    if (!nav)
      this.router.navigate(['/addons']);

    interval(60 * 1000).subscribe(() => this.setLogs());

    const state = nav?.extras.state;

    this.installedAddOn = state as InstalledAddOn;
    this.addon = state?.addOn as AddOn;
    this.logs = state?.logs;
    this.capacity = this.installedAddOn.capacity;
    this.capacitySchedules = this.installedAddOn?.capacitySchedules ?? [];
    this.addOnSchedulerSettings = state?.setting;
    this.credentials = JSON.parse(state?.credentials);
    this.sourceId = this.addon.itemSourceId as AddOnSource;
    this.workspaceField = this.credentials.find((field: Credentials) => field.id === 'workspaceId');

    this.loadRoles();
  }

  loadRoles(): void {
    this.rlsService.getAll().subscribe({
      next: (roles: RoleLevelSecurity[]) => this.roles = roles,
      error: (e: any) => this.errorHandler.handleError(e)
    });
  }

  setRoles(roles: RoleLevelSecurity[]): void {
    this.roles = roles;

    this.isDirty = true;
  }

  setValid(isValid: boolean): void {
    this.isValid = isValid;
  }

  setCapacity(capacity: Capacity): void {
    this.capacity = capacity;
    this.isDirty = true;
  }

  setSchedulerFrequency(frequency: number): void {
    this.addOnSchedulerSettings = {
      id: this.installedAddOn.setting.id,
      installedAddonId: this.installedAddOn.id,
      frequencyInMinutes: frequency
    };
  }

  parseMessage(message: string, iconless: boolean = false): string {
    let [date, ...logs] = message.split(' ');

    message = `${new Date(date).toLocaleDateString(this.translationService.currentLang,
        { month: '2-digit', day: '2-digit', year: 'numeric' })} ${logs.join(' ')}`;

    return `${translateMessage(this.translationService, message, iconless)}`;
  }

  setLogs(): void {
    this.addonLogService.getAll(this.installedAddOn.id)
      .subscribe({
        next: (logs) => {
          this.logs = logs;
        },
        error: (e) => this.errorHandler.handleError(e)
      })
  }

  save(): void {
    this.loading = true;

    const payload = {
      sourceId: this.sourceId,
      credentials: JSON.stringify(this.credentials),
      setting: this.addOnSchedulerSettings,
      roles: this.roles,
      capacity: this.capacity,
      capacitySchedules: this.capacitySchedules
    } as UpdateInstalledAddOnCommand;

    this.addonService.update(payload)
      .subscribe({
        next: (installedAddOn) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.instant('tooltip.successful'),
            detail: this.translationService.instant('addons.settings.save'),
            life: 3000,
          });

          this.installedAddOn.capacitySchedules.forEach(schedule => {
            if (schedule.turnOn)
              schedule.turnOn = increaseTimeOffset(new Date(schedule.turnOn as Date));
            if (schedule.turnOff)
              schedule.turnOff = increaseTimeOffset(new Date(schedule.turnOff as Date));
          });

          this.router.navigate(['../settings'], {
            relativeTo: this.route,
            state: installedAddOn
          });

          this.location.replaceState('addons/settings', '', installedAddOn);

          this.loadRoles();

          this.loading = false;
        },
        error: (e) => {
          this.errorHandler.handleError(e);

          this.isDirty = true;

          this.loading = false;
        }
      })
  }

  copyToClipboard(): void {
    const input = document.createElement("textarea");

    const text = this.logs.map(log =>
      log.messages.map(message =>
        this.parseMessage(message.message, true)).join("\n")).join("\n");

    input.value = text;
    document.body.appendChild(input);

    input.select();

    navigator.clipboard.writeText(input.value).then(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translationService.instant('tooltip.successful'),
        detail: this.translationService.instant('addons.settings.clipboard'),
        life: 3000,
      });

      document.body.removeChild(input);
    });
  }

  onTabChange(index: number): void {
    if (index === this.tabIndexes.find(tab => tab.name === 'Capacity')?.index)
      this.refreshTrigger.next();
  }

  get filterData() {
    if (!this.searchTerm) {
      return this.logs;
    }

    return this.logs.map(item => ({
      ...item,
      messages: item.messages.filter(message =>
        this.parseMessage(message.message, true).toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    })).filter(item => item.messages.length > 0);

  }
}
