<div class="grid grid-nogutter mt-4">
    <div class="col-12">
        @if (itemTerms.length>0) {
        <p-table #dt [value]="itemTerms" [columns]="['name', 'type', 'acronym']"
                 responsiveLayout="scroll" [rows]="10"
                 [globalFilterFields]="['name', 'type', 'acronym']"
                 [currentPageReportTemplate]="translatedTemplate.page_report"
                 styleClass="p-datatable-gridlines"
                 [tableStyle]="{'min-width': '50rem'}"
                 [rowsPerPageOptions]="[5, 10, 20]" [paginator]="true" dataKey="id">
            <ng-template pTemplate="caption">
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <div>
                        <h3>Termos</h3>
                    </div>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="onGlobalFilter(dt, $event)"
                               [placeholder]="translatedTemplate.table_search_field" class="w-full sm:w-auto" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width:50%; min-width:10rem;border-radius: 0.5em 0 0 0;">
                        {{'catalog.form.fields.name.label' | translate }}
                    </th>
                    <th style="width:25%; min-width:5rem;">{{ 'catalog.form.fields.type.label' | translate }}</th>
                    <th style="width:14%; min-width:8rem;">{{ 'catalog.form.fields.acronym.label' | translate }}</th>
                    <th style="border-radius: 0 0.5em 0 0;">
                        <p-button [icon]="isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'"
                                  (click)="toggleFilter()" [rounded]="true" [text]="true" [raised]="true"
                                  severity="secondary" />
                    </th>
                </tr>
                <tr *ngIf="isFilterRowVisible">
                    <th style="width:50%; min-width:10rem;">
                        <p-columnFilter type="text" field="name" placeholder="Search by name" ariaLabel="Filter name" />
                    </th>
                    <th>
                        <p-columnFilter type="text" field="typeLabel" placeholder="Search by Type"
                                        ariaLabel="Filter Type" />
                    </th>
                    <th style="width:14%; min-width:8rem;">
                        <p-columnFilter type="text" field="acronym" placeholder="Search by Acronym"
                                        ariaLabel="Filter Acronym" />
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="width:50%; min-width:10rem;">{{item.name}}</td>
                    <td>{{getTermTypeLabel(item.type)}}</td>
                    <td style="width:14%; min-width:8rem;">{{item.acronym}}</td>
                    <td></td>
                </tr>
            </ng-template>
        </p-table>
        }
    </div>
</div>