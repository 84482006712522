import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ToolbarModule } from 'primeng/toolbar';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DEFAULT_LANGUAGE } from './app.config';
import { UserService } from './service/user.service';
import { User } from './api/user';
import { SettingsService } from './service/settings.service';
import { GA4Service } from './service/ga4.service';
declare var newrelic: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MsalModule,
    RouterOutlet,
    RouterLink,
    ToolbarModule,
    ButtonModule,
    MenuModule,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Angular 18 Sample - MSAL Angular v3';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  loginItems: MenuItem[] | undefined;
  logoutItems: MenuItem[] | undefined;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private translate: TranslateService,
    private primeConfig: PrimeNGConfig,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private settingsService: SettingsService,
    private router: Router,
    private gAService: GA4Service
  ) {
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Reporta ao New Relic a troca de rota
        newrelic
          .interaction()
          .setName(`Route Change: ${event.urlAfterRedirects}`);
      }
    });
    this.loginItems = [
      {
        label: 'Login using Redirect',
        icon: 'pi pi-link',
        command: () => {
          this.loginRedirect();
        },
      },
      {
        label: 'Login using Popup',
        icon: 'pi pi-link',
        command: () => {
          this.loginPopup();
        },
      },
    ];

    this.logoutItems = [
      {
        label: 'Logout using Redirect',
        icon: 'pi pi-link',
        command: () => {
          this.logout();
        },
      },
      {
        label: 'Logout using Popup',
        icon: 'pi pi-link',
        command: () => {
          this.logout(true);
        },
      },
    ];

    this.authService.handleRedirectObservable().subscribe();
    this.isIframe =
      typeof window !== 'undefined' &&
      window !== window.parent &&
      !window.opener; // Remove this line to use Angular Universal

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
          this.setTranslationDefaults();
        } else {
          this.saveNewUser();
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.saveNewUser();
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.setTranslationDefaults();
        this.setTagManager();
      });
  }

  setTagManager(): void {
    this.settingsService.get().subscribe((settings) => {
      const tagId = settings?.tenantSettings?.tagManagerId ?? '';
      this.gAService.initializeGTM(tagId);
    });
  }

  saveNewUser(): void {
    const userId: string = this.getUserId();
    this.userService
      .getUserInformation(userId)
      .subscribe((user: User | null) => {
        if (!user || Object.keys(user).length === 0) {
          this.userService.createNewUser(userId).subscribe((user: User) => {
            console.log(user);
          });
        }
      });
  }

  getUserId(): string {
    const account = this.authService.instance.getActiveAccount();
    return account?.localAccountId || '';
  }

  setTranslationDefaults(): void {
    this.translate
      .get('primeng')
      .subscribe((res) => this.primeConfig.setTranslation(res));

    const storedLanguage = localStorage.getItem('language');

    if (!storedLanguage) {
      const browserLang = this.translate.getBrowserLang() + '';

      localStorage.setItem('language', browserLang);
      this.translate.use(browserLang);
    } else this.translate.use(storedLanguage);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
