import { Injectable } from '@angular/core';
import { EnvironmentTypeEnum } from '../api/settings';
import { SettingsService } from './settings.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}
@Injectable({
  providedIn: 'root',
})
export class GA4Service {
  constructor(private settingsService: SettingsService) {}

  initializeGTM(id: string) {
    // Adicionar o script do GTM no head
    if (!document.getElementById('gtm-script') && id != '') {
      // Cria o script do GTM
      const gtmScript = document.createElement('script');
      gtmScript.id = 'gtm-script';
      gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
      `;
      // Adiciona o script no head
      document.head.prepend(gtmScript);
    }

    // Adicionar o iframe no body para o caso de noscript
    const gtmNoscript = document.createElement('noscript');
    gtmNoscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(gtmNoscript);
  }

  sendGTMEvent(event: string, options?: Object): void {
    this.settingsService.get().subscribe((settings) => {
      if (!settings) return;
      const { tenantName, environmentTypeId } = settings.tenantSettings;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: event,
        'Tenant Name': tenantName,
        'Environment Type': EnvironmentTypeEnum[environmentTypeId],
        'Date/Time': new Date().toISOString(),
        ...options,
      });
    });
    // Cria o evento para enviar ao dataLayer do GTM
  }
}
