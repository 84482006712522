<div class="flex justify-content-start mt-3">
  <h5>{{ 'catalog.tab.business_glossary' | translate }}</h5>
</div>
<div class="flex justify-content-start mt-2">
  <div class="col-4">
    <p-dropdown [(ngModel)]="selectedTerm" id="term" name="term" [filter]="true" [showClear]="true" [options]="terms"
      optionLabel="name" optionValue="id" [disabled]="isDisabled" placeholder="{{ 'catalog.form.fields.term.label' | translate }}" />
  </div>
  <div class="col-1">
    <button pButton pRipple icon="pi pi-plus" [disabled]="isDisabled" class="p-button-rounded p-button-outlined" (click)="addTerm()"></button>
  </div>
</div>

<div class="grid grid-nogutter mt-4">
  <div class="col-12">
    <p-table #dt [value]="itemTerms" [columns]="['name', 'type', 'acronym']" responsiveLayout="scroll" [rows]="10"
      [rowsPerPageOptions]="[5, 10, 20]" [paginator]="true" dataKey="id">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'catalog.form.fields.name.label' | translate }}</th>
          <th>{{ 'catalog.form.fields.type.label' | translate }}</th>
          <th>{{ 'catalog.form.fields.acronym.label' | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="width:50%; min-width:10rem;">{{item.term.name}}</td>
          <td>{{getTermTypeLabel(item.term.type)}}</td>
          <td style="width:14%; min-width:8rem;">{{item.term.acronym}}</td>
          <td class="flex justify-content-end">
            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger"
              (click)="removeTerm(item.term.id)" [disabled]="isDisabled"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-toast></p-toast>
