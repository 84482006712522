export interface Capacity {
  id: number;
  installedAddonId: number;
  capacityId: string;
  displayName: string;
  sku: string;
  region: string;
  statusId: number;
  typeId: number;
  subscriptionId: string;
  resourceGroupName: string;
}

export interface CapacitySchedule {
  id: number;
  recurrencyTypeId: number;
  name: string;
  recurrencyValue: string;
  recurrencyEndDate?: Date;
  turnOn?: Date;
  turnOff?: Date;
}

export enum CapacityType {
  PowerBI = 1,
  MSFabric = 2
}

export enum CapacityStatus {
  Active = 1,
  Suspended = 2,
  Fail = 3,
  Paused = 4
}
