import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChipModule } from 'primeng/chip';
import { RatingModule } from 'primeng/rating';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { Item, ItemCustomField } from '../../api/item';
import {
  catalogTypesTranslationPaths,
  translateFromArrayAt,
} from '../../api/term';
import { User } from '../../api/user';
import { DetailsOverviewTabComponent } from '../../pages/item-details/details-overview-tab/details-overview-tab.component';
import { DetailsRelationshipTabComponent } from '../../pages/item-details/details-relationship-tab/details-relationship-tab.component';
import { CatalogService } from '../../service/catalog.service';
import { UserService } from '../../service/user.service';
import { ItemRequestService } from '../../service/item-request.service';
import { ItemAccessRequestStatusEnum } from '../../api/item-request';
import { ButtonModule } from 'primeng/button';
import { RequestAccessFormComponent } from '../request-access-form/request-access-form.component';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';
import { DetailsReviewsTabComponent } from '../../pages/item-details/details-reviews-tab/details-reviews-tab.component';
import { ItemReview, ItemReviewAverageRate, ItemReviewFilterByEnum, ItemReviewGetList, ItemReviewOrderByEnum } from '../../api/item-review';
import { ItemReviewService } from '../../service/item-review.service';
import { AverageRatingDetailsComponent } from './average-rating/average-rating-details.component';
import { ItemReviewFormComponent } from './item-review-form/item-review-form.component';
import { UserItemFavoriteService } from '../../service/user-item-favorites.service';
import { ToastModule } from 'primeng/toast';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [
    ChipModule,
    RatingModule,
    FormsModule,
    TranslateModule,
    SidebarModule,
    TabViewModule,
    DetailsOverviewTabComponent,
    DetailsRelationshipTabComponent,
    DetailsReviewsTabComponent,
    ButtonModule,
    RequestAccessFormComponent,
    CarouselModule,
    ImageModule,
    AverageRatingDetailsComponent,
    ItemReviewFormComponent,
    ToastModule
  ],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
})
export class DetailsComponent implements OnChanges {
  @Input() item!: Item;
  @Input() isOpen: boolean = false;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAverageRateRefresh: EventEmitter<{ total: number, average: number }> = new EventEmitter();
  @Output() onFavoriteChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showActions = true;

  itemUrl = '';
  iconUrlImg = 'assets/no_preview.png';
  nRates = 1;
  activeIndex: number = 0;
  reviewsTabIndex: number = 2;
  rating: number = 0;
  user!: User | undefined;
  curator!: User | undefined;
  aditionalInfo: ItemCustomField[] = [];
  userHasPermission = false;
  userPermissionPending = false;
  openRequestForm = false;
  images: any[] = [];

  reviews: ItemReview[] = [];
  reviewsAverageRate: ItemReviewAverageRate = {
    total: 0,
    average: 0,
    ratingCount: {}
  };
  hasSentReview: boolean = false;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private userService: UserService,
    private cdref: ChangeDetectorRef,
    private catalogService: CatalogService,
    private itemRequestService: ItemRequestService,
    private itemReviewService: ItemReviewService,
    private favoriteService: UserItemFavoriteService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const id = params['itemId'];
      if (id) {
        this.catalogService.getById(id).subscribe(async (item) => {
          this.item = item;
          this.startupComponent(item);
        });
      }
    });
  }

  ngOnChanges() {
    this.isOpenChange.emit(this.isOpen);
  }

  loadReviews(): Observable<ItemReview[]> {
    const reviewRequestParams = {
      itemId: this.item.id,
      currentPage: 1,
      itemVersion: this.item.itemVersion,
      numberOfRecords: 10,
      orderBy: ItemReviewOrderByEnum.MostRecent,
      filterBy: ItemReviewFilterByEnum.All
    } as ItemReviewGetList;

    return new Observable(sub => {
      this.itemReviewService.getItemReviews(reviewRequestParams).subscribe(result => {
        if (!result) return;

        this.reviews = result.reviews;
        this.hasSentReview = result.hasSentReview;
        this.reviewsAverageRate = result.averageRate;
        this.rating = this.itemReviewService.getAverageRate(result);
        this.item.itemReviewAverage = this.rating;

        this.onAverageRateRefresh.emit({
          total: this.reviewsAverageRate?.total ?? 0,
          average: this.rating || 0
        });

        sub.next(this.reviews);
        sub.complete();
      });
    });
  }

  refreshItem(): void {
    this.loadReviews().subscribe();
    this.catalogService.getById(this.item.id!).subscribe(async (item) => {
      this.item = { ...this.item, ...item };
      this.startupComponent(item);
    });
  }

  onTabChange(tab: TabViewChangeEvent): void {
    if (tab.index === this.reviewsTabIndex)
      this.loadReviews().subscribe();
  }

  onReviewSubmitted(): void {
    this.loadReviews().subscribe();
  }

  isItemView(): boolean {
    return this.route.snapshot.url[0].path === 'item-view';
  }

  async startupComponent(item: Item) {
    this.itemUrl =
      '/item-view?itemId=' +
      this.item.id +
      '&url=' +
      encodeURIComponent(`${item.itemAccessUrl}`);
    this.checkUserPermissions(item);
    await this.loadIconURLFromBlob();
    this.item.itemCustomFields?.forEach((field) => {
      if (field.visible) {
        this.aditionalInfo.push(field);
      }
    });
    this.setUserInfo();
    this.setCuratorInfo();

    this.images = await Promise.all(
      this.item.itemPreviews.map(async (preview) => {
        const url = await this.loadImageURL(preview);
        return {
          itemImageSrc: url,
        };
      })
    );
    this.cdref.detectChanges();
  }

  setUserInfo(): void {
    if (this.item.ownerId) {
      this.userService
        .getUserInformation(this.item.ownerId)
        .subscribe((user) => {
          this.user = user;

          if (this.user)
            this.userService
              .getPhotoByUserId(this.user.id)
              .subscribe((photo) => {
                if (this.user) this.user.photo = photo;
              });
        });
    }
  }
  setCuratorInfo(): void {
    if (this.item.curator) {
      this.userService
        .getUserInformation(this.item.curator)
        .subscribe((curator) => {
          this.curator = curator;

          if (this.curator)
            this.userService
              .getPhotoByUserId(this.curator.id)
              .subscribe((photo) => {
                if (this.curator) this.curator.photo = photo || '';
              });
        });
    }
  }
  async loadIconURLFromBlob() {
    if (!this.item.itemIcon) return;
    const icon = this.item.itemIcon;
    this.iconUrlImg = await this.loadImageURL(icon);

    this.cdref.detectChanges();
  }
  async loadImageURL(image: any): Promise<string> {
    const url = 'data:image/png;base64,' + image?.content;

    const res = await fetch(url);
    const blob = await res.blob();

    const file = new File([blob], `${image?.name}`, { type: 'image/png' });
    return URL.createObjectURL(file);
  }

  getTypeLabel(typeKey?: number): string {
    if (!typeKey) return '';

    return translateFromArrayAt(
      catalogTypesTranslationPaths,
      typeKey as number,
      this.translateService
    );
  }

  hasAccess(item: Item): boolean {
    const userMsalId = this.userService.getUserId();

    return item.itemPermissions.some(
      (permission) => permission.userId === userMsalId
    );
  }

  isOwner(item: Item): boolean {
    const userMsalId = this.userService.getUserId();

    return item.ownerId === userMsalId;
  }

  checkUserPermissions(item: Item) {
    const userMsalId = this.userService.getUserId();
    this.userHasPermission = this.hasAccess(item);

    if (!this.userHasPermission) {
      const params: any = {
        itemId: item.id,
        userId: userMsalId
      };

      this.itemRequestService.isRequestPending(params).subscribe((isRequestPending) => {
        this.userPermissionPending = isRequestPending;
      });
    }
  }

  toggleOpen(event: Event) {
    this.isOpen = !this.isOpen;
    this.activeIndex = 0;
    this.isOpenChange.emit(this.isOpen);
  }

  toggleRequestForm() {
    this.openRequestForm = !this.openRequestForm;
  }

  addFavorite(item: Item): void {
    if (this.item.favorited)
      this.favoriteService.remove(item.id as number).subscribe();
    else
      this.favoriteService.add(item.id as number).subscribe();

    this.item.favorited = !this.item.favorited;
    this.onFavoriteChange.emit(this.item.favorited);
    this.isOpenChange.emit(false);
  }
}
