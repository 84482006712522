import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { UsersGroup } from '../../../api/user';

@Component({
  selector: 'app-new-group',
  standalone: true,
  imports: [
    DialogModule,
    CommonModule,
    TranslateModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    TabViewModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './new-group.component.html',
  styleUrl: './new-group.component.scss',
})
export class NewGroupComponent {
  @Output() onEdit = new EventEmitter<UsersGroup>();

  @Input() usersGroup: UsersGroup = {} as UsersGroup;

  activeTabIndex: number = 0;

  form!: FormGroup;

  constructor(
    private translate: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(this.usersGroup.id),
      name: new FormControl(this.usersGroup.name, Validators.required),
      description: new FormControl(
        this.usersGroup.description,
        Validators.required
      ),
    });
  }

  onChange() {
    if (this.form.get('name')?.value.trim() === '') {
      this.form.get('name')?.setErrors({ emptyField: true });
      this.form.markAsDirty();
      return;
    }
    this.usersGroup = this.form.value;
    this.onEdit.emit(this.usersGroup);
  }

  translatedMessageService(
    severity: string = 'success',
    summary: string = 'Successful',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }
}
