import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  private get messageService(): MessageService {
    return this.injector.get(MessageService);
  }

  override handleError(error: any): void {
    let message: string;

    if (error instanceof HttpErrorResponse) {
      if (error?.error?.length) message = error.error[0]?.Message;
      else message = error.error?.Message ?? error?.statusText;
    } else message = error.message;

    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 3000,
    });

    super.handleError(error);
  }
}
