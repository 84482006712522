import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';
import { Item } from '../api/item';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getById(itemId: number): Observable<Item> {
    return this.httpClient.get<Item>(`${this.baseRoute}item/${itemId}`);
  }

  getItems(): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}item/`);
  }

  getItemsFiltered(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}item/get-by-filter`, {
      params: filters,
    });
  }

  getCatalogItems(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}item/my-catalog`, {
      params: filters,
    });
  }

  getStoreItems(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}store/`, {
      params: filters,
    });
  }

  add(item: Item): Observable<Item> {
    return this.httpClient.post<Item>(`${this.baseRoute}item/`, item);
  }

  update(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/`, item);
  }

  publishItem(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/publish`, item);
  }
  unpublishItem(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/unpublish`, item);
  }

  deleteItem(itemToDelete: number[]): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.baseRoute}item?id=${itemToDelete[0]}`
    );
  }
}
