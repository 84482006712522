import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Settings } from "../api/settings";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) { }

  get(): Observable<Settings> {
    return this.httpClient.get<Settings>(`${this.baseRoute}settings`);
  }

  getFeedback(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseRoute}settings/feedback`);
  }

  create(settings: Settings): Observable<void> {
    return this.httpClient.post<void>(`${this.baseRoute}settings`, settings);
  }

  update(settings: Settings): Observable<void> {
    return this.httpClient.put<void>(`${this.baseRoute}settings`, settings);
  }
}
