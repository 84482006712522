<p-toast></p-toast>

<h3 class="mb-5">{{ 'addons.header' | translate }}</h3>

<p-tabView [(activeIndex)]="activeIndex">
  <span class="search-field p-input-icon-left">
    <i class="pi pi-search"></i>
    <input
      pInputText
      type="text"
      (input)="onGlobalFilter($event)"
      [placeholder]="'addons.search_field' | translate"
      class="w-full sm:w-auto" />
  </span>

  <p-tabPanel [header]="'addons.tabs.available.header' | translate">
    <p-progressSpinner class="flex" *ngIf="loading" />

    <div class="flex flex-wrap gap-2" *ngIf="availableAddons.length">
      <addon-card *ngFor="let addon of availableAddons" [addon]="addon" (refresh)="loadAddons()"></addon-card>
    </div>
  </p-tabPanel>

  <p-tabPanel [header]="'addons.tabs.installed.header' | translate">
    <p-progressSpinner class="flex" *ngIf="loading" />

    <div class="flex flex-wrap gap-2" *ngIf="installedAddons.length">
      <addon-card
        *ngFor="let addon of installedAddons"
        [addon]="addon.addOn"
        [installedAddon]="addon"
        [isInstalled]="true"
        (refresh)="uninstall()"
        (settings)="loadSettings($event)">
      </addon-card>
    </div>
  </p-tabPanel>
</p-tabView>
