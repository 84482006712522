<form [formGroup]="requestAccessForm" (ngSubmit)="onSubmit()" class="search-form">
  <p-sidebar [(visible)]="isOpen" position="right" (onHide)="toggleOpen($event)" styleClass="p-3 w-30rem">
    <ng-template pTemplate="header">
      @if(isRequest){
        <h3>{{ 'request_access.form.headline' | translate }}</h3>
      }
      @if(!isRequest){
        <h3>{{ 'request_access_rejected.form.headline' | translate }}</h3>
      }
    </ng-template>
    <div class="p-fluid p-formgrid flex flex-column">
      <div class="field col">
        <label for="ownerInfo">{{ 'request_access.form.owner.label' | translate }} </label>
        <br>
        <p-chip label="{{requester}}" image="{{user ? user.photo : ''}}"
                alt="Avatar image" />
      </div>
      <div class="field col">
        <div class="mb-2">
          {{ 'request_access.form.item_name.label' | translate }}
        </div>
        <h5 class="mt-0 text-700"><b>{{item.name}}</b></h5>
      </div>
      <div class="field col">
        <label for="requestJustification">{{ 'request_access.form.justification.label' | translate }} </label>
        <textarea pInputTextarea id="requestJustification" formControlName="requestJustification" required rows="10"
                  class="form-control"></textarea>
        @if(
        requestAccessForm.get('requestJustification')?.invalid &&
        requestAccessForm.get('requestJustification')?.touched &&
        requestAccessForm.get('requestJustification')?.hasError('whitespace')
        ){
        <small class="ng-dirty ng-invalid">
          {{ 'request_access.form.justification.error' | translate }}
        </small>
        }
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="field flex gap-2 col justify-content-end">
        <button type="submit"
                pButton
                [label]="submitButtonLabel"
                [loading]='isWaiting'
                [disabled]="requestAccessForm.invalid || submitButtonDisabled" class="mr-2 w-6"></button>
      </div>
    </ng-template>
  </p-sidebar>
  <p-toast></p-toast>
</form>
