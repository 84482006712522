import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { Item } from '../../api/item';
import {
  catalogTypesTranslationPaths,
  translateFromArrayAt,
} from '../../api/term';
import { RequestAccessFormComponent } from '../../components/request-access-form/request-access-form.component';
import { GA4Service } from '../../service/ga4.service';
import { ItemRequestService } from '../../service/item-request.service';
import { UserService } from '../../service/user.service';
import { DetailsComponent } from '../details/details.component';
import { RatingModule } from 'primeng/rating';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ItemReviewService } from '../../service/item-review.service';
import { ItemReviewOrderByEnum, ItemReviewFilterByEnum, ItemReviewGetList } from '../../api/item-review';

type Rating = {
  total: number;
  average: number;
};

@Component({
  selector: 'app-item-card',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    TranslateModule,
    RequestAccessFormComponent,
    DetailsComponent,
    RatingModule,
    FormsModule,
    TruncatePipe,
    TooltipModule,
    EmojiComponent,
  ],
  templateUrl: './item-card.component.html',
  styleUrl: './item-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardComponent implements OnInit {
  @Input() item!: Item;
  @Input() isMyCatalogView!: boolean;

  @Output() onFavoriteChange = new EventEmitter<Item>();

  rating: Rating = {
    total: 0,
    average: 0,
  };

  itemUrl = '';
  urlImg = 'assets/no_preview.png';
  emojiset: any = 'google';

  userHasPermission = false;
  userPermissionPending = false;
  openRequestForm = false;
  sidebarVisible = false;

  constructor(
    private cdref: ChangeDetectorRef,
    private translateService: TranslateService,
    private userService: UserService,
    private itemRequestService: ItemRequestService,
    private gAService: GA4Service,
    private itemReviewService: ItemReviewService
  ) {}

  ngOnInit() {
    if (!this.isMyCatalogView) this.checkUserPermissions(this.item);

    this.loadItemRating();

    if (this.item.itemPreviews) {
      const thumbnail =
        this.item.itemPreviews.find((preview: any) => preview.isThumbnail) ||
        this.item.itemIcon;
      if (thumbnail) {
        this.urlImg = 'data:image/png;base64,' + thumbnail.content;
      }
    }

    this.itemUrl =
      '/item-view?itemId=' +
      this.item.id +
      '&url=' +
      encodeURIComponent(`${this.item.itemAccessUrl}`);
  }

  private loadItemRating(): void {
    const reviewRequestParams = {
      itemId: this.item.id,
      currentPage: 1,
      itemVersion: this.item.itemVersion,
      numberOfRecords: 1,
      orderBy: ItemReviewOrderByEnum.MostRecent,
      filterBy: ItemReviewFilterByEnum.All,
    };

    this.itemReviewService.getItemReviews(reviewRequestParams as ItemReviewGetList).subscribe(result => {
      if (result) {
        this.rating = {
          average: result.averageRate.average || 0,
          total: result.averageRate.total || 0
        };
        this.cdref.detectChanges();
      }
    });
  }

  getTypeLabel(typeKey?: number): string {
    if (!typeKey) return '';

    return translateFromArrayAt(
      catalogTypesTranslationPaths,
      typeKey as number,
      this.translateService
    );
  }

  checkUserPermissions(item: Item) {
    const userMsalId = this.userService.getUserId();
    this.userHasPermission = item.itemPermissions.some(
      (permission) => permission.principalId === userMsalId
    );

    if (!this.userHasPermission) {
      const params: any = {
        itemId: item.id,
        userId: userMsalId,
      };

      this.itemRequestService
        .isRequestPending(params)
        .subscribe((isRequestPending) => {
          this.userPermissionPending = isRequestPending;
        });
    }
  }

  toggleRequestForm(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.openRequestForm = !this.openRequestForm;
  }

  @Output() onToggleSidebar = new EventEmitter<boolean>();

  closeSidebar(value: boolean): void {
    this.onToggleSidebar.emit(value);
  }

  toggleSidebar(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.sidebarVisible = !this.sidebarVisible;
    this.onToggleSidebar.emit(this.sidebarVisible);
  }

  cardClickHandler(event: any, isMyCatalogView: boolean): void {
    event.preventDefault();
    this.gAService.sendGTMEvent('analytics_open', {
      'Item Name': this.item.name,
      'Item Source': this.item.itemSourceId,
    });
    if (isMyCatalogView) {
      document.location = this.itemUrl;
    } else {
      this.toggleSidebar(event);
    }
  }
  openButton() {
    this.gAService.sendGTMEvent('analytics_open', {
      'Item Name': this.item.name,
      'Item Source': this.item.itemSourceId,
    });
  }

  setAverageRate(rating: Rating): void {
    this.rating = rating;
  }

  setFavoriteChange(isFavorited: boolean): void {
    this.item.favorited = isFavorited;
    this.onFavoriteChange.emit(this.item);
  }
}
