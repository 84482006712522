<p-toast></p-toast>

<div class="filter-button">
  <app-filter-button
    [items]="items"
    [isMyCatalogFilter]="true"
    (filteredItems)="updateFilteredItems($event)"
    ></app-filter-button>
</div>

<div class="content favorites" *ngIf="hasFavorites" [ngClass]="{ 'sidebar-open': isSidebarOpen}">
  <div class="header">
    <div class="col-6">
      <h4 class="mb-6">{{ 'my_catalog.grid.header_favorites' | translate }}</h4>
    </div>
  </div>
  <div class="grid w-full">
    <div class="col-12">
      <div class="card">
        <div class="col-12 items-counter">
          <span>
            {{
            favorites.length == 1
            ? favorites.length + ('my_catalog.filter.chip.singular' | translate)
            : favorites.length + ('my_catalog.filter.chip.plural' | translate)
            }}
          </span>

        </div>
        @if(!isLoading || favorites.length > 0){
        <p-carousel
          #carousel
          [page]="page"
          [value]="favorites"
          [numVisible]="5"
          [numScroll]="5"
          [responsiveOptions]="responsiveOptions"
          layout="grid">
          <ng-template pTemplate="item" let-item>
            <app-item-card
              [item]="item"
              [isMyCatalogView]="true"
              (onToggleSidebar)="setIsSidebarOpen($event)"
              (onFavoriteChange)="onFavoriteChange($event)">
            </app-item-card>
          </ng-template>
        </p-carousel>
        } @else {
        <div class="flex justify-content-between gap-3">
          @for(index of [1,2,3,4]; track index){
          <div class=" border-round border-1 surface-border p-4 surface-card flex-1">
            <p-skeleton width=" 100%" height="150px" />
            <div class="flex my-3">
              <div>
                <p-skeleton width="10rem" styleClass="mb-2" />
                <p-skeleton width="5rem" styleClass="mb-2" />
              </div>
            </div>
            <div class="flex justify-content-end mt-3 gap-2">
              <p-skeleton width="4rem" height="2rem" />
              <p-skeleton width="4rem" height="2rem" />
            </div>
          </div>
          }
        </div>
        }
      </div>
    </div>
  </div>
</div>

<div class="content available-items">
  <div class="header">
    <div class="col-6">
      <h4 class="mb-6">{{ 'my_catalog.grid.header' | translate }}</h4>
    </div>
  </div>
  <div class="grid w-full">
    <div class="col-12">
      <div class="card">
        <div class="col-12 items-counter">
          <span>
            {{
            itemsCounter == 1
            ? itemsCounter + ('my_catalog.filter.chip.singular' | translate)
            : itemsCounter + ('my_catalog.filter.chip.plural' | translate)
            }}
          </span>

        </div>
        @if(!isLoading || items.length > 0){
        <p-dataView
          #dv
          [value]="items"
          [currentPageReportTemplate]="translatedTemplate.pageReport"
          layout="grid">
          <ng-template pTemplate="grid" let-items>
            <div class="grid grid-nogutter">
              @for(item of items; track item){
              <div class="p-2" *ngIf="!item.favorited">
                <app-item-card
                  [item]="item"
                  [isMyCatalogView]="true"
                  (onFavoriteChange)="onFavoriteChange($event)">
                </app-item-card>
              </div>
              }
            </div>
          </ng-template>
        </p-dataView>
        } @else {
        <div class="flex justify-content-between gap-3">
          @for(index of [1,2,3,4]; track index){
          <div class=" border-round border-1 surface-border p-4 surface-card flex-1">
            <p-skeleton width=" 100%" height="150px" />
            <div class="flex my-3">
              <div>
                <p-skeleton width="10rem" styleClass="mb-2" />
                <p-skeleton width="5rem" styleClass="mb-2" />
              </div>
            </div>
            <div class="flex justify-content-end mt-3 gap-2">
              <p-skeleton width="4rem" height="2rem" />
              <p-skeleton width="4rem" height="2rem" />
            </div>
          </div>
          }
        </div>
        }
      </div>
    </div>
  </div>
</div>
