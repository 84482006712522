import { CommonModule, DatePipe } from "@angular/common";
import { Component, ErrorHandler, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { TableModule, TablePageEvent } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ItemRequestService } from "../../service/item-request.service";
import { ItemAccessRequestStatusEnum, ItemRequest, ItemRequestTypeEnum } from "../../api/item-request";
import { UserService } from "../../service/user.service";
import { TagModule } from "primeng/tag";
import { MenuModule } from "primeng/menu";
import { GlobalErrorHandler } from "../../utils/components/error-handler/error-handler.component";
import { LayoutService } from "../../service/app.layout.service";
import { RequestAccessFormComponent } from "../../components/request-access-form/request-access-form.component";
import { Item } from "../../api/item";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

type RequestStatus = {
  id: number;
  name: string;
  severity: "success" | "info" | "warning" | "danger" | "secondary" | "contrast";
}

@Component({
  selector: 'item-requests',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  imports: [
    FormsModule,
    TableModule,
    ButtonModule,
    ToastModule,
    CommonModule,
    DatePipe,
    TranslateModule,
    TagModule,
    MenuModule,
    RequestAccessFormComponent
  ],
  templateUrl: './item-requests.component.html',
  styleUrl: './item-requests.component.scss',
})
export class ItemRequestsComponent implements OnInit {

  @Input() item!: Item;
  @Input() isOpen: boolean = false;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  paginatorIndex: number = 0;
  paginatorRows: number = 10;

  isFilterRowVisible: boolean = false;
  isApprovalRequest: boolean = false;

  itemRequests: ItemRequest[] = [];
  itemRequestsUnfiltered: ItemRequest[] = [];

  selectedReports: ItemRequest[] = [];

  selectedReport: ItemRequest = {} as ItemRequest;

  requestTypeEnum = ItemRequestTypeEnum;
  itemAccessRequestStatusEnum = ItemAccessRequestStatusEnum;

  tableActions: any[] = [];

  activeRequestTypeFilter: number = 0;

  openRequestForm = false;

  translatedTemplate: any = {
    page_report: ''
  };

  constructor(
    private itemRequestService: ItemRequestService,
    private messageService: MessageService,
    private userService: UserService,
    private translate: TranslateService,
    private layoutService: LayoutService,
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler
  ) { }

  ngOnInit() {
    this.layoutService.setAdminStuff(false);
    this.initTranslations();

    this.translate.onLangChange.subscribe(() => {
      this.initTranslations();
    });

    this.getAllRequests();
  }

  initTranslations(): void {
    this.tableActions = [];

    this.translate
      .get('glossary.table.page_report')
      .subscribe((res: string) => {
        this.translatedTemplate.page_report = res;
      });

    this.tableActions.push({
      statusId: 2,
      label: this.translate.instant('item_requests.table.fields.actions.cancel'),
      icon: 'pi pi-fw pi-undo',
      command: () => this.cancelRequest()
    });

    this.tableActions.push({
      statusId: 3,
      label: this.translate.instant('item_requests.table.fields.actions.approve'),
      icon: 'pi pi-fw pi-check',
      command: () => this.approveRequest()
    });

    this.tableActions.push({
      statusId: 4,
      label: this.translate.instant('item_requests.table.fields.actions.reject'),
      icon: 'pi pi-fw pi-ban',
      command: () => this.rejectRequest()
    });
  }

  isActionVisible(itemRequest: ItemRequest, action: any): boolean {
    const requestType = itemRequest.requestType;

    if (itemRequest.statusId !== ItemAccessRequestStatusEnum.Pending)
      return false;

    if (
      requestType as ItemRequestTypeEnum === ItemRequestTypeEnum.Sent &&
      action.statusId === ItemAccessRequestStatusEnum.Cancelled
    ) return true;

    if (requestType as ItemRequestTypeEnum === ItemRequestTypeEnum.Received &&
      (
        action.statusId === ItemAccessRequestStatusEnum.Rejected ||
        action.statusId === ItemAccessRequestStatusEnum.Approved
      )
    ) return true;

    return false;
  }

  getRequestTypeCount(requestType: ItemRequestTypeEnum): string {
    if (requestType === ItemRequestTypeEnum.Pending)
      return this.itemRequestsUnfiltered.filter(request => request.statusId === ItemAccessRequestStatusEnum.Pending).length.toString();

    return (this.itemRequestsUnfiltered.filter(request => request.requestType === requestType).length || 0).toString();
  }

  filterByType(requestType: ItemRequestTypeEnum): void {
    this.activeRequestTypeFilter = requestType as number;
    
    const filteredItems = requestType === ItemRequestTypeEnum.All 
      ? this.itemRequestsUnfiltered 
      : this.itemRequestsUnfiltered.filter(request => 
          requestType === ItemRequestTypeEnum.Pending
            ? request.statusId === ItemAccessRequestStatusEnum.Pending
            : request.requestType === requestType
        );

    const maxPages = Math.ceil(filteredItems.length / this.paginatorRows);
    const currentPage = Math.floor(this.paginatorIndex / this.paginatorRows);
    
    if (currentPage >= maxPages) {
      this.paginatorIndex = Math.max(0, (maxPages - 1) * this.paginatorRows);
    }

    this.itemRequests = filteredItems;
  }

  getAllRequests(): void {
    this.itemRequestService.getMyItemRequests()
      .subscribe(requests => {
        this.itemRequests = requests;

        if (!this.itemRequestsUnfiltered.length)
          this.itemRequestsUnfiltered = this.itemRequests;

        this.itemRequests.forEach(request => this.setRequestType(request));

        this.initTranslations();
      });
  }

  setSelectedItemRow(item: ItemRequest) {
    this.item = item.item;
    this.selectedReport = item;
    this.tableActions = this.tableActions.filter(action => action.statusId !== this.selectedReport.statusId);
  }

  toggleFilter(): void {
    this.isFilterRowVisible = !this.isFilterRowVisible;
  }

  pageChange(event: TablePageEvent): void {
    this.paginatorIndex = event.first;
    this.paginatorRows = event.rows;
  }

  setRequestType(request: ItemRequest): void {
    const userId = this.userService.getUserId();

    if (request.item.ownerId === userId)
      request.requestType = this.requestTypeEnum.Received;
    else
      request.requestType = this.requestTypeEnum.Sent;
  }

  getRequestType(request: ItemRequest): string {
    return {
      [this.requestTypeEnum.Received]: this.translate.instant('item_requests.table.fields.types.received'),
      [this.requestTypeEnum.Sent]: this.translate.instant('item_requests.table.fields.types.sent')
    }[request.requestType as number];
  }

  getRequestStatus(request: ItemRequest): RequestStatus {
    const statuses: RequestStatus[] = [
      {
        id: 1,
        name: this.translate.instant('item_requests.table.fields.status.pending'),
        severity: 'warning'
      },
      {
        id: 2,
        name: this.translate.instant('item_requests.table.fields.status.canceled'),
        severity: 'contrast'
      },
      {
        id: 3,
        name: this.translate.instant('item_requests.table.fields.status.approved'),
        severity: 'success'
      },
      {
        id: 4,
        name: this.translate.instant('item_requests.table.fields.status.rejected'),
        severity: 'danger'
      }
    ];

    return statuses.find(status => status.id === request.statusId) ?? statuses[0];
  }

  cancelRequest(): void {
    this.itemRequestService.cancelMyItemRequest({
      requestId: this.selectedReport.id
    }).subscribe({
      next: () => {
        this.itemRequestsUnfiltered = [];
        this.getAllRequests();

        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('item_requests.tooltips.actions.cancel'),
          detail: '',
          life: 3000,
        });
      },
      error: (error) => this.errorHandler.handleError(error)
    })
  }

  approveRequest(): void {
    this.openRequestForm = true;
    this.isApprovalRequest = true;
    this.itemRequestsUnfiltered = [];
  }

  rejectRequest(): void {
    this.isApprovalRequest = false;
    this.openRequestForm = true;
    this.itemRequestsUnfiltered = [];
  }

  onRequestSent(event: Item) : void {
    this.getAllRequests()
  }

  printRejectionResponse(request: ItemRequest): string {
    let rejectionResponse = '';

    if (request.response) {
      rejectionResponse = this.translate.instant('item_requests.table.fields.status.rejected') + ': ' + request.response;
    }

    return rejectionResponse;
  }

}
