<div>
  <p-panel class="scheduler-panel">
    <ng-template pTemplate="header">
      <span class="font-bold">{{ 'addons.widgets.scheduler.title' | translate }}</span>
    </ng-template>

    <ng-template pTemplate="icons">
      <p-tag icon="pi pi-clock" class="timer-tag" [value]="value.toString() + 'm'" />
    </ng-template>

    <div class="pt-3 pb-5 pl-2">
      <p-slider
        [(ngModel)]="value"
        (onChange)="onChange($event)"
        [step]="5"
        max="60"
        min="5"
        styleClass="w-14rem"/>
    </div>

    <p class="max-w-20rem">{{ 'addons.widgets.scheduler.description' | translate }}</p>
  </p-panel>
</div>
