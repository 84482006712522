<div class="flex flex-column w-full">
  <ng-container *ngIf="showFeedbackForm">
  <h1 class="flex align-items-center justify-content-center">{{ 'general_feedback.text' | translate }}</h1>

  <p-rating
    [(ngModel)]="ratingValue"
    (ngModelChange)="checkRatingValue()"
    stars="5"
    cancel="false"
    required
    id="rating-group"
    class="flex align-items-center justify-content-center">
  </p-rating>

  <textarea
    class="mt-3 flex align-items-center justify-content-center"
    pInputTextarea
    rows="5"
    [(ngModel)]="feedbackText"
    placeholder="{{ 'general_feedback.placeholder' | translate }}"></textarea>

    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>

  <p-button class="mt-6 flex align-items-end justify-content-end" (click)="submitFeedback()">{{ 'general_feedback.send-button' | translate }}</p-button>
  </ng-container>
  <ng-container *ngIf="!showFeedbackForm">
    <span class="success-message">{{ 'general_feedback.success-message' | translate }}</span>
    <p-button class="mt-6 flex align-items-end justify-content-end" (click)="closeFeedback()">{{ 'form.button.close' | translate }}</p-button>  
  </ng-container>

</div>
