import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ErrorHandler,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { GlobalErrorHandler } from '../../../utils/components/error-handler/error-handler.component';
import { InputTextModule } from 'primeng/inputtext';
import {
  AddOn,
  AddOnConnectionLog,
  AddOnSource,
  InstalledAddOn,
  translateMessage,
} from '../../../api/addon';
import { DialogModule } from 'primeng/dialog';
import { StepperModule } from 'primeng/stepper';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule, NgForm } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { AddonService } from '../../../service/addon.service';
import { PasswordModule } from 'primeng/password';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { AddonSchedulerComponent } from '../addon-widgets/power-bi/addon-scheduler.component';
import { AddonScheduleService } from '../../../service/addon-scheduler.service';
import { AddOnSchedulerSettings } from '../../../api/addon-scheduler';
import { GA4Service } from '../../../service/ga4.service';

type Schedule = {
  timer: string;
  description: string;
};

@Component({
  selector: 'addon-setup-dialog',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    StepperModule,
    CommonModule,
    TranslateModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    InputSwitchModule,
    InputTextModule,
    FloatLabelModule,
    PasswordModule,
    ToastModule,
    MessagesModule,
    AddonSchedulerComponent,
  ],
  templateUrl: './addon-setup.component.html',
  styleUrl: './addon-setup.component.scss',
})
export class AddonSetupComponent implements OnInit {
  @Input() addon!: AddOn;
  @Input() showSetupDialog = false;

  @Output() closed = new EventEmitter<InstalledAddOn>();

  installedAddOn!: InstalledAddOn;

  selectedSchedule!: Schedule;
  AddOnSource = AddOnSource;
  addOnSchedulerSettings!: AddOnSchedulerSettings;

  emailChecked = false;
  loading = false;
  sourceId!: AddOnSource;

  authenticationFields!: any;
  connectionLog!: AddOnConnectionLog;

  translation = {
    headers: [] as string[],
  };

  messages: any[] = [];

  form!: NgForm;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private translationService: TranslateService,
    private messageService: MessageService,
    private addonService: AddonService,
    private addonScheduleService: AddonScheduleService,
    private cdref: ChangeDetectorRef,
    private gAService: GA4Service
  ) {}

  ngOnInit() {
    this.initTranslations();

    this.translationService.onLangChange.subscribe(() =>
      this.initTranslations()
    );
  }

  initTranslations(): void {
    this.translation.headers = this.translationService.instant(
      'addons.setup.stepper.headers'
    );

    this.cdref.detectChanges();
  }

  onShow(): void {
    const fields = JSON.parse(this.addon.authenticationFields);

    this.authenticationFields = fields.authenticationFields;
    this.sourceId = Number(fields.sourceId) as AddOnSource;
  }

  onHide(): void {
    this.closed.emit(this.installedAddOn);
  }

  parseMessage(message: string, iconless: boolean = false) {
    return translateMessage(this.translationService, message, iconless);
  }

  connect(nextCallback: any): void {
    this.loading = true;

    const payload = {
      authenticationFields: this.authenticationFields,
      sourceId: JSON.parse(this.addon.authenticationFields).sourceId,
    };

    this.addonService.connect(JSON.stringify(payload)).subscribe({
      next: (result) => {
        this.connectionLog = result;
        this.loading = false;
        nextCallback.emit();
      },
      error: () => {
        this.messages.push({
          severity: 'error',
          detail: this.translationService.instant(
            'addons.setup.stepper.tooltip.error'
          ),
        });

        setTimeout(() => {
          this.messages = [];
        }, 5000);

        this.loading = false;
      },
    });
  }

  setSchedulerFrequency(frequency: number): void {
    this.addOnSchedulerSettings = {
      id: 0,
      installedAddonId: this.addon.id,
      frequencyInMinutes: frequency,
    };
  }

  setupScheduler(
    installedAddonId: number,
    schedule: AddOnSchedulerSettings
  ): void {
    schedule.installedAddonId = installedAddonId;
    this.loading = true;

    this.addonScheduleService.setup(schedule).subscribe({
      next: () => {
        this.loading = false;
      },
      error: (e) => this.errorHandler.handleError(e),
    });
  }

  finish(): void {
    this.loading = true;

    this.gAService.sendGTMEvent('catalog_add', {
      'Item Source': this.sourceId,
    });

    const installCommand = {
      credentials: JSON.stringify(this.authenticationFields),
      reports: this.connectionLog.reports,
      logs: this.connectionLog,
    };

    this.addonService.install(installCommand, this.addon.id).subscribe({
      next: (installedAddon) => {
        this.installedAddOn = installedAddon;

        if (this.sourceId === AddOnSource.PowerBI)
          this.setupScheduler(installedAddon.id, this.addOnSchedulerSettings);

        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: this.translationService.instant(
            'addons.setup.stepper.tooltip.success'
          ),
          life: 3000,
        });

        this.showSetupDialog = false;
        this.loading = false;
      },
      error: (e) => this.errorHandler.handleError(e),
    });
  }
}
