import { CommonModule } from '@angular/common';
import { Component, ErrorHandler, Inject, ViewChild } from '@angular/core';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { mapFilters } from '../../api/item-paginator';
import { Seal } from '../../api/seals';
import { LayoutService } from '../../service/app.layout.service';
import { SealService } from '../../service/seal.service';
import { GlobalErrorHandler } from '../../utils/components/error-handler/error-handler.component';
import { NewSealComponent } from './new-seal/new-seal.component';

@Component({
  selector: 'app-seals',
  standalone: true,
  imports: [
    EmojiComponent,
    TableModule,
    TranslateModule,
    ToastModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    CommonModule,
    NewSealComponent,
    DialogModule,
  ],
  providers: [SealService, MessageService],
  templateUrl: './seals.component.html',
  styleUrl: './seals.component.scss',
})
export class SealsComponent {
  seals: Seal[] = [];
  seal: Seal = {} as Seal;

  selectedRows: Seal[] = [];

  emojiset: any = 'google';

  @ViewChild('dt') table!: Table;
  totalRecords: number = 0;
  paginatorIndex: number = 0;
  rows = 10;
  page: number = 0;
  size: number = 0;
  sortField: string | string[] = 'Id';
  sortOrder: number = 1;
  filters: any = {};
  loading: boolean = false;

  cols = [
    { field: 'id', header: 'Id' },
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
  ];

  translatedTemplate: any = {
    page_report: '',
    table_search_field: '',
    form_tab_validation_tooltip: '',
    dialog_change_status: '',
    dialog_delete_item: '',
    dialog_unpublish_item: '',
    dialog_publish_item: '',
  };

  isFilterRowVisible: boolean = false;

  isNewSealVisible: boolean = false;

  deleteSealDialog: boolean = false;

  constructor(
    private sealsService: SealService,
    private translate: TranslateService,
    public layoutService: LayoutService,
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.layoutService.setAdminStuff(true);
    this.initTranslations();
    this.translate.onLangChange.subscribe(() => this.initTranslations());
  }

  getAllSeals(event: TableLazyLoadEvent) {
    this.loading = true;

    this.page = event.first && event.rows ? event.first / event.rows : 0;
    this.size = event.rows ?? this.rows;

    this.sortField = event.sortField ?? 'Id';
    this.sortOrder = event.sortOrder ?? 1;

    this.filters = event.filters;
    let filters = this.filters ? mapFilters(this.filters) : {};
    this.sealsService
      .getSeals({
        currentPage: this.page,
        numberOfRecords: this.size,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        filters: filters,
      })
      .subscribe((response) => {
        this.seals = response.items;
        this.totalRecords = response.total;
        this.loading = false;
      });
  }

  initTranslations() {
    this.translate.get('seals.table.page_report').subscribe((res: string) => {
      this.translatedTemplate.page_report = res;
    });

    this.translate
      .get('seals.form.tab.general.validation_tooltip')
      .subscribe((res: string) => {
        this.translatedTemplate.form_tab_validation_tooltip = res;
      });
  }

  openNew() {
    this.seal = {} as Seal;
    this.isNewSealVisible = true;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  openDeleteDialog(seal: Seal) {
    this.seal = seal;
    this.translate
      .get('seals.dialogs.delete_item.message', {
        name: this.seal.name,
      })
      .subscribe((translation: any) => {
        this.translatedTemplate.dialog_delete_item = translation;
        this.deleteSealDialog = true;
      });
  }

  confirmDeleteSeal() {
    this.deleteSealDialog = false;
    this.sealsService.deleteSeal(this.seal.id).subscribe({
      next: () => {
        this.seal = {} as Seal;

        this.getAllSeals({
          first: this.paginatorIndex,
          rows: this.rows,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          filters: this.filters,
        });

        this.translatedMessageService(
          'success',
          'Successful',
          'tooltip.content.item_deleted'
        );
      },
      error: (error) => this.errorHandler.handleError(error),
    });
  }

  openEditDialog(seal: Seal) {
    this.seal = { ...seal };
    this.isNewSealVisible = true;
  }

  didSave(seal: Seal) {
    this.totalRecords++;
    this.paginatorIndex = Math.floor(this.totalRecords / this.rows) * this.rows;
    this.isNewSealVisible = false;
    this.getAllSeals({
      first: this.paginatorIndex,
      rows: this.rows,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filters: this.filters,
    });
  }

  didEdit(seal: Seal) {
    this.isNewSealVisible = false;
    this.getAllSeals({
      first: this.paginatorIndex,
      rows: this.rows,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filters: this.filters,
    });
  }

  toggleFilter() {
    this.isFilterRowVisible = !this.isFilterRowVisible;
  }

  translatedMessageService(
    severity: string = 'success',
    summary: string = 'Successful',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }
}
