import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';
import { Observable } from 'rxjs';

type EmbeddedToken = {
  embeddedToken: string;
};

@Injectable({
  providedIn: 'root',
})
export class PowerBiService {
  baseRoute = environment.genuzApiEndpoint;
  configs: any;

  constructor(private httpClient: HttpClient) {}

  getEmbeddedToken(
    sourceID: number,
    reportId: string,
    workspaceId: string,
    itemId: number
  ): Observable<EmbeddedToken> {
    return this.httpClient.get<EmbeddedToken>(`${this.baseRoute}addons/token`, {
      params: { sourceId: sourceID, reportId, workspaceId, itemId },
    });
  }
}
