import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { GenericType, catalogTypesTranslationPaths, termsTypesTranslationPaths, translateFromArray } from '../../../api/term';
import { CatalogService } from '../../../service/catalog.service';
import { GlossaryService } from '../../../service/glossary.service';
import { UserDropdownComponent } from '../../../components/user-dropdown/user-dropdown.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { User } from '../../../api/user';
import { SearchService } from '../../../service/search.service';
import { ActivatedRoute } from '@angular/router';
import { UserItemFavoriteService } from '../../../service/user-item-favorites.service';

@Component({
  selector: 'app-filter-button',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FieldsetModule,
    DropdownModule,
    FormsModule,
    SidebarModule,
    SkeletonModule,
    UserDropdownComponent,
    TranslateModule
  ],
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss',
})
export class FilterButtonComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() isMyCatalogFilter!: boolean;
  @Input() isSearchFilter!: boolean;
  @Output() filteredItems = new EventEmitter<any[]>();

  isLoading = true;
  isSidebarVisible = false;

  getTypeOptions = (): Observable<GenericType[]> =>
    translateFromArray(catalogTypesTranslationPaths, this.translateService);

  getTermTypeOptions = (): Observable<GenericType[]> =>
    translateFromArray(termsTypesTranslationPaths, this.translateService);

  termOptions: SelectItem[] = [];

  selectedType!: string;
  selectedOwner!: User;
  selectedTermType!: string;
  selectedTermName!: string;

  constructor(
    private catalogService: CatalogService,
    private glossaryService: GlossaryService,
    private searchService: SearchService,
    private favoritesService: UserItemFavoriteService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadItems(false);
  }

  isOutlined() {
    return !(this.selectedType || this.selectedOwner || this.selectedTermName);
  }

  sidebarVisibleToggle() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  loadTerms(): void {
    const filters = {
      type: this.selectedTermType,
    };

    this.glossaryService.getTermsFiltered(filters).subscribe((terms) => {
      this.termOptions = terms.map((term) => ({
        label: term.name,
        value: term.id,
      }));
    });
  }

  loadItems(toggleSidebar: boolean = true) {
    let filters: any = {
      StatusId: 2,
    };

    if (this.selectedType) {
      filters.ItemTypeId = this.selectedType;
    }

    if (this.selectedOwner) {
      filters.OwnerName = this.selectedOwner.name;
    }

    if (this.selectedTermName) {
      filters.TermId = this.selectedTermName;
    }

    if (this.isMyCatalogFilter) {
      this.catalogService.getCatalogItems(filters).subscribe((filteredItems) => {

        this.favoritesService.getAll().subscribe(favorites => {
          filteredItems.forEach(item =>
            item.favorited = favorites.some(f => f.id === item.id));

          this.filteredItems.emit(filteredItems);
        });

        this.isLoading = false;
        if (toggleSidebar) this.sidebarVisibleToggle();
      });
    }
    else if (this.isSearchFilter) {
      this.activatedRoute.queryParams.subscribe(params => {
        filters = {
          ...filters,
          ...params
        }

        this.searchService.search(filters).subscribe((filteredItems) => {
          this.filteredItems.emit(filteredItems.items);
          this.isLoading = false;
          if (toggleSidebar) this.sidebarVisibleToggle();
        });
      });
    }
    else {
      this.catalogService.getStoreItems(filters).subscribe((filteredItems) => {
        this.filteredItems.emit(filteredItems);
        this.isLoading = false;
        if (toggleSidebar) this.sidebarVisibleToggle();
      });
    }
  }

  setOwner(user: User) {
    this.selectedOwner = user;
  }
}
