import { Component, ErrorHandler, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { GlobalErrorHandler } from "../../../../../utils/components/error-handler/error-handler.component";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FloatLabelModule } from "primeng/floatlabel";
import { InputTextModule } from "primeng/inputtext";
import { Capacity, CapacityStatus, CapacityType } from "../../../../../api/capacity";
import { TagModule } from "primeng/tag";
import { SkeletonModule } from "primeng/skeleton";
import { CapacityService } from "../../../../../service/capacity.service";
import { Observable } from "rxjs";
import { AddonScheduleComponent } from "./schedule/capacity-schedule.component";
import { CapacitySchedule } from "../../../../../api/capacity";
import { DropdownModule } from "primeng/dropdown";
import { HttpStatusCode } from "@angular/common/http";
import { ToastModule } from "primeng/toast";
import { CustomInputValidators } from "../../../../../utils/validator.utils";

type Severity = "success" | "info" | "warning" | "danger" | "secondary" | "contrast";

type CapacityTypeDropdown = {
  name: string,
  id: CapacityType
};

@Component({
  selector: 'addon-capacity',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    FloatLabelModule,
    InputTextModule,
    TagModule,
    SkeletonModule,
    AddonScheduleComponent,
    DropdownModule,
    ToastModule
  ],
  templateUrl: './addon-capacity.component.html',
  styleUrl: './addon-capacity.component.scss',
})
export class AddonCapacityComponent implements OnInit {

  @Input() refresh!: Observable<void>;
  @Input() capacity: Capacity | undefined;
  @Input() schedules: CapacitySchedule[] = [];
  @Input() sourceId: number = 0;

  @Output() capacityChanged = new EventEmitter<Capacity>();
  @Output() isCapacityValid = new EventEmitter<boolean>();

  form!: FormGroup;

  loading: boolean = false;
  valid: boolean = false;

  capacityTypes: CapacityTypeDropdown[] = [
    { name: 'Power BI', id: CapacityType.PowerBI },
    { name: 'MS Fabric', id: CapacityType.MSFabric }
  ];

  CapacityType = CapacityType;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private messageService: MessageService,
    private capacityService: CapacityService,
    private translation: TranslateService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      capacityId: new FormControl<string>('', [CustomInputValidators.noWhitespaceValidator()]),
      capacityName: new FormControl<string>('', [CustomInputValidators.noWhitespaceValidator()]),
      capacityType: new FormControl<CapacityTypeDropdown | null>(null),
      subscriptionId: new FormControl<string>('', [CustomInputValidators.noWhitespaceValidator()]),
      resourceGroupName: new FormControl<string>('', [CustomInputValidators.noWhitespaceValidator()]),
    });

    if (this.capacity) {
      this.form.patchValue(this.capacity as Capacity);

      if (this.capacity.typeId === CapacityType.MSFabric)
        this.form.get('capacityName')?.setValue(this.capacity.capacityId);

      this.setCapacityType(this.capacity?.typeId);
    }

    this.form.valueChanges.subscribe(() => {
      this.capacity = {
        ...this.capacity,
        ...this.form.value
      };

      this.capacityChanged.emit(this.capacity);
      this.isCapacityValid.emit(this.form.valid);
    });

    this.refresh.subscribe(() => this.loadCapacity());
  }

  refreshCapacityInfo(event: any): void {
    let capacityType = this.form.get('capacityType')?.value?.id;
    let isValid = false;

    if (capacityType === CapacityType.MSFabric) {
      let capacityName = this.form.get('capacityName')?.value?.trim();
      let subscriptionId = this.form.get('subscriptionId')?.value?.trim();
      let resourceGroupName = this.form.get('resourceGroupName')?.value?.trim();

      isValid = !!(capacityName && subscriptionId && resourceGroupName);

    } else if (capacityType === CapacityType.PowerBI) {
      let capacityId = this.form.get('capacityId')?.value?.trim();
      isValid = !!capacityId;
    }

    if (isValid) {
      this.loadCapacity();
    }
  }

  setCapacityType(typeId: number): void {
    this.form.get('capacityType')?.setValue(
      this.capacityTypes.find(type => type.id === typeId)
    );
  }

  relayCapacity(): void {
    this.form.patchValue(this.capacity as Capacity);

    this.capacityChanged.emit(this.capacity);
  }

  get capacityType(): CapacityType {
    return this.form.get('capacityType')?.value?.id as CapacityType;
  }

  resetCapacityType(): void {
    this.form.get('capacityId')?.setValue('');
    this.form.get('capacityName')?.setValue('');
    this.form.get('subscriptionId')?.setValue('');
    this.form.get('resourceGroupName')?.setValue('');
    this.form.markAsPristine();
  }

  loadCapacity(): void {
    let capacityId = '';
    let subscriptionId = '';
    let resourceGroupName = '';

    if (this.capacityType === CapacityType.MSFabric) {
      capacityId = this.form.get('capacityName')?.value?.trim(); //Usa o valor do CapacityName para fazer a requisição
      subscriptionId = this.form.get('subscriptionId')?.value?.trim();
      resourceGroupName = this.form.get('resourceGroupName')?.value?.trim();

      if (!capacityId || !subscriptionId || !resourceGroupName)
        return;

      this.loading = true;
    } else if (this.capacityType === CapacityType.PowerBI) {
      capacityId = this.form.get('capacityId')?.value?.trim();

      if (!capacityId)
        return;

      this.loading = true;
    }

    this.capacityService.getCapacity(
      capacityId,
      this.sourceId,
      this.form.get('capacityType')?.value?.id,
      this.form.get('subscriptionId')?.value,
      this.form.get('resourceGroupName')?.value
    ).subscribe({
      next: (capacity) => {
        this.loading = false;
        this.valid = true;

        const { subscriptionId, resourceGroupName, typeId } = this.capacity as Capacity;

        this.capacity = {
          ...capacity,
          subscriptionId: subscriptionId,
          resourceGroupName: resourceGroupName
        };

        if (!capacity)
          this.form.get('capacityId')?.setErrors({ 'capacity-not-found': true });

        this.relayCapacity();
      },
      error: (response) => {
        if (this.capacity)
          this.capacity.statusId = CapacityStatus.Fail;

        this.valid = false;
      }
    });
  }

  getCapacityType(typeId: number): string {
    if (!typeId) return '';

    return this.translation.instant({
      [CapacityType.PowerBI]: 'addons.settings.tabs.capacity.fields.capacityType.powerbi',
      [CapacityType.MSFabric]: 'addons.settings.tabs.capacity.fields.capacityType.msfabric'
    }[typeId] || '');
  }

  getStatus(statusId: number): string {
    return this.translation.instant({
      [CapacityStatus.Active]: 'addons.settings.tabs.capacity.fields.status.active',
      [CapacityStatus.Suspended]: 'addons.settings.tabs.capacity.fields.status.suspended',
      [CapacityStatus.Fail]: 'addons.settings.tabs.capacity.fields.status.fail',
      [CapacityStatus.Paused]: 'addons.settings.tabs.capacity.fields.status.paused'
    }[statusId] || 'addons.settings.tabs.capacity.fields.status.unknown');
  }

  getStatusIcon(statusId: number): string {
    return {
      [CapacityStatus.Active]: 'pi pi-check-circle',
      [CapacityStatus.Suspended]: 'pi pi-exclamation-circle',
      [CapacityStatus.Paused]: 'pi pi-pause-circle',
      [CapacityStatus.Fail]: 'pi pi-times-circle'
    }[statusId] || 'pi pi-question-circle';
  }

  getStatusSeverity(statusId: number): Severity {
    return {
      [CapacityStatus.Active]: 'success',
      [CapacityStatus.Suspended]: 'warning',
      [CapacityStatus.Paused]: 'warning',
      [CapacityStatus.Fail]: 'danger'
    }[statusId] as Severity || 'info';
  }
}
