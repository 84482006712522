import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RatingModule } from 'primeng/rating';
import { Item } from '../../api/item';
import { LayoutService } from '../../service/app.layout.service';
import { CatalogService } from '../../service/catalog.service';
import { FormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { DetailsOverviewTabComponent } from './details-overview-tab/details-overview-tab.component';
import { DetailsRelationshipTabComponent } from './details-relationship-tab/details-relationship-tab.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChipModule } from 'primeng/chip';
import {
  catalogTypesTranslationPaths,
  translateFromArrayAt,
} from '../../api/term';
import { RequestAccessFormComponent } from '../../components/request-access-form/request-access-form.component';
import { UserService } from '../../service/user.service';
import { ItemRequestService } from '../../service/item-request.service';
import { ItemAccessRequestStatusEnum } from '../../api/item-request';
import { DetailsReviewsTabComponent } from './details-reviews-tab/details-reviews-tab.component';

@Component({
  selector: 'app-item-details',
  standalone: true,
  imports: [
    RatingModule,
    FormsModule,
    TabViewModule,
    ButtonModule,
    DetailsOverviewTabComponent,
    DetailsRelationshipTabComponent,
    DetailsReviewsTabComponent,
    CommonModule,
    TranslateModule,
    ChipModule,
    RequestAccessFormComponent,
  ],
  templateUrl: './item-details.component.html',
  styleUrl: './item-details.component.scss',
})
export class ItemDetailsComponent {
  item!: Item;
  iconUrlImg = 'assets/no_preview.png';
  rating = '3.0';
  nRates = 100;
  activeIndex: number = 0;
  itemUrl = '';
  userHasPermission = false;
  userPermissionPending = false;
  openRequestForm = false;

  constructor(
    public layoutService: LayoutService,
    private route: ActivatedRoute,
    private catalogService: CatalogService,
    private cdref: ChangeDetectorRef,
    private translateService: TranslateService,
    private userService: UserService,
    private itemRequestService: ItemRequestService
  ) {}
  ngOnInit() {
    this.layoutService.hideMenu();
    this.route.queryParams.subscribe((params) => {
      const id = params['itemId'];
      if (id) {
        this.catalogService.getById(id).subscribe(async (item) => {
          this.item = item;
          this.checkUserPermissions(item);
          this.itemUrl =
            '/item-view?itemId=' +
            this.item.id +
            '&url=' +
            encodeURIComponent(`${item.itemAccessUrl}`);
          await this.loadIconURLFromBlob();
        });
      }
    });
  }

  async loadIconURLFromBlob() {
    if (!this.item.itemIcon) return;
    const icon = this.item.itemIcon;
    this.iconUrlImg = await this.loadImageURL(icon);

    this.cdref.detectChanges();
  }

  async loadImageURL(image: any): Promise<string> {
    const url = 'data:image/png;base64,' + image?.content;

    const res = await fetch(url);
    const blob = await res.blob();

    const file = new File([blob], `${image?.name}`, { type: 'image/png' });
    return URL.createObjectURL(file);
  }

  getTypeLabel(typeKey?: number): string {
    if (!typeKey) return '';

    return translateFromArrayAt(
      catalogTypesTranslationPaths,
      typeKey as number,
      this.translateService
    );
  }
  checkUserPermissions(item: Item) {
    const userMsalId = this.userService.getUserId();
    this.userHasPermission = item.itemPermissions.some(
      (permission) => permission.principalId === userMsalId
    );

    if (!this.userHasPermission) {
      this.itemRequestService.getMyItemRequests().subscribe((requests) => {
        const itemRequest = requests.find(
          (request) =>
            request.itemId === item.id &&
            request.userId === userMsalId &&
            request.statusId === ItemAccessRequestStatusEnum.Pending
        );
        this.userPermissionPending = !!itemRequest;
      });
    }
  }

  toggleRequestForm() {
    this.openRequestForm = !this.openRequestForm;
  }
}
