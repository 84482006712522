import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Item } from '../../../api/item';
import { ItemReviewService } from '../../../service/item-review.service';
import { ItemReview, ItemReviewAverageRate, ItemReviewFilterByEnum, ItemReviewGetList, ItemReviewOrderByEnum } from '../../../api/item-review';
import { RatingModule } from 'primeng/rating';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { UserService } from '../../../service/user.service';
import { Observable } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-details-reviews-tab',
  standalone: true,
  imports: [
    RatingModule,
    DataViewModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    PaginatorModule,
    ButtonModule,
    DialogModule,
    InputTextareaModule,
  ],
  providers: [MessageService],
  templateUrl: './details-reviews-tab.component.html',
  styleUrl: './details-reviews-tab.component.scss'
})
export class DetailsReviewsTabComponent {

  constructor(
    private itemReviewService: ItemReviewService,
    private translateService: TranslateService,
    private userService: UserService,
    private messageService: MessageService
  ) {}

  @Input() item!: Item;
  @Input() reviews: ItemReview[] = [];
  @Input() averageRate!: ItemReviewAverageRate;

  @Output() onReviewUpdate = new EventEmitter();

  rows: number = 10;
  totalRecords: number = 0;
  currentPage: number = 1;
  datetimeFormat: string = '';

  selectedReview!: ItemReview;

  isEditing: boolean = false;
  deleteDialog: boolean = false;

  sortOptions: { label: string, value: ItemReviewOrderByEnum }[] = [];
  filterOptions: {label: string, value: ItemReviewFilterByEnum }[] = [];

  sortKey: string = ItemReviewOrderByEnum.MostRecent;
  filterKey: string = ItemReviewFilterByEnum.All;

  translateOrderByOptions(key: string) {
    return this.translateService.instant('item_details.details-reviews-tab.order-by.' + key);
  }

  translateFilterByOptions(key: string) {
    return this.translateService.instant('item_details.details-reviews-tab.filter-by.' + key);
  }

  ngOnInit() {
    this.setFilterAndSort();

    this.translateService
      .get('primeng')
      .subscribe(res => {
        this.datetimeFormat = res.datetimeFormat;
      });

    this.translateService.onLangChange.subscribe(() => {
      this.setFilterAndSort();
    });

    this.totalRecords = this.averageRate?.total;
    this.loadReviews();
  }

  setFilterAndSort(): void {
    this.sortOptions = [
      { label: this.translateOrderByOptions(ItemReviewOrderByEnum.MostRecent), value: ItemReviewOrderByEnum.MostRecent },
      { label: this.translateOrderByOptions(ItemReviewOrderByEnum.DescendingRate), value: ItemReviewOrderByEnum.DescendingRate },
      { label: this.translateOrderByOptions(ItemReviewOrderByEnum.AscendingRate), value: ItemReviewOrderByEnum.AscendingRate }
    ];

    this.filterOptions = [
      { label: this.translateFilterByOptions(ItemReviewFilterByEnum.All), value: ItemReviewFilterByEnum.All },
      { label: this.translateFilterByOptions(ItemReviewFilterByEnum.ByMe), value: ItemReviewFilterByEnum.ByMe }
    ];
  }

  loadReviews(): void {
    const reviewRequestParams =  {
      itemId: this.item.id,
      currentPage: this.currentPage,
      itemVersion: this.item.itemVersion,
      numberOfRecords: this.rows,
      orderBy: this.sortKey,
      filterBy: this.filterKey
    } as ItemReviewGetList;

    this.itemReviewService.getItemReviews(reviewRequestParams).subscribe(result => {
      this.reviews = result.reviews;

      this.reviews.forEach(review => this.setPhoto(review));

      this.totalRecords = result.averageRate.total;
    });
  }

  confirmDelete(review: ItemReview): void {
    this.itemReviewService.delete(this.item.id as number, review.id).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('tooltip.successful'),
        detail: this.translateService.instant('item_reviews.tooltip.delete'),
        life: 3000,
      });

      this.onReviewUpdate.emit();
      this.deleteDialog = false;
    });
  }

  update(review: ItemReview): void {
    const payload = {
      rate: review.rate,
      comment: review.comment
    };

    this.itemReviewService.update(this.item.id as number, review.id, payload).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('tooltip.successful'),
        detail: this.translateService.instant('item_reviews.tooltip.update'),
        life: 3000,
      });

      this.onReviewUpdate.emit();
      this.isEditing = false;
    });
  }

  onFilterChange(event: any): void {
    this.loadReviews();
  }

  onPageChange(event: any): void {
    this.currentPage = (event.page + 1);
    this.rows = event.rows;

    this.loadReviews();
  }

  isMyReview(review: ItemReview): boolean {
    return review.userId === this.userService.getUserId() &&
      review.itemVersion === this.item.itemVersion;
  }

  getUserPhoto(userId: string): Observable<string> {
    return this.userService.getPhotoByUserId(userId ?? '');
  }

  setPhoto(item: ItemReview): void {
    if (item.userId)
      this.getUserPhoto(item.userId)
        .subscribe(avatar => item.userPhoto = avatar)
  }

  markReviewToDelete(review: ItemReview): void {
    this.deleteDialog = true;
    this.setSelectedReview(review);
  }

  setSelectedReview(review: ItemReview): void {
    this.selectedReview = Object.assign({}, review);
  }

  setEditMode(review: ItemReview): void {
    this.setSelectedReview(review);
    this.isEditing = true;
  }

  revertEdit(review: ItemReview): void {
    this.reviews[this.reviews.findIndex(x => x.id === review.id)] = this.selectedReview;
    this.isEditing = false;
  }
}
