
export interface UserNotification {
  id: number;
  userNotificationTypeId: number;
  userNotificationStatusId: number;
  senderUserId: string;
  recipientUserId: string;
  message: string;
  link: string;
  sentDate: Date;
  receivedDate: Date;
  readDate: Date;
  removedDate: Date;
  createdAt: Date;
  relativeDate: string;
}

export enum UserNotificationType {
  Simple = 1,
  Action = 2
}

export enum UserNotificationStatus {
  Sent = 1,
  Received = 2,
  Read = 3,
  Removed = 4
}

