<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <h3 class="mb-5">{{ 'glossary.header' | translate }}</h3>
      <p-toast></p-toast>
      <p-table #dt [value]="terms" responsiveLayout="scroll" [rows]="10"
               [globalFilterFields]="['id','name', 'type', 'acronym','status']" [paginator]="true"
               [rowsPerPageOptions]="[10,20,30,40,50,100]" [showCurrentPageReport]="true"
               [currentPageReportTemplate]="translatedTemplate.page_report" [(selection)]="selectedTerms"
               selectionMode="multiple" [rowHover]="true" dataKey="id" [first]="paginatorIndex"
               (onPage)="pageChange($event)">
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
              <div class="my-2">
                <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-outlined mr-3"
                        (click)="openNew()"></button>
              </div>
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="onGlobalFilter(dt, $event)"
                     [placeholder]="'glossary.table.search_field' | translate" class="w-full sm:w-auto" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="id">{{ 'glossary.table.column_header.id' | translate }} <p-sortIcon
                          field="id"></p-sortIcon></th>
            <th pSortableColumn="type">{{ 'glossary.table.column_header.type' | translate }} <p-sortIcon
                          field="type"></p-sortIcon></th>
            <th pSortableColumn="name">{{ 'glossary.table.column_header.name' | translate }} <p-sortIcon
                          field="name"></p-sortIcon></th>
            <th pSortableColumn="acronym">{{ 'glossary.table.column_header.acronym' | translate }} <p-sortIcon
                          field="acronym"></p-sortIcon></th>
            <th pSortableColumn="status">{{ 'glossary.table.column_header.status' | translate }} <p-sortIcon
                          field="status"></p-sortIcon></th>
            <th>{{ 'glossary.table.column_header.actions' | translate }} </th>
            <th>
              <p-button [icon]="
                  isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'
                " (click)="toggleFilter()" [rounded]="true" [text]="true" [raised]="true" severity="secondary" />
            </th>
          </tr>
          <tr *ngIf="isFilterRowVisible">
            <th></th>
            <th>
              <p-columnFilter type="text" field="id" placeholder="Search by Id" ariaLabel="Filter Name" />
            </th>
            <th>
              <p-columnFilter type="text" field="itemType.description" placeholder="Search by Type"
                              ariaLabel="Filter Type" />
            </th>
            <th>
              <p-columnFilter type="text" field="name" placeholder="Search by name" ariaLabel="Filter name" />
            </th>
            <th>
              <p-columnFilter type="text" field="ownerName" placeholder="Search by Owner" ariaLabel="Filter Owner" />
            </th>
            <th>
              <p-columnFilter type="text" field="status.description" placeholder="Search by Status"
                              ariaLabel="Filter Status" />
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-term>
          <tr>
            <td>
              <p-tableCheckbox [value]="term"></p-tableCheckbox>
            </td>
            <td style="width:5%; min-width:8rem;">
              <span class="p-column-title">
                {{ 'glossary.table.column_header.id' | translate }}
              </span>
              {{term.id}}
            </td>
            <td style="width:14%; min-width:10rem;">
              <span class="p-column-title">{{ 'glossary.table.column_header.type' | translate }}</span>
              {{getTermTypeLabel(term.type)}}
            </td>
            <td style="width:50%; min-width:10rem;">
              <span class="p-column-title">{{ 'glossary.table.column_header.name' | translate }}</span>
              {{term.name}}
            </td>
            <td style="width:14%; min-width:8rem;">
              <span class="p-column-title">{{ 'glossary.table.column_header.acronym' | translate }}</span>
              {{term.acronym}}
            </td>
            <td style="width:14%; min-width: 10rem;">
              <span class="p-column-title">
                {{ 'glossary.table.column_header.status' | translate }}
              </span>
              @if (term.status === true){
              <p-tag icon="pi pi-check" value="{{ 'entities.term.status.active' | translate }}" />
              } @else {
              <p-tag icon="pi pi-times" severity="danger" value="{{ 'entities.term.status.inactive' | translate }}" />
              }
            </td>
            <td>
              <div class="flex">
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-outlined mr-2"
                        (click)="editTerm(term)"></button>
                <button pButton pRipple icon="pi pi-trash"
                        class="p-button-rounded p-button-outlined p-button-danger mr-2" (click)="deleteTerm(term)"
                        [disabled]="term.status"></button>
                <button type="button" pButton icon="pi pi-ellipsis-v" (click)="tableItemMenu.toggle($event)"
                        class="p-button-rounded p-button-outlined"></button>
                <p-menu #tableItemMenu [popup]="true" [model]="tableItemActions" appendTo="body">
                  <ng-template pTemplate="item" let-item>
                    <a pRipple rel="noopener noreferrer" target="_blank" class="flex align-items-center p-menuitem-link"
                       (click)="changeTermStatus(term)">
                      <span [class]="item.icon"></span>
                      <span class="ml-2">{{ item.label }}</span>
                      <span *ngIf="item.shortcut"
                            class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
                        {{ item.shortcut }}
                      </span>
                    </a>
                  </ng-template>
                </p-menu>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <p-dialog [style]="{ width: '30vw' }" [breakpoints]="{ '1199px': '75vw', '1500px': '50vw', '575px': '90vw' }"
              [(visible)]="termDialog" [modal]="true" [draggable]="false" [resizable]="false" class="p-fluid"
              (onHide)="hideDialog()">
      <ng-template pTemplate="header">
        @if(form){
        <h3>{{ 'glossary.form.header.edit' | translate }}</h3>
        } @else {
        <h3>{{ 'glossary.form.header.new' | translate }}</h3>
        }
      </ng-template>
      <ng-template pTemplate="content">
        <form [formGroup]="form">
          <p-tabView [(activeIndex)]="activeTabIndex">
            <p-tabPanel header="General" class="line-height-3 m-0">
              <ng-template pTemplate="header">
                <div class="flex align-items-center gap-2">
                  <p>{{ 'glossary.form.tab.general.title' | translate }}
                    <i class="pi pi-exclamation-circle" style="color: red"
                      *ngIf="activeTabIndex != 0 && (!form.valid && form.dirty)"
                       [pTooltip]="translatedTemplate.form_tab_validation_tooltip"></i>
                  </p>
                </div>
              </ng-template>

              <div class="p-fluid p-formgrid flex flex-column">
                <input hidden type="text" pInputText id="id" formControlName="id" name="id" />
                <input hidden type="text" pInputText id="status" formControlName="status" name="status" />
                <div class="field col-12">
                  <label for="name">
                    {{ 'glossary.form.fields.name.label' | translate }}
                    <span class="p-error">*</span>
                  </label>
                  <input type="text" pInputText id="name" formControlName="name" required maxlength="100" name="name"
                         [ngClass]="{'ng-invalid ng-dirty' : form.controls['name'].dirty && !form.controls['name'].value}" />
                  <small class="ng-dirty ng-invalid"
                         *ngIf="form.controls['name'].dirty && !form.controls['name'].value">
                    {{ 'glossary.form.fields.name.validation_text' | translate }}
                  </small>
                </div>
                <div class="field col-12 md:col-5">
                  <label for="acronym">
                    {{ 'glossary.form.fields.acronym.label' | translate }}
                  </label>
                  <input type="text" pInputText id="acronym" formControlName="acronym" maxlength="50" name="acronym" />
                </div>
                <div class="field col-12 md:col-5">
                  <label for="type">
                    {{ 'glossary.form.fields.type.label' | translate }}
                    <span class="p-error">*</span>
                  </label>
                  <p-dropdown id="type" formControlName="type" [options]="(getTermTypes() | async)!" optionLabel="name"
                              name="type"
                              optionValue="key" [filter]="true" filterBy="name" [showClear]="true" appendTo="body"
                              [ngClass]="{'ng-invalid ng-dirty' : form.controls['type'].dirty && !form.controls['type'].value}">
                  </p-dropdown>
                  <small class="ng-dirty ng-invalid"
                         *ngIf="form.controls['type'].dirty && !form.controls['type'].value">
                    {{ 'glossary.form.fields.type.validation_text' | translate }}
                  </small>
                </div>
                <div class="field col-12">
                  <label for="definition">
                    {{ 'glossary.form.fields.definition.label' | translate }}
                    <span class="p-error">*</span>
                  </label>
                  <textarea id="definition" pInputTextarea formControlName="definition" required rows="10"
                            name="definition"
                            [ngClass]="{'ng-invalid ng-dirty' : form.controls['definition'].dirty && !form.controls['definition'].value}"
                            maxlength="1000" cols="20"></textarea>
                  <small class="ng-dirty ng-invalid"
                         *ngIf="form.controls['definition'].dirty && !form.controls['definition'].value">
                    {{ 'glossary.form.fields.definition.validation_text' | translate }}
                  </small>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Details" class="line-height-3 m-0">
              <ng-template pTemplate="header">
                <p>
                  {{ 'glossary.form.tab.details.title' | translate }}
                </p>
              </ng-template>

              <div class="p-fluid p-formgrid flex flex-column">
                <div class="field col-12 md:col-6">
                  <label for="owner">
                    {{ 'glossary.form.fields.owner.label' | translate }}
                  </label>
                  <user-dropdown [userName]="form.controls['owner'].value"
                                 [placeholder]="'components.user-dropdown.placeholder'"
                                 (selectedUser)="setOwner($event)"></user-dropdown>
                </div>
                <div class="field col-12">
                  <label for="synonymous">
                    {{ 'glossary.form.fields.synonymous.label' | translate }}
                  </label>
                  <input type="text" pInputText id="synonymous" formControlName="synonymous" maxlength="100"
                         name="synonymous" />
                </div>
                <div class="field col-12">
                  <label for="examples">
                    {{ 'glossary.form.fields.examples.label' | translate }}
                  </label>
                  <textarea id="examples" pInputTextarea formControlName="examples" name="examples" rows="3" maxlength="1000"
                            cols="20"></textarea>
                </div>
                <div class="field col-1">
                  <label for="gdpr">
                    {{ 'glossary.form.fields.gdpr.label' | translate }}
                  </label>
                  <br>
                  <p-inputSwitch id="gdpr" formControlName="gpdr" name="gdpr" value="false"></p-inputSwitch>
                </div>
                <div class="field col-12">
                  <label for="origin">
                    {{ 'glossary.form.fields.origin.label' | translate }}
                  </label>
                  <textarea id="origin" pInputTextarea formControlName="origin" name="origin" rows="3" maxlength="1000"
                            cols="20"></textarea>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>

          <div class="flex justify-content-end flex-wrap">
            <div class="w-auto gap-2 flex justify-end items-end">
              <button pButton pRipple label="{{ 'glossary.buttons.cancel' | translate }}" icon="pi pi-times"
                      class="p-button" (click)="hideDialog()"></button>
              <button pButton pRipple label="{{ 'glossary.buttons.save' | translate }}" icon="pi pi-check"
                      class="p-button" (click)="saveTerm()" [disabled]="!(form.valid && form.dirty)"></button>
            </div>
          </div>
        </form>
      </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="changeTermStatusDialog" header="{{ 'components.dialogs.header.confirm' | translate }}"
              [modal]="true" [style]="{width:'450px'}">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="term" [innerHTML]="translatedTemplate.dialog_change_status"></span>
      </div>
      <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="p-button-text"
                label="{{ 'components.dialogs.no' | translate }}" (click)="changeTermStatusDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="p-button-text"
                label="{{ 'components.dialogs.yes' | translate }}" (click)="confirmChangeStatus()"></button>
      </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="deleteTermDialog" header="{{ 'components.dialogs.header.confirm' | translate }}"
              [modal]="true" [style]="{width:'450px'}">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="term" [innerHTML]="translatedTemplate.dialog_delete_term"></span>
      </div>
      <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="p-button-text"
                label="{{ 'components.dialogs.no' | translate }}" (click)="deleteTermDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="p-button-text"
                label="{{ 'components.dialogs.yes' | translate }}" (click)="confirmDelete()"></button>
      </ng-template>
    </p-dialog>
  </div>
</div>
