import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from '../../service/app.layout.service';
import { AppMenuitemComponent } from './app.menuitem.component';
import { NgFor, NgIf } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  standalone: true,
  imports: [NgFor, NgIf, AppMenuitemComponent],
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor(
    public layoutService: LayoutService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initTranslations();

    this.translate.onLangChange.subscribe(() => {
      this.initTranslations();
    });
  }

  initTranslations() {
    this.model = [];

    this.translate
      .get('components.app-menu.dashboard.home.label')
      .subscribe((res: string) => {
        this.model.push({
          label: res,
          items: [],
        });
      });
    this.translate
      .get('components.app-menu.dashboard.home.dashboard')
      .subscribe((res: string) => {
        this.model[0].items.push({
          label: res,
          icon: 'pi pi-fw pi-home',
          routerLink: ['/dashboard'],
        });
      });
    this.translate
      .get('components.app-menu.dashboard.manager.label')
      .subscribe((res: string) => {
        this.model.push({
          label: res,
          items: [],
        });
      });
    this.translate
      .get('components.app-menu.dashboard.manager.glossary')
      .subscribe((res: string) => {
        this.model[1].items.push({
          label: res,
          icon: 'pi pi-fw pi-book',
          routerLink: ['/glossary'],
        });
      });
    this.translate
      .get('components.app-menu.dashboard.manager.Catalog')
      .subscribe((res: string) => {
        this.model[1].items.push({
          label: res,
          icon: 'pi pi-fw pi-cart-minus',
          routerLink: ['/catalog'],
        });
      });
    this.translate
      .get('components.app-menu.settings.label')
      .subscribe((res: string) => {
        this.model.push({
          label: res,
          items: [],
        });
      });
    this.translate
      .get('components.app-menu.settings.addons')
      .subscribe((res: string) => {
        this.model[2].items.push({
          label: res,
          icon: 'pi pi-objects-column',
          routerLink: ['/addons'],
        });
      });
    this.translate
      .get('components.app-menu.settings.general')
      .subscribe((res: string) => {
        this.model[2].items.push({
          label: res,
          icon: 'pi pi-cog',
          routerLink: ['/general-settings'],
        });
      });
  }
}
