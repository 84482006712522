import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../environments/environments';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes(environment.apiConfig.uri)) {
      return next.handle(req);
    }
    return from(this.getToken()).pipe(
      switchMap((token: string | null) => {
        if (token) {
          req = req.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
        }
        return next.handle(req);
      })
    );
  }

  async getToken(): Promise<string | null> {
    try {
      let account: any = this.msalService.instance.getActiveAccount() ?? null;
      const response = await this.msalService.instance.acquireTokenSilent({
        scopes: environment.apiConfig.scopes,
        account: account,
      });

      return response.accessToken;
    } catch (error) {
      console.error('Erro ao obter o token:', error);
      setTimeout(() => this.msalService.instance.loginRedirect(), 1000);
      return null;
    }
  }
}
