import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Seal } from '../../../api/seals';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ButtonModule } from 'primeng/button';
import { SealService } from '../../../service/seal.service';
import { GlobalErrorHandler } from '../../../utils/components/error-handler/error-handler.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-new-seal',
  standalone: true,
  imports: [
    DialogModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    PickerComponent,
    EmojiComponent,
  ],
  templateUrl: './new-seal.component.html',
  styleUrl: './new-seal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewSealComponent {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  @Output() didSaveEvent = new EventEmitter<Seal>();
  @Output() didEditEvent = new EventEmitter<Seal>();

  @Input() seal: Seal = {} as Seal;

  form!: FormGroup;

  selectedEmoji: string = '';
  emojiset: any = 'google';

  constructor(
    private sealsService: SealService,
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private translate: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    import('@ctrl/ngx-emoji-mart').then(() => {});

    this.form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?!\s*$).+/)
      ]),
      description: new FormControl('', {
        nonNullable: true,
      }),
      emoji: new FormControl('', Validators.required),
    });
  }

  hideDialog() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  selectEmoji(event: any): void {
    this.selectedEmoji = event.emoji.colons;
    this.form.controls['emoji'].setValue(this.selectedEmoji);
    this.form.markAsDirty();
  }

  save() {
    if (this.form.valid) {
      this.seal = this.form.value;

      if (this.seal.id) {
        this.sealsService.updateSeal(this.seal).subscribe({
          next: (seal) => {
            this.didEditEvent.emit(seal);
            this.translatedMessageService(
              'success',
              'Successful',
              'tooltip.content.item_updated'
            );
          },
          error: (e) => this.errorHandler.handleError(e),
        });
      } else {
        this.sealsService.createSeal(this.seal).subscribe({
          next: (seal) => {
            this.didSaveEvent.emit(seal);
            this.translatedMessageService(
              'success',
              'Successful',
              'tooltip.content.item_updated'
            );
          },
          error: (e) => this.errorHandler.handleError(e),
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
    this.hideDialog();
  }

  translatedMessageService(
    severity: string = 'success',
    summary: string = 'Successful',
    detail: string
  ) {
    this.translate.get([summary, detail]).subscribe((res: any) => {
      this.messageService.add({
        severity: severity,
        summary: res[summary],
        detail: res[detail],
        life: 3000,
      });
    });
  }
  ngOnChanges(): void {
    if (this.seal?.id) {
      this.updateForm();
    } else {
      this.form.reset();
      this.selectedEmoji = '';
    }
  }

  updateForm(): void {
    this.form.patchValue(this.seal);
    this.selectedEmoji = this.seal.emoji;
    this.form.enable();
  }
}
