import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { AddOn, AddOnConnectionLog, InstallAddOnCommand, InstalledAddOn, UpdateInstalledAddOnCommand } from "../api/addon";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { decreaseTimeOffset } from "../utils/time.utils";

/**
 * Service for managing addons
 */
@Injectable({
  providedIn: 'root',
})
export class AddonService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(
    private httpClient: HttpClient,
    private userService: UserService) {}

  getAvailableAddons(): Observable<AddOn[]> {
    return this.httpClient.get<AddOn[]>(`${this.baseRoute}addons/available`, {
      params: { userId: this.userService.getUserId() }
    });
  }

  getInstalledAddons(): Observable<InstalledAddOn[]> {
    return this.httpClient.get<InstalledAddOn[]>(`${this.baseRoute}addons/installed`, {
      params: { userId: this.userService.getUserId() }
    });
  }

  connect(connectionInfo: string): Observable<AddOnConnectionLog> {
    return this.httpClient.post<AddOnConnectionLog>(`${this.baseRoute}addons/connect`, JSON.stringify(connectionInfo), {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  install(installCommand: InstallAddOnCommand, addonId: number): Observable<InstalledAddOn> {
    return this.httpClient.post<InstalledAddOn>(`${this.baseRoute}addons/install/${addonId}`, installCommand, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  update(installedAddon: UpdateInstalledAddOnCommand): Observable<InstalledAddOn> {
    const payload = installedAddon;

    payload.capacitySchedules.forEach(schedule => {
      schedule.id = schedule.id ?? 0;
      if (schedule.turnOn)
        schedule.turnOn = decreaseTimeOffset(new Date(schedule.turnOn as Date));
      if (schedule.turnOff)
        schedule.turnOff = decreaseTimeOffset(new Date(schedule.turnOff as Date));
    })

    return this.httpClient.post<InstalledAddOn>(`${this.baseRoute}addons/installed/update`, payload);
  }

  uninstall(addonId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseRoute}addons/uninstall/${addonId}`, null, {
      params: { userId: this.userService.getUserId() }
    });
  }
}
