import { CommonModule } from "@angular/common";
import { Component, ErrorHandler, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { GlobalErrorHandler } from "../../../../../utils/components/error-handler/error-handler.component";
import { TableModule } from "primeng/table";
import { DialogModule } from "primeng/dialog";
import { RoleLevelSecurity, RoleLevelSecurityEnum, RoleType } from "../../../../../api/rls";
import { DropdownModule } from "primeng/dropdown";
import { FloatLabelModule } from "primeng/floatlabel";
import { RoleLevelSecurityService } from "../../../../../service/rls.service";

@Component({
  selector: 'role-level-security',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    ToastModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    TableModule,
    DialogModule,
    DropdownModule,
    FloatLabelModule
  ],
  templateUrl: './role-level-security.component.html',
  styleUrl: './role-level-security.component.scss',
})
export class RoleLevelSecurityComponent implements OnInit {

  @Input() installedAddOnId!: number;

  @Output() rolesChanged = new EventEmitter<RoleLevelSecurity[]>();

  form!: FormGroup;

  roles: RoleLevelSecurity[] = [];
  roleTypes: RoleType[] = [];

  showRoleModal: boolean = false;
  showDeleteRoleModal: boolean = false;

  isEditing: boolean = false;
  isFilterRowVisible: boolean = false;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private messageService: MessageService,
    private translationService: TranslateService,
    private rlsService: RoleLevelSecurityService
  ) { }

  ngOnInit(): void {
    this.loadRoleTypes();
    this.getAll();

    this.translationService.onLangChange
      .subscribe(() => this.loadRoleTypes());

    this.form = new FormGroup({
      id: new FormControl<number>(0),
      roleName: new FormControl<string>(''),
      type: new FormControl<RoleLevelSecurityEnum | null>(null)
    });
  }

  loadRoleTypes(): void {
    this.roleTypes = [
      {
        id: RoleLevelSecurityEnum.Static,
        value: this.translationService.instant('addons.settings.tabs.security.table.dialog.types.static')
      },
      {
        id: RoleLevelSecurityEnum.Dynamic,
        value: this.translationService.instant('addons.settings.tabs.security.table.dialog.types.dynamic')
      }
    ];
  }

  getRoleTypeById(id: RoleLevelSecurityEnum): RoleType | undefined {
    return this.roleTypes.find(role => role.id === id);
  }

  onFocusOut(event: any): void {
    const value = event.target?.value.trim();

    if (!value)
      this.form.get('roleName')?.setErrors({ 'emptyField': true });
  }

  onModalHide(): void {
    this.form.reset();
  }

  displayDeleteModal(role: RoleLevelSecurity): void {
    this.form.patchValue(role);
    this.showDeleteRoleModal = true;
  }

  getAll(): void {
    this.rlsService.getAll().subscribe({
      next: (roles: RoleLevelSecurity[]) => {
        this.roles = roles;
      },
      error: (e) => this.errorHandler.handleError(e)
    });
  }

  save(): void {
    if (this.isEditing)
      this.update();
    else
      this.add();
  }

  edit(role: RoleLevelSecurity): void {
    this.form.patchValue(role);
    this.isEditing = true;
    this.showRoleModal = true;
  }

  add(): void {
    if (this.hasDuplicateRole(this.form.value)) {
      this.messageService.add({
        severity: 'error',
        summary: this.translationService.instant('tooltip.error'),
        detail: this.translationService.instant('addons.settings.tabs.security.tooltip.conflict'),
        life: 3000,
      });

      this.form.get('roleName')?.setErrors({ 'duplicate': true });

      return;
    }

    let newRole = { ...this.form.value, id: 0 };
    this.roles = [...this.roles, newRole];
    this.rolesChanged.emit(this.roles);

    this.showRoleModal = false;
  }

  hasDuplicateRole(role: RoleLevelSecurity): boolean {
    return this.roles.some(r => r.roleName === role.roleName);
  }

  update(): void {
    if (this.hasDuplicateRole(this.form.value)) {
      this.messageService.add({
        severity: 'error',
        summary: this.translationService.instant('tooltip.error'),
        detail: this.translationService.instant('addons.settings.tabs.security.tooltip.conflict'),
        life: 3000,
      });

      this.form.get('roleName')?.setErrors({ 'duplicate': true });

      return;
    }

    const roleIdx = this.roles.findIndex(role => role.id === this.form.value.id);

    if (roleIdx !== -1)
      this.roles[roleIdx] = this.form.value;

    this.showRoleModal = false;
  }

  remove(roleId: number): void {
    this.roles.splice(this.roles.findIndex(role => role.id === roleId), 1);

    this.roles = [...this.roles];

    this.rolesChanged.emit(this.roles);
  }

  delete(): void {
    const roleId = this.form.get('id')?.value;

    if (!roleId) {
      this.remove(roleId);
      this.showDeleteRoleModal = false;
      return;
    }

    this.rlsService.canRemove(this.installedAddOnId, roleId).subscribe({
      next: (canRemove) => {
        if (canRemove) {
          this.remove(roleId);

          this.messageService.add({
            severity: 'success',
            summary: this.translationService.instant('tooltip.successful'),
            detail: this.translationService.instant('addons.settings.tabs.security.tooltip.delete'),
            life: 3000,
          });

          this.showDeleteRoleModal = false;
        }
        else {
          this.messageService.add({
            severity: 'error',
            summary: this.translationService.instant('tooltip.error'),
            detail: this.translationService.instant('addons.settings.tabs.security.tooltip.conflict_delete'),
            life: 3000,
          });

          this.showDeleteRoleModal = false;
        }
      },
      error: (e) => this.errorHandler.handleError(e)
    });
  }
}
