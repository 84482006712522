import { HttpParams } from '@angular/common/http';

export interface ItemPaginator {
  text: string;
  currentPage: number;
  numberOfRecords: number;
  itemStatusId: number;
}

export type PaginationInfo = Pick<
  ItemPaginator,
  'currentPage' | 'numberOfRecords'
> & {
  sortField?: string | string[];
  sortOrder?: number;
  filters: { [key: string]: any };
};

export interface PaginatedResponse<T> {
  items: T[]; // Dados retornados
  total: number; // Total de registros
}

export const DEFAULT_PAGINATION = {
  page: '0',
  size: '10',
  sortOrder: '1',
};

export function buildHttpParams(
  pagination?: PaginationInfo,
  directFilters?: boolean
): HttpParams {
  let params = new HttpParams()
    .set('page', pagination?.currentPage.toString() || DEFAULT_PAGINATION.page)
    .set(
      'size',
      pagination?.numberOfRecords.toString() || DEFAULT_PAGINATION.size
    );

  if (pagination?.sortField) {
    const sortField = Array.isArray(pagination.sortField)
      ? pagination.sortField.join(',') // Combina os valores se for um array
      : pagination.sortField; // Caso contrário, usa diretamente

    params = params.set('sortField', sortField);
  }
  if (pagination?.sortOrder)
    params = params.set(
      'sortOrder',
      pagination.sortOrder?.toString() || DEFAULT_PAGINATION.sortOrder
    );

  if (pagination?.filters) {
    const filterPrefix = directFilters ? '' : 'Filters[';
    const filterSuffix = directFilters ? '' : ']';

    Object.entries(pagination.filters).forEach(([key, value]) => {
      params = params.append(`${filterPrefix}${key}${filterSuffix}`, value);
    });
  }
  return params;
}

export function mapFilters(tableFilters: any): any {
  const mappedFilters: any = {};
  for (const key in tableFilters) {
    if (tableFilters[key]?.value) {
      mappedFilters[key] = tableFilters[key].value;
    }
  }
  return mappedFilters;
}
