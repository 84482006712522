<div class="content available-items">
  <div class="header">
    <div class="col-6">
      <h4 class="mb-6">{{ 'store.grid.header' | translate }}</h4>
    </div>
    <div class="col-6 filter-button">
      <app-filter-button
        [items]="items"
        [isMyCatalogFilter]="false"
        [filterPagination]="currentPagination"
        (filteredItems)="updateFilteredItems($event)"
      ></app-filter-button>
    </div>
  </div>
  <div class="grid w-full">
    <div class="col-12">
      <div class="card">
        <div class="col-12 items-counter">
          <span>
            {{
            itemsCounter == 1
            ? itemsCounter + ('store.filter.chip.singular' | translate)
            : itemsCounter + ('store.filter.chip.plural' | translate)
            }}
          </span>
        </div>
        <div class="items-container" (scroll)="onScroll($event)">
          <div class="grid-nogutter">
            <div *ngFor="let item of displayedItems">
              <app-item-card
                [item]="item"
                (onToggleSidebar)="($event)">
              </app-item-card>
            </div>
          </div>
          <p-progressSpinner
            *ngIf="loading"
            class="scroll-pagination-spinner"
            styleClass="w-4rem h-4rem"
            strokeWidth="8"
            fill="transparent"
            ariaLabel="loading" />

          <!-- Mensagem quando todos os itens foram carregados -->
          @if(allItemsLoaded) {
            <div class="text-center p-4 text-600">
              {{ 'store.no_more_items' | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
