import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { Item } from '../../../api/item';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-details-overview-tab',
  standalone: true,
  imports: [GalleriaModule, CarouselModule, ImageModule, TranslateModule],
  templateUrl: './details-overview-tab.component.html',
  styleUrl: './details-overview-tab.component.scss',
})
export class DetailsOverviewTabComponent {
  @Input() item!: Item;
  images: any[] = [];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
      numScroll: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(private cdref: ChangeDetectorRef) {}

  async ngOnInit() {
    this.images = await Promise.all(
      this.item.itemPreviews.map(async (preview) => {
        const url = await this.loadImageURL(preview);
        return {
          itemImageSrc: url,
        };
      })
    );
    this.cdref.detectChanges();
  }

  async loadImageURL(image: any): Promise<string> {
    const url = 'data:image/png;base64,' + image?.content;

    const res = await fetch(url);
    const blob = await res.blob();

    const file = new File([blob], `${image?.name}`, { type: 'image/png' });
    return URL.createObjectURL(file);
  }
}
