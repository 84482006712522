import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../../service/app.layout.service";
import { SearchService } from "../../service/search.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ItemPaginator } from "../../api/item-paginator";
import { Item, ItemStatusEnum } from "../../api/item";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { FilterButtonComponent } from "../my-catalog/filter-button/filter-button.component";
import { ChipModule } from "primeng/chip";
import { UserService } from "../../service/user.service";
import { Observable } from "rxjs";
import { ItemSearchResult } from "../../api/item-search-result";
import { ButtonModule } from "primeng/button";
import { PaginatorModule } from "primeng/paginator";
import { ItemRequestService } from "../../service/item-request.service";
import { HighlightDirective } from "../../directives/highlight.directive";
import { RequestAccessFormComponent } from "../../components/request-access-form/request-access-form.component";
import { RatingModule } from "primeng/rating";
import { catalogTypesTranslationPaths, translateFromArrayAt } from "../../api/term";
import { DetailsComponent } from "../../components/details/details.component";
import { ItemReviewService } from "../../service/item-review.service";
import { ItemReviewFilterByEnum, ItemReviewGetList, ItemReviewOrderByEnum, ItemReviews } from "../../api/item-review";

type Rating = {
  total: number;
  average: number;
};

@Component({
  selector: 'item-search',
  standalone: true,
  providers: [],
  imports: [
    CommonModule,
    TranslateModule,
    ProgressSpinnerModule,
    FilterButtonComponent,
    ChipModule,
    ButtonModule,
    PaginatorModule,
    RouterModule,
    HighlightDirective,
    RequestAccessFormComponent,
    RatingModule,
    DetailsComponent
  ],
  templateUrl: './item-search.component.html',
  styleUrl: './item-search.component.scss',
})
export class ItemSearchComponent implements OnInit {

  loading: boolean = true;
  items: Item[] = [];
  total: number = 0;
  queryParams!: ItemPaginator;
  first: number = 0;
  rows: number = 15;
  itemRatings: Map<number, Rating> = new Map();
  sidebarVisible = false;
  selectedItem?: Item;
  itemUrl = '';

  openRequestForm = false;

  constructor(
    private layoutService: LayoutService,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private itemRequestService: ItemRequestService,
    private translateService: TranslateService,
    private itemReviewService: ItemReviewService,
  ) { }

  ngOnInit(): void {
    this.layoutService.setAdminStuff(false);

    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params as ItemPaginator;

      if (!this.queryParams.text)
        this.loading = false;

      this.paginate(this.queryParams);
    });
  }

  paginate(paginator: ItemPaginator): void {
    paginator = {
      ...paginator,
      numberOfRecords: this.rows,
      itemStatusId: ItemStatusEnum.PUBLISHED
    };

    this.loading = true;

    this.searchService.search(paginator).subscribe({
      next: (result: ItemSearchResult) => {
        this.items = result.items;
        this.total = result.total;

        Promise.all(
          this.items.map(async (item) => {
            this.processItemImages(item);
            this.setAccessStatus(item);
            this.loadItemRating(item);
          })
        ).then(() => {
          this.loading = false;
        });

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: paginator,
          queryParamsHandling: 'merge'
        });
      }
    });
  }

  private processItemImages(item: Item): void {
    if (item.itemPreviews?.length) {
      const thumbnail = item.itemPreviews.find(preview => preview.isThumbnail);
      if (thumbnail?.content) {
        thumbnail.refUrl = `data:image/png;base64,${thumbnail.content}`;
      }
    }

    if (item.itemIcon?.content) {
      item.itemIcon.refUrl = `data:image/png;base64,${item.itemIcon.content}`;
    }
  }

  getThumbnail(item: Item): string {
    const thumbnail = item.itemPreviews?.find(preview => preview.isThumbnail);
    return thumbnail?.refUrl || 'assets/no_preview.png';
  }

  getIcon(item: Item): string | null {
    return item.itemIcon?.refUrl || null;
  }

  getOwnerPhoto(ownerId?: string): Observable<string> {
    return this.userService.getPhotoByUserId(ownerId ?? '');
  }

  hasAccess(item: Item): boolean {
    const userMsalId = this.userService.getUserId();
    return item.itemPermissions.some(
      (permission) => permission.principalId === userMsalId
    );
  }

  isOwner(item: Item): boolean {
    const userMsalId = this.userService.getUserId();
    return item.ownerId === userMsalId;
  }

  setAccessStatus(item: Item): void {
    const userMsalId = this.userService.getUserId();
    const userHasPermission = this.hasAccess(item);
    if (!userHasPermission) {
      const params: any = {
        itemId: item.id,
        userId: userMsalId
      };

      this.itemRequestService.isRequestPending(params).subscribe((isRequestPending) => {
        item.isAccessPending = isRequestPending;
      });
    }
  }

  private loadItemRating(item: Item): void {
    if (!item.id) return;

    const reviewRequestParams = this.createReviewRequestParams(item);
    
    this.itemReviewService.getItemReviews(reviewRequestParams).subscribe({
      next: (result) => this.updateItemRating(item.id!, result),
      error: () => this.setDefaultRating(item.id!)
    });
  }

  private createReviewRequestParams(item: Item): ItemReviewGetList {
    return {
      itemId: item.id,
      currentPage: 1,
      itemVersion: item.itemVersion,
      numberOfRecords: 1,
      orderBy: ItemReviewOrderByEnum.MostRecent,
      filterBy: ItemReviewFilterByEnum.All,
    } as ItemReviewGetList;
  }

  private updateItemRating(itemId: number, result: ItemReviews): void {
    if (!result?.averageRate) return;

    const average = this.itemReviewService.getAverageRate(result);
    this.setItemRating(itemId, {
      average,
      total: result.averageRate.total
    });
  }

  private setItemRating(itemId: number, rating: Rating): void {
    this.itemRatings.set(itemId, rating);
  }

  private setDefaultRating(itemId: number): void {
    this.setItemRating(itemId, { average: 0, total: 0 });
  }

  updateFilteredItems(filteredItems: any[]): void { }

  toggleRequestForm(item: Item, event: any): void {
    event.preventDefault();

    this.openRequestForm = !this.openRequestForm;
    this.selectedItem = this.openRequestForm ? item : undefined;

    this.setAccessStatus(item);
  }

  toggleDetailsView(item: Item, event: any): void {
    event.preventDefault();

    this.sidebarVisible = !this.sidebarVisible;
    this.selectedItem = this.sidebarVisible ? item : undefined;
  }

  onPageChange(event: any): void {
    this.first = event.first;
    this.rows = event.rows;

    let paginator: ItemPaginator = {
      ...this.queryParams,
      currentPage: (event.page + 1).toString(),
      numberOfRecords: event.rows,
      itemStatusId: ItemStatusEnum.PUBLISHED
    };

    this.paginate(paginator);
  }

  getTypeLabel(typeKey?: number): string {
    if (!typeKey) return '';

    return translateFromArrayAt(
      catalogTypesTranslationPaths,
      typeKey as number,
      this.translateService
    );
  }

  toItemView(item: any): void {

    const itemUrl =
      item.id +
      '&url=' +
      encodeURIComponent(`${item.itemAccessUrl}`);

    window.open('/item-view?itemId=' + itemUrl, '_blank');
  }
}
