import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';
import { Item } from '../api/item';

/**
 * Service for managing item requests.
 */
@Injectable({
  providedIn: 'root',
})
export class UserItemFavoriteService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}user/item-favorites`);
  }

  add(itemId: number): Observable<Item> {
    return this.httpClient.post<Item>(`${this.baseRoute}user/item-favorites`, null, {
      params: { itemId }
    });
  }

  remove(itemId: number): Observable<Item> {
    return this.httpClient.delete<Item>(`${this.baseRoute}user/item-favorites`, {
      params: { itemId }
    });
  }
}
