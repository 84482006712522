import { CommonModule } from "@angular/common";
import { Component, ErrorHandler, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { GlobalErrorHandler } from "../../../../../utils/components/error-handler/error-handler.component";
import { TableModule } from "primeng/table";
import { DialogModule } from "primeng/dialog";
import { RoleLevelSecurity } from "../../../../../api/rls";
import { DropdownModule } from "primeng/dropdown";
import { FloatLabelModule } from "primeng/floatlabel";
import { RoleLevelSecurityService } from "../../../../../service/rls.service";
import { User } from "../../../../../api/user";
import { UserDropdownComponent } from "../../../../../components/user-dropdown/user-dropdown.component";
import { UserService } from "../../../../../service/user.service";

@Component({
  selector: 'role-level-security',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    ToastModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    TableModule,
    DialogModule,
    DropdownModule,
    FloatLabelModule,
    UserDropdownComponent
  ],
  templateUrl: './role-level-security.component.html',
  styleUrl: './role-level-security.component.scss',
})
export class RoleLevelSecurityComponent implements OnInit {

  @Input() installedAddOnId!: number;

  @Output() rolesChanged = new EventEmitter<RoleLevelSecurity[]>();

  form!: FormGroup;

  roles: RoleLevelSecurity[] = [];
  users: User[] = [];
  userIds?: string[] = [];
  selectedUser: User = { name: '' } as User;
  selectedUsers: User[] = [];

  columnDefinitions: string[] = ['name', 'type', 'mail', 'actions'];

  showRoleModal: boolean = false;
  showDeleteRoleModal: boolean = false;

  isEditing: boolean = false;
  isFilterRowVisible: boolean = false;

  loading: boolean = false;

  totalRecords: number = 0;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private messageService: MessageService,
    private translationService: TranslateService,
    private rlsService: RoleLevelSecurityService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getAll();

    this.form = new FormGroup({
      id: new FormControl<number>(0),
      roleName: new FormControl<string>(''),
      users: new FormControl<User[]>([])
    });
  }

  onFocusOut(event: any): void {
    const value = event.target?.value.trim();

    if (!value)
      this.form.get('roleName')?.setErrors({ 'emptyField': true });
  }

  onModalHide(): void {
    this.form.reset();
    this.users = [];
    this.loading = false;
  }

  displayDeleteModal(role: RoleLevelSecurity): void {
    this.form.patchValue(role);
    this.showDeleteRoleModal = true;
  }

  getAll(): void {
    this.rlsService.getAll().subscribe({
      next: (roles: RoleLevelSecurity[]) => {
        this.roles = roles;
      },
      error: (e) => this.errorHandler.handleError(e)
    });
  }

  save(): void {
    if (this.isEditing)
      this.update();
    else
      this.add();

    this.rolesChanged.emit(this.roles);
  }

  edit(role: RoleLevelSecurity): void {
    this.form.patchValue(role);

    if (role?.users)
      this.userIds = role?.users?.map((user: User) => user.id);

    if (role?.userIds)
      this.userIds = role?.userIds.map((id: string) => id);

    if (role?.userIds)
      role.userIds = this.userIds;

    this.loadPermissions(0, this.userIds?.length as number);

    this.totalRecords = this.userIds?.length || 0;
    this.isEditing = true;
    this.showRoleModal = true;

    this.rolesChanged.emit(this.roles);
  }

  add(): void {
    if (this.hasDuplicateRole(this.form.value)) {
      this.messageService.add({
        severity: 'error',
        summary: this.translationService.instant('tooltip.error'),
        detail: this.translationService.instant('addons.settings.tabs.security.tooltip.conflict'),
        life: 3000,
      });

      this.form.get('roleName')?.setErrors({ 'duplicate': true });

      return;
    }

    let newRole = { ...this.form.value, id: 0 };
    this.roles = [...this.roles, newRole];

    this.showRoleModal = false;
  }

  hasDuplicateRole(role: RoleLevelSecurity): boolean {
    return this.roles.some(r => r.roleName === role.roleName);
  }

  update(): void {
    if (this.form.get('roleName')?.dirty && this.hasDuplicateRole(this.form.value)) {
      this.messageService.add({
        severity: 'error',
        summary: this.translationService.instant('tooltip.error'),
        detail: this.translationService.instant('addons.settings.tabs.security.tooltip.conflict'),
        life: 3000,
      });

      this.form.get('roleName')?.setErrors({ 'duplicate': true });

      return;
    }

    const roleIdx = this.roles.findIndex(role => role.id === this.form.value.id);

    if (roleIdx !== -1)
      this.roles[roleIdx] = this.form.value;

    this.showRoleModal = false;
  }

  remove(roleId: number): void {
    this.roles.splice(this.roles.findIndex(role => role.id === roleId), 1);

    this.roles = [...this.roles];

    this.rolesChanged.emit(this.roles);
  }

  delete(): void {
    const roleId = this.form.get('id')?.value;

    if (!roleId) {
      this.remove(roleId);
      this.showDeleteRoleModal = false;
      return;
    }

    this.rlsService.canRemove(this.installedAddOnId, roleId).subscribe({
      next: (canRemove) => {
        if (canRemove) {
          this.remove(roleId);

          this.messageService.add({
            severity: 'success',
            summary: this.translationService.instant('tooltip.successful'),
            detail: this.translationService.instant('addons.settings.tabs.security.tooltip.delete'),
            life: 3000,
          });

          this.showDeleteRoleModal = false;
        }
        else {
          this.messageService.add({
            severity: 'error',
            summary: this.translationService.instant('tooltip.error'),
            detail: this.translationService.instant('addons.settings.tabs.security.tooltip.conflict_delete'),
            life: 3000,
          });

          this.showDeleteRoleModal = false;
        }
      },
      error: (e) => this.errorHandler.handleError(e)
    });
  }

  setUser(user: User): void {
    this.selectedUser = user;
  }

  addPermission(): void {
    if (this.users.some((x => x.id == this.selectedUser.id))) {
      this.translationService.get(['Error', 'tooltip.content.user_group_duplicated']).subscribe((res: any) => {
        this.messageService.add({
          severity: 'error',
          summary: res['Error'],
          detail: res['tooltip.content.user_group_duplicated'],
          life: 3000,
        });
      });

      this.selectedUser = { name: '' } as User;
      return;
    }

    this.users.push(this.selectedUser);
    this.form.get('users')?.setValue(this.users);
    this.selectedUser = { name: '' } as User;

    const role = this.roles.find(r => r.id === this.form.get('id')?.value) as RoleLevelSecurity;

    if (role) {
      role.users = this.users;
      role.userIds = this.users.map(u => u.id);
    }
  }

  deletePermission(user: User): void {
    this.users = this.users.filter(x => x.id !== user.id);
    this.form.get('users')?.setValue(this.users);
  }

  onPageChange(event: any): void {
    let total = event.first + event.rows;
    this.users = [];
    this.loadPermissions(event.first, total);
  }

  loadPermissions(start: number, end: number): void {
    this.loading = true;

    let userIds = this.userIds?.slice(start, end);

    let usersToDisplay: User[] = userIds?.map(id => {
      return {
        id: id,
        name: '',
        mail: '',
        setting: { language: '', allowNotifications: true },
        groupType: undefined,
        isAdmin: false,
        staticRLS: [{ id: 1, roleName: '', installedAddonId: 0, type: 0 }],
        photo: '',
        hasSentFeedback: false,
        agreementStatus: false
      }
    }) as User[];

    this.userService.getUserAndGroupsByIds(userIds as string[]).subscribe(userOrGroups => {
      userOrGroups.forEach(userOrGroup => {
        const index = usersToDisplay.findIndex(u => u.id === userOrGroup.id);

        if (index !== -1)
          Object.assign(usersToDisplay[index], userOrGroup);
      });

      this.users = usersToDisplay;
      this.loading = false;
    });
  }
}
