<div class="header">
    <div class="col-12">
      <div class="col-6">
        <h4 class="mb-6">{{ 'store.grid.header' | translate }}</h4>
      </div>
      <div class="col-6 filter-button">
        <div class="mb-4">
          <app-filter-button [items]="items" [isMyCatalogFilter]="false" (filteredItems)="updateFilteredItems($event)"></app-filter-button> 
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="col-12 items-counter">
            <span>
              {{
              items.length == 1
              ? items.length + ('store.filter.chip.singular' | translate)
              : items.length + ('store.filter.chip.plural' | translate)
              }}
            </span>
  
          </div>
          @if(!isLoading || items.length > 0){
          <p-dataView
                      #dv
                      [value]="items"
                      [currentPageReportTemplate]="translatedTemplate.pageReport"
                      layout="grid">
            <ng-template pTemplate="grid" let-items>
              <div class="grid grid-nogutter">
                @for(item of items; track item){
  
                <div class="col-12 sm:col-6 md:col-4 xl:col-2 p-2">
                  <app-item-card [item]="item" [isMyCatalogView]="false"></app-item-card>
                </div>
                }
              </div>
            </ng-template>
          </p-dataView>
  
          } @else {
          <div class="flex justify-content-between gap-3">
            @for(index of [1,2,3,4]; track index){
            <div class=" border-round border-1 surface-border p-4 surface-card flex-1">
              <p-skeleton width=" 100%" height="150px" />
              <div class="flex my-3">
                <div>
                  <p-skeleton width="10rem" styleClass="mb-2" />
                  <p-skeleton width="5rem" styleClass="mb-2" />
                </div>
              </div>
              <div class="flex justify-content-end mt-3 gap-2">
                <p-skeleton width="4rem" height="2rem" />
                <p-skeleton width="4rem" height="2rem" />
              </div>
            </div>
            }
          </div>
          }
        </div>
      </div>
    </div>