<div class="w-6 text-center border-primary align-items-center">
  <p-card>
    <div class="text-center pb-3">
      <h1 class="text-7xl">
        <b>{{ average || 0 }}</b>
      </h1>
      <p class="text-xl">{{ total }} {{ 'item_reviews.ratings.label' | translate }}</p>
    </div>

    <div class="formgroup-inline w-full" *ngFor="let scale of scales">
      <i class="pi pi-star-fill text-xl"></i>
      <p class="mx-2">{{ scale }}</p>
      <p-progressBar class="w-10" showValue="false" [value]="!total ? 0 : (ratings[scale] / total) * 100"></p-progressBar>
    </div>
  </p-card>
</div>
