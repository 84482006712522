<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <h3 class="mb-5">{{ 'catalog.header' | translate }}</h3>
      <p-toast></p-toast>
      <p-table #dt [value]="items" [columns]="cols" responsiveLayout="scroll" [rows]="rows" [globalFilterFields]="[
          'Id',
          'Name',
          'itemType.description',
          'ownerName',
          'status.description'
        ]" [paginator]="true" [(first)]="paginatorIndex" [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]"
               [totalRecords]="totalRecords"
               [showCurrentPageReport]="true" [currentPageReportTemplate]="translatedTemplate.page_report"
               [(selection)]="selectedItems" selectionMode="multiple" [rowHover]="true" dataKey="id" [lazy]="true"
               [loading]="loading"
               (onLazyLoad)="getAllCatalog($event)">
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
              <div class="my-2">
                <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-outlined mr-3"
                  (click)="openNew()"></button>
                <button pButton pRipple label="{{ 'catalog.buttons.publish' | translate }}" icon="pi pi-upload"
                  class="p-button-rounded p-button-outlined mr-3" (click)="publishSelectedItems()"
                  [disabled]="!selectedItems || !selectedItems.length"></button>
              </div>
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="onGlobalFilter(dt, $event)"
                [placeholder]="'catalog.table.search_field' | translate" class="w-full sm:w-auto" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="Id" style="width: 10rem">
              {{ 'catalog.table.colum_header.id' | translate }} <p-sortIcon field="Id"></p-sortIcon>
            </th>
            <th pSortableColumn="itemType.description">
              {{ 'catalog.table.colum_header.type' | translate }} <p-sortIcon field="itemType.description"></p-sortIcon>
            </th>
            <th pSortableColumn="name">
              {{ 'catalog.table.colum_header.name' | translate }} <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="ownerName">
              {{ 'catalog.table.colum_header.owner' | translate }} <p-sortIcon field="ownerName"></p-sortIcon>
            </th>
            <th pSortableColumn="status.description" style="width: 2000rem">
              {{ 'catalog.table.colum_header.status' | translate }} <p-sortIcon field="status.description"></p-sortIcon>
            </th>
            <th> {{ 'catalog.table.colum_header.actions' | translate }} </th>
            <th>
              <p-button [icon]="
                  isFilterRowVisible ? 'pi pi-filter' : 'pi pi-filter-slash'
                " (click)="toggleFilter()" [rounded]="true" [text]="true" [raised]="true" severity="secondary" />
            </th>
          </tr>
          <tr *ngIf="isFilterRowVisible">
            <th></th>
            <th>
              <p-columnFilter type="text" field="Id" placeholder="{{ 'catalog.table.table_filter.id' | translate }}"
                              ariaLabel="Filter Name" />
            </th>
            <th>
              <p-columnFilter type="text" field="itemType.description"
                placeholder="{{ 'catalog.table.table_filter.type' | translate }}" ariaLabel="Filter Type" />
            </th>
            <th>
              <p-columnFilter type="text" field="Name" placeholder="{{ 'catalog.table.table_filter.name' | translate }}"
                              ariaLabel="Filter name" />
            </th>
            <th>
              <p-columnFilter type="text" field="ownerName"
                placeholder="{{ 'catalog.table.table_filter.owner' | translate }}" ariaLabel="Filter Owner" />
            </th>
            <th>
              <p-columnFilter type="text" field="status.description"
                placeholder="{{ 'catalog.table.table_filter.status' | translate }}" ariaLabel="Filter Status" />
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td style="width: 5%; min-width: 8rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.id' | translate }} </span>
              {{ item.id }}
            </td>
            <td style="width: 14%; min-width: 10rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.type' | translate }}</span>
              {{ getItemTypeLabel(item.itemTypeId)}}
            </td>
            <td style="width: 50%; min-width: 10rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.type' | translate }}</span>
              {{ item.name }}
            </td>
            <td style="width: 25%; min-width: 8rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.owner' | translate }}</span>
              {{ item.ownerName }}
            </td>
            <td style="width: 14%; min-width: 10rem">
              <span class="p-column-title">{{ 'catalog.table.colum_header.status' | translate }}</span>
              <p-tag *ngIf="item.status.id === itemStatus.PUBLISHED"
                     [value]="'entities.catalog.status.published' | translate" />

              <p-tag *ngIf="item.status.id === itemStatus.DRAFT"
                     [value]="'entities.catalog.status.draft' | translate" />
            </td>
            <td>
              <div class="flex">
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-outlined mr-2"
                        [disabled]="!isEditable(item)" (click)="editItem(item)"></button>
                <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-outlined mr-2"
                        (click)="visualizeItem(item)"></button>
                <button pButton pRipple icon="pi pi-trash"
                  class="p-button-rounded p-button-outlined p-button-danger mr-2" (click)="deleteItem(item)"
                  [disabled]="item.status.description === 'Published'"></button>
                <button type="button" pButton icon="pi pi-ellipsis-v" (click)="tableItemMenu.toggle($event)"
                  [disabled]="item.status.description === 'Inactive'"
                  class="p-button-rounded p-button-outlined"></button>
                <p-menu #tableItemMenu [popup]="true" [model]="tableItemActions" appendTo="body">
                  <ng-template pTemplate="item" let-temp>
                    <a pRipple rel="noopener noreferrer" target="_blank" class="flex align-items-center p-menuitem-link"
                      *ngIf="isActionVisible(item, temp.label)" (click)="setSelectedItemRow(item)">
                      <span [class]="temp.icon"></span>
                      <span class="ml-2">{{ temp.label }}</span>
                      <span *ngIf="temp.shortcut"
                        class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
                        {{ temp.shortcut }}
                      </span>
                    </a>
                  </ng-template>
                </p-menu>
              </div>
            </td>
            <td></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-dialog [style]="{ width: '50vw', height: '80vh' }" [breakpoints]="{ '1199px': '75vw', '1000px': '90vw' }"
    [(visible)]="itemDialog" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="hideDialog()"
    class="p-fluid">
    <ng-template pTemplate="header">
      @if(item.id){
      <h3>{{ item.name }}</h3>
      } @else {
      <h3>{{ 'catalog.form.header.new' | translate }}</h3>
      }
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="form" class="item-form">
        <p-tabView [(activeIndex)]="activeTabIndex">
          <p-tabPanel header="General" class="line-height-3 m-0">
            <ng-template pTemplate="header">
              <div class="flex justify-content-start mt-3">
                <p>{{ 'catalog.form.tab.general.title' | translate }}
                  <i class="pi pi-exclamation-circle" style="color: red"
                    *ngIf="activeTabIndex != 0 && (!form.valid && form.dirty)"
                    pTooltip="You have validation errors in the form."></i>
                </p>
              </div>
            </ng-template>

            <input hidden type="text" pInputText id="id" formControlName="id" name="id" />
            <input hidden type="text" pInputText id="creatorUser" formControlName="creatorUser" name="creatorUser" />

            <div class="field col-6" style="float: right">
              <item-icon-uploader [item]="item" [isDisabled]="form.disabled" (onIconUpload)="setItemIcon($event)">
              </item-icon-uploader>
            </div>

            <div class="field col-4">
              <label for="itemType">{{ 'catalog.form.fields.type.label' | translate }} <span
                  class="p-error">*</span></label>
              <p-dropdown id="type" formControlName="itemTypeId" [options]="(getTypeOptions() | async)!"
                          optionLabel="name" optionValue="key" [filter]="true" filterBy="description" [showClear]="true"
                          [ngClass]="{ 'ng-invalid ng-dirty': form.controls['itemTypeId'].dirty && !form.controls['itemTypeId'].value }"
                          placeholder="{{ 'catalog.form.fields.type.select' | translate }}" required>
              </p-dropdown>
              <small class="ng-dirty ng-invalid"
                *ngIf="form.controls['itemTypeId'].dirty && !form.controls['itemTypeId'].value ">
                {{ 'catalog.form.fields.type.validation_text' | translate }}
              </small>
            </div>

            <div class="field col-4">
              <label for="seal">{{ 'catalog.form.fields.seal.label' | translate }} </label>
              <p-dropdown id="seal" formControlName="itemSealId" [options]="sealsOptions" 
                          optionLabel="name" optionValue="id" [filter]="true" filterBy="description" [showClear]="true"
                          placeholder="{{ 'catalog.form.fields.seal.select' | translate }}">
                          <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center" *ngIf="selectedSeal">
                              <ngx-emoji [set]="emojiset" [emoji]="selectedSeal.emoji"></ngx-emoji>
                              <span class="ml-2">{{ selectedSeal.name }}</span>
                            </div>
                          </ng-template>
                          <ng-template let-item pTemplate="item">
                            <div class="flex align-items-center">
                              <ngx-emoji [set]="emojiset" [emoji]="item.emoji"></ngx-emoji>
                              <span class="ml-2">{{ item.name }}</span>
                            </div>
                          </ng-template>
              </p-dropdown>
            </div>
            @if(isSourceVisible){
            <div class="field col-4">
              <label for="itemSource">{{ 'catalog.form.fields.source.label' | translate}}
                <span class="p-error">*</span>
              </label>
              <p-dropdown id="source" formControlName="itemSourceId" [options]="(getSourceOptions() | async)!"
                          optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
                          (onChange)="onSourceChange($event)"
                          [ngClass]="{ 'ng-invalid ng-dirty': form.controls['itemSourceId'].dirty && !form.controls['itemSourceId'].value }"
                          placeholder="{{ 'catalog.form.fields.source.select' | translate }}" required>
              </p-dropdown>
              <small class="ng-dirty ng-invalid"
                *ngIf="form.controls['itemSourceId'].dirty && !form.controls['itemSourceId'].value ">
                {{ 'catalog.form.fields.source.validation_text' | translate }}
              </small>
            </div>
            }
            <div class="field col-6">
              <label for="nameType">{{ 'catalog.form.fields.name.label' | translate }} <span
                  class="p-error">*</span></label>
              <input type="text" pInputText id="name" formControlName="name" required maxlength="80"
                [ngClass]="{ 'ng-invalid ng-dirty': form.controls['name'].dirty && !form.controls['name'].value }"
                required />
              <small class="ng-dirty ng-invalid" *ngIf="form.controls['name'].dirty && !form.controls['name'].value">
                {{ 'catalog.form.fields.name.validation_text' | translate }}
              </small>
            </div>

            <div class="field col-12 md:col-6">
              <label for="owner"> {{ 'catalog.form.fields.owner.label' | translate }}</label>
              <user-dropdown [placeholder]="'components.user-group-dropdown.placeholder'"
                             [userName]="form.controls['ownerName'].value" [isDisabled]="form.disabled"
                             (selectedUser)="setOwner($event)"></user-dropdown>
            </div>

            <div class="field col-12">
              <label for="itemVersion"> {{ 'catalog.form.fields.itemVersion.label' | translate }} <span
                      class="p-error">*</span></label>
              <input type="text" id="itemVersion" pInputText formControlName="itemVersion" maxlength="50" required
                     [ngClass]="{ 'ng-invalid ng-dirty': form.controls['itemVersion'].dirty && !form.controls['itemVersion'].value }" />
              <small class="ng-dirty ng-invalid"
                     *ngIf="form.controls['itemVersion'].dirty && !form.controls['itemVersion'].value"> {{
                'catalog.form.fields.itemVersion.validation_text' | translate }}</small>
            </div>

            <div class="field col-12">
              <label for="headline"> {{ 'catalog.form.fields.headline.label' | translate }} <span
                  class="p-error">*</span></label>
              <textarea id="headline" pInputTextarea formControlName="headline" required rows="2"
                [ngClass]="{ 'ng-invalid ng-dirty': form.controls['headline'].dirty && !form.controls['headline'].value }"
                maxlength="256" cols="20" required></textarea>
              <small class="ng-dirty ng-invalid"
                *ngIf="form.controls['headline'].dirty && !form.controls['headline'].value"> {{
                'catalog.form.fields.headline.validation_text' | translate }}</small>
            </div>

            <div class="field col-12">
              <label for="description"> {{ 'catalog.form.fields.description.label' | translate }}</label>
              <textarea id="description" pInputTextarea formControlName="description" rows="10" maxlength="1024"
                cols="20">
              </textarea>
            </div>
          </p-tabPanel>

          <p-tabPanel header="Preview" class="line-height-3 m-0">
            <ng-template pTemplate="header">
              <div class="flex justify-content-start mt-3">
                <p> {{ 'catalog.form.fields.preview.label' | translate }}</p>
              </div>
            </ng-template>

            <app-image-uploader [item]="item" [isDisabled]="form.disabled"
                                (onUpload)="setPreviews($event)"></app-image-uploader>
          </p-tabPanel>

          <p-tabPanel header="Relationships" class="line-height-3 m-0">
            <ng-template pTemplate="header">
              <div class="flex justify-content-start mt-3">
                <p>{{ 'catalog.form.fields.relationships.label' | translate }}</p>
              </div>
            </ng-template>

            <app-item-terms [item]="item" [isDisabled]="form.disabled"
                            (itemTermsChanged)="onItemTermsChanged($event)"></app-item-terms>
          </p-tabPanel>

          <p-tabPanel header="Access" class="line-height-3 m-0">
            <ng-template pTemplate="header">
              <div class="flex justify-content-start mt-3">
                <p>{{ 'catalog.form.fields.access.label' | translate }}</p>
              </div>
            </ng-template>

            <div class="field col-4">
              <p-dropdown id="accessmethod" formControlName="itemAccessMethod" name="itemAccessMethod"
                [options]="itemAccessMethods" optionLabel="desc" [filter]="true" filterBy="desc" [showClear]="true"
                placeholder="{{ 'catalog.form.fields.text_address.dropdown_access_method' | translate }}">
              </p-dropdown>
            </div>

            <div class="field col-12">
              <input type="text" formControlName="itemAccessUrl" pInputText id="address" maxlength="512"
                placeholder="{{ getItemAccessUrlLabel()}}" />
              <small class="ng-dirty ng-invalid"
                *ngIf="form.controls['itemAccessUrl'].dirty && !isValidAccessUrl(form.controls['itemAccessUrl'].value)">
                {{ 'catalog.form.fields.text_address.validation_text' | translate }}
              </small>
            </div>
          </p-tabPanel>

          <p-tabPanel header="permissions" class="line-height-3 m-0">
            <ng-template pTemplate="header">
              <div class="flex justify-content-start mt-3">
                <p>{{ 'catalog.form.fields.permissions.label' | translate }}</p>
              </div>
            </ng-template>

            <app-item-permissions [item]="item" [sourceId]="form.controls['itemSourceId'].value"
                                  (itemPermissionsChanged)="onItemPermissionsChanged($event)"
                                  (dynamicRoleChanged)="onDynamicRoleChanged($event)" [isDisabled]="form.disabled">
            </app-item-permissions>

            <div *ngIf="form.controls['itemSourceId'].value === AddOnSource.PowerBI" class="mt-3 flex flex-wrap">
              <h5> {{ 'catalog.form.fields.permissions.resources.title' | translate }}</h5>

              <div class="flex w-full mt-3">
                <div formGroupName="resources">
                  <p class="font-bold flex align-items-center mb-4" style="line-height: 0">
                    <p-inputSwitch formControlName="hasFilter" />
                    <span class="ml-2">{{ 'catalog.form.fields.permissions.resources.filters' | translate }}</span>
                  </p>

                  <p class="font-bold flex align-items-center mb-4" style="line-height: 0">
                    <p-inputSwitch formControlName="hasPagination" />
                    <span class="ml-2">{{ 'catalog.form.fields.permissions.resources.pagination' | translate }}</span>
                  </p>

                  <p class="font-bold flex align-items-center mb-4" style="line-height: 0">
                    <p-inputSwitch formControlName="hasStatusBar" />
                    <span class="ml-2">{{ 'catalog.form.fields.permissions.resources.statusBar' | translate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </p-tabPanel>

          <p-tabPanel header="translatedTemplate.form_tab_additional_info_label" class="line-height-3 m-0">
            <ng-template pTemplate="header">
              <div class="flex justify-content-start mt-3">
                <p>{{ 'catalog.form.fields.additional_info.label' | translate }}</p>
              </div>
            </ng-template>
            <app-item-custom-fields [item]="item"
                                    (itemCustomFieldsChanged)="onItemCustomFieldsChanged($event)"
                                    [isDisabled]="form.disabled"></app-item-custom-fields>
          </p-tabPanel>
        </p-tabView>

        <div class="flex justify-content-end flex-wrap">
          <div class="w-auto gap-2 flex justify-end items-end mb-5">
            <button pButton pRipple label="{{ 'catalog.buttons.cancel' | translate }}" icon="pi pi-times"
              class="p-button" (click)="hideDialog()"></button>
            <button pButton pRipple label="{{ 'catalog.buttons.save' | translate }}" icon="pi pi-check" class="p-button"
              (click)="saveItem()" [disabled]="!(form.valid && form.dirty)"></button>
          </div>
        </div>
      </form>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="publishItemStatusDialog" header="{{ 'components.dialogs.header.confirm' | translate }}"
    [modal]="true" [style]="{ width: '450px' }">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span *ngIf="item" [innerHTML]="translatedTemplate.dialog_publish_item">
      </span>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'components.dialogs.no' | translate }}"
        (click)="publishItemStatusDialog = false"></button>
      <button pButton pRipple icon="pi pi-check" class="p-button-text"
              label="{{ 'components.dialogs.yes' | translate }}" (click)="confirmPublishItem()"></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="unpublishItemStatusDialog" header="{{ 'components.dialogs.header.confirm' | translate }}"
            [modal]="true" [style]="{ width: '450px' }">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span *ngIf="item" [innerHTML]="translatedTemplate.dialog_unpublish_item"></span>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'components.dialogs.no' | translate }}"
              (click)="unpublishItemStatusDialog = false"></button>
      <button pButton pRipple icon="pi pi-check" class="p-button-text"
              label="{{ 'components.dialogs.yes' | translate }}" (click)="confirmUnpublishItem()"></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="deleteItemDialog" header="{{ 'components.dialogs.header.confirm' | translate }}" [modal]="true"
    [style]="{ width: '450px' }">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span *ngIf="item" [innerHTML]="translatedTemplate.dialog_delete_item"></span>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'components.dialogs.no' | translate }}"
        (click)="deleteItemDialog = false"></button>
      <button pButton pRipple icon="pi pi-check" class="p-button-text"
              label="{{ 'components.dialogs.yes' | translate }}" (click)="confirmDelete()"></button>
    </ng-template>
  </p-dialog>
</div>