import { TranslateService } from "@ngx-translate/core";
import { AddOnSchedulerSettings } from "./addon-scheduler";
import { Capacity, CapacitySchedule } from "./capacity";
import { RoleLevelSecurity } from "./rls";

export interface InstalledAddOn {
  id: number;
  addOnId: number;
  addOn: AddOn;
  setting: AddOnSchedulerSettings;
  capacity: Capacity;
  capacitySchedules: CapacitySchedule[];
  credentials: string;
  logs: AddOnSchedulerLog[];
}

export interface AddOn {
  id: number;
  description: string;
  itemSourceId: number;
  logo?: string;
  title: string;
  linkDetail: string;
  type: number;
  authenticationFields: string;
}

export interface AddOnSchedulerLog {
  messages: AddOnSchedulerLogMessage[];
  executionDate: Date;
}

export interface AddOnSchedulerLogMessage {
  message: string;
}

export interface AddOnConnectionLog {
  workspaceName: string;
  messages: string[];
  reports: AddOnReport[];
}

export interface AddOnReport {
  name: string;
  embedUrl: string;
}

export interface InstallAddOnCommand {
  credentials: string;
  reports: AddOnReport[];
}

export interface UpdateInstalledAddOnCommand {
  sourceId: number;
  credentials: string;
  setting: AddOnSchedulerSettings;
  capacity: Capacity;
  capacitySchedules: CapacitySchedule[];
  roles: RoleLevelSecurity[];
}

export enum AddOnSource {
  Genuz = 1,
  PowerBI = 2
}

export interface Credentials {
  id: string;
  title:string;
  value:string;
}


export const translateMessage = (translator: TranslateService, message: string, iconless: boolean = false): string => {
  const pattern = /translate:{(.*)}/im;
  let matched = message.match(pattern)?.[1] as string;

  if (iconless)
    matched += '_iconless';

  const translated = translator.instant(matched);

  return message.replace(pattern, translated);
}
