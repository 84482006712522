import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { MsalService } from '@azure/msal-angular';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

function getUserId(authService: MsalService): string {
  const account = authService.instance.getActiveAccount();
  return account?.localAccountId || '';
}
export const AdminGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const authService = inject(MsalService);
  const router = inject(Router);

  return userService.getUserInformation(getUserId(authService)).pipe(
    map((user) => {
      if (user.isAdmin) {
        return true;
      } else {
        router.navigate(['/my-catalog']);
        return false;
      }
    }),
    catchError((error) => {
      console.error('Error fetching user information:', error);
      router.navigate(['/my-catalog']);
      return of(false);
    })
  );
};
