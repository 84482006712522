import { TranslateService } from "@ngx-translate/core";
import { Observable, map } from "rxjs";

export enum TermStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type GenericType = {
  key: number;
  name: string;
}

export interface Term {
  id?: number;
  name?: string;
  acronym?: string;
  type: number;
  definition?: string;
  owner?: string;
  synonymous?: string;
  examples?: string;
  origin?: string;
  gpdr: boolean;
  status: boolean;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
}

export const termsTypesTranslationPaths: string[] = [
  'entities.term.term-type.BusinessTerm',
  'entities.term.term-type.BusinessUnit',
  'entities.term.term-type.Department',
  'entities.term.term-type.SubDepartment',
  'entities.term.term-type.Dimension',
  'entities.term.term-type.Domain',
  'entities.term.term-type.SubDomain',
  'entities.term.term-type.Function',
  'entities.term.term-type.Indicator',
  'entities.term.term-type.KPI',
  'entities.term.term-type.Metric',
  'entities.term.term-type.NSM',
  'entities.term.term-type.Source',
];

export const catalogTypesTranslationPaths: string[] = [
  'entities.catalog.catalog-type.Visualization',
  'entities.catalog.catalog-type.Report',
  'entities.catalog.catalog-type.DataProduct',
  'entities.catalog.catalog-type.Dataset'
];

export const translateFromArrayAt = (arrayMap: string[], key: number, translator: TranslateService): string =>
  translator.instant(arrayMap[key - 1]);

export const translateFromArray = (arrayMap: string[], translator: TranslateService): Observable<GenericType[]> =>
  translator.get(arrayMap).pipe(
    map(translations =>
      Object.keys(translations).map((path: string, i: number) => {
        return {
          key: i + 1,
          name: translations[path]
        }
      })
    )
  );
