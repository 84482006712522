<p-sidebar [(visible)]="isOpen" position="right" (onHide)="toggleOpen($event)" (onShow)="refreshItem()" [class.loading]="loading" [baseZIndex]="1000" [modal]="true" [dismissible]="true" [closeOnEscape]="true" [showCloseIcon]="true" [appendTo]="'body'">
  <p-progressSpinner
    *ngIf="loading"
    strokeWidth="5"
    fill="transparent"
    animationDuration=".5s"
    [style]="{ width: '100px', height: '100px' }"
    class="details-loading-spinner"
    />
  <ng-template pTemplate="closeicon">
    @if(!loading){
    <i class="pi pi-arrow-right"></i>
    }
  </ng-template>
  <ng-template pTemplate="header">
    @if(item && !loading){
    <div class="details">
      <div class="header">
        <div class="icon">
          <img src="{{iconUrlImg}}" alt="" srcset="">
        </div>
        <div class="flex flex-column flex-grow-1 overflow-hidden">
          <div class="name-container">
            <h2 class="item-name-detail">{{ item.name }}</h2>
            <ngx-emoji *ngIf="item.seal?.name && item.seal?.emoji" class="item-seal-detail" [pTooltip]="item.seal.name" tooltipPosition="top" [set]="emojiset" [emoji]="item.seal.emoji"></ngx-emoji>
          </div>
          <p class="text-color-secondary">{{ getTypeLabel(item.itemType?.id) }}</p>
        </div>
        <div class="actions">
          <p-button
            *ngIf="userHasPermission || isOwner(item)"
            [outlined]="!item.favorited"
            (click)="addFavorite(item)"
            rounded
            icon="pi {{ item.favorited ? 'pi-heart-fill' : 'pi-heart' }}"
            class="favorite-button mr-2" />
          @if(showActions){
          @if(userHasPermission || isOwner(item)){
          <a *ngIf="item.itemAccessUrl"
             pButton
             rounded
             [href]="itemUrl"
             size="large"
             class="p-button"
             target="_blank"
             [label]="'components.item-card.button.open' | translate"></a>
          <a *ngIf="!item.itemAccessUrl"
             pButton
             rounded
             class="p-button p-button-disabled"
             style="opacity: 0.4;"
             size="large"
             (click)="$event.preventDefault();"
             [label]="'components.item-card.button.open' | translate"></a>
          } @else if (userPermissionPending){
          <p-button rounded [label]="'item_details.header.actions.pending' | translate" disabled
                    size="large" />
          } @else{
          <a pButton rounded [label]="'item_details.header.actions.get' | translate"
             (click)="toggleRequestForm()" size="large"></a>
          }
          }
        </div>
        <div class="subheader">
          <div>
            <p-chip label="{{item.ownerName}}" image="{{user ? user.photo : ''}}"
                    alt="Avatar image" />
          </div>
          <div class="counters flex gap-2">
            <p-chip [label]="item.itemTerms?.length?.toString() ?? '0'" icon="pi pi-book" />
            <p-chip [label]="item.itemPermissions.length.toString() || '0'" icon="pi pi-user" />
          </div>
          <div class="ratings">
            <p-rating [(ngModel)]="rating" [cancel]="false" [readonly]="true" />
            <span class="p-text-secondary text-lg ml-2">
              <b>{{ rating || 0 }} ({{ reviewsAverageRate.total }})</b>
            </span>
          </div>
        </div>
      </div>
    </div>
    }
  </ng-template>
  <div class="content">
    @if(item){
    <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex">
      <p-tabPanel [header]="'item_details.content.tab.overview' | translate">
        <div class="about mt-5">
          <h3>{{ 'item_details.details-overview-tab.about' | translate }}</h3>
          <p class="">{{ item.description }}</p>
        </div>
        <div class="gallery mt-5" *ngIf="!isItemView()">
          <div>
            <h3>{{ 'item_details.details-overview-tab.title' | translate }}</h3>
            <p-carousel
                        *ngIf="images.length > 0"
                        [value]="images"
                        [numVisible]="2"
                        [numScroll]="2"
                        [circular]="false"
                        [responsiveOptions]="responsiveOptions">
              <ng-template let-image pTemplate="item">
                <p-image
                         appendTo="body"
                         [src]="image.itemImageSrc"
                         alt="Image"
                         width="250"
                         [preview]="true" />
              </ng-template>
            </p-carousel>
          </div>
        </div>
        <div class="mt-7">
          <h3>{{ 'item_view.details.overview.curator' | translate }}</h3>
          <div class="flex align-items-center gap-4 ">
            @if (curator?.photo) {
            <img class="border-circle" [src]="curator?.photo" style="width: 40px" />
            }
            @else {
            <i class="pi pi-user"></i>
            }
            <div>
              <span class="font-medium">{{ curator?.name }}</span>
              <div class="text-sm text-color-secondary">{{ curator?.mail }}</div>
            </div>
          </div>
        </div>
        <div class="mt-7">
          <h3>{{ 'item_view.details.overview.aditional_info' | translate }}</h3>
          <div class="">
            <ul class="aditional-info">
              @if(aditionalInfo){
              @for (customField of aditionalInfo; track customField.id) {
              <li>
                <span class="font-medium">{{ customField.label }}</span>
                <div class="text-sm text-color-secondary">{{ customField.value }}</div>
              </li>
              }
              }
            </ul>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel [header]="'item_details.content.tab.relationships' | translate">
        <app-details-relationship-tab [item]="item" [tabIndex]="activeIndex"></app-details-relationship-tab>
      </p-tabPanel>
      <p-tabPanel [header]="'item_details.content.tab.reviews' | translate">
        <div class="flex flex-wrap">
          <average-rating-details [itemAverageRate]="reviewsAverageRate"></average-rating-details>

          <item-review-form
                            *ngIf="userHasPermission || isOwner(item)"
                            [item]="item"
                            [hasSentReview]="hasSentReview"
                            (onReviewSubmitted)="onReviewSubmitted()">
          </item-review-form>
        </div>

        <app-details-reviews-tab
                                 [item]="item"
                                 [reviews]="reviews"
                                 [averageRate]="reviewsAverageRate"
                                 (onReviewUpdate)="refreshItem()">
        </app-details-reviews-tab>
      </p-tabPanel>
    </p-tabView>
    }
  </div>
</p-sidebar>
@if(item){
<app-request-access-form
  [isRequest]="true"
  [(isOpen)]="openRequestForm"
  [item]="item"
  [requester]="item.ownerName"
  appendTo="body"
  (requestSent)="checkUserPermissions($event)"></app-request-access-form>
}
