import { Routes } from '@angular/router';
import { AppLayoutComponent } from './components/app-layout/app.layout.component';
import { HomeComponent } from './pages/home/home.component';
import { GlossaryComponent } from './pages/glossary/glossary.component';
import { MsalGuard } from '@azure/msal-angular';
import { FailedComponent } from './pages/failed/failed.component';
import { CatalogComponent } from './pages/catalog/catalog.component';
import { ItemViewComponent } from './pages/item-view/item-view.component';
import { StoreComponent } from './pages/store/store.component';
import { MyCatalogComponent } from './pages/my-catalog/my-catalog.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ItemDetailsComponent } from './pages/item-details/item-details.component';
import { AdminGuard } from './guards/admin-guard.guard';
import { ItemRequestsComponent } from './pages/item-requests/item-requests.component';
import { ItemSearchComponent } from './pages/item-search/item-search.component';
import { AddonComponent } from './pages/addons/addons.component';
import { AddonSettingsComponent } from './pages/addons/addon-settings/addon-settings.component';
import { AddonListComponent } from './pages/addons/addon-list/addon-list.component';
import { UserAgreementComponent } from './pages/user-agreement/user-agreement.component';
import { GeneralSettingsComponent } from './pages/general-settings/general-settings.component';
import { GeneralFeedbackGuard } from './guards/general-feedback-guard.guard';
import { SealsComponent } from './pages/seals/seals.component';
import { UsersGroupsComponent } from './pages/users-groups/users-groups.component';

export const routes: Routes = [
  {
    path: 'user-agreement',
    component: UserAgreementComponent
    // canActivate: [AgreementGuard],
  },
  {
    path: 'item-view',
    component: ItemViewComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: LandingComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'dashboard',
        component: HomeComponent,
        // canActivate: [AdminGuard, AgreementGuard, GeneralFeedbackGuard],
        canActivate: [MsalGuard],
      },
      {
        path: 'glossary',
        component: GlossaryComponent,
        // canActivate: [AdminGuard,AgreementGuard, GeneralFeedbackGuard],
        canActivate: [MsalGuard, AdminGuard],
      },
      {
        path: 'login-failed',
        component: FailedComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'catalog',
        component: CatalogComponent,
        // canActivate: [AdminGuard,AgreementGuard, GeneralFeedbackGuard],
        canActivate: [MsalGuard, AdminGuard],
      },
      {
        path: 'users-groups',
        component: UsersGroupsComponent,
        //canActivate: [AgreementGuard, GeneralFeedbackGuard],
        canActivate: [MsalGuard],
      },
      {
        path: 'addons',
        component: AddonComponent,
        // canActivate: [AdminGuard,AgreementGuard, GeneralFeedbackGuard],
        canActivate: [MsalGuard, AdminGuard],
        children: [
          { path: '', component: AddonListComponent, canActivate: [MsalGuard] },
          {
            path: 'settings',
            component: AddonSettingsComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: 'store',
        component: StoreComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'my-catalog',
        component: MyCatalogComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'item-details',
        component: ItemDetailsComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'store',
        component: ItemDetailsComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'item-requests',
        component: ItemRequestsComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'search',
        component: ItemSearchComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard]
        canActivate: [MsalGuard],
      },
      {
        path: 'general-settings',
        component: GeneralSettingsComponent,
        // canActivate: [AgreementGuard, GeneralFeedbackGuard],
        canActivate: [MsalGuard],
      },
      {
        path: 'seals',
        component: SealsComponent,
        //canActivate: [AgreementGuard, GeneralFeedbackGuard],
        canActivate: [MsalGuard],
      },
    ],
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    redirectTo: '/my-catalog',
    pathMatch: 'prefix',
  },
];
