import { NgClass } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { LayoutService } from '../../service/app.layout.service';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { User } from '../../api/user';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { ManageProfileComponent } from '../manage-profile/manage-profile.component';
import { ItemPaginator } from '../../api/item-paginator';
import { TooltipModule } from 'primeng/tooltip';
import { ItemStatusEnum } from '../../api/item';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  standalone: true,
  styleUrl: './app.topbar.component.scss',
  providers: [UserService],
  imports: [
    RouterLink,
    NgClass,
    ToolbarModule,
    TooltipModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    TranslateModule,
    FormsModule,
    DialogModule,
    CardModule,
    OverlayPanelModule,
    DividerModule,
    RouterModule,
    ManageProfileComponent,
    ReactiveFormsModule
  ],
})
export class AppTopBarComponent {
  languages: any[] = [];
  selectedLanguage: any;

  translatedTemplate: any = {
    change_language: '',
  };

  languageDialog: boolean = false;
  languageDialogPosition: string = 'right';

  manageProfileBar: boolean = false;

  user: User | undefined;

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  searchControl = new FormControl('', [Validators.minLength(3), Validators.maxLength(80)]);

  constructor(
    public layoutService: LayoutService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (typeof window !== 'undefined')
      this.selectedLanguage = localStorage.getItem('language');

    this.translate.get('languages.options.en').subscribe((res: string) => {
      this.languages.push({
        label: res,
        code: 'en',
      });
    });

    this.translate.get('languages.options.pt').subscribe((res: string) => {
      this.languages.push({
        label: res,
        code: 'pt',
      });
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params)
        this.searchControl.setValue(params.text);
    });

    this.setUserInfo();
  }

  get searchQuery(): string {
    return this.activatedRoute.snapshot.queryParams['text'] ?? '';
  }

  setUserInfo(): void {
    this.userService.getUserInformation(this.getUserId()).subscribe((user) => {
      if (!user) return;
      this.user = user;

      this.userService.getPhoto().subscribe((photo) => {
        if (this.user) this.user.photo = photo;
      });
    });
  }

  logout(): void {
    this.authService.logout();
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  changeSystemLanguage(event: DropdownChangeEvent) {
    if (typeof window !== 'undefined')
      localStorage.setItem('language', event.value);
    this.translate.use(event.value);
    this.userService
      .updateLanguagePreference(this.getUserId(), event.value)
      .subscribe((user) => {
        if (this.user) this.user.setting.language = user.setting.language;
      });
  }

  adminButtonClick() {
    this.router.navigate(['/dashboard']);
  }

  redirectToSearch(event: any): void {
    if (this.searchControl?.errors)
      return;

    const paginator: ItemPaginator = {
      text: event.target.value.trim(),
      currentPage: 1,
      numberOfRecords: 15,
      itemStatusId: ItemStatusEnum.PUBLISHED
    };

    if (paginator.text)
      this.router.navigate(['/search'], { queryParams: paginator });
  }

  getUserId(): string {
    const account = this.authService.instance.getActiveAccount();
    return account?.localAccountId || '';
  }

  showDialogLanguage(position: string) {
    this.languageDialogPosition = position;
    this.languageDialog = true;
  }

  showManageProfile() {
    this.manageProfileBar = true;
  }

  closeManageProfile() {
    this.manageProfileBar = false;
  }

  toFullLocale(shorthand?: string): string {
    if (!shorthand) return '';

    return (
      {
        en: this.translate.instant('languages.options.en'),
        pt: this.translate.instant('languages.options.pt'),
      }[shorthand] || shorthand
    );
  }

  toggleUserCard(op: any, event: any): void {
    op.toggle(event);
  }
}
