import { CommonModule, NgClass } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { LayoutService } from '../../service/app.layout.service';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { User } from '../../api/user';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { ManageProfileComponent } from '../manage-profile/manage-profile.component';
import { ItemPaginator } from '../../api/item-paginator';
import { TooltipModule } from 'primeng/tooltip';
import { ItemStatusEnum } from '../../api/item';
import { UserNotification, UserNotificationStatus } from '../../api/user-notification';
import { UserNotificationsService } from '../../service/user-notifications.service';
import moment from 'moment/min/moment-with-locales';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  standalone: true,
  styleUrl: './app.topbar.component.scss',
  providers: [UserService],
  imports: [
    RouterLink,
    NgClass,
    ToolbarModule,
    TooltipModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    TranslateModule,
    FormsModule,
    DialogModule,
    CardModule,
    OverlayPanelModule,
    DividerModule,
    RouterModule,
    ManageProfileComponent,
    ReactiveFormsModule,
    CommonModule,
    SidebarModule
  ],
})
export class AppTopBarComponent {
  languages: any[] = [];
  selectedLanguage: any;

  translatedTemplate: any = {
    change_language: '',
  };

  languageDialog: boolean = false;
  languageDialogPosition: string = 'right';

  manageProfileBar: boolean = false;
  isNotificationSidebarVisible: boolean = false;

  notificationCount: number = 0;
  notifications: UserNotification[] = [];

  user: User | undefined;

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  @ViewChild('opNotification') notificationPanel!: ElementRef;

  searchControl = new FormControl('', [Validators.minLength(3), Validators.maxLength(80)]);

  constructor(
    public layoutService: LayoutService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userNotificationsService: UserNotificationsService
  ) { }

  ngOnInit(): void {
    if (typeof window !== 'undefined')
      this.selectedLanguage = localStorage.getItem('language');

    moment.locale(this.selectedLanguage);

    this.translate.onLangChange.subscribe(() => {
      this.selectedLanguage = localStorage.getItem('language');

      moment.locale(this.selectedLanguage);
      this.getUserNotifications();
    });

    this.translate.get('languages.options.en').subscribe((res: string) => {
      this.languages.push({
        label: res,
        code: 'en',
      });
    });

    this.translate.get('languages.options.pt').subscribe((res: string) => {
      this.languages.push({
        label: res,
        code: 'pt',
      });
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params)
        this.searchControl.setValue(params.text);
    });

    this.setUserInfo();
    this.getUserNotifications();
  }

  getUserNotifications(): void {
    this.userNotificationsService.getAll().subscribe(result => {
      this.notifications = result;
      this.notificationCount = result.filter(notification => notification.userNotificationStatusId != UserNotificationStatus.Read).length;

      this.notifications = this.notifications.map(notification => {
        return {
          ...notification,
          relativeDate: moment(notification.createdAt).fromNow()
        }
      });
    });
  }

  getNotificationStatusClass(notification: UserNotification): string {
    return notification.userNotificationStatusId != UserNotificationStatus.Read ? 'new' : '';
  }

  getNotificationsCount(): string {
    return this.notificationCount > 999 ? '999' : `${this.notificationCount}`;
  }

  getBadgeClass(): string {
    const countLength = this.notificationCount.toString().length;

    if (countLength === 1) return 'small';
    if (countLength === 2) return 'medium';
    return 'large';
  }

  deleteAllNotifications() {
    this.userNotificationsService.deleteAllNotifications().subscribe({
      next: (response) => {
        this.notifications = [];
      },
      error: (error) => {
        console.error('Error while deleting all notifications:', error);
      },
    });
  }

  deleteNotification(notificationToDelete: UserNotification) {
    this.userNotificationsService.deleteNotification(notificationToDelete.id).subscribe({
      next: (response) => {
        this.notifications = this.notifications.filter(
          (notification) => notification.id !== notificationToDelete.id
        );
      },
      error: (error) => {
        console.error('Error while deleting all notifications:', error);
      },
    });
  }

  get searchQuery(): string {
    return this.activatedRoute.snapshot.queryParams['text'] ?? '';
  }

  setUserInfo(): void {
    this.userService.getUserInformation(this.getUserId()).subscribe((user) => {
      if (!user) return;
      this.user = user;

      this.userService.getPhoto().subscribe((photo) => {
        if (this.user) this.user.photo = photo;
      });
    });
  }

  logout(): void {
    this.authService.logout();
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  changeSystemLanguage(event: DropdownChangeEvent) {
    if (typeof window !== 'undefined')
      localStorage.setItem('language', event.value);
    this.translate.use(event.value);
    this.userService
      .updateLanguagePreference(this.getUserId(), event.value)
      .subscribe((user) => {
        if (this.user) this.user.setting.language = user.setting.language;
      });
  }

  adminButtonClick() {
    this.router.navigate(['/dashboard']);
  }

  docButtonClick() {
    const language = this.selectedLanguage;
    const url = language === 'pt' ? 'https://docs.genuz.io/portugues' : 'https://docs.genuz.io/english';
    window.open(url, '_blank');
  }

  redirectToSearch(event: any): void {
    if (this.searchControl?.errors)
      return;

    const paginator: ItemPaginator = {
      text: event.target.value.trim(),
      currentPage: 1,
      numberOfRecords: 15,
      itemStatusId: ItemStatusEnum.PUBLISHED
    };

    if (paginator.text)
      this.router.navigate(['/search'], { queryParams: paginator });
  }

  getUserId(): string {
    const account = this.authService.instance.getActiveAccount();
    return account?.localAccountId || '';
  }

  showDialogLanguage(position: string) {
    this.languageDialogPosition = position;
    this.languageDialog = true;
  }

  showManageProfile() {
    this.manageProfileBar = true;
  }

  closeManageProfile() {
    this.manageProfileBar = false;
  }

  toFullLocale(shorthand?: string): string {
    if (!shorthand) return '';

    return (
      {
        en: this.translate.instant('languages.options.en'),
        pt: this.translate.instant('languages.options.pt'),
      }[shorthand] || shorthand
    );
  }

  toggleUserCard(op: any, event: any): void {
    op.toggle(event);
  }

  toggleNotificationsSidebar(): void {
    this.isNotificationSidebarVisible = true;

    if (this.notificationCount > 0)
      this.readAllNotifications();
  }

  setAllNotificationsToRead(): void {
    this.notifications.forEach(notification =>
      notification.userNotificationStatusId = UserNotificationStatus.Read);
  }

  readAllNotifications(): void {
    this.userNotificationsService.readAllNotifications().subscribe({
      next: () => {
        this.notificationCount = 0;
      },
      error: (error) => {
        console.error('Error while reading all notifications:', error);
      },
    });
  }
}
