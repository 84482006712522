import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Capacity, CapacityType } from "../api/capacity";

@Injectable({
  providedIn: 'root',
})
export class CapacityService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getCapacity(id: string, sourceId: number, capacityType: CapacityType, subscriptionId: string, resourceGroupName: string): Observable<Capacity> {
    return this.httpClient.get<any>(`${this.baseRoute}addons/installed/capacity/${id}`, {
      params: { sourceId, capacityType, subscriptionId, resourceGroupName }
    });
  }
}
