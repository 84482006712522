import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../environments/environments';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (typeof window !== 'undefined') {

      return from(
        this.msalService.instance.acquireTokenSilent(
          { scopes: [...environment.apiConfig.scopes] }
        )
      ).pipe(switchMap((tokenResponse) => {
        if (req.url.startsWith(environment.genuzApiEndpoint)) {
          const cloned = req.clone({
            headers: req.headers.set(
              'Authorization',
              `Bearer ${tokenResponse.accessToken}`
            ),
          })
          return next.handle(cloned);
        }
        return next.handle(req);
      }));
    } else {
      return of();
    }
  }
}
