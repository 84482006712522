import { Component, EventEmitter, Output, ErrorHandler, Inject, ChangeDetectorRef } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AgreementsService } from '../../service/agreements.service';
import { Agreement, UserAgreement } from '../../api/agreement';
import { MessageService } from 'primeng/api';
import { GlobalErrorHandler } from '../../utils/components/error-handler/error-handler.component';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../../service/user.service';
import { User } from '../../api/user';
import { NgFor, NgIf } from '@angular/common';
import { DividerModule } from 'primeng/divider';

type TranslatedTemplate = {
  pageReport: string;
};

@Component({
  selector: 'app-user-agreement',
  standalone: true,
  providers: [
    MessageService,
    {
      provide: ErrorHandler
    }
  ],
  imports: [
    CheckboxModule,
    ButtonModule,
    TooltipModule,
    NgFor,
    NgIf,
    DividerModule,
    TranslateModule
  ],
  templateUrl: './user-agreement.component.html',
  styleUrl: './user-agreement.component.scss'
})
export class UserAgreementComponent implements CanActivate{

  @Output() acceptChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  user: User = {} as User;
  userAgreement: UserAgreement = {} as UserAgreement;
  agreement?: Agreement;
  acceptanceText: string = "I have read and agree to these terms and policies.";
  translatedTemplate: TranslatedTemplate = {} as TranslatedTemplate;

  constructor(
    @Inject(ErrorHandler) private errorHandler: GlobalErrorHandler,
    private route: ActivatedRoute,
    private router:Router,
    private agreementsService: AgreementsService,
    private authService: MsalService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  canActivate(): boolean {
      const account = this.authService.instance.getActiveAccount()
      let userId = account?.localAccountId || '';

      this.userService.getUserInformation(userId).subscribe({
        next: (result) => {
          this.user = result;
        },
      });

      return this.user.agreementStatus || false;
  }

  isAccepted: boolean = false;
  termsScrolled: boolean = false;
  userAgreementContent: any = []
  currentlanguage: any = 'en'

  ngOnInit() {
    this.agreementsService.getActiveAgreement().subscribe({
      next: agreement => {
        this.agreement = agreement;
        this.userAgreement = {
          agreementId: agreement.id,
          agreementName: agreement.name,
          agreementContent: 'test',
          agreementAcceptance: this.acceptanceText,
          agreementDate: new Date().toISOString(),
        };

        this.currentlanguage =  localStorage.getItem('language');
        this.agreementsService.getAgreementsData(agreement.url)
          .subscribe({
            next: result => {
              this.userAgreementContent = JSON.parse(result);
            },
          });
        },
        error: (e) => this.errorHandler.handleError(e)
      });
    }

    initTranslations(): void {
      this.translateService
        .get('my_catalog.dataview.page_report')
        .subscribe((res: string) => (this.translatedTemplate.pageReport = res));
    }

    ngAfterViewInit(): void {
    const termsElement = document.getElementById('terms');
    if (termsElement) {
      const hasScroll = termsElement.scrollHeight > termsElement.clientHeight;

      if (!hasScroll) {
        this.termsScrolled = true;
      }
    }
  }

  onScroll(event: any): void {
    const element = event.target;
    const hasScroll = element.scrollHeight > element.clientHeight;

    if (hasScroll) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.termsScrolled = true;
      }
    }
  }

  onAcceptChange(event: any): void {
    this.isAccepted = event.checked;
  }

  acceptProcess():void {
    this.agreementsService.acceptAgreement(this.userAgreement).subscribe({
      next: result => {
        this.router.navigate(['/my-catalog']);
      },
      error: (e) => this.errorHandler.handleError(e)
    });
  }
}
