<div class="flex flex-column align-items-center justify-content-center min-h-screen">

  <h3 class="mb-5 text-justify">
    <span class="font-semibold"> {{ 'user_agreements.title' | translate }} </span>
  </h3>

  <div id="terms" *ngIf="userAgreementContent?.license?.[currentlanguage].sections"
    class="m-1 p-3 w-6 h-20rem border-2 border-solid border-300 border-round" style="height: 200px; overflow-y: scroll;"
    (scroll)="onScroll($event)">

    <div *ngFor="let itens of userAgreementContent?.license?.[currentlanguage].sections">
      <p class="text-lg">
        <strong>
          {{itens.title}}
        </strong>
      </p>
      <p class="mb-2">
        {{itens.content}}
      </p>
      <p-divider />
    </div>
  </div>

  <div class="w-6 mt-3">
    <p-checkbox
    (onChange)="onAcceptChange($event)"
    binary="true"
    inputId="isAccepted"
    [disabled]="!termsScrolled"
    pTooltip="{{ 'user_agreements.tooltip_mensage' | translate }}"
      tooltipPosition="top"
      label="{{ 'user_agreements.i_read_and_agree' | translate }}"
      tooltipPosition="bottom">
    </p-checkbox>

    <p-button
      (click)="acceptProcess()"
      class="flex justify-content-end flex-wrap"
      label="{{ 'user_agreements.accept' | translate }}"
      [disabled]="!isAccepted">
    </p-button>
  </div>

</div>
