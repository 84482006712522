import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Renderer2,
  SecurityContext,
  SimpleChanges
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Directive({
  standalone: true,
  selector: "[highlight]"
})
export class HighlightDirective implements OnChanges {
  @Input("highlight") searchTerm!: string;
  @Input("content") content?: string = '';
  @Input() caseSensitive = false;
  @Input() customClasses = "";

  constructor(
    private el: ElementRef,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.el?.nativeElement) {
      if ("searchTerm" in changes || "caseSensitive" in changes) {
        if (!this.searchTerm)
          return;

        const regex = new RegExp(
          this.searchTerm,
          this.caseSensitive ? "g" : "gi"
        );

        const newText = this.content?.replace(regex, (match: string) => {
          return `<mark class="highlight ${this.customClasses}">${match}</mark>`;
        });

        const sanitzed = this.sanitizer.sanitize(
          SecurityContext.HTML,
          newText || ''
        );

        this.el.nativeElement.innerHTML = sanitzed;
      }
    }
  }
}
